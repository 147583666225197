/* disable-eslint react-hooks/exhaustive-deps */
import {
  DocumentViewerProps, errorMessage,
  findRecursive,
  getDimensionObject, getIsExpire,
  reactIntlDirectMessage,
  toast
} from "../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactNode, SetStateAction, useCallback, useEffect, useRef, useState} from "react";
// import { IAuthState } from "../../app/modules/auth";
import {useDispatch, useSelector} from "react-redux";
import {FetchProps, UserModelNashTa, UserProps} from "../types";
import {AxiosResponse} from "axios";
import moment from "moment";
import {FcmTokenAction} from "../redux/action";
import {ApiDeleteProps, ApiGetProps, ApiPatchProps, ApiPostProps} from "../apis";
import {useAuth} from "../../app/modules/auth";

export const usePrivateComponent = (path: string): boolean => {
  const {menuRecursive, currentUser} = useAuth()
  const find = findRecursive(menuRecursive, (e: any) => e.menu_path === path, 'children')
  return !!(find && find.roles?.includes(currentUser?.role_id || 0));
}
export const useParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [params, updateParams] = useState(Object.fromEntries(searchParams))

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    updateParams(Object.fromEntries(searchParams));
  }, [location]);

  const setParams = (data: Record<string, any>) => {
    for (let key in data) {
      searchParams.set(key, data[key]);
    }
    navigate(
        '',
        {
          state: {
            search: searchParams.toString()
          }
        }
    )
  };

  return {
    params,
    setParams
  };
};

export function useDimensions(data: any = null, liveMeasure = true) {
  const [dimensions, setDimensions] = useState({}) as any;
  const [node, setNode] = useState(null);

  const ref = useCallback((node: SetStateAction<null>) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, data]);

  return [ref, dimensions, node];

  //EXAMPLE
  // const MyComponent = ({ data }: any) => {
  //   const [
  //     ref,
  //     { height, width, top, left, x, y, right, bottom }
  //   ] = useDimensions(data)
  //
  //   console.log();
  //
  //   return (
  //     <div ref={ref}>
  //       <pre>{JSON.stringify({ height, width, top, left, x, y, right, bottom }, null, 3)}</pre>
  //       </div>
  //   );
  // };
}
export function useDocumentViewer() {
  const navigate = useNavigate()
  return (props: DocumentViewerProps) => {
    navigate('/document-viewer', {
      state: {data: props}
    })
  }
}

export const useUser = () => {
  const a = useAuth()
  const [user, setUser] = useState<UserProps>({
    accessToken: "",
    emp_info_data: undefined,
    employee: undefined,
    isExpire: false,
    listMenuFlat: [],
    listMenuRecursive: [],
    refreshToken: ""
  });
  const {auth, menuRecursive: listMenuRecursive, menuFlat: listMenuFlat, currentUser: emp_info_data} = a
  const {
    email,
    alias,
      user_id,
      emp_name,
      role_id,
      role_name,
      hr_job_position_id: position_id,
      hr_job_position_job_position_name: position_name,
      business_unit_id,
      business_unit_name,
      hr_organization_id: organization_id,
      hr_organization_name: organization_name,
      hr_emp_info_id,
      approval_name,
      initialName,
  } = emp_info_data as UserModelNashTa

  useEffect(() => {
    setUser({
      isExpire: !!auth?.accessToken,
      accessToken: auth?.accessToken,
      refreshToken: auth?.refreshToken,
      employee: {
        email,
        alias,
        id: user_id,
        name: emp_name,
        role_id,
        role_name,
        position_id,
        position_name,
        business_unit_id,
        business_unit_name,
        organization_id,
        organization_name,
        user_id,
        hr_emp_info_id,
        approval_name,
        initialName,
      },
      emp_info_data,
      listMenuFlat,
      listMenuRecursive
    } as any)
  }, [a]);


  return user
}
export const usePagination = (props: {initialPage?: number, initialSize?: number, prefix?: string} = {}) => {
  const {initialPage = 1, initialSize = 10, prefix = ''} = props
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const prfPage = prefix + "page"
  const prfSize = prefix + "size"
  const [page, updatePage] = useState(Number(searchParams.get(prfPage)) || initialPage);
  const [size, updateSize] = useState(Number(searchParams.get(prfSize)) || initialSize);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    updatePage(Number(searchParams.get(prfPage)) || page);
    updateSize(Number(searchParams.get(prfSize)) || size);
  }, [location, page, size, prfPage, prfSize]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [loading]);


  const setPage = (toPage: any) => {
    setLoading(true)
    searchParams.set(prfPage, toPage || initialPage);
    navigate('?' + searchParams.toString(), {
      state: { search: searchParams.toString() },
      replace: true
    })
  };

  const setSize = (toRows: any) => {
    setLoading(true)
    searchParams.set(prfSize, toRows || initialSize);
    navigate('?' + searchParams.toString(), {
      state: { search: searchParams.toString() },
      replace: true
    })
  };

  return {
    loading,
    page,
    size,
    setPage,
    setSize
  };
};
let fetchData = {}
const getInitialFetchDataList = (props: any): any[] => {
  // @ts-ignore
  const response = fetchData[props.saveDataWithName]
  let list = []
  if (response) {
    // @ts-ignore
    const rawDataList = response?.data?.data || response?.data
    if (Array.isArray(rawDataList)) {
      // @ts-ignore
      const v = props.listValueAndLabel?.valueKey
      const l1 = props.listValueAndLabel?.labelKey1
      const l2 = props.listValueAndLabel?.labelKey2
      const custom = props.listValueAndLabel?.custom
      list = (v ? rawDataList.map((el, index) => {
        return {
          ...el,
          // @ts-ignore
          value: el[v],
          // @ts-ignore
          label: custom ? custom(el, index, rawDataList) :`${el[l1]}${l2 ? ' - ' + el[l2] : ''}`,
        }
      }) : rawDataList)
    }
  }
  return list
}
export function useFetch<P>(props: FetchProps<P>) {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState<AxiosResponse>()
  const [list, setList] = useState<any[]>(getInitialFetchDataList(props))
  const fetch = useCallback((dataOrParams: any = props.initialParamOrDataForFetch) => new Promise(async (resolve, reject) => {
    setIsLoading(true)
    try {
      const response = await props.apiFunction(dataOrParams)
      setError(null)
      setIsError(false)
      // @ts-ignore
      setResponse(response)
      if (props.saveDataWithName) {
        fetchData = {
          ...fetchData,
          [props.saveDataWithName]: response
        }
      }
      if (props.showToastWhenSuccess) {
        // @ts-ignore
        toast.success(reactIntlDirectMessage('MESSAGE.SUCCESS'))
      }
      resolve(response)
    } catch (e) {
      // @ts-ignore
      setError(e)
      setIsError(true)
      if (props.showToastWhenFailed === undefined || props.showToastWhenFailed) {
        // @ts-ignore
        if (!getIsExpire()) {
          // @ts-ignore
          toast.danger(errorMessage(e))
        }
      }
      reject(e)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])
  useEffect(() => {
    if (props.fetchOnMounted) {
      fetch(props.initialParamOrDataForFetch).then().catch(() => {})
    }

    // @ts-ignore
    if (props.saveDataWithName && !!fetchData[props.saveDataWithName]) {
      // @ts-ignore
      setResponse(fetchData[props.saveDataWithName])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (response) {
      // @ts-ignore
      const rawDataList = response?.data?.data || response?.data
      if (Array.isArray(rawDataList)) {
        // @ts-ignore
        const v = props.listValueAndLabel?.valueKey
        const l1 = props.listValueAndLabel?.labelKey1
        const l2 = props.listValueAndLabel?.labelKey2
        const custom = props.listValueAndLabel?.custom
        setList(v ? rawDataList.map((el, index) => {
          return {
            ...el,
            // @ts-ignore
            value: el[v],
            // @ts-ignore
            label: custom ? custom(el, index, rawDataList) :`${el[l1]}${l2 ? ' - ' + el[l2] : ''}`,
          }
        }) : rawDataList)
      }
    }
    // eslint-disable-next-line
  }, [props.listValueAndLabel?.labelKey1, props.listValueAndLabel?.labelKey2, props.listValueAndLabel?.valueKey, response])

  const filterListData = (data : any = {}) => {
    return list.filter(el => {
      const holder = []
      for (const key in data) {
        holder.push(data[key] === el[key])
      }
      return !holder.includes(false)
    })
  }
  const reset = () => {
    setList([])
    setIsLoading(false)
    setIsError(false)
    // @ts-ignore
    setResponse(null)
    setError(null)
  }
  return {
    isError,
    isLoading,
    error,
    response,
    list,
    fetch,
    filterListData,
    reset
  }
}

export function useFetch2<P>(props: FetchProps<P>) {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState<AxiosResponse>()
  const [list, setList] = useState<any[]>(getInitialFetchDataList(props))
  const fetch = useCallback((
      dataOrParams:
          | ApiGetProps
          | ApiPostProps
          | ApiDeleteProps
          | ApiPatchProps = props.initialParamOrDataForFetch,
  ) => new Promise(async (resolve, reject) => {
    setIsLoading(true)
    try {
      const response = await props.apiFunction(dataOrParams)
      setError(null)
      setIsError(false)
      // @ts-ignore
      setResponse(response)
      if (props.saveDataWithName) {
        fetchData = {
          ...fetchData,
          [props.saveDataWithName]: response
        }
      }
      if (props.showToastWhenSuccess) {
        // @ts-ignore
        toast.success(reactIntlDirectMessage('MESSAGE.SUCCESS'))
      }
      resolve(response)
    } catch (e) {
      // @ts-ignore
      setError(e)
      setIsError(true)
      if (props.showToastWhenFailed === undefined || props.showToastWhenFailed) {
        // @ts-ignore
        if (!getIsExpire()) {
          // @ts-ignore
          toast.danger(errorMessage(e))
        }
      }
      reject(e)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])
  useEffect(() => {
    if (props.fetchOnMounted) {
      fetch(props.initialParamOrDataForFetch).then().catch(() => {})
    }

    // @ts-ignore
    if (props.saveDataWithName && !!fetchData[props.saveDataWithName]) {
      // @ts-ignore
      setResponse(fetchData[props.saveDataWithName])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (response) {
      // @ts-ignore
      const rawDataList = response?.data?.data || response?.data
      if (Array.isArray(rawDataList)) {
        // @ts-ignore
        const v = props.listValueAndLabel?.valueKey
        const l1 = props.listValueAndLabel?.labelKey1
        const l2 = props.listValueAndLabel?.labelKey2
        const custom = props.listValueAndLabel?.custom
        setList(v ? rawDataList.map((el, index) => {
          return {
            ...el,
            // @ts-ignore
            value: el[v],
            // @ts-ignore
            label: custom ? custom(el, index, rawDataList) :`${el[l1]}${l2 ? ' - ' + el[l2] : ''}`,
          }
        }) : rawDataList)
      }
    }
    // eslint-disable-next-line
  }, [props.listValueAndLabel?.labelKey1, props.listValueAndLabel?.labelKey2, props.listValueAndLabel?.valueKey, response])

  const filterListData = (data : any = {}) => {
    return list.filter(el => {
      const holder = []
      for (const key in data) {
        holder.push(data[key] === el[key])
      }
      return !holder.includes(false)
    })
  }
  const reset = () => {
    setList([])
    setIsLoading(false)
    setIsError(false)
    // @ts-ignore
    setResponse(null)
    setError(null)
  }
  return {
    isError,
    isLoading,
    error,
    response,
    list,
    fetch,
    filterListData,
    reset
  }
}
export function useRatePlan() {
  const [rate, setRate] = useState<any[]>([])
  const getRate = async () => {
    try {
      const cur_date = moment().format('DDMMYYYY')
      const rate_local = JSON.parse(localStorage.getItem('rate_plan') || '[]')
      if (rate_local.length === 0 || (rate_local.length > 0 && rate_local[0].cur_date !== cur_date)) {
        const resp = await fetch('https://api.currencyapi.com/v3/latest?apikey=cc71cba0-5736-11ec-ae10-19168b819e95')
        const json = await resp.json()
        const data = json?.data || {}
        const fixData = Object.keys(data).map(el => {
          return {
            rate: data[el],
            label: `${data[el].toLocaleString()} - ${el}`,
            value: el,
            cur_date
          }
        })
        localStorage.setItem('rate_plan', JSON.stringify(fixData))
        setRate(fixData)
      } else {
        setRate(rate_local)
      }
    } catch (e) {
      setRate([])
    }
  }
  useEffect(() => {
    getRate()
  }, [])
  return rate
}
export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}
export const useDidMount = () => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const useChooseModal = (props: {
  title?: string | ReactNode
  columns?: any[]
  tableScrollX?: number
  tablePageSize?: number
  component: (ReactNode | ((props: any) => JSX.Element))
  isMultiple?: boolean
  hideRowForId?: any[],
  formik?: any,
  getAllBusinessUnit?: any,
  getAllOrganization?: any,
  getAllParamGender?: any,
  onSubmitFilterEmployee?: any,
  filterEmpNatura?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState() as any
  const [columns, setColumns] = useState(props.columns)
  const [dataSource, setDataSource] = useState([]) as any

  const onToggle = (v: boolean) => {
    setVisible(v)
  }
  return {
    ...{
      ...props,
      component: undefined,
      columns: undefined,
    },
    Component: props.component,
    hide: () => onToggle(false),
    show: () => onToggle(true),
    selected,
    setSelected,
    visible,
    setVisible,
    columns,
    setColumns,
    dataSource,
    setDataSource,
    isLoading,
    setIsLoading,
  }
}
export const useFcmToken = () => {
  const dispatch = useDispatch()
  const fcmTokenHolder = useSelector((state: any) => state.fcmToken)
  const [fcmToken, setFcmToken] = useState(null) as any;
  useEffect(() => {
    if (fcmTokenHolder) {
      setFcmToken(fcmTokenHolder)
    }
  }, [fcmTokenHolder]);

  const updateFcmToken = (value: string) => {
    dispatch(FcmTokenAction(value))
  }

  return {fcmToken, updateFcmToken}
}
