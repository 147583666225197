import React, {useState} from 'react'
import Upload from 'rc-upload'
import {fakePromise} from '../../utils'
import {MetroButton} from '../MetroButton'
import {getAuth} from "../../../app/modules/auth";

const {REACT_APP_API_HEADER_KEY, REACT_APP_API_KEY, REACT_APP_PROD_SITE, NODE_ENV}: any = process.env
const MetroXlsImport = (props: {
  onSuccess?: (response: any, file: any) => void
  action?: any
  onError?: (error: any) => void
  isDisabled?: any
}) => {
  const [loading, setLoading] = useState(false)
  const [imported, setImported] = useState(false)
  const [selectedFile, setSelectedFile]: any = useState(false)

  const headersDev = {
    Authorization: `Bearer ${getAuth()?.accessToken}`,
  }

  const headersProd = {
    Authorization: `Bearer ${getAuth()?.accessToken}`,
    [REACT_APP_API_HEADER_KEY]: REACT_APP_API_KEY
  }
  const aa = {
    headers: NODE_ENV === 'production' && window.location.host === REACT_APP_PROD_SITE ? headersProd : headersDev,
    onStart(file: any) {
      setLoading(true)
    },
    onSuccess: async (res: any, file: any) => {
      await fakePromise(500)
      setSelectedFile(file)
      setLoading(false)
      setImported(true)
      props.onSuccess?.(res, file)
    },
    onError(err: any) {
      setLoading(false)
    },
  }

  const clearImported = () => {
    setImported(false)
    setSelectedFile(false)
  }
  // @ts-ignore
  return (<Upload disabled={props.isDisabled || imported} {...{...props, ...aa}}>
    <MetroButton
      loading={loading}
      disabled={props.isDisabled || imported}
      size={'small'}>
      <i className={`fad ${imported ? 'fa-check' : 'fa-upload'} `} />
      {imported ? 'imported' : 'Import'}
    </MetroButton>
    {imported ?
      <span className={'text-success d-block px-2 my-2 text-truncate'}>
      {selectedFile.name}
        <span className={'text-danger'} onClick={() => clearImported()}>
        <i className='fas fa-trash-alt text-reset ms-1' />
      </span>
    </span> : null}
  </Upload>)
}

export {MetroXlsImport}
