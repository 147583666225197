import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAkpqOnvbUGzu0mnEi-hkMS7aXTKJjp72k",
    authDomain: "nashtanet.firebaseapp.com",
    projectId: "nashtanet",
    storageBucket: "nashtanet.appspot.com",
    messagingSenderId: "614518815437",
    appId: "1:614518815437:web:b5d33e1fc166aab5a98784",
    measurementId: "G-GW4XHTXSY5"
};

firebase.initializeApp(firebaseConfig);

const messaging = (firebase.messaging.isSupported() ? firebase.messaging() : null) as any;
const publicKey = 'BKRDLg4vAg-00XefRQ7D5BaasLJLlyJM6INNHsaS5o5dR9ghMoh_HL8_-J8jUqmTFkEnIoDx1VYIaXBDMcyDVhs';

export const getToken = () => new Promise(async (resolve, reject) => {
    try {
        if (!messaging) {
            reject(new Error('Unsupported device or protocol'))
            return
        }
        const currentToken = await messaging.getToken({ vapidKey: publicKey });
        if (currentToken) {
            resolve(currentToken)
        } else {
            reject(new Error('No registration token available. Request permission to generate one.'))
        }
    } catch (error) {
        console.error("An error occurred while retrieving token. ", error);
        reject(error)
    }
})

export const onMessageListener = () => new Promise((resolve, reject) => {
        try {
            messaging.onMessage((payload: any) => {
                resolve(payload);
            });
        } catch (e) {
            console.error("An error occurred while listen to message. ", e);
            reject(e)
        }
    });
