import React from 'react'
import Tree, {TreeProps} from 'rc-tree'

const MinusSquareO = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox='64 -65 897 897'>
    <g>
      <path
        d='M888 760v0v0v-753v0h-752v0v753v0h752zM888 832h-752q-30 0 -51 -21t-21 -51v-753q0 -29 21 -50.5t51 -21.5h753q29 0 50.5 21.5t21.5 50.5v753q0 30 -21.5 51t-51.5 21v0zM732 347h-442q-14 0 -25 10.5t-11 25.5v0q0 15 11 25.5t25 10.5h442q14 0 25 -10.5t11 -25.5v0
  q0 -15 -11 -25.5t-25 -10.5z'
      />
    </g>
  </svg>
)

const PlusSquareO = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox='64 -65 897 897'>
    <g>
      <path
        d='M888 760v0v0v-753v0h-752v0v753v0h752zM888 832h-752q-30 0 -51 -21t-21 -51v-753q0 -29 21 -50.5t51 -21.5h753q29 0 50.5 21.5t21.5 50.5v753q0 30 -21.5 51t-51.5 21v0zM732 420h-184v183q0 15 -10.5 25.5t-25.5 10.5v0q-14 0 -25 -10.5t-11 -25.5v-183h-184
  q-15 0 -25.5 -11t-10.5 -25v0q0 -15 10.5 -25.5t25.5 -10.5h184v-183q0 -15 11 -25.5t25 -10.5v0q15 0 25.5 10.5t10.5 25.5v183h184q15 0 25.5 10.5t10.5 25.5v0q0 14 -10.5 25t-25.5 11z'
      />
    </g>
  </svg>
)
interface MetroTreeProps {
  prefixCls?: string;
}
const MetroTree = (props:TreeProps & MetroTreeProps ) => {
  const {
    showIcon = false,
    showLine = true,
    defaultExpandAll = true,
    selectable = false,
    switcherIcon = (data) => {
      return (data?.data?.children || []).length > 0 ? (
        data?.expanded ? (
          <MinusSquareO height={13} />
        ) : (
          <PlusSquareO height={13} />
        )
      ) : undefined
    },
  } = props
  return (
    <Tree
      {...props}
      defaultExpandAll={defaultExpandAll}
      selectable={selectable}
      switcherIcon={switcherIcon}
      showLine={showLine}
      showIcon={showIcon}
    />
  )
}

export {MetroTree}
