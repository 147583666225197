import React, {useRef} from 'react'
import 'rc-tooltip/assets/bootstrap.css'
// import {Tooltip} from 'bootstrap'
import {randomString} from '../../utils'
import Tooltip from 'rc-tooltip'
interface MetroTooltipProps {
  // className?: string
  zIndex?: number
  children: React.ReactElement | React.ReactNode
  tooltip: string | React.ReactNode
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
  trigger?: 'hover' | 'click' | 'focus'
}
const MetroTooltip = (props: MetroTooltipProps) => {
  const {tooltip, children, placement, trigger = 'hover', zIndex} = props
  const d_id = useRef('metro-tooltip-' + randomString())
  // useLayoutEffect(() => {
  //   // @ts-ignore
  //   new Tooltip(document.getElementById(d_id.current))
  // }, [tooltip])

  if (!tooltip) {
    return <>
      {children}
    </>
  }
  return (
    // @ts-ignore
    <Tooltip id={d_id} placement={placement} trigger={[trigger]} overlay={tooltip} zIndex={zIndex}>
      <span>{children}</span>
    </Tooltip>
  )
  // {/*<span*/}
  // {/*  id={d_id.current}*/}
  // {/*  data-bs-placement={placement}*/}
  // {/*  className={`${className || ''}`}*/}
  // {/*  data-bs-toggle='tooltip'*/}
  // {/*  data-bs-html='true'*/}
  // {/*  title={ReactDOMServer.renderToString(*/}
  // {/*    tooltip as React.ReactElement<any, string | React.JSXElementConstructor<any>>*/}
  // {/*  )}*/}
  // {/*>*/}
  // {/*  {children}*/}
  // {/*</span>*/}
}

export {MetroTooltip}
