/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactElement} from 'react'
import clsx from 'clsx'
import Dropdown from 'rc-dropdown'
import Menu, {MenuItem} from 'rc-menu'
import {MenuProps} from 'rc-menu/lib/Menu'
import {DropdownProps} from 'rc-dropdown/lib/Dropdown'
interface MetroDropdownMenuV2Props {
  children: React.ReactNode
  header: React.ReactNode | ReactElement
  contentClassName?: string
  activeWhenClickItem?: boolean
  trigger?: 'click' | 'hover'
  menuProps?: MenuProps
  dropdownProps?: DropdownProps
}
const MetroDropdownMenuV2 = (props: MetroDropdownMenuV2Props) => {
  const {
    children,
    menuProps,
    dropdownProps,
    activeWhenClickItem = false,
    contentClassName,
    trigger = 'click',
  } = props
  // @ts-ignore
  const Header =
    props.header &&
    // @ts-ignore
    React.cloneElement(props.header, {
      className: clsx(
        // @ts-ignore
        props.header?.props?.className,
        // 'dropdown-toggle',
        'cursor-pointer'
      ),
    })
  const content_children = Array.isArray(children) ? children : [children]
  return (
    <>
      <Dropdown
        {...dropdownProps}
        trigger={dropdownProps?.trigger || [trigger]}
        animation='slide-up'
        overlay={
          <Menu
            {...menuProps}
            selectable={menuProps?.selectable || activeWhenClickItem}
            className={clsx(menuProps?.className && (menuProps?.className || contentClassName))}
          >
            {content_children.map((el, index) => (
              el && <MenuItem className={'cursor-pointer'} key={index.toString()}>{el}</MenuItem>
            ))}
          </Menu>
        }
        // onVisibleChange={onVisibleChange}
      >
        {/*// @ts-ignore*/}
        {props.header ? Header : <></>}
      </Dropdown>
    </>
  )
}

export {MetroDropdownMenuV2}
