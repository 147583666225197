/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
interface MetroPaginationProps {
  total?: number;
  defaultCurrent?: number;
  disabled?: boolean;
  current?: number;
  // defaultPageSize?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize?: number) => void;
  // hideOnSinglePage?: boolean;
  // showSizeChanger?: boolean;
  // pageSizeOptions?: string[];
  // onShowSizeChange?: (current: number, size: number) => void;
  // showQuickJumper?: boolean | {
  //   goButton?: React.ReactNode;
  // };
  // showTotal?: (total: number, range: [number, number]) => React.ReactNode;
  // size?: string;
  // simple?: boolean;
  style?: React.CSSProperties;
  // locale?: Object;
  className?: string;
  // prefixCls?: string;
  // selectPrefixCls?: string;
  // itemRender?: (page: number, type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next', originalElement: React.ReactElement<HTMLElement>) => React.ReactNode;
  position?: 'left' | 'right' | 'center';
  // role?: string;
  // showLessItems?: boolean;

}
function MetroPagination(props: MetroPaginationProps) {
  const isMount = useRef(false)
  const {disabled, position = 'right', total = 0, defaultCurrent = 1, onChange, pageSize = 10, className, current} = props
  const [active, setActive] = useState(current || defaultCurrent)
  const [pages, setPages] = useState<(number | string)[]>([])
  useEffect(() => {
    if (isMount.current) {
      onChange && onChange(active, pageSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, pageSize ])

  useEffect(() => {
    isMount.current = true
  }, [])


  const pos = {
    left: 'justify-content-start',
    right: 'justify-content-end',
    center: 'justify-content-center',
  }
  const totalFix = Math.ceil(total / pageSize)

  useEffect(() => {
    if (totalFix <= pageSize) {
      let arr = [];
      for (let i = 1; i <= totalFix; i++) {
        arr.push(i);
      }
      setPages(arr);
      return;
    }
    if (active <= 4) {
      setPages([1, 2, 3, 4, 5, "...", totalFix]);
    }
    if (active > 4 && active < totalFix - 3) {
      setPages([1, "...", active - 1, active, active + 1, "...", totalFix]);
    }
    if (active > totalFix - 4) {
      setPages([1, "...", totalFix - 4, totalFix - 3, totalFix - 2, totalFix - 1, totalFix]);
    }
  }, [active, pageSize, props, totalFix])
  // }, [props])

  const isDisabledPrev = active <= 1
  const isDisabledNext = active >= totalFix

  if (pages.length <= 1) {
    return null
  }
  return (
    <div className={clsx('metro-pagination', className)}>
      <ul className={clsx('pagination', pos[position])}>
        <li className={clsx('paginate_button page-item previous', isDisabledPrev && 'disabled')}><a href="#" aria-controls="kt_table_users" data-dt-idx={0} tabIndex={0} className="page-link" onClick={(e) => {
          e.preventDefault()
          if (disabled) {
            return
          }
          !isDisabledPrev && setActive(prevState => prevState - 1)
        }}><i className="previous" /></a></li>
        {
          pages.map((p, index) => (
            <li key={p.toString() + index} className={clsx('paginate_button page-item', p === active && 'active')}><a href="#" aria-controls="kt_table_users" data-dt-idx={p} tabIndex={0} className="page-link" onClick={(e) => {
              e.preventDefault()
              if (disabled) {
                return
              }
              if (typeof p === 'number') {
                setActive(Number(p))
              }
            }}>{p}</a></li>
          ))
        }
        {/*<li className={clsx('paginate_button page-item', pos[position])}><a href="#" aria-controls="kt_table_users" data-dt-idx={1} tabIndex={0} className="page-link">1</a></li>*/}
        {/*<li className="paginate_button page-item "><a href="#" aria-controls="kt_table_users" data-dt-idx={2} tabIndex={0} className="page-link">2</a></li>*/}
        {/*<li className="paginate_button page-item "><a href="#" aria-controls="kt_table_users" data-dt-idx={3} tabIndex={0} className="page-link">3</a></li>*/}
        <li className={clsx('paginate_button page-item next', isDisabledNext && 'disabled')}><a href="#" aria-controls="kt_table_users" data-dt-idx={total} tabIndex={0} className="page-link" onClick={(e) => {
          e.preventDefault()
          if (disabled) {
            return
          }
          !isDisabledNext && setActive(prevState => prevState + 1)
        }}><i className="next" /></a></li>
      </ul>
    </div>
  )
}
export {MetroPagination}
