import { MetroModal } from "../MetroModal";
import { MetroTable, MetroTableProps } from "../MetroTable";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {getHrEmployeeApi, getHrEmployeeApiV1} from "../../apis";
import { useFetch } from "../../hooks";
import config from "../../config";
import { useIntl } from "react-intl";
const ModalChooseEmployee = forwardRef(
  (
    props: {
      onSelectedRow: (value: []) => void,
      hideRowForId?: []
    },
    ref,
  ) => {
    const intl = useIntl()
    const [visible, setVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState(null);
    const getHrEmployee = useFetch({
      apiFunction: getHrEmployeeApiV1
    })
    useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true)
      },
      hide: () => {
        setVisible(false)
      }
    }));

    useEffect(() => {
      getHrEmployee.fetch({
        ...config.paramsPageAndPagination,
      })
    }, []);

    useEffect(() => {
      if (selectedRows) {
        setSelectedRows(selectedRows)
      }
    }, [selectedRows]);

    const columns = [
      {
        title: intl.formatMessage({id: 'LABEL.NIK'}),
        dataIndex: 'hr_emp_company_nik',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
        dataIndex: 'emp_name',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
        dataIndex: 'business_unit_name',
        filterAble: true,
        filterType: 'business_unit',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.JOB_POSITION'}),
        dataIndex: 'hr_job_position_job_position_name',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.JOB_LEVEL'}),
        dataIndex: 'hr_job_level_job_level_name',
        filterAble: true,
        filterType: 'job_level',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
    ]

    const dataSource = useMemo(() => {
      return getHrEmployee.list.map((el: any) => ({...el, key: el.id || el.key || el.value})).filter((el: any) => {
        const h: boolean[] = []
        const cek = props.hideRowForId || []
        if (cek.length === 0) {
          return true
        }
        cek.forEach((id: any) => {
          h.push(el.key === id)
        })
        return !h.includes(true)
      })
    }, [getHrEmployee.list, props.hideRowForId]);

    return (
      <MetroModal
        size={'large'}
        destroyOnClose={true}
        title={'Employee'}
        visible={visible}
        onDismiss={() => {
          setVisible(false)
        }}
        okText={intl.formatMessage({id: 'LABEL.SAVE'})}
        onOk={() => {
          props.onSelectedRow && props.onSelectedRow(selectedRows as any)
        }}
      >
        <MetroTable
          scroll={{x: true}}
          pageSize={5}
          rowSelection={{
            onChange: (selectionKeys, selectionRows) => {
              setSelectedRows(selectionRows as any)
            },
            type: 'checkbox',
          }}
          columns={columns}
          data={dataSource}
          rowKey={'id'}
        />
      </MetroModal>
    );

  })
export { ModalChooseEmployee }
