import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {
  Notification,
  HeaderUserMenu,
  QuickLinks
} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from "../../../../app/modules/auth";
import {getFncAccPayable1000Api, viewFiles3} from "../../../../source/apis";
import { useFetch } from '../../../../source/hooks'
import moment from 'moment'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
    const {currentUser} = useAuth()
  const {config} = useLayout()

    const [check, setCheck] = useState(false);
    const [dataNotif, setDataNotif]: any = useState(false)
    const getFncAccPayable = useFetch({apiFunction: getFncAccPayable1000Api})

    useEffect(() => {
        setTimeout(()=> {
            const params = {
                payment_status:'unpaid',
                invoice_due_date: moment().format('DD/MM/YYYY')
            }
            getFncAccPayable.fetch(params).then(({data}: any) => {
                setDataNotif(data.data)
            }).catch(() =>{})
        }, 1500)
        // eslint-disable-next-line
    }, [check])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        {/*<Search />*/}
      </div>
      {/* Activities */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Drawer toggle *!/*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
      {/*      toolbarButtonHeightClass*/}
      {/*    )}*/}
      {/*    id='kt_activities_toggle'*/}
      {/*  >*/}
      {/*    <KTSVG*/}
      {/*      path='/media/icons/duotune/general/gen032.svg'*/}
      {/*      className={toolbarButtonIconSizeClass}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  /!* end::Drawer toggle *!/*/}
      {/*</div>*/}

      {/* NOTIFICATIONS */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Menu- wrapper *!/*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
      {/*      toolbarButtonHeightClass*/}
      {/*    )}*/}
      {/*    data-kt-menu-trigger='click'*/}
      {/*    data-kt-menu-attach='parent'*/}
      {/*    data-kt-menu-placement='bottom-end'*/}
      {/*    data-kt-menu-flip='bottom'*/}
      {/*  >*/}
      {/*    <KTSVG*/}
      {/*      path='/media/icons/duotune/general/gen022.svg'*/}
      {/*      className={toolbarButtonIconSizeClass}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <HeaderNotificationsMenu />*/}
      {/*  /!* end::Menu wrapper *!/*/}
      {/*</div>*/}

      {/* CHAT */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Menu wrapper *!/*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'btn btn-icon btn-active-light-primary btn-custom position-relative',*/}
      {/*      toolbarButtonHeightClass*/}
      {/*    )}*/}
      {/*    id='kt_drawer_chat_toggle'*/}
      {/*  >*/}
      {/*    <KTSVG*/}
      {/*      path='/media/icons/duotune/communication/com012.svg'*/}
      {/*      className={toolbarButtonIconSizeClass}*/}
      {/*    />*/}

      {/*    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>*/}
      {/*  </div>*/}
      {/*  /!* end::Menu wrapper *!/*/}
      {/*</div>*/}

        {/*LONCENG*/}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            {/* begin::Menu wrapper */}
            <div
                onClick={()=> setCheck(prevCheck => !prevCheck)}
                className={clsx('btn btn-icon btn-active-light-primary pulse pulse-primary', toolbarButtonHeightClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
            >
                <i
                    className='fad fa-bell fs-2'
                    data-bs-toggle='tooltip'
                    title='Phone number must be active'
                ></i>
                <span className="pulse-ring"></span>
                <span
                    className="position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">{getFncAccPayable.list.length}</span>
            </div>
            <Notification data={dataNotif} />
            {/* end::Menu wrapper */}
        </div>
        {/*LONCENG*/}
      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::Theme mode */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  <ThemeModeSwitcher*/}
      {/*    toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}*/}
      {/*  />*/}
      {/*</div>*/}
      {/* end::Theme mode */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
            {
                currentUser?.file_url ?
                    <img style={{objectFit:'cover'}} src={viewFiles3(currentUser?.file_url)} alt='metronic' loading={'eager'} />
                    :
                    <div className="symbol symbol-50px">
                        <div className="symbol-label fs-2 fw-bold text-primary">{currentUser?.initialName}</div>
                    </div>
            }
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
