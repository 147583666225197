import {lazy} from 'react'

const CalculatePcsAdd = lazy(() => import('./Sales/CalculatePcs/CalculatePcsAdd'))
const CalculatePcs = lazy(() => import('./Sales/CalculatePcs'))
const NewCalculatePcsAdd = lazy(() => import('./Sales/NewCalculatePcs/CalculatePcsAdd'))
const NewCalculatePcs = lazy(() => import('./Sales/NewCalculatePcs'))
const ApprovalCalculatePcs = lazy(() => import('./Sales/ApprovalCalculatePcs'))
const ApprovalCalculatePcsView = lazy(
  () => import('./Sales/ApprovalCalculatePcs/ApprovalCalculatePcsView')
)
const NewApprovalCalculatePcs = lazy(() => import('./Sales/NewApprovalCalculatePcs'))
const NewApprovalCalculatePcsView = lazy(
  () => import('./Sales/NewApprovalCalculatePcs/ApprovalCalculatePcsView')
)
const CustomQuery = lazy(() => import('./Sales/CustomQuery'))
const TimeOfReq = lazy(() => import('./Master/Sistem/UserAccess/TimeOffReq'))
const DelegationReq = lazy(() => import('./Master/Sistem/UserAccess/DelegationReq'))
const ChangeDataReq = lazy(() => import('./Master/Sistem/UserAccess/ChangeDataReq'))
const ChangeShiftReq = lazy(() => import('./Master/Sistem/UserAccess/ChangeShiftReq'))
const AttendanceReq = lazy(() => import('./Master/Sistem/UserAccess/AttendanceReq'))
const ReimbursementReq = lazy(() => import('./Master/Sistem/UserAccess/ReimbursementReq'))
const CashAdvanceReq = lazy(() => import('./Master/Sistem/UserAccess/CashAdvanceReq'))
const CashAdvanceSettlement = lazy(() => import('./Master/Sistem/UserAccess/CashAdvanceSettlement'))
const AddEmployee = lazy(() => import('./Master/Sistem/UserAccess/AddEmployee'))
const GeneralRequestAdd = lazy(() => import('./GeneralAffair/GeneralRequest/GeneralRequestAdd'))
const PrRequestAdd = lazy(() => import('./GeneralAffair/PrRequest/PrRequestAdd'))
const ReportCashOnHand = lazy(() => import('./Finance/ReportCashOnHand'))
const ReportEmployeExpense = lazy(() => import('./Finance/ReportEmployeExpense'))
const ListDataNatura = lazy(() => import('./Finance/ListDataNatura'))
const ReportPcs = lazy(() => import('./Finance/ReportPcs'))
const ReportPcsView = lazy(() => import('./Finance/ReportPcs/ReportPcsView'))
const ReportPettyCash = lazy(() => import('./Finance/ReportPettyCash'))
const ReportBukuBesar = lazy(() => import('./Finance/ReportBukuBesar'))
const DashboardFinance = lazy(() => import('./Finance/DashboardFinance'))
const Expense = lazy(() => import('./Finance/Expense'))
const COGS = lazy(() => import('./Finance/COGS'))

const ReportTraining = lazy(() => import('./HumanResource/Report/Training'))
const ReportListTrainingEmoloyee = lazy(() => import('./HumanResource/Report/ListTrainingEmployee'))
const ReportMedical = lazy(() => import('./HumanResource/Report/Medical'))
const ReportListEmployee = lazy(() => import('./HumanResource/Report/ListEmployee'))
const TrainingDashboard = lazy(() => import('./HumanResource/TrainingDashboard'))
const VisitorBackOffice = lazy(() => import('./HumanResource/VisitorBackOffice'))
const OperationalVehicleSettlement = lazy(
  () => import('./GeneralAffair/OperationalVehicleSettlement')
)
const OperationalVehicleSettlementAdd = lazy(
  () => import('./GeneralAffair/OperationalVehicleSettlement/OperationalVehicleSettlementAdd')
)
const ApprovalOperationalVehicle = lazy(() => import('./GeneralAffair/ApprovalOperationalVehicle'))
const OperationalVehicleAdd = lazy(
  () => import('./GeneralAffair/OperationalVehicleRequest/OperationalVehicleAdd')
)
const OperationalVehicleRequest = lazy(() => import('./GeneralAffair/OperationalVehicleRequest'))
const ApprovalCertificate = lazy(() => import('./HumanResource/Approval/ApprovalCertificate'))
const UploadCertificateAdd = lazy(
  () => import('./HumanResource/Request/UploadCertificate/UploadCertificateAdd')
)
const UploadCertificate = lazy(() => import('./HumanResource/Request/UploadCertificate'))
const GeneralRequestUpdate = lazy(() => import('./GeneralAffair/GeneralRequestUpdate'))
const CashOnHandTopUp = lazy(() => import('./Finance/CashOnHands/CashOnHandTopUp'))
const CashOnHandTransaction = lazy(() => import('./Finance/CashOnHands/CashOnHandTransaction'))
const CashOnHandSettings = lazy(() => import('./Finance/CashOnHands'))
const ApprovalCashOnHandTransaction = lazy(
  () => import('./Finance/ApprovalCashOnHand/CashOnHandTransaction')
)
const PaymentGeneralRequest = lazy(() => import('./GeneralAffair/PaymentGeneralRequest'))
const ReportGeneralRequest = lazy(() => import('./GeneralAffair/Report/ReportGeneralRequest'))
const ApprovalGeneralRequestAdd = lazy(
  () => import('./GeneralAffair/ApprovalGeneralRequest/ApprovalGeneralRequestAdd')
)
const ApprovalGeneralRequest = lazy(() => import('./GeneralAffair/ApprovalGeneralRequest'))
const GeneralRequest = lazy(() => import('./GeneralAffair/GeneralRequest'))
const DashboardTender = lazy(() => import('./Sales/DashboardTender'))
const PrRequest = lazy(() => import('./GeneralAffair/PrRequest'))
const ReportListPr = lazy(() => import('./GeneralAffair/ReportListPr'))
const ReportListPrAdd = lazy(() => import('./GeneralAffair/ReportListPr/ReportListPrAdd'))
const PurchaseOrderRecap = lazy(() => import('./GeneralAffair/PORecap'))
const PurchaseOrderRequest = lazy(() => import('./GeneralAffair/PoRequest'))
const ReportListPo = lazy(() => import('./GeneralAffair/ReportListPo'))
const ReportListPoAdd = lazy(() => import('./GeneralAffair/ReportListPo/ReportListPoAdd'))
const PurchaseOrderRequestAdd = lazy(() => import('./GeneralAffair/PoRequest/PoRequestAdd'))
const ApprovalRequestInformal = lazy(
  () => import('./HumanResource/Approval/ApprovalRequestInformal')
)
const EditCompany = lazy(() => import('./CompanySettings/EditCompany'))
const SpecialAchievementReward = lazy(
  () => import('./HumanResource/Request/SpecialAchievementReward')
)
const SpecialAchievementRewardAdd = lazy(
  () => import('./HumanResource/Request/SpecialAchievementReward/SpecialAchievementRewardAdd')
)
const CashAdvanceRequestSettlement = lazy(
  () => import('./HumanResource/Request/CashAdvanceSettlement')
)
const ApprovalCashAdvance = lazy(() => import('./HumanResource/Approval/ApprovalCashAdvance'))
const ViewReimbursementRequest = lazy(() => import('./HumanResource/ViewRequestReimbursement'))
const ViewRequest = lazy(() => import('./HumanResource/ViewRequest'))
const RequestInformalAward = lazy(() => import('./HumanResource/RequestInformalAward'))
const RequestInformalAwardAdd = lazy(
  () => import('././HumanResource/RequestInformalAward/RequestInformalAwardAdd')
)
const RequestSkillAllowanceAdd = lazy(
  () => import('./HumanResource/RequestSkillAllowance/RequestSkillAllowanceAdd')
)
const RequestSkillAllowance = lazy(() => import('./HumanResource/RequestSkillAllowance'))
const RequestTrainingAndCertificationAdd = lazy(
  () => import('./HumanResource/ApprovalTrainingAndCertification/TrainingAndCertificationAdd')
)
const ApprovalTrainingAndCertification = lazy(
  () => import('./HumanResource/ApprovalTrainingAndCertification')
)
const TrainingAndCertificationAdd = lazy(
  () => import('./HumanResource/TrainingAndCertification/TrainingAndCertificationAdd')
)
const TrainingAndCertification = lazy(() => import('./HumanResource/TrainingAndCertification'))
const ContactAdd = lazy(() => import('./Sales/Contact/ContactAdd'))
const OpportunityAdd = lazy(() => import('./Sales/Opportunity/OpportunityAdd'))

const Task = lazy(() => import('./Sales/Task'))
const ProjectGuarantees = lazy(() => import('./Sales/ProjectGuarantees'))
const ProjectGuaranteesAdd = lazy(() => import('./Sales/ProjectGuarantees/ProjectGuaranteesAdd'))
const ApprovalProjectGuarantees = lazy(() => import('./Sales/ApprovalProjectGuarantees'))
const ApprovalProjectGuaranteesView = lazy(
  () => import('./Sales/ApprovalProjectGuarantees/ApprovalProjectGuaranteesView')
)
const SalesOrder = lazy(() => import('./Sales/SalesOrder'))
const SalesOrderAdd = lazy(() => import('./Sales/SalesOrder/SalesOrderAdd'))
const ApprovalSalesOrder = lazy(() => import('./Sales/ApprovalSalesOrder'))
const ApprovalSalesOrderAdd = lazy(
  () => import('./Sales/ApprovalSalesOrder/ApprovalSalesOrderView')
)
const TaskAdd = lazy(() => import('./Sales/Task/TaskAdd'))
const SalesBoard = lazy(() => import('./Sales/SalesBoard'))
const SalesDashboard = lazy(() => import('./Sales/SalesDashboard'))
const Contact = lazy(() => import('./Sales/Contact'))
const Opportunity = lazy(() => import('./Sales/Opportunity'))
const Dashboard = lazy(() => import('./Sales/Dashboard'))
const ApprovalRequestMeetingRoom = lazy(() => import('./HumanResource/ApprovalRequestMeetingRoom'))
const RequestMeetingRoomAdd = lazy(
  () => import('./HumanResource/RequestMeetingRoom/RequestMeetingRoomAdd')
)
const RequestMeetingRoom = lazy(() => import('./HumanResource/RequestMeetingRoom'))
const ReportAp = lazy(() => import('./Finance/ReportAp'))
const RequestApproval = lazy(() => import('./HumanResource/RequestApproval'))
const RequestApprovalAdd = lazy(() => import('./HumanResource/RequestApproval/RequestApprovalAdd'))
const ScheduleMeetingRoom = lazy(() => import('./GeneralAffair/ScheduleMeetingRoom'))
const DashboardAp = lazy(() => import('./Finance/DashboardAp'))
const ReportEBanking = lazy(() => import('./Finance/ReportEBanking'))
const MonitoringFinance = lazy(() => import('./Finance/MonitoringFinance'))
const HrArchiveView = lazy(() => import('./HumanResource/HrDocument/HrArchive/view'))
const HrArchive = lazy(() => import('./HumanResource/HrDocument/HrArchive'))
const ReportAr = lazy(() => import('./Finance/ReportAr'))
const AccountUserRole = lazy(() => import('./AccountManagement/Account/UserRole'))
const OrganizationAndStructure = lazy(() => import('./CompanySettings/OrganizationStructure'))
const JobAndPosition = lazy(() => import('./CompanySettings/JobPosition'))
const JobAndLevel = lazy(() => import('./CompanySettings/JobLevel'))
const GradeAndClass = lazy(() => import('./CompanySettings/GradeAndClass'))
const BusinessUnit = lazy(() => import('./CompanySettings/BusinessUnit'))
const BusinessUnitAdd = lazy(() => import('./CompanySettings/BusinessUnit/BusinessUnitAdd'))
const ManageAccount = lazy(() => import('./AccountManagement/Account'))
const FormEmployeeHr = lazy(() => import('./HumanResource/Employee/Form'))
const ReimbursementRequest = lazy(() => import('./HumanResource/ReimbursementRequest'))
const Meeting = lazy(() => import('./HumanResource/Meeting'))
const ReimbursementRequestAdd = lazy(
  () => import('./HumanResource/ReimbursementRequest/ReimbursementRequestAdd')
)
const EmployeeHr = lazy(() => import('./HumanResource/Employee'))
// const DocumentHr = lazy(() => import('./HumanResource/HrDocument'))
const HrDocCategory = lazy(() => import('./HumanResource/HrDocument/HrDocCategory'))
const HrDocUploadAdd = lazy(() => import('./HumanResource/HrDocument/HrDocUpload/HrDocUploadAdd'))
const HrDocUpload = lazy(() => import('./HumanResource/HrDocument/HrDocUpload'))
const CashAdvanceRequest = lazy(() => import('./HumanResource/Request/CashAdvanceRequest'))
const CashAdvanceRequestAdd = lazy(
  () => import('./HumanResource/Request/CashAdvanceRequest/CashAdvanceRequestAdd')
)
const ReceivablePayableReport = lazy(() => import('./ReceivablePayableReport'))
const ApPaid = lazy(() => import('./Finance/OutstandingAp/ApPaid'))
const ReportBankDaily = lazy(() => import('./ReportBankDaily'))
const SettingPage = lazy(() => import('./SettingPage'))
const FormAdd = lazy(() => import('./Finance/OutstandingAp/FormAdd'))
const KpiDashboard = lazy(() => import('./KpiDashboard'))
const FormAddRequest = lazy(() => import('./Finance/OutstandingAp/AprRequest/FormAdd'))
const ApApproval = lazy(() => import('./Finance/OutstandingAp/ApApproval'))
const ApEbanking = lazy(() => import('./Finance/OutstandingAp/ApEbanking'))
const ApReport = lazy(() => import('./Finance/OutstandingAp/ApReport'))
const ApPayment = lazy(() => import('./Finance/OutstandingAp/ApPayment'))
const AprApproval = lazy(() => import('./Finance/OutstandingAp/AprApproval'))
const AprRequest = lazy(() => import('./Finance/OutstandingAp/AprRequest'))
const ApEntry = lazy(() => import('./Finance/OutstandingAp/ApEntry'))
const ApVerifikasi = lazy(() => import('./Finance/OutstandingAp/ApVerifikasi'))
const OutstandingAp = lazy(() => import('./Finance/OutstandingAp'))
const OutstandingApV2 = lazy(() => import('./Finance/OutstandingApV2'))
const ReimbursementPolicy = lazy(() => import('./Master/ReimbursementPolicy'))
const FncHistorySpp = lazy(() => import('./Finance/FncHistorySpp'))
const FncHistoryAp = lazy(() => import('./Finance/FncHistoryAp'))
const ReimbursementPolicyAdd = lazy(
  () => import('./Master/ReimbursementPolicy/ReimbursementPolicyAdd')
)
const ReimbursementEmployeeAssign = lazy(
  () => import('./Master/ReimbursementPolicy/ReimbursementEmployeeAssign')
)
const ReimbursementEmployeeAssignAdd = lazy(
  () =>
    import(
      './Master/ReimbursementPolicy/ReimbursementEmployeeAssign/ReimbursementEmployeeAssignAdd'
    )
)
const FormAddEdit = lazy(() => import('./Finance/OutstandingAr/FormAddEdit'))
const OutstandingAr = lazy(() => import('./Finance/OutstandingAr'))
const NaturaSplitExpense = lazy(() => import('./Finance/NaturaSplitExpense'))
const NaturaSplitExpenseAddEdit = lazy(() => import('./Finance/NaturaSplitExpense/FormAddEdit'))
const AdjustNatura = lazy(() => import('./Finance/AdjustNatura'))
const AdjustNaturaAddEdit = lazy(() => import('./Finance/AdjustNatura/FormAddEdit'))
const ReportNatura = lazy(() => import('./Finance/ReporNatura'))
const ReportMonitoringNatura = lazy(() => import('./Finance/ReportMonitoringNatura'))
const FinancialDailyEntry = lazy(() => import('./Finance/FinancialDailyEntry'))
const MasterBank = lazy(() => import('./Finance/MasterBank'))
const LMAsset = lazy(() => import('./Finance/LMAsset'))
const Testpage = lazy(() => import('./TestPage'))
const InitialPage = lazy(() => import('./InitialPage'))
const InitialGroupPage = lazy(() => import('./InitialGroupPage'))
const SalesReport = lazy(() => import('./SalesReport'))
// const Master = lazy(() => import('./Master'))
// const Finance = lazy(() => import('./Finance'))
const ViewSchedulePayment = lazy(() => import('./Master/ViewSchedulePayment'))
const SchedulePaymentProjectAdd = lazy(
  () => import('./Master/SchedulePaymentProject/SchedulePaymentProjectAdd')
)
const SchedulePaymentProject = lazy(() => import('./Master/SchedulePaymentProject'))
const MasterRefBank = lazy(() => import('./Master/MasterRefBank'))
const Customer = lazy(() => import('./Master/Customer'))
const CustomerSales = lazy(() => import('./Sales/Customer'))
const CustomerSalesAdd = lazy(() => import('./Sales/Customer/CustomerAdd'))
const ErrorData = lazy(() => import('./Master/ErrorData'))
const CustomerAdd = lazy(() => import('./Master/Customer/CustomerAdd'))
const Project = lazy(() => import('./Master/Project'))
const ProjectAdd = lazy(() => import('./Master/Project/ProjectAdd'))
const ProfitGross = lazy(() => import('./Master/ProfitGross'))
const ProfitNet = lazy(() => import('./Master/ProfitNet'))
const TargetSales = lazy(() => import('./Master/TargetSales'))
const User = lazy(() => import('./Master/User'))
const Role = lazy(() => import('./Master/Role'))
// const MasterSettings = lazy(() => import('./Master/Settings'))
const ApprovalSettings = lazy(() => import('./Master/Settings/ApprovalSettings'))
const CashAdvanceSettings = lazy(() => import('./Master/Settings/CashAdvanceSettings'))
const GeneralRequestSettings = lazy(() => import('./Master/Settings/GeneralRequestSettings'))
const CashAdvanceSettings_Policies = lazy(
  () => import('./Master/Settings/CashAdvanceSettings/CashAdvanceSettings_Policies')
)

const CashAdvanceSettings_PoliciesAdd = lazy(
  () =>
    import(
      './Master/Settings/CashAdvanceSettings/CashAdvanceSettings_Policies/CashAdvanceSettings_PoliciesAdd'
    )
)
const CashAdvanceSettings_Categories = lazy(
  () => import('./Master/Settings/CashAdvanceSettings/CashAdvanceSettings_Categories')
)
const CashAdvanceSettings_CategoriesAdd = lazy(
  () =>
    import(
      './Master/Settings/CashAdvanceSettings/CashAdvanceSettings_Categories/CashAdvanceSettings_CategoriesAdd'
    )
)
const CashAdvanceSettings_EmployeeEnrollment = lazy(
  () => import('./Master/Settings/CashAdvanceSettings/CashAdvanceSettings_EmployeeEnrollment')
)
const CashAdvanceSettings_EmployeeEnrollmentAdd = lazy(
  () =>
    import(
      './Master/Settings/CashAdvanceSettings/CashAdvanceSettings_EmployeeEnrollment/CashAdvanceSettings_EmployeeEnrollmentAdd'
    )
)

const PurchaseRequestSettings = lazy(() => import('./Master/Settings/PurhcaseRequestSettings'))

const PurchaseRequestSettings_Policies = lazy(
  () => import('./Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_Policies/')
)

const PurchaseRequestSettings_PoliciesAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_Policies/PurchaseRequestSettings_PoliciesAdd'
    )
)

const PurchaseRequestSettings_Categories = lazy(
  () => import('./Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_Categories')
)

const PurchaseRequestSettings_CategoriesAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_Categories/PurchaseRequestSettings_CategoriesAdd'
    )
)

const PurchaseRequestSettings_EmployeeEnrollment = lazy(
  () =>
    import('./Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_EmployeeEnrollment')
)

const PurchaseRequestSettings_EmployeeEnrollmentAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseRequestSettings/PurchaseRequestSettings_EmployeeEnrollment/PurchaseRequestSettings_EmployeeEnrollmentAdd'
    )
)

const ApprovalPurchaseRequest = lazy(() => import('./GeneralAffair/ApprovalPR'))

const ApprovalPurchaseRequestFormView = lazy(
  () => import('./GeneralAffair/ApprovalPR/ApprovalPRView')
)

const ApprovalPurchaseOrder = lazy(() => import('./GeneralAffair/ApprovalPO'))

const ApprovalPurchaseOrderFormView = lazy(
  () => import('./GeneralAffair/ApprovalPO/ApprovalPOView')
)

const PurchaseRequestList = lazy(() => import('./GeneralAffair/PRList'))

const PurchaseRequestListFormView = lazy(() => import('./GeneralAffair/PRList/PRListView'))

const PurchaseOrderSettings = lazy(() => import('./Master/Settings/PurhcaseOrderSettings'))

const PurchaseOrderSettings_Policies = lazy(
  () => import('./Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_Policies/')
)

const PurchaseOrderSettings_PoliciesAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_Policies/PurchaseOrderSettings_PoliciesAdd'
    )
)

const PurchaseOrderSettings_Categories = lazy(
  () => import('./Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_Categories')
)

const PurchaseOrderSettings_CategoriesAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_Categories/PurchaseOrderSettings_CategoriesAdd'
    )
)

const PurchaseOrderSettings_EmployeeEnrollment = lazy(
  () => import('./Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_EmployeeEnrollment')
)

const PurchaseOrderSettings_EmployeeEnrollmentAdd = lazy(
  () =>
    import(
      './Master/Settings/PurhcaseOrderSettings/PurchaseOrderSettings_EmployeeEnrollment/PurchaseOrderSettings_EmployeeEnrollmentAdd'
    )
)

const GeneralRequestSettings_Policies = lazy(
  () => import('./Master/Settings/GeneralRequestSettings/GeneralRequestSettings_Policies/')
)
const GeneralRequestSettings_PoliciesAdd = lazy(
  () =>
    import(
      './Master/Settings/GeneralRequestSettings/GeneralRequestSettings_Policies/GeneralRequestSettings_PoliciesAdd'
    )
)
const GeneralRequestSettings_Categories = lazy(
  () => import('./Master/Settings/GeneralRequestSettings/GeneralRequestSettings_Categories')
)
const GeneralRequestSettings_CategoriesAdd = lazy(
  () =>
    import(
      './Master/Settings/GeneralRequestSettings/GeneralRequestSettings_Categories/GeneralRequestSettings_CategoriesAdd'
    )
)
const GeneralRequestSettings_EmployeeEnrollment = lazy(
  () => import('./Master/Settings/GeneralRequestSettings/GeneralRequestSettings_EmployeeEnrollment')
)
const GeneralRequestSettings_EmployeeEnrollmentAdd = lazy(
  () =>
    import(
      './Master/Settings/GeneralRequestSettings/GeneralRequestSettings_EmployeeEnrollment/GeneralRequestSettings_EmployeeEnrollmentAdd'
    )
)

// BUSINESS TRIP : IMPORT PAGE
const BusinessTripSettings = lazy(() => import('./Master/Settings/BusinessTripSettings'))
const BusinessTripSettings_Policies = lazy(
  () => import('./Master/Settings/BusinessTripSettings/BusinessTripSettings_Policies')
)
const BusinessTripSettings_Categories = lazy(
  () => import('./Master/Settings/BusinessTripSettings/BusinessTripSettings_Categories')
)
const BusinessTripSettings_EmployeeEnrollment = lazy(
  () => import('./Master/Settings/BusinessTripSettings/BusinessTripSettings_EmployeeEnrollment')
)

const BusinessTripSettings_CategoriesAdd = lazy(
  () =>
    import(
      './Master/Settings/BusinessTripSettings/BusinessTripSettings_Categories/BusinessTripSettings_CategoriesAdd'
    )
)

const BusinessTripSettings_PoliciesAdd = lazy(
  () =>
    import(
      './Master/Settings/BusinessTripSettings/BusinessTripSettings_Policies/BusinessTripSettings_PoliciesAdd'
    )
)

const BusinessTripSettings_EmployeeEnrollmentAdd = lazy(
  () =>
    import(
      './Master/Settings/BusinessTripSettings/BusinessTripSettings_EmployeeEnrollment/BusinessTripSettings_EmployeeEnrollmentAdd'
    )
)

const NaturaPolicySettings = lazy(() => import('./Master/Settings/NaturaPolicy'))
const NaturaPolicySettingsAdd = lazy(() => import('./Master/Settings/NaturaPolicy/NaturaPolicyAdd'))

const ApprovalSettingForm = lazy(
  () => import('./Master/Settings/ApprovalSettings/ApprovalSettingForm')
)
const AddUser = lazy(() => import('./Master/User/UserAdd'))
const Vendor = lazy(() => import('./Master/Vendor'))
const VendorAdd = lazy(() => import('./Master/Vendor/VendorAdd'))
const CompanyInfo = lazy(() => import('./Master/Sistem/CompanyInfo'))
const SpjReq = lazy(() => import('./Master/Sistem/UserAccess/SpjReq'))
const OrganizationStructure = lazy(() => import('./Master/Sistem/OrganizationStructure'))
const JobLevel = lazy(() => import('./Master/Sistem/JobLevel'))
const JobPosition = lazy(() => import('./Master/Sistem/JobPosition'))
const Employee = lazy(() => import('./Master/Sistem/Employee'))
// const MasterSistem = lazy(() => import('./Master/Sistem'))
const UserAccess = lazy(() => import('./Master/Sistem/UserAccess'))
const Menu = lazy(() => import('./Master/Menu'))
const VersioningMobile = lazy(() => import('./Master/VersioningMobile'))
const VersioningMobileAdd = lazy(() => import('./Master/VersioningMobile/VersioningMobileAdd'))
const MenuAdd = lazy(() => import('./Master/Menu/MenuAdd'))
const Welcome = lazy(() => import('./Welcome'))

const sales_page = [
  {
    path: '/sales',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/sales/customer',
    exact: true,
    element: CustomerSales,
  },
  {
    path: '/sales/custom-query',
    exact: true,
    element: CustomQuery,
  },
  {
    path: '/sales/customer/add',
    element: CustomerSalesAdd,
  },
  {
    path: '/sales/customer/edit',
    element: CustomerSalesAdd,
  },
  {
    path: '/sales/dashboard-tender',
    exact: true,
    element: DashboardTender,
  },
  {
    path: '/sales/pcs',
    exact: true,
    element: CalculatePcs,
  },
  {
    path: '/sales/pcs/add',
    exact: true,
    element: CalculatePcsAdd,
  },
  {
    path: '/sales/pcs/edit',
    exact: true,
    element: CalculatePcsAdd,
  },
  {
    path: '/sales/new-pcs',
    exact: true,
    element: NewCalculatePcs,
  },
  {
    path: '/sales/new-pcs/add',
    exact: true,
    element: NewCalculatePcsAdd,
  },
  {
    path: '/sales/new-pcs/edit',
    exact: true,
    element: NewCalculatePcsAdd,
  },
  {
    path: '/sales/approval-pcs',
    exact: true,
    element: ApprovalCalculatePcs,
  },
  {
    path: '/sales/approval-pcs/view',
    exact: true,
    element: ApprovalCalculatePcsView,
  },
  {
    path: '/sales/new-approval-pcs',
    exact: true,
    element: NewApprovalCalculatePcs,
  },
  {
    path: '/sales/new-approval-pcs/view',
    exact: true,
    element: NewApprovalCalculatePcsView,
  },
  {
    path: '/sales/task/add',
    element: TaskAdd,
  },
  {
    path: '/sales/project-guarantees',
    element: ProjectGuarantees,
  },
  {
    path: '/sales/project-guarantees/add',
    element: ProjectGuaranteesAdd,
  },
  {
    path: '/sales/project-guarantees/edit',
    element: ProjectGuaranteesAdd,
  },
  {
    path: '/sales/task/edit',
    element: TaskAdd,
  },
  {
    path: '/sales/opportunity',
    element: Opportunity,
  },
  {
    path: '/sales/opportunity/add',
    element: OpportunityAdd,
  },
  {
    path: '/sales/opportunity/edit',
    element: OpportunityAdd,
  },
  {
    path: '/',
    element: Opportunity,
  },
  {
    path: '/sales/contact/add',
    element: ContactAdd,
  },
  {
    path: '/sales/contact/edit',
    element: ContactAdd,
  },
  {
    path: '/sales/contact',
    element: Contact,
  },
  {
    path: '/sales/task',
    element: Task,
  },
  {
    path: '/sales/sales-board',
    element: SalesBoard,
  },
  {
    path: '/sales/sales-dashboard',
    element: SalesDashboard,
  },
  {
    path: '/sales/dashboard',
    element: Dashboard,
  },
  {
    path: '/sales/approval-project-guarantees',
    element: ApprovalProjectGuarantees,
  },
  {
    path: '/sales/approval-project-guarantees/view',
    element: ApprovalProjectGuaranteesView,
  },
  {
    path: '/sales/sales-order',
    element: SalesOrder,
  },
  {
    path: '/sales/sales-order/add',
    element: SalesOrderAdd,
  },
  {
    path: '/sales/sales-order/edit',
    element: SalesOrderAdd,
  },
  {
    path: '/sales/approval-sales-order',
    element: ApprovalSalesOrder,
  },
  {
    path: '/sales/approval-sales-order/view',
    element: ApprovalSalesOrderAdd,
  },
]

const hr_approval_page = [
  {
    path: '/hr/approval',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/hr/approval/approval-cash-advance',
    element: ApprovalCashAdvance,
  },
  {
    path: '/hr/approval/approval-request-informal',
    element: ApprovalRequestInformal,
  },
  {
    path: '/hr/approval/approval-certificate',
    element: ApprovalCertificate,
  },
]
const hr_request_page = [
  {
    path: '/hr/request',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/hr/request/cash-advance-request/settlement',
    element: CashAdvanceRequestSettlement,
  },
  {
    path: '/hr/request/special-achievement-reward/add',
    element: SpecialAchievementRewardAdd,
  },
  {
    path: '/hr/request/special-achievement-reward/edit',
    element: SpecialAchievementRewardAdd,
  },
  {
    path: '/hr/request/cash-advance-request/edit',
    element: CashAdvanceRequestAdd,
  },
  {
    path: '/hr/request/cash-advance-request/add',
    element: CashAdvanceRequestAdd,
  },
  {
    path: '/hr/request/cash-advance-request',
    element: CashAdvanceRequest,
  },
  {
    path: '/hr/request/special-achievement-reward',
    element: SpecialAchievementReward,
  },
  {
    path: '/hr/request/upload-certificate',
    element: UploadCertificate,
  },
  {
    path: '/hr/request/upload-certificate/add',
    element: UploadCertificateAdd,
  },
  {
    path: '/hr/request/upload-certificate/edit',
    element: UploadCertificateAdd,
  },
  {
    path: '/hr/training-dashboard',
    element: TrainingDashboard,
  },
  {
    path: '/hr/visitor-back-office',
    element: VisitorBackOffice,
  },
]
const hr_document_page = [
  {
    path: '/hr/document',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/hr/document/upload/add',
    element: HrDocUploadAdd,
  },
  {
    path: '/hr/document/upload/edit',
    element: HrDocUploadAdd,
  },
  {
    path: '/hr/document/archive/view',
    element: HrArchiveView,
  },
  {
    path: '/hr/document/upload',
    element: HrDocUpload,
  },
  {
    path: '/hr/document/category',
    element: HrDocCategory,
  },
  {
    path: '/hr/document/archive',
    element: HrArchive,
  },
]

const hr_page = [
  {
    path: '/hr',
    exact: true,
    element: InitialGroupPage,
  },
  ...hr_document_page,
  ...hr_request_page,
  ...hr_approval_page,
  {
    path: '/hr/informal-award/edit',
    element: RequestInformalAwardAdd,
  },
  {
    path: '/hr/informal-award/add',
    element: RequestInformalAwardAdd,
  },
  {
    path: '/hr/approval-training-certification/add',
    element: RequestTrainingAndCertificationAdd,
  },
  {
    path: '/hr/approval-training-certification/edit',
    element: RequestTrainingAndCertificationAdd,
  },
  {
    path: '/hr/training-certification-request/add',
    element: TrainingAndCertificationAdd,
  },
  {
    path: '/hr/training-certification-request/edit',
    element: TrainingAndCertificationAdd,
  },
  {
    path: '/hr/reimbursement-request/add',
    element: ReimbursementRequestAdd,
  },
  {
    path: '/hr/reimbursement-request/edit',
    element: ReimbursementRequestAdd,
  },
  {
    path: '/hr/request-skill-allowance/add',
    element: RequestSkillAllowanceAdd,
  },
  {
    path: '/hr/request-skill-allowance/edit',
    element: RequestSkillAllowanceAdd,
  },
  {
    path: '/hr/request-skill-allowance',
    element: RequestSkillAllowance,
  },
  {
    path: '/hr/reimbursement-request',
    element: ReimbursementRequest,
  },
  {
    path: '/hr/view-request-reimbursement',
    element: ViewReimbursementRequest,
  },
  {
    path: '/hr/view-request',
    element: ViewRequest,
  },
  {
    path: '/hr/meeting',
    element: Meeting,
  },
  {
    path: '/hr/user-meeting',
    element: Meeting,
  },
  {
    path: '/hr/employee/add',
    element: FormEmployeeHr,
  },
  {
    path: '/hr/employee',
    element: EmployeeHr,
  },
  {
    path: '/hr/training-certification-request',
    element: TrainingAndCertification,
  },
  {
    path: '/hr/approval-training-certification',
    element: ApprovalTrainingAndCertification,
  },
  // {
  //   path: '/hr/document',
  //   element: DocumentHr,
  // },
  {
    path: '/hr/request-approval',
    element: RequestApproval,
  },
  {
    path: '/hr/request-approval/edit',
    element: RequestApprovalAdd,
  },
  {
    path: '/hr/informal-award',
    element: RequestInformalAward,
  },
  {
    path: '/hr/report-training',
    element: ReportTraining,
  },
  {
    path: '/hr/report/list-training-employee',
    element: ReportListTrainingEmoloyee,
  },
  {
    path: '/hr/report-medical',
    element: ReportMedical,
  },
  {
    path: '/hr/report-list-employee',
    element: ReportListEmployee,
  },
]
const ga_page = [
  {
    path: '/ga',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/ga/operational-vehicle-settlement',
    element: OperationalVehicleSettlement,
  },
  {
    path: '/ga/operational-vehicle-settlement/edit',
    element: OperationalVehicleSettlementAdd,
  },
  {
    path: '/ga/operational-vehicle-request',
    element: OperationalVehicleRequest,
  },
  {
    path: '/ga/approval-operational-vehicle',
    element: ApprovalOperationalVehicle,
  },
  {
    path: '/ga/operational-vehicle-request/edit',
    element: OperationalVehicleAdd,
  },
  {
    path: '/ga/operational-vehicle-request/add',
    element: OperationalVehicleAdd,
  },
  {
    path: '/ga/payment-general-request',
    element: PaymentGeneralRequest,
  },
  {
    path: '/ga/report/general-request',
    element: ReportGeneralRequest,
  },
  {
    path: '/ga/general-request-update',
    element: GeneralRequestUpdate,
  },
  {
    path: '/ga/approval-request-meeting-room',
    element: ApprovalRequestMeetingRoom,
  },
  {
    path: '/ga/general-request',
    element: GeneralRequest,
  },
  {
    path: '/ga/pr-request',
    element: PrRequest,
  },
  {
    path: '/ga/pr-request/add',
    element: PrRequestAdd,
  },
  {
    path: '/ga/pr-request/edit',
    element: PrRequestAdd,
  },
  {
    path: '/ga/pr-request/view',
    element: PrRequestAdd,
  },
  {
    path: '/ga/po-recap',
    element: PurchaseOrderRecap,
  },
  {
    path: '/ga/po-request',
    element: PurchaseOrderRequest,
  },
  {
    path: '/ga/po-request/add',
    element: PurchaseOrderRequestAdd,
  },
  {
    path: '/ga/po-request/edit',
    element: PurchaseOrderRequestAdd,
  },
  {
    path: '/ga/po-request/view',
    element: PurchaseOrderRequestAdd,
  },
  {
    path: '/ga/approval-general-request',
    element: ApprovalGeneralRequest,
  },
  {
    path: '/ga/approval-general-request/edit',
    element: ApprovalGeneralRequestAdd,
  },
  {
    path: '/ga/general-request/add',
    element: GeneralRequestAdd,
  },
  {
    path: '/ga/general-request/edit',
    element: GeneralRequestAdd,
  },
  {
    path: '/ga/request-meeting-room/add',
    element: RequestMeetingRoomAdd,
  },
  {
    path: '/ga/request-meeting-room/edit',
    element: RequestMeetingRoomAdd,
  },
  {
    path: '/ga/request-meeting-room',
    element: RequestMeetingRoom,
  },
  {
    path: '/ga/schedule-meeting-room',
    element: ScheduleMeetingRoom,
  },
  {
    path: '/ga/approval-purchase-request',
    element: ApprovalPurchaseRequest,
  },
  {
    path: '/ga/approval-purchase-request/view',
    element: ApprovalPurchaseRequestFormView,
  },
  {
    path: '/ga/approval-purchase-order',
    element: ApprovalPurchaseOrder,
  },
  {
    path: '/ga/approval-purchase-order/view',
    element: ApprovalPurchaseOrderFormView,
  },
  {
    path: '/ga/purchase-request-list',
    element: PurchaseRequestList,
  },
  {
    path: '/ga/purchase-request-list/view',
    element: PurchaseRequestListFormView,
  },
  {
    path: '/ga/report-list-pr',
    element: ReportListPr,
  },
  {
    path: '/ga/report-list-pr/view',
    element: ReportListPrAdd,
  },
  {
    path: '/ga/report-list-po',
    element: ReportListPo,
  },
  {
    path: '/ga/report-list-po/view',
    element: ReportListPoAdd,
  },
]

const account_management = [
  {
    path: '/account-management',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/account-management/account/user-role',
    element: AccountUserRole,
  },
  {
    path: '/account-management/account',
    element: ManageAccount,
  },
]
const company_settings = [
  {
    path: '/company-settings',
    element: InitialGroupPage,
  },
  {
    path: '/company-settings/edit-company',
    exact: true,
    element: EditCompany,
  },
  {
    path: '/company-settings/grade-and-class',
    element: GradeAndClass,
  },
  {
    path: '/company-settings/business-unit',
    element: BusinessUnit,
  },
  {
    path: '/company-settings/business-unit/add',
    element: BusinessUnitAdd,
  },
  {
    path: '/company-settings/business-unit/edit',
    element: BusinessUnitAdd,
  },
  {
    path: '/company-settings/job-level',
    element: JobAndLevel,
  },
  {
    path: '/company-settings/job-position',
    element: JobAndPosition,
  },
  {
    path: '/company-settings/organization-structure',
    element: OrganizationAndStructure,
  },
  {
    path: '/master/reimbursement-policy/edit',
    element: ReimbursementPolicyAdd,
  },
  {
    path: '/master/reimbursement-policy/assign-employee/edit',
    element: ReimbursementEmployeeAssignAdd,
  },
  {
    path: '/master/reimbursement-policy/assign-employee/add',
    element: ReimbursementEmployeeAssignAdd,
  },
  {
    path: '/master/reimbursement-policy/assign-employee',
    element: ReimbursementEmployeeAssign,
  },
  {
    path: '/master/reimbursement-policy/add',
    element: ReimbursementPolicyAdd,
  },
  {
    path: '/master/reimbursement-policy',
    element: ReimbursementPolicy,
  },
]

const finance_page = [
  {
    path: '/finance',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/finance/bank',
    element: MasterBank,
  },
  {
    path: '/finance/daily-entry',
    element: FinancialDailyEntry,
  },
  {
    path: '/finance/outstanding/edit',

    element: FormAddEdit,
  },
  {
    path: '/finance/out-ap/add',
    element: FormAdd,
  },
  {
    path: '/finance/out-ap/add-request',
    element: FormAddRequest,
  },
  {
    path: '/finance/out-ap',
    element: OutstandingAp,
  },
  {
    path: '/finance/outstanding-ap/ap-request/add-request',
    element: FormAddRequest,
  },
  {
    path: '/finance/outstanding-ap/ap-entry/add',
    element: FormAdd,
  },
  {
    path: '/finance/outstanding',
    element: OutstandingAr,
  },
  {
    path: '/finance/outstanding-ap/*',
    element: OutstandingApV2,
  },
  {
    path: '/finance/cash-on-hand/*',
    element: CashOnHandSettings,
  },
  {
    path: '/finance/approval-cash-on-hand/transaction',
    element: ApprovalCashOnHandTransaction,
  },
  {
    path: '/finance/history-spp',
    element: FncHistorySpp,
  },
  {
    path: '/finance/history-ap',
    element: FncHistoryAp,
  },
  {
    path: '/finance/report/report-ar',
    element: ReportAr,
  },
  {
    path: '/finance/report/report-bukubesar',
    element: ReportBukuBesar,
  },
  {
    path: '/finance/report/monitoring-finance',
    element: MonitoringFinance,
  },
  {
    path: '/finance/report/e-banking',
    element: ReportEBanking,
  },
  {
    path: '/finance/report/report-ap',
    element: ReportAp,
  },
  {
    path: '/finance/report/dashboard',
    element: DashboardAp,
  },
  {
    path: '/finance/report/report-finance',
    element: KpiDashboard,
  },
  {
    path: '/finance/report/report-bank-daily',
    element: ReportBankDaily,
  },
  {
    path: '/finance/report/receivable-payable-report',
    element: ReceivablePayableReport,
  },
  {
    path: '/finance/report/report-cash-on-hand',
    element: ReportCashOnHand,
  },
  {
    path: '/finance/report/report-employe-expense',
    element: ReportEmployeExpense,
  },
  {
    path: '/finance/report/list-data-natura',
    element: ListDataNatura,
  },
  {
    path: '/finance/report/report-petty-cash',
    element: ReportPettyCash,
  },
  {
    path: '/finance/report/report-natura',
    element: ReportNatura,
  },
  {
    path: '/finance/report/monitoring-natura',
    element: ReportMonitoringNatura,
  },
  {
    path: '/finance/report-pcs',
    element: ReportPcs,
  },
  {
    path: '/finance/report-pcs/view',
    element: ReportPcsView,
  },
  {
    path: '/finance/dashboard-finance',
    element: DashboardFinance,
  },
  {
    path: '/finance/expense',
    element: Expense,
  },
  {
    path: '/finance/cogs',
    element: COGS,
  },
  {
    path: '/finance/natura-split-expense',
    element: NaturaSplitExpense,
  },
  {
    path: '/finance/natura-split-expense/add',
    element: NaturaSplitExpenseAddEdit,
  },
  {
    path: '/finance/natura-split-expense/edit',
    element: NaturaSplitExpenseAddEdit,
  },
  {
    path: '/finance/adjust-natura',
    element: AdjustNatura,
  },
  {
    path: '/finance/adjust-natura/add',
    element: AdjustNaturaAddEdit,
  },
  {
    path: '/finance/adjust-natura/edit',
    element: AdjustNaturaAddEdit,
  },
  {
    path: '/finance/lm-asset',
    element: LMAsset,
  },
]

const finance_out_ap = [
  {
    path: 'ap-approval',
    element: ApApproval,
  },
  {
    path: 'ap-ebanking',
    element: ApEbanking,
  },
  {
    path: 'ap-payment',
    element: ApPayment,
  },
  {
    path: 'apr-approval',
    element: AprApproval,
  },
  {
    path: 'ap-request',
    element: AprRequest,
  },
  {
    path: 'ap-verification',
    element: ApVerifikasi,
  },
  {
    path: 'ap-entry',
    element: ApEntry,
  },
  {
    path: 'ap-report',
    element: ApReport,
  },
  {
    path: 'ap-paid',
    element: ApPaid,
  },
]

// PERJALANAN DINAS
const master_settings_perjalanan_dinas_page = [
  {
    path: 'policies',
    element: BusinessTripSettings_Policies,
  },
  {
    path: 'categories',
    element: BusinessTripSettings_Categories,
  },
  {
    path: 'employee-enrollment',
    element: BusinessTripSettings_EmployeeEnrollment,
  },
]
const master_settings_perjalanan_dinas_OPT_page = [
  {
    path: '/master/settings/business-trip/policies/add',
    element: BusinessTripSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/business-trip/categories/add',
    element: BusinessTripSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/business-trip/categories/edit',
    element: BusinessTripSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/business-trip/policies/edit',
    element: BusinessTripSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/business-trip/employee-enrollment/add',
    element: BusinessTripSettings_EmployeeEnrollmentAdd,
  },
  {
    path: '/master/settings/business-trip/employee-enrollment/edit',
    element: BusinessTripSettings_EmployeeEnrollmentAdd,
  },
]
const master_settings_purchase_rquest_OPT_page = [
  {
    path: '/master/settings/purchase-request/policies/add',
    element: PurchaseRequestSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/purchase-request/categories/add',
    element: PurchaseRequestSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/purchase-request/categories/edit',
    element: PurchaseRequestSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/purchase-request/policies/edit',
    element: PurchaseRequestSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/purchase-request/employee-enrollment/add',
    element: PurchaseRequestSettings_EmployeeEnrollmentAdd,
  },
  {
    path: '/master/settings/purchase-request/employee-enrollment/edit',
    element: PurchaseRequestSettings_EmployeeEnrollmentAdd,
  },
]
const master_settings_purchase_order_OPT_page = [
  {
    path: '/master/settings/purchase-order/policies/add',
    element: PurchaseOrderSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/purchase-order/categories/add',
    element: PurchaseOrderSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/purchase-order/categories/edit',
    element: PurchaseOrderSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/purchase-order/policies/edit',
    element: PurchaseOrderSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/purchase-order/employee-enrollment/add',
    element: PurchaseOrderSettings_EmployeeEnrollmentAdd,
  },
  {
    path: '/master/settings/purchase-order/employee-enrollment/edit',
    element: PurchaseOrderSettings_EmployeeEnrollmentAdd,
  },
]

const master_settings_natura_policy_page = [
  {
    path: '/master/settings/natura-policy',
    element: NaturaPolicySettings,
  },
  {
    path: '/master/settings/natura-policy/add',
    element: NaturaPolicySettingsAdd,
  },
  {
    path: '/master/settings/natura-policy/edit',
    element: NaturaPolicySettingsAdd,
  },
]

const master_settings_cash_advance_OPT_page = [
  {
    path: '/master/settings/cash-advance/policies/add',
    element: CashAdvanceSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/cash-advance/policies/edit',
    element: CashAdvanceSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/cash-advance/categories/add',
    element: CashAdvanceSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/cash-advance/categories/edit',
    element: CashAdvanceSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/cash-advance/employee-enrollment/add',
    element: CashAdvanceSettings_EmployeeEnrollmentAdd,
  },
  {
    path: '/master/settings/cash-advance/employee-enrollment/edit',
    element: CashAdvanceSettings_EmployeeEnrollmentAdd,
  },
]
const master_settings_cash_advance_page = [
  {
    path: 'policies',
    element: CashAdvanceSettings_Policies,
  },
  {
    path: 'categories',
    element: CashAdvanceSettings_Categories,
  },
  {
    path: 'employee-enrollment',
    element: CashAdvanceSettings_EmployeeEnrollment,
  },
]
const master_settings_purchase_request = [
  {
    path: 'policies',
    element: PurchaseRequestSettings_Policies,
  },
  {
    path: 'categories',
    element: PurchaseRequestSettings_Categories,
  },
  {
    path: 'employee-enrollment',
    element: PurchaseRequestSettings_EmployeeEnrollment,
  },
]
const master_settings_purchase_order = [
  {
    path: 'policies',
    element: PurchaseOrderSettings_Policies,
  },
  {
    path: 'categories',
    element: PurchaseOrderSettings_Categories,
  },
  {
    path: 'employee-enrollment',
    element: PurchaseOrderSettings_EmployeeEnrollment,
  },
]
const finance_cash_onhand_page = [
  {
    path: 'transaction',
    element: CashOnHandTransaction,
  },
  {
    path: 'topup',
    element: CashOnHandTopUp,
  },
]
// const finance_cash_onhand_OPT_page = []
const master_settings_general_request_OPT_page = [
  {
    path: '/master/settings/general-request/policies/add',
    element: GeneralRequestSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/general-request/policies/edit',
    element: GeneralRequestSettings_PoliciesAdd,
  },
  {
    path: '/master/settings/general-request/categories/add',
    element: GeneralRequestSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/general-request/categories/edit',
    element: GeneralRequestSettings_CategoriesAdd,
  },
  {
    path: '/master/settings/general-request/employee-enrollment/add',
    element: GeneralRequestSettings_EmployeeEnrollmentAdd,
  },
  {
    path: '/master/settings/general-request/employee-enrollment/edit',
    element: GeneralRequestSettings_EmployeeEnrollmentAdd,
  },
]
const master_settings_general_request_page = [
  {
    path: 'policies',
    element: GeneralRequestSettings_Policies,
  },
  {
    path: 'categories',
    element: GeneralRequestSettings_Categories,
  },
  {
    path: 'employee-enrollment',
    element: GeneralRequestSettings_EmployeeEnrollment,
  },
]

const master_settings_page = [
  {
    path: '/master/settings',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/master/settings/approval/form',
    element: ApprovalSettingForm,
  },
  {
    path: '/master/settings/approval',
    element: ApprovalSettings,
  },
  {
    path: '/master/settings/cash-advance/*',
    element: CashAdvanceSettings,
  },
  {
    path: '/master/settings/general-request/*',
    element: GeneralRequestSettings,
  },
  {
    path: '/master/settings/business-trip/*',
    element: BusinessTripSettings,
  },
  {
    path: '/master/settings/purchase-request/*',
    element: PurchaseRequestSettings,
  },
  {
    path: '/master/settings/purchase-order/*',
    element: PurchaseOrderSettings,
  },
  ...master_settings_cash_advance_OPT_page,
  ...master_settings_general_request_OPT_page,
  ...master_settings_perjalanan_dinas_OPT_page,
  ...master_settings_natura_policy_page,
  ...master_settings_purchase_rquest_OPT_page,
  ...master_settings_purchase_order_OPT_page,
]

const master_sistem_page = [
  {
    path: '/master/sistem/company_info',
    element: CompanyInfo,
  },
  {
    path: '/master/sistem/organization_structure',
    element: OrganizationStructure,
  },
  {
    path: '/master/sistem/job_level',
    element: JobLevel,
  },
  {
    path: '/master/sistem/job_position',
    element: JobPosition,
  },
  {
    path: '/master/sistem/employee',
    element: Employee,
  },
  {
    path: '/master/sistem/user_access',
    element: UserAccess,
  },
  {
    path: '/master/sistem/grade_class',
    element: InitialPage,
  },
  {
    path: '/master/versioning-mobile/add',
    element: VersioningMobileAdd,
  },
  {
    path: '/master/versioning-mobile/edit',
    element: VersioningMobileAdd,
  },
  {
    path: '/master/versioning-mobile',
    element: VersioningMobile,
  },
]

const master_sistem_user_access_page = [
  {
    path: '/master/sistem/user_access/spj_req',
    element: SpjReq,
  },
  {
    path: '/master/sistem/user_access/time_off_req',
    element: TimeOfReq,
  },
  {
    path: '/master/sistem/user_access/delegation_req',
    element: DelegationReq,
  },
  {
    path: '/master/sistem/user_access/change_data_req',
    element: ChangeDataReq,
  },
  {
    path: '/master/sistem/user_access/change_shift_req',
    element: ChangeShiftReq,
  },
  {
    path: '/master/sistem/user_access/attendance_req',
    element: AttendanceReq,
  },
  {
    path: '/master/sistem/user_access/reimbursement_req',
    element: ReimbursementReq,
  },
  {
    path: '/master/sistem/user_access/cash_adv_req',
    element: CashAdvanceReq,
  },
  {
    path: '/master/sistem/user_access/cash_adv_settlement',
    element: CashAdvanceSettlement,
  },
  {
    path: '/master/sistem/user_access/add_employee',
    element: AddEmployee,
  },
]

const master_page = [
  ...master_sistem_page,
  ...master_settings_page,
  {
    path: '/master',
    exact: true,
    element: InitialGroupPage,
  },
  {
    path: '/master/schedule-payment-project/add',
    element: SchedulePaymentProjectAdd,
  },
  {
    path: '/master/schedule-payment-project/edit',
    element: SchedulePaymentProjectAdd,
  },
  // {
  //   path: '/master/sistem',
  //   element: MasterSistem,
  // },
  {
    path: '/master/menu/add',
    element: MenuAdd,
  },
  {
    path: '/master/menu/edit',
    element: MenuAdd,
  },
  {
    path: '/master/menu',
    element: Menu,
  },
  {
    path: '/master/finance',
    element: MasterBank,
  },
  {
    path: '/master/sales',
    element: InitialPage,
  },
  {
    path: '/master/hr',
    element: InitialPage,
  },
  {
    path: '/master/ref-bank',
    element: MasterRefBank,
  },
  {
    path: '/master/customer/add',
    element: CustomerAdd,
  },
  {
    path: '/master/customer/edit',
    element: CustomerAdd,
  },
  {
    path: '/master/customer',
    element: Customer,
  },
  {
    path: '/master/project/add',
    element: ProjectAdd,
  },
  {
    path: '/master/project/edit',
    element: ProjectAdd,
  },
  {
    path: '/master/project',
    element: Project,
  },
  {
    path: '/master/vendor/add',
    element: VendorAdd,
  },
  {
    path: '/master/vendor/edit',
    element: VendorAdd,
  },
  {
    path: '/master/vendor',
    element: Vendor,
  },
  {
    path: '/master/profit-nett',
    element: ProfitNet,
  },
  {
    path: '/master/profit-gross',
    element: ProfitGross,
  },
  {
    path: '/master/target-sales',
    element: TargetSales,
  },
  {
    path: '/master/user/edit',
    element: AddUser,
  },
  {
    path: '/master/user/add',
    element: AddUser,
  },
  {
    path: '/master/user',
    element: User,
  },
  {
    path: '/master/role',
    element: Role,
  },
  // {
  //   path: '/master/settings',
  //   element: MasterSettings,
  // },
  {
    path: '/master/schedule-payment-project',
    element: SchedulePaymentProject,
  },
  {
    path: '/master/view-schedule-payment',
    element: ViewSchedulePayment,
  },
  {
    path: '/master/error-data',
    element: ErrorData,
  },
]

const pages = [
  //START ROUTE GROUP
  // ...finance_out_ap,
  ...sales_page,
  ...master_page,
  ...ga_page,
  ...hr_page,
  ...finance_page,
  ...account_management,
  ...company_settings,
  //END ROUTE GROUP
  {
    path: '/welcome',
    element: Welcome,
  },
  {
    path: '/test-page',
    element: Testpage,
  },
  {
    path: '/sales-report',
    element: SalesReport,
  },
  // {
  //   path: '/master',
  //   element: Master,
  // },
  // {
  //   path: '/finance',
  //   element: Finance
  // },
  // {
  //   path: '/sales',
  //   element: Sales
  // },
  // {
  //   path: '/report-finance',
  //   element: KpiDashboard
  // },
  {
    path: '/settings',
    element: SettingPage,
  },
  // {
  //   path:'/hr',
  //   element: HumanResource
  // },
  // {
  //   path:'/ga',
  //   element: GeneralAffair
  // },
  // {
  //   path:'/account-management',
  //   element: AccountManagement
  // },
  // {
  //   path:'/company-settings',
  //   element: CompanySettings
  // }
]

export {
  master_page,
  master_sistem_page,
  master_settings_cash_advance_page,
  master_settings_perjalanan_dinas_page,
  finance_cash_onhand_page,
  master_settings_general_request_page,
  master_sistem_user_access_page,
  master_settings_purchase_request,
  master_settings_purchase_order,
  finance_page,
  finance_out_ap,
  hr_page,
  sales_page,
  hr_document_page,
  account_management,
  company_settings,
  master_settings_page,
  ga_page,
}
export default pages
