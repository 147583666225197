import {MetroSpinner} from "../MetroSpinner";

export function MetroFallbackView() {
    return (
        <div className={'text-center py-20'}>
            <MetroSpinner type={'primary'}/>
            <br/>
            <span>Loading ...</span>
        </div>
    )
}
