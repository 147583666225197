/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {MetroBadge, useChooseModal, useFetch, ChooseComponentModal} from '../'
import {getAllBankCompanyLimit1000Api} from '../../apis'
import {useIntl} from 'react-intl'

const useHookModalBank = () => {
  const intl = useIntl()
  const dataRef = useFetch({apiFunction: getAllBankCompanyLimit1000Api})
  const chooseModal = useChooseModal({
    tablePageSize: 5,
    title: 'Bank',
    component: ChooseComponentModal,
    columns: [
      {
        title:  intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
        dataIndex: 'business_unit_name',
        filterAble: true,
        filterType: 'text',
        render:(val: any) => {
          return (val || '').toUpperCase()
        }
      },
      {
        title: intl.formatMessage({id: 'LABEL.NO_ACCOUNT'}),
        dataIndex: 'account_number',
      },
      {
        title:  intl.formatMessage({id: 'LABEL.ACCOUNT_NAME'}),
        dataIndex: 'account_name',
      },
      {
        title:  intl.formatMessage({id: 'LABEL.BANK'}),
        dataIndex: 'mst_bank_nama_bank',
        render:(val: any) => {
          return (val || '').toUpperCase()
        }
      },
      {
        title: intl.formatMessage({id: 'LABEL.BRANCH'}),
        dataIndex: 'bank_branch',
        sortAble: true,
        sortLocal: true,
      },
      {
        title:  intl.formatMessage({id: 'LABEL.CURRENCY'}),
        dataIndex: 'currency_value',
        sortAble: true,
        sortLocal: true,
        render:(val: any) => {
          return val.toUpperCase()
        }
      },
      {
        title: intl.formatMessage({id: 'LABEL.STATUS'}),
        dataIndex: 'status_value',
        render: (val: any) => {
          return <MetroBadge type={val === 'not active' ? 'warning' : 'success'} content={val} />
        }
      }
    ]
  })
  useEffect(() => {
    dataRef.fetch().then()
  }, [])

  useEffect(() => {
    chooseModal.setIsLoading(dataRef.isLoading)
  }, [dataRef.isLoading])


  useEffect(() => {
    if (dataRef.list.length > 0) {
      chooseModal.setDataSource(dataRef.list.filter(el => el.status_value === 'active'))
    }
  }, [dataRef.list])


  return chooseModal
}

export {useHookModalBank}
