import {CSSProperties, useState} from 'react'
// @ts-ignore
import JsonView from 'clay-react-json-view';

const MetroDebug = (props: any) => {
  const [isOpen, setOpen] = useState(true);
  const css = (style: CSSProperties | undefined) => {
    return {style}
  }
  return (
    <div
      {...css({
        zIndex: 100000,
        position: 'fixed',
        bottom: -2,
        left: 0,
        right: 0,
        transform: `translateY(calc(${isOpen ? '0% ' : '100% - 30px'}))`,
        transition: `all .2s ease-out`,
        margin: '3rem 0 0',
        borderRadius: 4,
        background: '#f6f8fa',
        boxShadow: '0 0 1px  #eee inset',
        maxHeight: '400px',
      })}
    >
      <div
        onClick={() => setOpen(!isOpen)}
        {...css({
          display: 'flex',
          alignItems: 'center',
          padding: '.5rem',
          cursor: 'pointer',
          background: '#333',
          color: '#fff',
          fontSize: 11,
          fontWeight: 800,
          justifyContent: 'space-between',
        })}
      >
        <div
          {...css({
            textTransform: 'uppercase',
            letterSpacing: '1px',
          })}
        >
          Debugger
        </div>
        <div>
          {isOpen ? '🔽' : '🔼'}
        </div>
      </div>
      <pre
        {...css({
          fontSize: 11,
          padding: '.25rem .5rem',
          overflowX: 'scroll',
          overflowY: 'scroll',
          height: '360px',
        })}
      >
        <JsonView
          src={props}
          style={{ fontSize: 13 }}
          onEdit={(edit: any) => console.log(edit)}
        />
      </pre>
    </div>
  );
};

export {MetroDebug}
