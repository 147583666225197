import Select, {Props, StylesConfig} from 'react-select'
import React from 'react'
import {GroupBase} from 'react-select/dist/declarations/src/types'
import AsyncSelect, {AsyncProps} from 'react-select/async'
import CreateAbleSelect, {CreatableProps} from 'react-select/creatable'
import AsyncCreatableSelect, {AsyncCreatableProps} from 'react-select/async-creatable';

export interface MetroSelectProps<Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> extends Props<Option, IsMulti, Group>, AsyncProps<Option, IsMulti, Group>, CreatableProps<Option, IsMulti, Group>, AsyncCreatableProps<Option, IsMulti, Group>{
  // options: any
  // field: any
  // form: any
  createAble?: boolean
  addClassName?: string
  className?: string
  sizeInput?: 'small' | 'large'
}

const colStyle: StylesConfig = {
  control: (styles) => (
    {
      ...styles, backgroundColor: "white"
    }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "rgba(242, 103, 34, 1)"
          : isFocused
            ? "rgba(242, 103, 34, 1)"
            : "white",
      color: isDisabled
        ? "grey"
        : isSelected
          ? "white"
          : isFocused
            ? "white"
            : "#3f4254",
     borderRadius: isFocused? "8px 8px 8px 8px" : "0px 0px 0px 0px",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "grey"
            : "#13b2a7"
          : undefined,
      }
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#f26722",
      borderRadius:'8px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
    borderRadius:'8px',

    textTransform:"capitalize",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    backgroundColor: "#13b2a7",
    borderRadius:"50px 20px 0 0",
    ":hover": {
      backgroundColor: "red",
      color: "white"
    }
  })
};

export function MetroSelect(props: MetroSelectProps) {
  const {sizeInput, className, placeholder = '', classNamePrefix = 'form-select2'} = props
  const ss = {
    'small': 'form-select2-sm',
    'large': 'form-select2-lg'
  }
  const fixProps = {
    ...props,
    placeholder,
    classNamePrefix
  }
  delete fixProps.addClassName
  delete fixProps.sizeInput
  // @ts-ignore
  fixProps.className = className ? className : `form-select ${ss[sizeInput] || ''} form-select-solid form-select2__container p-0 ${props.addClassName}`
  if (props.createAble) {
    return <CreateAbleSelect {...fixProps} />
  }
  if (props.loadOptions && props.createAble) {
    return <AsyncCreatableSelect {...fixProps} />
  }
  if (props.loadOptions) {
    return <AsyncSelect {...fixProps} />
  }
  // @ts-ignore
  return <Select styles={colStyle} {...fixProps} />
}
