import React, {useEffect, useState} from "react";
import {fakePromise} from "../../utils";
import {useDidMount} from "../../hooks";
import {useNavigate} from "react-router-dom";

function MicroFrontend(props: {
    window?: any;
    document?: any;
    modulSlug: string;
    host: string;
}) {
    const navigate = useNavigate()
    const isDidMount = useDidMount()
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const sendPropsToChild = {
        navigate,
    }
    useEffect(() => {
        const { host, document, modulSlug } = props;
        const scriptId = `microfrontend-${modulSlug}`

        const renderMicroFrontend = async () => {
            await fakePromise(3000)
            if (isDidMount.current) {
                setLoading(false)
                const {window, modulSlug } = props;
                window[`render${modulSlug}`] &&
                window[`render${modulSlug}`](`${modulSlug}-container`, sendPropsToChild);
            }
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
        } else {
            fetch(`${host}/asset-manifest.json`)
                .then((res) => res.json())
                .then(async (manifest) => {
                    const scriptPromise = () => new Promise((resolve) => {
                        const script = document.createElement("script");
                        const link = document.createElement("link");
                        const cssId = 'css' + scriptId
                        link.id = scriptId;
                        link.rel="stylesheet";
                        link.href = `${host}${manifest.files["main.css"]}`;

                        const files_js = Object.keys(manifest.files).filter(file => file !== 'main.js' && file.slice(file.length - 3) === '.js')
                        files_js.forEach((file, index) => {
                            const script2 = document.createElement("script");
                            script2.crossorigin = 'anonymous';
                            script2.id = scriptId + '_' + index;
                            script2.crossOrigin = "";
                            script2.src = `${host}${manifest.files[file]}`;
                            document.body.after(script2)
                        })
                        script.crossorigin = 'anonymous';
                        script.id = scriptId;
                        script.crossOrigin = "";
                        script.onload = () => {
                            resolve(true)
                        };
                        script.src = `${host}${manifest.files["main.js"]}`;
                        if(!document.getElementById(scriptId)) {
                            document.body.after(script)
                        }
                        if(!document.getElementById(cssId) && manifest.files["main.css"]) {
                            document.head.appendChild(link);
                        }
                    })

                    const mf = await scriptPromise()
                    if(mf) {
                        renderMicroFrontend()
                    }
                }).catch((e) => {
                console.log('failed render page mf', e?.message)
                setIsError(true)
            })
        }

        return () => {
            const { window, modulSlug } = props;
            window[`unmount${modulSlug}`] && window[`unmount${modulSlug}`](`${modulSlug}-container`);
        };
    }, []);

    // const aseToken = encrypt(props.refreshtoken)
    const {modulSlug} = props
    if (isError) return <div>Error Nih</div>
    return (
        <div
            id={`${modulSlug}-container`}
        >
            {loading ? ('loading') : null}
        </div>
    )
}
MicroFrontend.defaultProps = {
    document,
    window,
};
export default MicroFrontend;
