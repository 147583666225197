import React from "react";
import {MetroTable, MetroTableProps} from "../MetroTable";
import {usePagination} from "../../hooks";

export interface MetroTablePaginateProps extends MetroTableProps<any> {
    pagingPrefix: string
}

export const MetroTablePaginate = ({pagingPrefix, loading, onFilterChange, ...metroTableProps}: MetroTablePaginateProps) => {
    const pagination = usePagination({prefix: pagingPrefix})
    return (
      <MetroTable
          {...metroTableProps}
          onFilterChange={e => {
              pagination.setPage(1)
              onFilterChange && onFilterChange(e)
          }}
          loading={loading || pagination.loading}
          pageCurrent={pagination.page}
          onChangePage={(data) => {
              pagination.setPage(data.page)
          }}
      />
  )
}
