import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {MetroPageTitle, useUser} from '../../../../components'
import {findRecursive} from '../../../../utils'

const HeaderMenus: React.FC = () => {
  const location = useLocation()
  const user = useUser()

  const menu = findRecursive(user.listMenuRecursive, (e: any) => e.menu_path === '/finance/outstanding-ap', 'children')
  if ((menu?.children || []).length === 0) {
    return (
      <div className='d-flex mb-5'>
        <MetroPageTitle />
      </div>
    )
  }
  return (
    <div className='d-flex mb-5'>
      <MetroPageTitle />
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-7 fw-bolder mb-3 w-100'>
        {
          (menu?.children || []).sort((a: any, b: any) => a.menu_position - b.menu_position).map((el: any) => (
            <li key={el.id} className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === el.menu_path && 'active')
                }
                to={el.menu_path}
              >
                {el.name}
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default HeaderMenus
