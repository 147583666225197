import {
  confirmDialog, createOption, downloadFileS3,
  formattedNumber,
  renderColumnDate, renderColumnMonthDate, statusColor,
} from "../../utils";
import {MetroDropdownMenuV2} from '../MetroDropdownMenuV2'
import React from 'react'
import {MetroBadge} from "../MetroBadge";
import {MetroButton} from "../MetroButton";
import {data_color} from "../../types";
import {getAccPayableJasper} from "../../pages/Finance/OutstandingApV2";
import moment from "moment";
import {FormattedNumber, IntlProvider} from "react-intl";
import { MetroTooltip } from "../MetroTooltip";
import config from '../../config'
// import moment from "moment";

export const renderColumnNumberWithRp = {
  render: (val: any) => {
    return (
      // <div className={'d-flex min-w-180px justify-content-between'}>
      <div className={'text-end'}>
        <span>Rp. </span>
        <span>{formattedNumber(val)}</span>
      </div>
    )
  }
}
export const renderColumnNumberWithCurrency = {
  render: (val: any,props:any) => {
    return (
        <div className={'d-flex min-w-180px justify-content-between'}>
          <span>{formattedNumber(val) !== String(0) && `${props.currency_value === "IDR" ?'Rp. ' : props.currency_value ?props.currency_value + '. ' : 'Rp. '}`}</span>
          <span>{formattedNumber(val) === String(0) ? '' : formattedNumber(val)}</span>
        </div>
    )
  }
}

export const renderCurrency = (currency: any, value:any) => {
  return (
      <div className={'d-flex min-w-180px justify-content-between'}>
        <span>{currency}</span>
        <span>{formattedNumber(value)}</span>
      </div>
  )
}

// @ts-ignore
export const columnsApVerifikasiAndApproval = ({intl, getAccPayableJasper, downloadAttachment}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },{
      title: intl.formatMessage({id: 'LABEL.VENDOR_NAME'}),
      dataIndex: 'mst_vendor_name',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      render: (val: any) => {
        return <>{val? val : '-'}</>
      }

    },{
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      render: (val: any) => {
        return <>{val.hr_emp_account_info_first_name} {val.hr_emp_account_info_last_name}</>
      }

    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_NUMBER'}),
      dataIndex: 'invoice_number',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.AP_DATE'}),
      dataIndex: 'invoice_date',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DUE_DATE'}),
      dataIndex: 'invoice_due_date',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DESCRIPTION'}),
      dataIndex: 'payment_description',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.GROSS'}),
      dataIndex: 'gross',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      ...renderColumnNumberWithRp
    },
    {
      title:  intl.formatMessage({id: 'LABEL.PPN'}),
      dataIndex: 'ppn',
      align: 'right',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      ...renderColumnNumberWithRp
    },
    {
      title:  intl.formatMessage({id: 'LABEL.PPH21'}),
      dataIndex: 'pph21',
      align: 'right',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.PPH23'}),
      dataIndex: 'pph23',
      align: 'right',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.NETT'}),
      dataIndex: 'nett',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.APPROVAL_NAME'}),
      dataIndex: 'role_approval_name',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.CUSTOMER_NAME'}),
      dataIndex: 'customer_name',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'payment_type_value',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title:  intl.formatMessage({id: 'LABEL.NO_GIRO_CHEQUE'}),
      dataIndex: 'giro_cheque_number',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'payment_date',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.APR_NUMBER'}),
      dataIndex: 'fnc_apr_apr_number',
      sortAble: true,
      sortLocal: true,
      filterAble: true,
      filterLocal: true,
      filterType: 'text',
    },
   {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      align: 'center',
      render: (props: any) => {
        return <>
          <MetroDropdownMenuV2
            header={
              <span className={'fs-6'}>Action <i
                className='fas fa-chevron-down text-reset text-primary' /></span>
            }>
            {!props.file_url? null : <div onClick={() => downloadAttachment && downloadAttachment(props?.file_url)} ><i className='fas fa-paperclip text-reset'/> {intl.formatMessage({id: 'LABEL.EXPORT_ATTACHMENT'})}</div>}
            {!props.spp_number? null : <div onClick={() => getAccPayableJasper && getAccPayableJasper(props?.spp_number)} ><i className='fas fa-download text-reset'/> {intl.formatMessage({id: 'LABEL.DOWNLOAD_SPP'})}</div>}
            {!props.fnc_apr_file? null : <div onClick={() => downloadAttachment && downloadAttachment(props?.fnc_apr_file)} >`<i className='fas fa-download text-reset'/>` {intl.formatMessage({id: 'LABEL.DOWNLOAD_ATTACHMENT'})}</div>}
          </MetroDropdownMenuV2>
        </>
      }
    }
  ]
}

// @ts-ignore
export const columnsCustomer = ({intl, navigate, deleteCustomer, refreshData, downloadAttachment, openModal, setDataModal}) => {
  return [{
    title: intl.formatMessage({ id: 'LABEL.NICK_CUST' }),
    dataIndex: 'nick_cust',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble: true,
    // sortLocal: true,
  },
    {
      title: intl.formatMessage({ id: 'LABEL.CUSTOMER_NAME' }),
      dataIndex: 'name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.NPWP' }),
      dataIndex: 'npwp',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.CATEGORY' }),
      dataIndex: 'category_value',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.TYPE' }),
      dataIndex: 'type_value',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.ADDRESS' }) + ' 1',
      dataIndex: 'address1',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.ADDRESS' }) + ' 2',
      dataIndex: 'address2',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PHONE_NUMBER' }),
      dataIndex: 'no_hp',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.NIK_AM' }),
      dataIndex: 'am_nik',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.AM' }),
      dataIndex: 'am_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.CREATED_AT' }),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.UPDATED_AT' }),
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      dataIndex: 'updated_at',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.ACTION' }),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({ id: 'BTN.ACTIONS' })}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      navigate(window.location.pathname + '/edit', {state: { ...props }})
                      // setSelectedData(props)
                      // setVisibleModalAdd(true)
                    }}
                >
                  <i className='fas fa-edit text-reset' /> {intl.formatMessage({ id: 'BTN.EDIT' })}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteCustomer
                              .fetch(props)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => { })
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({ id: 'BTN.DELETE' })}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },]
}

// @ts-ignore
export const columnsRefBank = ({intl, navigate, deleteMstBank, refreshData, downloadAttachment, openModal, setDataModal}) => {
  return [{
    title: intl.formatMessage({id: 'LABEL.BANK_NAME'}),
    dataIndex: 'nama_bank',
    filterAble: true,
    filterType: 'text',
    filterLocal: true,
    sortAble: true,
    sortLocal: true
  },
    {
      title: intl.formatMessage({id: 'LABEL.INITIAL_BANK'}),
      dataIndex: 'alias',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true
    },
    {
      title: intl.formatMessage({id: 'LABEL.CLEARING_CODE'}),
      className: 'text-nowrap',
      dataIndex: 'kliring_code',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true
    },
    {
      title: intl.formatMessage({id: 'LABEL.CREATED_AT'}),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.UPDATED_AT'}),
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      dataIndex: 'updated_at',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (val: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'LABEL.ACTION'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div onClick={() => openModal(val)}>
                  <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        text: `${intl.formatMessage({id: 'MESSAGE.CONFIRM_DELETE'})} ${val.nama_bank}`,
                        onOk: () => {
                          deleteMstBank
                              .fetch(val.id)
                              .then(() => {
                                refreshData()
                              })
                              .catch(() => {})
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },]
}
// @ts-ignore
export const columnsProject = ({intl, navigate, deleteProject, refreshData, downloadAttachment, openModal, setDataSelected, patchProject}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SALES_NAME'}),
      dataIndex: 'sales',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      render: (val: any) => {
        return val.map((el: any) => <MetroBadge key={el.id} className={'me-1'} content={<span>{el.name}</span>}/>)
      }
    },
    {
      title: 'SO ID',
      dataIndex: 'id',
      filterAble: true,
      // filterLocal: true,
      width: '10rem',
      // filterType: 'text',
      // className: 'text-nowrap',
      render: (val: any) => {
        return <b>{val}</b>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.NAME'}),
      dataIndex: 'name',
      width: '20rem',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.STATUS'}),
      width: 130,
      align: 'center',
      dataIndex: 'flag_close',
      filterAble: true,
      filterType: 'select',
      filterData: [
        {value: 'true', label: 'Close'},
        {value: 'false', label: 'Active'},
      ],
      // defaultFilter: {value: 'true'},
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      render: (val: any) => {
        let color: data_color
        let text = ''
        const valf = String(val)
        if (valf === 'true') {
          color = 'danger'
          text = intl.formatMessage({id: 'LABEL.CLOSE'})
        } else if (valf === 'false') {
          color = 'success'
          text = intl.formatMessage({id: 'LABEL.ACTIVE'})
        } else {
          color = 'secondary'
          text = valf
        }
        return (
            // @ts-ignore
            <MetroBadge content={text} type={color} />
        )
      }
    },
    {
      title: 'PO',
      dataIndex: 'contract_number_po',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CUSTOMER_NAME'}),
      dataIndex: 'customer_name',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.START_DATE'}),
      dataIndex: 'contract_start_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.END_DATE'}),
      dataIndex: 'contract_end_date',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      sortAble: true,
      // sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      fixed: 'right',
      // width: 180,
      align: 'right',
      dataIndex: 'nominal',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_nominal',
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      navigate(window.location.pathname + '/edit', {
                        state: {...props}
                      })
                    }}
                >
                  <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
                {props.file_url !== null? <div onClick={() => downloadAttachment(props.file_url)}>
                  <i className='fas fa-download text-reset' /> {intl.formatMessage({id: 'BTN.DOWNLOAD_ATTACHMENT'})}
                </div> : ''}
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          const v = props
                          const payload = {
                            id: v.id,
                            name: v.name,
                            customer_id: v.customer_id,
                            description: v.description,
                            is_double_booking: v.is_double_booking,
                            nominal: v.total,
                            file_url: v.file_url,
                            flag_close: false,
                            contract_number_po: v.contract_number_po,
                            contract_start_date: moment(v.contract_start_date).format(config.formatDate),
                            revenue_year: moment(v.revenue_year).format(config.formatDate),
                            contract_end_date:  moment(v.contract_end_date).format(config.formatDate),
                            business_unit_id: v.business_unit_id,
                            project_id: v.project_id,
                            empoyee_id: (v.sales || []).map((el: any) => {
                              return el.id
                            })
                          }

                          if (props.flag_close===true) {
                            payload.flag_close = false
                          } else {
                            payload.flag_close = true
                          }

                          patchProject
                              .fetch({id: props?.id, payload: payload})
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {refreshData()})
                          // alert("Ok")
                        },
                      })
                    }}
                >
                  <i className='fas fa-closed-captioning' /> {intl.formatMessage({id: 'BTN.CHANGE_STATUS'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteProject
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {refreshData()})
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]
}

// @ts-ignore
export const columnsEmployeeReportExpense = ({
  intl,
  renderColumnDate,
}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'sort_by_business_unit_name',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'payment_date',
      sortAble: true,
      sortParam: 'payment_date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'project_id',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      dataIndex: 'employee_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'employee_name',
      sortAble: true,
    },

    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NIK'}),
      dataIndex: 'employee_nik',
      filterAble: true,
      filterType: 'text',
      sortParam: 'employee_nik',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CURRENCY'}),
      dataIndex: 'amount',
      align: 'right',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TYPE'}),
      dataIndex: 'type',
      filterAble: true,
      filterType: 'text',
      sortParam: 'type',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CATEGORY'}),
      dataIndex: 'category',
      filterAble: true,
      filterType: 'text',
      sortParam: 'type',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      sortParam: 'type',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'payment_type',
      filterAble: true,
      filterType: 'text',
      sortParam: 'payment_type',
      sortAble: true,
    },
  ]
}

// @ts-ignore
export const columnsListDataNatura = ({
  intl,
  renderColumnDate,
}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_ID'}),
      dataIndex: 'transaction_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'sort_by_transaction_id',
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      dataIndex: 'employee_name',
      filterAble: true,
      sortParam: 'employee_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NIK'}),
      dataIndex: 'nik',
      filterAble: true,
      filterType: 'text',
      sortParam: 'nik',
    },
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit',
      filterAble: true,
      filterType: 'text',
      sortParam: 'business_unit',
    },

    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'project_id',
    },
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_TYPE'}),
      dataIndex: 'type_transaction',
      filterAble: true,
      filterType: 'text',
      sortParam: '  type_transaction',
    },
    {
      title: intl.formatMessage({id: 'LABEL.POLICY_NAME'}),
      dataIndex: 'reimburse_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'reimburse_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.CATEGORY'}),
      dataIndex: 'category_natura',
      filterAble: true,
      filterType: 'text',
      sortParam: 'category_natura',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOTES'}),
      dataIndex: 'notes',
      filterAble: true,
      filterType: 'text',
      sortParam: 'notes',
    },
    {
      title: intl.formatMessage({id: 'LABEL.DETAIL_NAME'}),
      dataIndex: 'detail_name',
      filterAble: true,
      filterType: 'text',
      // sortParam: 'employee_detail_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      sortParam: 'description',
    },
    {
      title: intl.formatMessage({id: 'LABEL.AMOUNT'}),
      dataIndex: 'req_amount',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'payment_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_DATE'}),
      dataIndex: 'invoice_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
  ]
}

// @ts-ignore
export const columnsReportPettyCash = ({ intl, renderColumnDate }: any) => {
  return [
    {
      title: intl.formatMessage({ id: 'LABEL.NIK_USER_CREATED' }),
      dataIndex: 'created_by_nik',
      filterAble: true,
      filterType: 'text',
      sortParam: 'created_by_nik',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.USER_CREATED_NAME' }),
      dataIndex: 'created_by_name',
      filterAble: true,
      sortParam: 'created_by_name',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.TRANSACTION_ID' }),
      dataIndex: 'transaction_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'sort_by_transaction_id',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.BUSINESS_UNIT' }),
      dataIndex: 'bu_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'bu_name',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.NIK_REQUESTOR' }),
      dataIndex: 'nik_requestor',
      filterAble: true,
      filterType: 'text',
      sortParam: 'nik_requestor',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.REQUESTOR_NAME' }),
      dataIndex: 'requestor_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'requestor_name',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.POLICY_NAME' }),
      dataIndex: 'gr_policy_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'gr_policy_name',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.DATE_OF_NEEDS' }),
      dataIndex: 'date_of_needs',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      render: (val: any) => {
        return moment(val).format(config.formatDate)
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.CREATED_DATE' }),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      render: (val: any) => {
        return moment(val).format(config.formatDate)
      },
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PURPOSE_OF_NEEDS' }),
      dataIndex: 'purpose_of_needs',
      filterAble: true,
      filterType: 'text',
      sortParam: 'purpose_of_needs',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.IS_PROJECT' }),
      dataIndex: 'is_project',
      filterAble: true,
      filterType: 'text',
      sortParam: 'is_project',
      render: (val: any) => {
        return val === true ? 'Yes' : 'No'
      },
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PROJECT_ID' }),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'project_id',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.CATEGORY_NAME' }),
      dataIndex: 'name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'name',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.DESCRIPTION' }),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      sortParam: 'description',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.QTY' }),
      dataIndex: 'qty',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.UNIT_PRICE' }),
      dataIndex: 'unit_price',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PPN' }),
      dataIndex: 'ppn_percent',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PPN_NOMINAL' }),
      dataIndex: 'ppn_nominal',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.TOTAL_PRICE' }),
      dataIndex: 'total_price',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.REALIZATION_NOMINAL' }),
      dataIndex: 'realization_nominal',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PAYMENT_STATUS' }),
      dataIndex: 'payment_status',
      filterAble: true,
      filterType: 'text',
      sortParam: 'payment_status',
    },
    {
      title: intl.formatMessage({ id: 'LABEL.PAYMENT_DATE' }),
      dataIndex: 'payment_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.VENDOR_NAME' }),
      dataIndex: 'vendor_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'vendor_name',
    },
  ];
};



// @ts-ignore
export const columnsReportNatura = ({
  intl,
  renderColumnDate,
}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_ID'}),
      dataIndex: 'transaction_id',
      filterAble: true,
      filterType: 'text',
      sortParam: 'sort_by_transaction_id',
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      dataIndex: 'full_name',
      filterAble: true,
      sortParam: 'full_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NIK'}),
      dataIndex: 'nik',
      filterAble: true,
      filterType: 'text',
      sortParam: 'nik',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NATURA_TYPE'}),
      dataIndex: 'type_natura',
      filterAble: true,
      filterType: 'text',
      sortParam: 'type_natura',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NATURA_CATEGORY'}),
      dataIndex: 'jenis_natura',
      filterAble: true,
      filterType: 'text',
      sortParam: 'jenis_natura',
    } ,
    {
      title: intl.formatMessage({id: 'LABEL.REQUEST_POLICY'}),
      dataIndex: 'reimburse_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'reimburse_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.REQUEST_POLICY_DETAIL'}),
      dataIndex: 'benefit_name',
      filterAble: true,
      filterType: 'text',
      sortParam: 'benefit_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      sortParam: 'description',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOTES'}),
      dataIndex: 'notes',
      filterAble: true,
      filterType: 'text',
      sortParam: 'notes',
    },
    {
      title: intl.formatMessage({id: 'LABEL.AMOUNT'}),
      dataIndex: 'req_amount',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.LIMITATION'}),
      dataIndex: 'limitation',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        return formattedNumber(val)
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.CREATED_AT'}),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
  ]
}

// @ts-ignore
export const columnsExpense = ({intl, deleteExpense, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PERIOD'}),
      dataIndex: 'periode',
      filterAble: true,
      filterType: 'date-month',
      render: (val: any) => {
        const date = moment(val)
        if (val && date.isValid()) {
          return moment(val).format("MMM YYYY")
        }
        return '-'
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.CATEGORY'}),
      dataIndex: 'category',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      align: 'right',
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={() => {
                  setSelectedData(props)
                  setVisibleModalAdd(true)
                }}
              >
                <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
              </div>
              <div
                onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteExpense
                        .fetch(props.id)
                        .then(async () => {
                          refreshData()
                        })
                        .catch(() => {})
                    },
                  })
                }}
              >
                <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
  ]
}

// @ts-ignore
export const columnsCOGS = ({intl, deleteExpense, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PERIOD'}),
      dataIndex: 'periode',
      filterAble: true,
      filterType: 'date-month',
      render: (val: any) => {
        const date = moment(val)
        if (val && date.isValid()) {
          return moment(val).format("MMM YYYY")
        }
        return '-'
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.CATEGORY'}),
      dataIndex: 'category',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      align: 'right',
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={() => {
                  setSelectedData(props)
                  setVisibleModalAdd(true)
                }}
              >
                <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
              </div>
              <div
                onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteExpense
                        .fetch(props.id)
                        .then(async () => {
                          refreshData()
                        })
                        .catch(() => {})
                    },
                  })
                }}
              >
                <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
  ]
}

// @ts-ignore
export const columnsNett = ({intl, navigate, deleteProfitNett, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [{
    title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
    dataIndex: 'business_unit_name',
    filterAble: true,
    filterType: 'business_unit',
    // filterLocal: true,
    // sortAble: true,
    // sortLocal: true,
  },
    {
      title: intl.formatMessage({id: 'LABEL.PERIOD'}),
      dataIndex: 'periode',
      filterAble: true,
      filterType: 'date-month',
      // filterFormatDate: periodeFormat,
      // filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_periode',
      // render: (val: any) => {
      //   return moment(val).format(periodeFormat)
      // }
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      align: 'right',
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_nominal',
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={() => {
                  setSelectedData(props)
                  setVisibleModalAdd(true)
                }}
              >
                <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
              </div>
              <div
                onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteProfitNett
                        .fetch(props.id)
                        .then(async () => {
                          refreshData()
                        })
                        .catch(() => {})
                    },
                  })
                }}
              >
                <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
    ]
}

// @ts-ignore
export const columnsRole = ({intl, navigate, deleteRole, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.NAME'}),
      dataIndex: 'name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      // colSpan: 2,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CREATED_AT'}),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.UPDATED_AT'}),
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      // dataIndex: 'updated_at',
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      // className: 'metro-table-cell-d-none text-end',
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      setSelectedData(props)
                      setVisibleModalAdd(true)
                    }}
                >
                  <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteRole
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {})
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]
}
// @ts-ignore
export const columnsBank = ({intl, navigate, deleteMenu, refreshData, openModal, setDataSelected}) => {
return [
  {
    title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
    dataIndex: 'business_unit_name',
    filterAble: true,
    filterType: 'business_unit',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true
  },
  {
    title: intl.formatMessage({id: 'LABEL.NO_ACCOUNT'}),
    className:'text-nowrap',
    width:'10rem',
    dataIndex: 'account_number',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true
  },
  {
    title: intl.formatMessage({id: 'LABEL.ACCOUNT_NAME'}),
    dataIndex: 'account_name',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true
  },
  {
    title: intl.formatMessage({id: 'LABEL.BANK'}),
    dataIndex: 'mst_bank_nama_bank',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true,
    render: (val: any) => {
      return (val || '').toUpperCase()
    },
  },
  {
    title: intl.formatMessage({id: 'LABEL.BRANCH'}),
    dataIndex: 'bank_branch',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true
  },
  {
    title: intl.formatMessage({id: 'LABEL.CURRENCY'}),
    className:'text-nowrap',
    dataIndex: 'currency_value',
    filterAble: true,
    filterType: 'text',
    // filterLocal: true,
    // sortAble:true,
    // sortLocal: true,
    render: (val: any) => {
      return val.toUpperCase()
    },
  },
  {
    title: intl.formatMessage({id: 'LABEL.STATUS'}),
    dataIndex: 'status_value',
    filterAble: true,
    filterType: 'select',
    // filterLocal: true,
    sortAble:true,
    // sortLocal: true,
    filterData: [
      {value: 'not active', label: 'not active'},
      {value: 'active', label: 'active'},
    ],
    render: (val: any) => {
      if (val === 'not active') {
        return <MetroBadge type={'warning'} content={val} className={'m-2'} />
      } else {
        return <MetroBadge type={'success'} content={val} className={'m-2'} />
      }
    },
  },
  {
    title: intl.formatMessage({id: 'LABEL.ACTION'}),
    fixed: 'right',
    className: 'text-center',
    render: (val: any) => {
      return (
          <MetroButton
              iconOnly
              icon={<i className='las la-edit fs-2 text-center' />}
              type={'success'}
              light
              onClick={async () => {
                openModal(val)
              }}
              size={'small'}
          />
      )
    },
  },
]
}
// @ts-ignore
export const columnsMenu = ({intl, navigate, deleteMenu, refreshData, setModalEditRoleVisible, setDataSelected}) => {
  // id: 8,
  //   name: 'Outstanding AR',
  //   created_at: '2021-11-29T19:43:26.376Z',
  //   updated_at: '2021-11-29T19:43:26.376Z',
  //   deleted_at: null,
  //   created_by: null,
  //   updated_by: null,
  //   deleted_by: null,
  //   parent_menu_id: 6,
  //   menu_position: 8,
  //   menu_path: '/finance/outstanding',
  //   menu_icon: null,
  return [
    {
      title: 'Menu ID',
      dataIndex: 'id',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: 'Menu Parent ID',
      dataIndex: 'parent_menu_id',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NAME'}),
      dataIndex: 'name',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.POSITION'}),
      dataIndex: 'menu_position',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PATH'}),
      dataIndex: 'menu_path',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.ICON'}),
      dataIndex: 'menu_icon',
      // sortAble: true,
      // sortLocal: true,
      filterAble: true,
      // filterLocal: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return <>
          <MetroDropdownMenuV2
            header={
              <span className={'fs-6'}>{intl.formatMessage({id: 'BTN.ACTIONS'})} <i className="fas fa-chevron-down text-reset text-primary" /></span>
            }>
            <div onClick={() => {
              navigate('/master/menu/edit', {
                state: {...props}
              })
            }}><i className='fas fa-edit text-reset'/> {intl.formatMessage({id: 'BTN.EDIT'})}</div>
            <div onClick={() => {
              setDataSelected(props)
              setModalEditRoleVisible(true)
            }}><i className='fas fa-project-diagram text-reset'/> {intl.formatMessage({id: 'BTN.EDIT_ROLE_MENU'})}</div>
            <div onClick={() => {
              confirmDialog({onOk: async () => {
                  deleteMenu.fetch(props.id).then(async () => {
                    await refreshData()
                  }).catch(() => {})
                }})
            }}><i className='fas fa-trash text-reset'/>  {intl.formatMessage({id: 'BTN.DELETE'})}</div>
          </MetroDropdownMenuV2>
        </>
      },
    },
  ]
}

export const columnsHistorySpp = ({intl, getAccPayableJasper, deleteMasterVendor, refreshData, navigate}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.SPP_NUMBER'}),
      className: 'text-nowrap',
      dataIndex: 'spp_number',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_DATE'}),
      className: 'text-nowrap',
      dataIndex: 'request_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.SENDING_BANK_NAME'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_bank_company_bank_nama_bank',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SENDING_NAME'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_bank_company_account_name',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SENDING_ACCOUNT_NAME'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_bank_company_account_number',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TOTAL_AMOUNT'}),
      className: 'text-nowrap',
      dataIndex: 'total',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      className: 'text-nowrap',
      dataIndex: 'payment_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.RECIPIENT_BANK_NAME'}),
      className: 'text-nowrap',
      dataIndex: 'nama_bank_penerima',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true
    },
    {
      title: intl.formatMessage({id: 'LABEL.RECIPIENT_NAME'}),
      dataIndex: 'nama_penerima',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.RECIPIENT_ACCOUNT_NUMBER'}),
      dataIndex: 'nomor',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.AMOUNT'}),
      dataIndex: 'nett',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'payment_type_value',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  Action <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div hidden={!props.spp_number} onClick={() => getAccPayableJasper(props.spp_number)}>
                  <i className='fas fa-download text-reset' />{' '}
                  Download SPP
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]}
export const columnsHistoryAp = ({intl, downloadFileS3, deleteMasterVendor, refreshData, navigate, openModal}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      className: 'text-nowrap',
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.APR_NUMBER'}),
      dataIndex: 'apr_number',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_NUMBER'}),
      dataIndex: 'inv_number',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NETT'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_acc_payable_nett',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PPH23'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_acc_payable_pph23',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PPH21'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_acc_payable_pph21',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PPN'}),
      className: 'text-nowrap',
      dataIndex: 'fnc_acc_payable_ppn',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.GROSS'}),
      className: 'text-nowrap',
      dataIndex: 'nominal',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_RECEIVE_DATE'}),
      dataIndex: 'inv_rec_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_DATE'}),
      dataIndex: 'inv_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_DUE_DATE'}),
      dataIndex: 'fnc_apr_payment_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'fnc_acc_payable_payment_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TYPE'}),
      dataIndex: 'tipe_ap',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_NAME'}),
      dataIndex: 'project_name',
      width: 190,
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_FROM'}),
      dataIndex: 'collectorname',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'ref_parameter_value',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SPP_NUMBER'}),
      dataIndex: 'fnc_acc_payable_spp_number',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.STATUS'}),
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (props: any) => {
        return <MetroButton  size={'small'} onClick={() => {
          openModal(props)
        }}>{props.ap_status}</MetroButton>
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.ATTACHMENT'}),
      dataIndex: 'file',
      className: 'text-center',
      fixed: 'right',
      render: (val: string) => {
        if (val) {
          return (
              <MetroButton
                  light
                  type={'success'}
                  size={'small'}
                  iconOnly
                  icon={<i className='fas fa-download' />}
                  onClick={() => {
                    downloadFileS3({file: val})
                  }}
              />
          )
        }
      },
    },
  ]}

export const columnsAprRequest = ({intl, deleteMasterVendor, base_path, handleClick, openModal, deleteAprReq, refreshData, navigate, getApproval}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      className: 'bg-white',
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_DATE'}),
      dataIndex: 'fnc_apr_inv_date',
      className: 'text-center',
      width: '150px',
      sortAble: true,
      sortParam: 'sort_by_fnc_apr_inv_date',
      // filterAble: true,
      // filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DUE_DATE'}),
      dataIndex: 'fnc_apr_payment_date',
      width: '10rem',
      sortAble: true,
      sortParam: 'sort_by_fnc_apr_payment_date',
      // filterAble: true,
      // filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DESCRIPTION'}),
      className: '',
      dataIndex: 'fnc_apr_payment_desc',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_NUMBER'}),
      dataIndex: 'fnc_apr_inv_number',
      width: '12rem',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      width: '10rem',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        if (val == null) {
          return (
              <MetroBadge
                  type={'danger'}
                  content={intl.formatMessage({id: 'LABEL.NON_PROJECT'})}
                  className={'m-2'}
              />
          )
        } else {
          return <MetroBadge type={'warning'} content={val} className={'m-2'} />
        }
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      dataIndex: 'emp_name',
      filterAble: true,
      filterType: 'text',
      // render: (val: any, row: any) => {
      //   if (val) {
      //     return row.hr_emp_info_first_name + ' ' + row.hr_emp_info_last_name || ''
      //   }
      //   return '-'
      // },
    },
    {
      title: intl.formatMessage({id: 'LABEL.CUSTOMER_NAME'}),
      dataIndex: 'customer_name',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'fnc_apr_payment_date',
      sortAble: true,
      sortParam: 'sort_by_fnc_apr_payment_date',
      // filterAble: true,
      // filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.APR_NUMBER'}),
      dataIndex: 'fnc_apr_apr_number',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.VENDOR_NAME'}),
      dataIndex: 'mst_vendor_name',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        if (val) {
          return val
        }
        return '-'
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_REC_DATE'}),
      dataIndex: 'fnc_apr_inv_rec_date',
      width: '15rem',
      sortAble: true,
      sortParam: 'sort_by_fnc_apr_inv_rec_date',
      // filterAble: true,
      // filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TOTAL_INVOICE'}),
      dataIndex: 'fnc_apr_nominal',
      filterAble: true,
      filterType: 'text',
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.REMARKS'}),
      dataIndex: 'fnc_apr_remarks',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.APPROVAL_STATE'}),
      dataIndex: 'fnc_apr_approval_state',
      filterAble: true,
      filterType: 'select',
      filterData: createOption({api:getApproval,property:'fnc_apr_approval_state'}),
      render: (val: any) => {
        let color: any = '';
        let content = '';
        // process
        // on going
        // revised
        // approve
        if(val === 'process') {
          color = 'info'
          content = 'Processed'
        } else if(val === 'on going') {
          color = 'info'
          content = 'On Going'
        } else if(val === 'revised') {
          color = 'warning'
          content = 'Revised'
        } else if(val === 'approve') {
          color = 'success'
          content = 'Approved'
        } return <div>
          <MetroBadge type={color} content={content} />
        </div>
        // if (val === null) {
        //   return (
        //     <MetroBadge type={'info'} content={intl.formatMessage({id: 'LABEL.IN_PROGRESS'})} />
        //   )
        // }
        // if (val === true) {
        //   return (
        //     <MetroBadge
        //       type={'success'}
        //       content={<i className='fa fa-thumbs-up text-secondary' aria-hidden='true' />}
        //       className={'m-2'}
        //     />
        //   )
        // }
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.USER_NEXT_APPROVAL'}),
      className: '',
      dataIndex: 'user_next_approve',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: '',
      fixed: 'right',
      className: 'text-center',
      width: 100,
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                {[false].includes(props.is_update) ? null : (
                    <div onClick={() => navigate(base_path + '/add-request', {
                      state: {props, edit: true}
                    })}>
                      <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                    </div>
                )}
                {!props.fnc_apr_file ? (
                    ''
                ) : (
                    <div onClick={() => handleClick(props.fnc_apr_file)}>
                      <i className='fas fa-paperclip text-reset' /> Download Attachment
                    </div>
                )}
                <div onClick={() => openModal(props)}>
                  <i className='fas fa-eye text-reset' /> {intl.formatMessage({id: 'BTN.STATUS'})}
                </div>
                {[false].includes(props.is_update) ? null : (
                    <div onClick={() => deleteAprReq(props)}>
                      <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                    </div>
                )}
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]
}
export const columnsApEntry= ({intl, base_path, viewFile, openHistoryModal, deleteAp, deleteMasterVendor, getFncAccPayable, navigate}: any) => {
  return [
    {
      title: 'Busines Unit',
      fixed: 'left',
      className: 'bg-white',
      dataIndex: 'business_unit_name',
      width: '20em',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: 'SPP Number',
      className: 'bg-white',
      dataIndex: 'spp_number',
      width: '20em',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Vendor Name',
      dataIndex: 'mst_vendor_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,

    },
    {
      title: 'No. Invoice',
      dataIndex: 'invoice_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Project ID',
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      render: (val: any) => {
        if (val == null) {
          return <MetroBadge type={'danger'} content={'Non Project'} className={'m-2'} />
        } else {
          return <MetroBadge type={'warning'} content={val} className={'m-2'} />
        }
      },
    },
    {
      title: 'Date',
      dataIndex: 'invoice_date',
      sortAble: true,
      sortParam: 'sort_by_invoice_date',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate
    },
    {
      title: 'Due Date',
      className: 'text-danger',
      dataIndex: 'invoice_due_date',
      sortAble: true,
      sortParam: 'sort_by_invoice_due_date',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate
    },
    {
      title: 'Payment Description',
      dataIndex: 'payment_description',
      width: 200,
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Gross',
      dataIndex: 'gross',
      className: 'text-center',
      sortAble: true,
      sortParam: 'sort_by_gross',
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      ...renderColumnNumberWithRp
    },
    {
      title: 'Nett',
      dataIndex: 'nett',
      className: 'text-center',
      sortAble: true,
      sortParam: 'sort_by_nett',
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      ...renderColumnNumberWithRp
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      width: 200,
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type_value',
      filterAble: true,
      filterType: 'select',
      filterData: createOption({api:getFncAccPayable, property:'payment_type_value'}),
      // filterLocal: true,
    },
    {
      title: 'No. Giro/Cheque',
      dataIndex: 'giro_cheque_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_date',
      className: 'text-success text-center',
      sortAble: true,
      sortParam: 'sort_by_payment_date',
      // filterAble: true,
      // filterType: 'date',
      ...renderColumnDate,
    },
    {
      title: 'No APR',
      dataIndex: 'fnc_apr_apr_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: 'Status',
      dataIndex: 'payment_status',
      filterAble: true,
      filterType: 'select',
      filterData: [
        {value: 'unpaid', label: 'unpaid'},
        {value: 'paid', label: 'paid'},
      ],
      // filterLocal: true,
      render: (val: any) => {
        if (val === 'unpaid') {
          return <MetroBadge type={'warning'} content={val} className={'m-2'} />
        } else {
          return <MetroBadge type={'success'} content={val} className={'m-2'} />
        }
      },
    },
    {
      title: 'Flag Approval',
      dataIndex: 'flag_approve',
      // filterAble: true,
      // filterType: 'select',
      // filterData: [
      //   {value: 'true', label: 'Approve'},
      //   {value: 'null', label: 'Not Set'},
      // ],
      // filterLocal: true,
      render: (val: any) => {
        if (val === true) {
          return (
              <MetroBadge
                  type={'success'}
                  content={<i className='fa fa-thumbs-up text-secondary' aria-hidden='true'></i>}
                  className={'m-2'}
              />
          )
        }
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.USER_NEXT_APPROVAL'}),
      dataIndex: 'fnc_apr_role_approval_user_next_approve',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'bg-white text-center',
      width: 100,
      render: (props: any) => {
        return (
            <>
              {/*<MetroButton*/}
              {/*  disabled={props.payment_status === 'paid'}*/}
              {/*  type={'success'}*/}
              {/*  light*/}
              {/*  size={'small'}*/}
              {/*  iconOnly*/}
              {/*  icon={<i className='las la-money-bill-wave fs-2 text-center' />}*/}
              {/*  onClick={() => paidPay(props)}>*/}
              {/*  +*/}
              {/*</MetroButton>*/}
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  Action <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >

                {props.request_date? null : <div
                    onClick={() => navigate(base_path + '/add', {
                      state: {props, edit: true}
                    })}
                >
                  <i className='fad fa-edit text-reset' /> Edit
                </div> }
                {props.fnc_apr_file? <div onClick={() => viewFile(props.fnc_apr_file)}>
                  <i className='fad fa-paperclip text-reset' />{' '}
                  Download Attachment
                </div> : null }
                {props.spp_number? <div onClick={() => getAccPayableJasper(props.spp_number)}>
                  <i className='fad fa-download text-reset' />{' '}
                  Download SPP
                </div> : null }
                <div onClick={() => openHistoryModal(props)}>
                  <i className='fad fa-eye text-reset' /> {intl.formatMessage({id: 'BTN.STATUS'})}
                </div>
                <div hidden={props.request_date != null} onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteAp(props)
                          .catch(() => {})
                    },
                  })
                }}>
                  <i className='fad fa-trash text-reset' /> Delete
                </div>

              </MetroDropdownMenuV2>

              {/*<MetroButton*/}
              {/*  light*/}
              {/*  iconOnly={true}*/}
              {/*  icon={<i className='las la-trash fs-2 text-center' />}*/}
              {/*  size={'small'}*/}
              {/*  onClick={() => deleteAp(props)}*/}
              {/*></MetroButton>*/}
            </>
        )
      },
    },
  ]
}
export const columnsApPayment = ({intl, deleteMasterVendor, refreshData, navigate}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      className: 'bg-white',
      dataIndex: 'business_unit_name',
      width: '20em',
      filterAble: true,
      filterType: 'text',

    },
    {
      title: intl.formatMessage({id: 'LABEL.VENDOR_NAME'}),
      className: 'bg-white',
      dataIndex: 'mst_vendor_name',
      filterAble: true,
      filterType: 'text',

    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      className: 'bg-white',
      filterAble: true,
      filterType: 'text',
      render: (val :any) => {
        return <>{val.hr_emp_account_info_first_name} {val.hr_emp_account_info_last_name}</>
      }

    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_NUMBER'}),
      dataIndex: 'invoice_number',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.AP_DATE'}),
      dataIndex: 'invoice_date',
      filterAble: true,
      filterType: 'date',
      render: (val: any) => {
        return <div>{moment(val).format('DD/MM/YYYY')}</div>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.DUE_DATE'}),
      className: 'text-danger',
      dataIndex: 'invoice_due_date',
      filterAble: true,
      filterType: 'date',
      render: (val: any) => {
        return <div>{moment(val).format('DD/MM/YYYY')}</div>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DESCRIPTION'}),
      dataIndex: 'payment_description',
      width: 200,
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.GROSS'}),
      dataIndex: 'gross',
      className: 'text-center',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        return <>Rp
          <IntlProvider locale='id'>
            <FormattedNumber
                value={val}
                currency={'idr'} />
          </IntlProvider>
        </>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.NETT'}),
      dataIndex: 'nett',
      className: 'text-center',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        return <>Rp
          <IntlProvider locale='id'>
            <FormattedNumber
                value={val}
                currency={'idr'} />
          </IntlProvider>
        </>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.CUSTOMER_NAME'}),
      dataIndex: 'customer_name',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'payment_type_value',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NO_GIRO_CHEQUE'}),
      dataIndex: 'giro_cheque_number',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'payment_date',
      className: 'text-success text-center',
      filterAble: true,
      filterType: 'date',
      render: (val: any) => {
        return <div>{moment(val).format('DD/MM/YYYY')}</div>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.STATUS'}),
      dataIndex: 'payment_status',
      filterAble: true,
      filterType: 'select',
      filterData: [
        {value: 'unpaid', label: 'unpaid'},
        {value: 'paid', label: 'paid'},
      ],
      render: (val: any) => {
        if (val === 'unpaid') {
          return <MetroBadge type={'warning'} content={val} className={'m-2'} />
        } else {
          return <MetroBadge type={'success'} content={val} className={'m-2'} />
        }
      },
    },
  ]
}
export const columnsRequestApprval = ({intl, paidOrUnpaid, setVisible, setDataDetail}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_ID'}),
      className: 'text-center',
      dataIndex: 'request_reimbursement_transaction_id',
      filterAble: true,
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TOTAL_PAID'}),
      className: 'text-center',
      dataIndex: 'request_reimbursement_total_paid',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NIK'}),
      className: 'text-center',
      dataIndex: 'requestor_co_nik',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.TRANSACTION_DATE'}),
      className: 'text-center',
      dataIndex: 'request_reimbursement_effective_date',
      defaultSort: 'asc',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.REQUESTOR_NAME'}),
      className: 'text-center',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      render: (val: any) => {
        return <>{val.requestor_first_name} {val.requestor_last_name}</>
      },
    },
	{
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      className: 'text-center',
      dataIndex: 'hr_emp_info_name',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      className: 'text-center',
      dataIndex: 'request_reimbursement_notes',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      className: 'text-center',
      dataIndex: 'business_unit_requestor_name',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.REIMBURSE_NAME'}),
      className: 'text-center',
      dataIndex: 'sys_setting_approval_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.STATUS'}),
      className: 'text-center',
      dataIndex: 'status_approval',
      filterAble: true,
      filterType: 'select',
      filterData: [
        {value: 'in progress', label: paidOrUnpaid('in progress')},
        {value: 'complete', label: paidOrUnpaid('complete')},
        {value: 'reject', label: paidOrUnpaid('reject')},
      ],
      defaultFilter: {value: 'in progress'},
      // filterLocal: true,
      render: (val: any) => {
        return <MetroBadge content={val} type={statusColor(val)} light={true} />
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      //fixed: 'left',
      render: (val: any) => {
        return (
            <>
              <MetroButton
                  light
                  size={'small'}
                  iconOnly
                  icon={<i className='fad fa-eye text-center' />}
                  onClick={() => {
                    console.log(val)
                    setVisible(true)
                    setDataDetail(val)
                  }}>
              </MetroButton>
            </>
        )
      },
    }
  ]
}
export const columnsApprovalInformal = ({refTimeline, intl, setDataUser, getRequestAchievReward, setDataDetail, getSpecialRewards, getDetailInformalAward, setVisible, getInformalRewards,getReport,statusApproval }: any) => {
  return [
    {
      title: intl.formatMessage({ id: 'LABEL.EMPLOYEE_NAME' }),
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        return <>
          {val?.emp_name}
        </>
      },
    },
    {
      title: intl.formatMessage({ id: 'LABEL.REQUESTOR' }),
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        return <>
          {val?.requestor_first_name} {val?.requestor_last_name}
        </>
      },
    },
    {
      title: intl.formatMessage({ id: 'LABEL.TYPE' }),
      dataIndex: 'type',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any) => {
        let content = ''
        let type = 'success' as any
        if (val === 'req_informal_rewards') {
          content = 'Informal Rewards'
          type = 'success'
        } else if (val === 'req_achive_reward') {
          content = 'Special Achievement'
          type = 'primary'
        } else {
          content = val
        }
        return <MetroBadge content={content} light type={type} />
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.BONUS_AWARD' }),
      dataIndex: 'bonus',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.REASON' }),
      dataIndex: 'reason',
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
    },
    {
      title: intl.formatMessage({ id: 'LABEL.APPROVAL_STATUS' }),
      dataIndex: 'status_approval',
      filterAble: true,
      filterType: 'select',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      filterData: [
        {value: 'in progress', label: statusApproval('IN_PROGRESS')},
        {value: 'complete', label: statusApproval('COMPLETED')},
        {value: 'reject', label: statusApproval('REJECT')},
      ],
      defaultFilter: {value: 'in progress'},
      render: (val: any) => {
        let content = '-';
        let type: any = 'info';
        if (val === 'in progress') {
          content='In-progress'
          type='warning'
        } else if (val === 'reject') {
          content='Rejected'
          type='danger'
        } else if (val === 'complete') {
          content='Complete'
          type='success'
        }
        return <MetroBadge content={content} light type={type} />
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.CURRENT_STATUS'}),
      filterAble: true,
      filterType: 'text',
      filterLocal: true,
      sortAble: true,
      sortLocal: true,
      render: (val: any, props: any) => {
        return <span className={'text-primary cursor-pointer'} onClick={() => {
          refTimeline.current.show(props.history_proses)
        }}>{val.current_status}</span>
      }
    },
    {
      title: intl.formatMessage({ id: 'LABEL.ACTION' }),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({ id: 'BTN.ACTIONS' })}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={async () => {
                  setDataUser(props)
                  if (props.type === 'req_achive_reward') {
                    const params = {
                      hr_t_proses_id: props.id
                    }
                    await getRequestAchievReward.fetch(params.hr_t_proses_id).then(async ({ data }: any) => {
                      setVisible(true)
                      await setDataDetail(data.data)
                      await getSpecialRewards()
                    }).catch(() => { })
                  } else if (props.type === 'req_informal_rewards') {
                    await getDetailInformalAward.fetch(props.id).then(async ({ data }: any) => {
                      setVisible(true)
                      await setDataDetail(data.data[0])
                      await getInformalRewards()
                    })
                  }
                }}
              >
                <i className='fad fa-eye text-reset' /> {intl.formatMessage({ id: 'LABEL.VIEW' })}
              </div>
              <div onClick={() => getReport({type: props.type, id: props.id})}>
                <i className='fad fa-download text-reset' />{' '}
                {intl.formatMessage({id: 'BTN.DOWNLOAD_REPORT'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
  ]
}
export const columnsVendor = ({intl, deleteMasterVendor, refreshData, navigate}: any) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.VENDOR_ID'}),
      dataIndex: 'id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },{
      title: intl.formatMessage({id: 'LABEL.VENDOR_TYPE'}),
      dataIndex: 'vendor_type_value',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NAME'}),
      dataIndex: 'name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NPWP'}),
      dataIndex: 'npwp',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CONTACT_PERSON'}),
      dataIndex: 'contact_person',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.MOBILE_PHONE_NUMBER'}),
      dataIndex: 'mobile_phone_number',
      className: 'metro-table-thead-cell-ellipsis',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.POSITION'}),
      dataIndex: 'position',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PHONE_NUMBER'}),
      dataIndex: 'phone_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMAIL'}) + '1',
      dataIndex: 'email1',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      render: (val: any) => {
        return (
          <div className={'text-wrap w-200px'}>
            {val}
          </div>
        )
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMAIL'}) + '2',
      dataIndex: 'email2',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ADDRESS'}) + '1',
      dataIndex: 'address1',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ADDRESS'}) + '2',
      dataIndex: 'address2',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ADDRESS'}) + '3',
      dataIndex: 'address3',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.FAX'}),
      dataIndex: 'fax',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.BANK_KLIRING_CODE'}) ,
      dataIndex: 'mst_bank_kliring_code',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.BANK_NAME'}) ,
      dataIndex: 'mst_bank_nama_bank',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.BANK_ACCOUNT'}) ,
      dataIndex: 'bank_account',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACCOUNT_NAME'}),
      dataIndex: 'account_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CREATED_AT'}),
      dataIndex: 'created_at',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.UPDATED_AT'}),
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      dataIndex: 'updated_at',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={() => {
                  navigate(window.location.pathname + '/edit', {
                    state: {...props}
                  })
                }}
              >
                <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
              </div>
              <div
                onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteMasterVendor
                        .fetch(props.id)
                        .then(async () => {
                          refreshData()
                        })
                        .catch(() => {})
                    },
                  })
                }}
              >
                <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
  ]
}
// @ts-ignore
export const columnsTargetSales = ({intl, navigate, deleteMstSalesTarget, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.DATE'}),
      dataIndex: 'date',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SALES_NAME'}),
      dataIndex: 'sales_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      align: 'right',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary'/>
                </span>
                  }
              >
                <div
                    onClick={() => {
                      setSelectedData(props)
                      setVisibleModalAdd(true)
                    }}
                >
                  <i className='fas fa-edit text-reset'/> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteMstSalesTarget
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {
                              })
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset'/> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]
}


// @ts-ignore
export const columnsGross = ({intl, navigate, deleteProfitGross, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PERIOD'}),
      dataIndex: 'periode',
      filterAble: true,
      filterType: 'date-month',
      // filterFormatDate: periodeFormat,
      // filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_periode',
      // render: (val: any) => {
      //   return moment(val).format(periodeFormat)
      // },
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      render: (val: any) => {
        if (val) {
          return val
        }
        return '-'
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.SALES_NAME'}),
      dataIndex: 'sales_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_sales_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      align: 'right',
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      sortAble: true,
      sortLocal: true,
      sortParam: 'sort_by_nominal',
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
          <>
            <MetroDropdownMenuV2
              header={
                <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                  <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
              }
            >
              <div
                onClick={() => {
                  setSelectedData(props)
                  setVisibleModalAdd(true)
                }}
              >
                <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
              </div>
              <div
                onClick={() => {
                  confirmDialog({
                    onOk: async () => {
                      deleteProfitGross
                        .fetch(props.id)
                        .then(async () => {
                          refreshData()
                        })
                        .catch(() => {})
                    },
                  })
                }}
              >
                <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
              </div>
            </MetroDropdownMenuV2>
          </>
        )
      },
    },
  ]
}

// @ts-ignore
export const columnsAssign= ({intl, navigate, deleteFncReimburseAssign, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.REIMBURSEMENT_NAME'}),
      dataIndex: 'fnc_reimburse_policy_reimburse_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'description',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.FLAG_ACTIVE'}),
      dataIndex: 'is_active',
      render: (val:any)=>val+''
    },
    {
      title: intl.formatMessage({id: 'LABEL.EFFECTIVE_DATE'}),
      dataIndex: 'effective_date',
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      width: 100,
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      navigate(window.location.pathname + '/edit', {
                        state: {...props}
                      })

                    }}
                >
                  <i className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteFncReimburseAssign
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {})
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]}
// @ts-ignore
export const columnsSchedulePayment= ({intl, navigate, deleteSchPayment, refreshData, setVisibleModalAdd, setSelectedData, setIsEdit}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      className: 'text-center',
      dataIndex: 'business_unit_name',
      //fixed: 'left',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      className: 'text-center',
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_NAME'}),
      dataIndex: 'project_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SCHEDULE_DATE'}),
      className: 'text-center',
      dataIndex: 'sch_date',
      filterAble: true,
      filterType: 'date',
      filterLocal: true,
      // sortAble: true,
      // defaultSort: 'asc',
      // sortLocal: true,
      ...renderColumnMonthDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // defaultSort: 'asc',
      // sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_INFORMATION'}),
      dataIndex: 'payment_information',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SALES_NAME'}),
      fixed: 'right',
      className: 'text-center',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      render: (val: any) => {
        return (<div>{val.created_by_emp_first_name} {val.created_by_emp_last_name}</div>)
      },

    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      setSelectedData(props)
                      setVisibleModalAdd(true)
                      setIsEdit(false)
                      // navigate(window.location.pathname + '/edit', {...props})
                    }}
                >
                  <i className='fad fa-eye text-reset' /> {intl.formatMessage({id: 'LABEL.VIEW'})}
                </div>
                <div
                    onClick={() => {
                      setSelectedData(props)
                      setVisibleModalAdd(true)
                      setIsEdit(true)
                      // navigate(window.location.pathname + '/edit', {...props})
                    }}
                >
                  <i className='fad fa-edit text-reset' /> {intl.formatMessage({id: 'LABEL.EDIT'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteSchPayment
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {
                              })
                        },
                      })
                    }}
                >
                  <i className='fad fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]}

// @ts-ignore
export const columnsViewSchedulePayment= ({intl, deleteSchPayment, refreshData, setDataModal, setVisible}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      //fixed: 'left',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      className: 'text-center',
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_NAME'}),
      dataIndex: 'project_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SCHEDULE_DATE'}),
      className: 'text-center',
      dataIndex: 'sch_date',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // defaultSort: 'asc',
      // sortLocal: true,
      ...renderColumnMonthDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // defaultSort: 'asc',
      // sortLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SALES_NAME'}),
      fixed: 'right',
      className: 'text-center',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        return <>
          {val.created_by_emp_first_name} {val.created_by_emp_last_name}
        </>
      }
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      setDataModal(props)
                      setVisible(true)
                      // navigate(window.location.pathname + '/edit', {...props})
                    }}
                >
                  <i className='fad fa-eye text-reset' /> {intl.formatMessage({id: 'LABEL.VIEW'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]}
// @ts-ignore
export const columnsAccount = ({intl, deleteSchPayment, navigate, formik, refreshData, setDataModal, openModal}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.EMAIL'}),
      dataIndex: 'email',
      filterAble: true,
      filterType: 'text',
      render: (val: any) => {
        return <a href={`mailto:${val}`}>{val}</a>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
      dataIndex: 'emp_full_name',
      className: 'text-nowrap',
      filterAble: true,
      filterType: 'text',
    },
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      sortAble: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ROLE'}),
      sortAble: true,
      dataIndex: 'role_name',
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (val: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>{intl.formatMessage({id: 'LABEL.ACTION'})} <i
                        className='fas fa-chevron-down text-reset text-primary' /></span>
                  }>
                <div onClick={() => {
                  formik.setFieldValue('company_id', val.business_unit_id)
                  formik.setFieldValue('hr_emp_info_id', val.hr_emp_info_id)
                  formik.setFieldValue('role_id', val.role_id)
                  openModal(val)
                }}><i
                    className='fas fa-edit text-reset' /> {intl.formatMessage({id: 'BTN.EDIT'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]
}
// @ts-ignore
export const columnsPolicy = ({intl, navigate, deleteFncReimbursePolicy, refreshData, setVisibleModalAdd, setSelectedData}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.REIMBURSEMENT_NAME'}),
      dataIndex: 'reimburse_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.EFFECTIVE_DATE'}),
      dataIndex: 'effective_date',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.EXPIRED_DATE'}),
      dataIndex: 'expired_date',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.REIMBURSEMENT_LIMIT'}),
      dataIndex: 'reimburse_limit_amt',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.DESCRIPTION'}),
      dataIndex: 'reimburse_desc',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      // sortAble: true,
      // sortLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (props: any) => {
        return (
            <>
              <MetroDropdownMenuV2
                  header={
                    <span className={'fs-6'}>
                  {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                      <i className='fas fa-chevron-down text-reset text-primary' />
                </span>
                  }
              >
                <div
                    onClick={() => {
                      navigate(window.location.pathname + '/edit', {
                        state: {...props}
                      })
                      // setSelectedData(props)
                      // setVisibleModalAdd(true)
                    }}
                >
                  <i className='fas fa-eye text-reset' /> {intl.formatMessage({id: 'LABEL.VIEW'})}
                </div>
                <div
                    onClick={() => {
                      confirmDialog({
                        onOk: async () => {
                          deleteFncReimbursePolicy
                              .fetch(props.id)
                              .then(async () => {
                                refreshData()
                              })
                              .catch(() => {})
                        },
                      })
                    }}
                >
                  <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                </div>
              </MetroDropdownMenuV2>
            </>
        )
      },
    },
  ]}

  // @ts-ignore
  export const columnsNaturaPolicy = ({intl, navigate, deleteFncNaturaPolicy, refreshData, setVisibleModalAdd, setSelectedData}) => {
    return [
      {
        title: intl.formatMessage({id: 'LABEL.NATURA_POLICY'}),
        dataIndex: 'jenis_natura',
        filterAble: true,
        filterType: 'text',
        // filterLocal: true,
        // sortAble: true,
        // sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.LIMITATION'}),
        dataIndex: 'limitation',
        filterAble: true,
        filterType: 'text',
        // filterLocal: true,
        // sortAble: true,
        // sortLocal: true,
        ...renderColumnNumberWithRp
      },
      {
        title: intl.formatMessage({id: 'LABEL.LIMITATION_COMPANY'}),
        dataIndex: 'limitation_company',
        filterAble: true,
        filterType: 'text',
        // filterLocal: true,
        // sortAble: true,
        // sortLocal: true,
        ...renderColumnNumberWithRp
      },
      {
        title: intl.formatMessage({id: 'LABEL.TYPE'}),
        dataIndex: 'type',
        filterAble: true,
        filterType: 'text',
        // filterLocal: true,
        // sortAble: true,
        // sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.ACTION'}),
        fixed: 'right',
        className: 'text-center',
        render: (props: any) => {
          return (
              <>
                <MetroDropdownMenuV2
                    header={
                      <span className={'fs-6'}>
                    {intl.formatMessage({id: 'BTN.ACTIONS'})}{' '}
                        <i className='fas fa-chevron-down text-reset text-primary' />
                  </span>
                    }
                >
                  <div
                      onClick={() => {
                        navigate(window.location.pathname + '/edit', {
                          state: {...props}
                        })
                        // setSelectedData(props)
                        // setVisibleModalAdd(true)
                      }}
                  >
                    <i className='fas fa-eye text-reset' /> {intl.formatMessage({id: 'LABEL.EDIT'})}
                  </div>
                  <div
                      onClick={() => {
                        confirmDialog({
                          onOk: async () => {
                            deleteFncNaturaPolicy
                                .fetch(props.id)
                                .then(async () => {
                                  refreshData()
                                })
                                .catch(() => {})
                          },
                        })
                      }}
                  >
                    <i className='fas fa-trash text-reset' /> {intl.formatMessage({id: 'BTN.DELETE'})}
                  </div>
                </MetroDropdownMenuV2>
              </>
          )
        },
      },
    ]}


  // @ts-ignore
export const columnsOutstandingAr = ({intl, navigate, setVisible, setPayload, getFncAcc}) => {
  return [
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      fixed: 'left',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DATE'}),
      dataIndex: 'invoice_date',
      sortAble:true,
      sortParam: 'sort_by_invoice_date',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.DUE_DATE'}),
      dataIndex: 'invoice_due_date',
      className: 'text-danger',
      sortAble:true,
      sortParam: 'sort_by_invoice_due_date',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.AGING_DAY'}),
      dataIndex: 'aging_day',
      className: 'text-center',
      sortAble:true,
      sortParam: 'sort_by_aging_day',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      render: (val: any) => {
        if (val < 0) {
          return (
            <div className={'text-center'}>
              <MetroBadge type={'danger'} content={val} className={'m-2'} />
            </div>
          )
        } else {
          return <div>{val}</div>
        }
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
      render: (val: any) => {
        return <div className={'fw-bold'}>{val}</div>
      },
    },
    {
      title: intl.formatMessage({id: 'LABEL.INVOICE_NUMBER'}),
      dataIndex: 'invoice_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NETT'}),
      dataIndex: 'nett',
      className: 'text-center',
      sortAble: true,
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.GROSS'}),
      dataIndex: 'gross',
      className: 'text-center',
      sortAble: true,
      // filterAble: true,
      // filterType: 'text',
      // filterLocal: true,
      ...renderColumnNumberWithRp,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DESCRIPTION'}),
      dataIndex: 'payment_description',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.CUSTOMER_NAME'}),
      dataIndex: 'customer_name',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_TYPE'}),
      dataIndex: 'payment_type_value',
      filterAble: true,
      filterType: 'select',
      filterData: createOption({api: getFncAcc, property:'payment_type_value'}),
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.NO_GIRO_CHEQUE'}),
      dataIndex: 'giro_cheque_number',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.PAYMENT_DATE'}),
      dataIndex: 'payment_date',
      className: 'text-success text-center',
      sortAble: true,
      sortParam : 'sort_by_payment_date',
      // filterAble: true,
      // filterType: 'date',
      // filterLocal: true,
      ...renderColumnDate,
    },
    {
      title: intl.formatMessage({id: 'LABEL.STATUS'}),
      dataIndex: 'payment_status',
      filterAble: true,
      filterType: 'select',
      filterData: createOption({api: getFncAcc, property:'payment_status'}),
      // filterLocal: true,
      // filterData: [
      //   {value: 'unpaid', label: 'unpaid'},
      //   {value: 'paid', label: 'paid'},
      // ],
      render: (val: any) => {
        if (val === 'unpaid') {
          return (
            <div className={'text-center'}>
              <MetroBadge type={'warning'} content={val} className={'m-2'} />
            </div>
          )
        } else {
          return (
            <div className={'text-center'}>
              <MetroBadge type={'success'} content={val} className={'m-2'} />
            </div>
          )
        }
      }, },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (val: any) => {
              return (
                <>
                {/* <MetroTooltip tooltip={'Payment'} trigger={'hover'}> */}
                    <MetroButton
                      className={'me-3'}
                      disabled={val.payment_status === 'paid'}
                      iconOnly
                      icon={<i className='las la-money-bill-wave fs-2 text-center' />}
                      type={'success'}
                      light
                      onClick={ () => {
                        setVisible(true)
                        setPayload(val)
                      }}
                      size={'small'}
                    >
                      Paid
                    </MetroButton>
                    {/* </MetroTooltip> */}
                  <MetroButton
                    disabled={val.payment_status === 'paid'}
                    iconOnly
                    icon={<i className='las la-edit fs-2 text-center' />}
                    type={'primary'}
                    light
                    onClick={(prop: any) => {
                        console.log(val)
                        navigate('/finance/outstanding/edit', {
                          state: {val, edit: true},
                        })
                      }}
                    size={'small'}
                  />
                </>
              )
            },
    },
  ]
}

// @ts-ignore
export const columnsOutstandingArCol = ({intl,editPageModal, navigate}) => {
  return  [
    {
      title: intl.formatMessage({id: 'LABEL.PROJECT_ID'}),
      dataIndex: 'project_id',
      fixed: 'left',
      filterAble: true,
      filterType: 'text',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.BUSINESS_UNIT'}),
      dataIndex: 'business_unit_name',
      fixed: 'left',
      filterAble: true,
      filterType: 'business_unit',
      // filterLocal: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.SCHEDULE_DATE'}),
      dataIndex: 'sch_date',
      fixed: 'left',
      filterAble: true,
      filterType: 'date',
      // defaultSort: 'asc',
      // filterLocal: true,
      ...renderColumnDate
    },
    {
      title: intl.formatMessage({id: 'LABEL.NOMINAL'}),
      dataIndex: 'nominal',
      fixed: 'left',
      filterAble: true,
      filterType: 'date',
      // filterLocal: true,
      ...renderColumnNumberWithRp
    },
    {
      title: intl.formatMessage({id: 'LABEL.ACTION'}),
      fixed: 'right',
      className: 'text-center',
      render: (val: any) => {
        return (
          <>
            <MetroButton
             // disabled={val.payment_status === 'paid'}
              iconOnly
              icon={<i className='fad fa-plus fs-2 text-center' />}
              type={'primary'}
              light
              onClick={(e) => editPageModal(val)}
              size={'small'}
            />
          </>
        )
      },
    },
  ]}
