import {AuthModel} from './_models'
import {
    currentAccessToken,
    currentRefreshToken,
    doRefreshToken,
    fakePromise,
    isAccessTokenExpire,
} from "../../../../source/utils";
import {postErrorRepoApi} from '../../../../source/apis';

const {REACT_APP_API_HEADER_KEY, REACT_APP_API_KEY, REACT_APP_PROD_SITE, NODE_ENV} = process.env
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// export function setupAxios(axios: any) {
//   axios.defaults.headers.Accept = 'application/json'
//   axios.interceptors.request.use(
//     (config: {headers: {Authorization: string}}) => {
//       const auth = getAuth()
//       if (auth && auth.accessToken) {
//         config.headers.Authorization = `Bearer ${auth.accessToken}`
//       }
//
//       return config
//     },
//     (err: any) => Promise.reject(err)
//   )
// }
let isRefreshingToken = false
const checkIsRefreshingToken = async () => new Promise<any>( (resolve) => {
    console.log('checkIsRefreshingToken')
    const check = async () => {
        if (isRefreshingToken) {
            await fakePromise(1000 * 10)
            if (isRefreshingToken) {
                check()
            } else {
                resolve(true)
            }
        } else {
            resolve(true)
        }
    }
    check()
})
export function setupAxios(axios: any) {
  axios.interceptors.request.use(
      async (config: any) => {
        if (NODE_ENV === 'production' && window.location.host === REACT_APP_PROD_SITE) {
          // @ts-ignore
          config.headers[REACT_APP_API_HEADER_KEY] = REACT_APP_API_KEY
        }

        const url = (new URL(config.url))
        if (['/v2/sysparam/auth/refresh', '/v2/sysparam/auth/signout'].includes(url.pathname)) {
          config.headers.Authorization = `Bearer ${currentRefreshToken()}`
        } else if (currentAccessToken() && config._useToken) {
          await checkIsRefreshingToken()
          if (!isRefreshingToken && isAccessTokenExpire(currentAccessToken() || '')) {
            isRefreshingToken = true
            await doRefreshToken()
            isRefreshingToken = false
          }
          await checkIsRefreshingToken()
          isRefreshingToken = false
          config.headers.Authorization = `Bearer ${currentAccessToken()}`
        }
        return config
      },
      (err: any) => Promise.reject(err)
  )

  // const {accesstoken, refreshtoken} = result?.data?.token || {}
  // setHasErrors(false)
  // setLoading(false)
  // localStorage.removeItem('isAuthenticated')
  // dispatch(auth.actions.login(accesstoken, refreshtoken))

//     axios.interceptors.response.use(
//         async (res: any) => {
//             return res;
//         },
//         async (err: { config: any; response: { status: number; }; }) => {
//             const originalConfig = err.config;
//             const url = (new URL(originalConfig.url))
//             if (!['/sysparam/auth/signin'].includes(url.pathname) && err.response) {
//                 // Access Token was expired
//                 if (err.response.status === 401 && !originalConfig._retry) {
//                     originalConfig._retry = true;
//                     try {
//                         isRefreshingToken = true
//                         const {data} = await refreshTokenApi()
//                         const { accesstoken, refreshtoken } = data?.data?.token || {};
//                         setUser({
//                             ...getUser(),
//                             accessToken: accesstoken,
//                             refreshToken: refreshtoken
//                         })
//                         store?.dispatch(actions?.login(accesstoken, refreshtoken))
//                         setIsExpire(false)
//                         store?.dispatch(actions?.setExpire(false))
//                         await fakePromise(800)
//                         return axios(originalConfig)
//                     } catch (_error) {
//                         store?.dispatch(actions?.setExpire(true))
//                         return Promise.reject(_error);
//                     } finally {
//                         isRefreshingToken = false
//                     }
//                 }
//             }
//             return Promise.reject(err);
//         }
//     );
  axios.interceptors.response.use(
      async (res: any) => {
        return res;
      },
      async (err: {message: any; config: any; response: { status: number; }; }) => {
          const auth = getAuth()
        const originalConfig = err.config;
        const url = (new URL(originalConfig?.url))
        if (!['/v2/sysparam/auth/signin'].includes(url.pathname) && err.response) {
          // Access Token was expired
          if (['/v2/sysparam/auth/refresh'].includes(url.pathname)) {
            // store?.dispatch(actions?.setExpire(true))
          }
        }

        if (
            ![
              '/v2/sysparam/error-repo',
              '/v2/sysparam/auth/signin',
              '/v2/sysparam/auth/refresh',
            ].includes(url.pathname) &&
            !!auth?.accessToken &&
            !['Operation canceled by the user.'].includes(err.message)
        ) {
          try {
            // console.log('window?.navigator?.userAgent', window?.navigator?.userAgent);
            // console.log('window?.navigator?.userAgent2', navigator.userAgent);
            postErrorRepoApi({
              user_agent: window?.navigator?.userAgent,
              error_date: new Date(),
              error_data: JSON.stringify(
                  err?.response || {
                    message: err.message,
                  },
              ),
            },)
                .then(() => {
                })
                .catch(() => {
                });
          } catch (e) {
          }
        }

        return Promise.reject(err);
      }
  );
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
