/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'
import {useLocation} from "react-router";

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  toolbarContent?: React.ReactNode
  setToolbarContent: (_node: React.ReactNode) => void
  toolbarAction?: React.ReactNode
  setToolbarAction: (_node: React.ReactNode) => void
  showBackButton?: boolean
  setShowBackButton: (_isShow: boolean) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setToolbarContent: (_node: React.ReactNode) => {},
  setToolbarAction: (_node: React.ReactNode) => {},
  setShowBackButton: (_isShow: boolean) => {}
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [toolbarContent, setToolbarContent] = useState<React.ReactNode>(null)
  const [toolbarAction, setToolbarAction] = useState<React.ReactNode>(null)
  const [showBackButton, setShowBackButton] = useState<boolean>(false)
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    toolbarContent,
    setToolbarContent,
    toolbarAction,
    setToolbarAction,
    showBackButton,
    setShowBackButton,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  showBackButton?: boolean
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs, showBackButton}) => {
  const location = useLocation()
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setShowBackButton} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    setShowBackButton(showBackButton ?? (location.key !== 'default' && !!location.key))
    // return () => {
    //   setShowBackButton(false)
    // }
  }, [showBackButton, location.key]);

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
