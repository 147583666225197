/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import moment from 'moment'
import {useIntl} from 'react-intl'
type Props = {
  data: any
}


const Notification: FC<Props> = ({data}) => {
  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-lg-325px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold mb-3'>Notifications</h3>

        {/*<span className='badge bg-primary py-2 px-3'>25 pending tasks</span>*/}
      </div>

      <div className='row g-0'>
        {/*{*/}
        {/*  data?.map((el: any, index: any) => {*/}
        {/*    return(*/}
        {/*      <>*/}
        {/*        <div id={index}>*/}
        {/*          {el.fnc_apr_apr_number}*/}
        {/*        </div>*/}
        {/*      </>*/}
        {/*    )*/}
        {/*  })*/}
        {/*}*/}
        <div className='mx-5 my-4' data-kt-search-element='main'>
          <div className='d-flex flex-stack fw-bold mb-4'>
            <span className='text-muted fs-6 me-2'>Due Today:</span>
          </div>
          <div className='scroll-y mh-200px mh-lg-325px'>
            {data? data?.map((el: any, index: any) => {
              return(
                <div key={index}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='symbol symbol-40px me-4'>
																	<span className='symbol-label bg-light'>
                                    <span className='svg-icon svg-icon-2 svg-icon-primary'>
																			<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                                           fill='none'>
																				<path d='M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z'
                                              fill='black'></path>
																				<path opacity='0.3' d='M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z'
                                              fill='black'></path>
																				<path opacity='0.3' d='M15 17H9V20H15V17Z' fill='black'></path>
																			</svg>
																		</span>
																	</span>
                    </div>
                    <div className='d-flex flex-column'>
                      <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>{el.invoice_number}</a>
                      <span className='fs-7 text-muted fw-bold'>Date: {moment(el.invoice_due_date).format("DD/MM/YYYY")} Rp. {intl.formatNumber(el.nett)}</span>
                    </div>
                  </div>
                </div>
              )
            }) : <div>No Due or Overdue today</div>}
            {data?.length > 0 ? null  : <div>No Due or Overdue today</div>}
          </div>
        </div>
      </div>

      <div className='py-2 text-center'>
        {/*<Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>*/}
        {/*  View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />*/}
        {/*</Link>*/}
      </div>
    </div>
  )
}

export {Notification}
