/* eslint-disable react-hooks/exhaustive-deps */
import { errorMessage, randomString, toast, toTitleCase } from "../../utils";
import moment from "moment";
import { MetroModal } from "../MetroModal";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFetch } from "../../hooks";
import { getHrTProsesApprovalApi } from "../../apis";
import { MetroTooltip } from "../MetroTooltip";
import { MetroFallbackView } from "../MetroFallbackView";

export const ModalTimelineProsesApproval = forwardRef(
  (
    props: {
      keyId?: string
      keyAmount?: string
    },
    ref
  ) => {
    const {
      keyId = 'hr_t_proses_id',
      keyAmount = 'est_cost'
    } = props
    const [data, setData] = useState(null) as any
    const [visible, setVisible] = useState(false)
    const getHrTProsesApproval = useFetch({
      apiFunction: getHrTProsesApprovalApi,
    })
    const dataHistory = getHrTProsesApproval.list

    const resetData = () => {
      getHrTProsesApproval.reset()
      setData(null)
    }
    useImperativeHandle(ref, () => ({
      show: (data: any) => {
        setVisible(true)
        setData(data)
      },
      hide: () => {
        setVisible(false)
        resetData()
      },
    }))

    useEffect(() => {
      const checkIsProject = (): boolean => {
        return !!data?.is_project || !!data?.project_id
      }
      
      if (visible && dataHistory.length === 0 && data) {
        if (!data[keyId]) {
          toast.danger(keyId + ' is undefined')
          return
        }
        const param = {
          id: data[keyId],
          amount: data[keyAmount] || 0,
          is_project: checkIsProject()
          // ...config.paramsPageAndPagination
        }
        if(param.is_project){
          getHrTProsesApproval.fetch({...param, project_id: data?.project_id})
          return
        }
        getHrTProsesApproval.fetch(param)
      }
    }, [visible, data])
    return (
      <MetroModal
        destroyOnClose={true}
        title={'Approval Status'}
        size={'medium'}
        footer={false}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        visible={visible}
        onDismiss={() => {
          setVisible(false)
          resetData()
        }}
      >
        <div className={'row my-1'}>
          <div className={'col-12'}>
            {getHrTProsesApproval.isLoading ? (
              <MetroFallbackView />
            ) : getHrTProsesApproval.isError ? (
              <div className={'text-muted'}>{errorMessage(getHrTProsesApproval.error)}</div>
            ) : dataHistory.length === 0 ? (
              <div className='my-5 fs-3 text-gray-400 text-center'>
                <span className={'fs-5'}>No data history</span>
              </div>
            ) : (
              <div className='timeline-label'>
                {dataHistory.map((el: any, index: any) => {
                  let icon = ''
                  let status_color = ''
                  let reject_message = ''
                  if (el.status_approval === 'complete' || (el.status_approval === 'revised' && el.reject_notes === '')) {
                    icon = 'fa-check'
                    status_color = 'text-success'
                  } else if (el.status_approval === 'in progress') {
                    icon = 'fa-clock'
                    status_color = 'text-warning'
                  } else if (el.status_approval === 'reject') {
                    icon = 'fa-times-circle'
                    status_color = 'text-danger'
                    reject_message = ` ${el.reject_notes}`
                  } else if (el.status_approval === 'revised') {
                    icon = 'fa-times-circle'
                    status_color = 'text-info'
                    reject_message = ` ${el.reject_notes}`
                  } else {
                    icon = 'fa-genderless'
                    status_color = 'text-muted'
                  }
                  return (
                    <div className='timeline-item align-items-start' key={index.toString()}>
                      <div className='timeline-label font-weight-bolder text-dark-75'></div>
                      <div key={randomString()} className='timeline-badge'>
                        <i className={`fas ${icon} ${status_color} fs-3`} />
                      </div>
                      <div className='timeline-content fw-mormal text-muted ps-3'>
                        <MetroTooltip zIndex={11000} tooltip={el.status_approval ? 'Status : ' + toTitleCase(el.status_approval) : null}>
                          <span className={status_color}>{el.job_position}</span>{' '}
                          <span className={'fw-normal'}>{el.fullname ? `(${el.fullname})` : ''}</span>
                        </MetroTooltip>
                        {el.approved_at && (
                          <div>
                            <span>
                              {moment(el.approved_at).format('dddd, DD MMMM YYYY, HH:mm:ss')}
                            </span>
                          </div>
                        )}
                        <div>
                          {reject_message ? (
                            <>
                              <i className={`fas fa-comment`} />
                              {reject_message}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* <div className={'col-1'} /> */}
        </div>
      </MetroModal>
    )
  }
)
