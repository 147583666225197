import React, {useRef} from 'react'
import {ButtonProps, MetroButton} from '../'
import clsx from 'clsx'
import {randomString} from '../../utils'
import { data_color } from '../../types'
interface MetroButtonGroupProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  vertical?: boolean
  size?: 'large' | 'small'
}
interface MetroButtonGroupRadioProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  outline?: true
  type?: data_color
  defaultChecked?: boolean | undefined;
  checked?: boolean | undefined;
}

const Radio = (props: MetroButtonGroupRadioProps) => {
  const id = useRef(randomString(8))
  return (
    <>
      <input
        type='radio'
        className='btn-check'
        name='btnradio'
        id={id.current}
        autoComplete='off'
        defaultChecked={props.defaultChecked}
        checked={props.checked}
      />
      <label className={clsx(
        'btn',
        props.outline && `btn-outline btn-outline-${props.type || 'primary'}`,
        !props.outline && 'btn-primary',
        props.className)} htmlFor={id.current}>
        {props.children}
      </label>
    </>
  )
}

const Checkbox = (props: MetroButtonGroupRadioProps) => {
  const id = useRef(randomString(8))
  return (
    <>
      <input
        type='checkbox'
        className='btn-check'
        name='btnradio'
        id={id.current}
        autoComplete='off'
        defaultChecked={props.defaultChecked}
        checked={props.checked}
      />
      <label className={clsx(
        'btn',
        props.outline && `btn-outline btn-outline-${props.type || 'primary'}`,
        !props.outline && 'btn-primary',
        props.className)} htmlFor={id.current}>
        {props.children}
      </label>
    </>
  )
}

const Button = (props: ButtonProps) => {
  return <MetroButton {...props} />
}

const MetroButtonGroup = (props: MetroButtonGroupProps) => {
  return (
    <div className={clsx(
      !props.vertical && 'btn-group',
      props.size === 'small' && 'btn-group-sm',
      props.size === 'large' && 'btn-group-lg',
      props.vertical && 'btn-group-vertical',
      props.className
    )} role='group'>
      {props.children}
    </div>
  )
}

MetroButtonGroup.Button = Button
MetroButtonGroup.Radio = Radio
MetroButtonGroup.Checkbox = Checkbox

export {MetroButtonGroup}
