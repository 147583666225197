import React from 'react'
import DatePicker, {ReactDatePickerProps} from 'react-datepicker'
import clsx from 'clsx'
import {range} from 'lodash'
import moment from 'moment'
import {deleteNegativeValueInObject} from '../../utils'

// @ts-ignore
export interface MetroDatePickerProps extends ReactDatePickerProps {
  addClassName?: string
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  sizeInput?: 'small' | 'large'
  availableChangeYearAndMonth?: boolean
  onChange?(
    date: Date | [Date | null, Date | null] | /* for selectsRange */ null,
    event: React.SyntheticEvent<any> | undefined
  ): void
}

const MetroDatePicker = (props: MetroDatePickerProps) => {
  // @ts-ignore
  const years = range(1990, 2050, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => {
    if (props.showYearPicker) {
      return undefined
    }
    return (
      <div className={'d-flex justify-content-center'}>
        <button
          className={'background-none border-none'}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          type='button'
          aria-label='Previous Month'
        >
          <i className='fa fas fa-chevron-left' />
        </button>
        {/*<Select*/}
        {/*  value={years.find((el: any) => String(el.value) === String(moment(date).format('YYYY')))}*/}
        {/*  options={years}*/}
        {/*  // value={this.state.selectedValue}*/}
        {/*  // onChange={this.optionChanged}*/}
        {/*  // getOptionLabel={option => option}*/}
        {/*  // getOptionValue={option => option}*/}
        {/*/>*/}

        <div className={'d-flex justify-content-center'} style={{zIndex: 10000}}>
          <select
            className={'form-select form-select-sm'}
            value={moment(date).format('MMMM')}
            onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
            disabled={!!props.showMonthYearPicker}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className={'mx-1'} />
          <select
            className={'form-select form-select-sm d-flex'}
            // value={Number(moment(date).format('YYYY'))}
            value={Number(moment(date).format('YYYY'))}
            onChange={({target: {value}}) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button
          className={'background-none border-none'}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          type='button'
          aria-label='Next Month'
        >
          <i className='fa fas fa-chevron-right' />
        </button>
      </div>
    )
  }
  const {addClassName = '', sizeInput = '', className} = props
  const fixProps = {
    renderCustomHeader,
    ...props,
  }
  delete fixProps.addClassName
  delete fixProps.sizeInput
  delete fixProps.customSizeInput

  const ss = {
    small: 'form-control-sm',
    large: 'form-control-lg',
  }
  fixProps.className = className
    ? className
    : clsx(
        'form-control',
        // @ts-ignore
        ss[sizeInput],
        'form-control-solid',
        addClassName
      )
  // @ts-ignore
  return <DatePicker dateFormat={'dd/MM/yyyy'} {...deleteNegativeValueInObject(fixProps)} />
}

export {MetroDatePicker}
