import React, {FC} from 'react'
import clsx from 'clsx'
interface MetroContentSpinnerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  active: boolean
}
const MetroContentSpinner: FC<MetroContentSpinnerProps> = (
  {
    className,
    children,
    active,
    ...props
  }) => {
  return (
    <div {...props} className={clsx(
      active && `overlay overlay-block`,
      className)}>
      {children}
      {
        active && <div className="overlay-layer card-rounded bg-dark bg-opacity-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </div>
  )
}
export {MetroContentSpinner}
