/* eslint-disable react-hooks/exhaustive-deps */
import {usePageData} from '../../../_metronic/layout/core'
import React, {useEffect} from 'react'

const MetroToolbar = (props: {children: React.ReactNode}) => {
  const {setToolbarContent} = usePageData()
  useEffect(() => {
    if (props.children) {
      setToolbarContent(props.children)
    }
    return () => {
      setToolbarContent(null)
    }
  }, [props.children])

  return <></>
}
export {MetroToolbar}
