import React, { forwardRef } from "react";
import { useIntl } from "react-intl";

export const MetroDatePickerCustomInput = forwardRef(({value, onClick}: any, ref) => {
  const intl = useIntl()
  return (
    <div className='input-group input-group-solid me-2' style={{width: 220}}>
      <span className='input-group-text' onClick={onClick}>
        <i className='fas fa-calendar-alt' />
      </span>
      {/*// @ts-ignore*/}
      <input ref={ref} type='text' className='form-control form-control-sm form-control-solid ps-0' value={value || ''}
             onClick={onClick} placeholder={intl.formatMessage({id: 'LABEL.PERIOD'})} readOnly />
    </div>)
})
