import {KTSVG} from '../../../_metronic/helpers'
import React from 'react'
import {data_color} from '../../types'

interface MetroAlertProps {
  bordered?: {
    style: 'dashed' | 'dotted'
    size: '1' | '2' | '3' | '4' | '5'
  }
  title?: string | React.ReactNode
  body?: string | React.ReactNode
  dismissible?: boolean
  dismissIcon?: React.ReactNode
  alertIcon?: React.ReactNode | 'none'
  light?: boolean
  type?: data_color
  theme?: 'solid' | 'light'
}
const MetroAlert = (props: MetroAlertProps) => {
  const tt = props.type || 'primary'
  const theme = props.theme
  const th = theme === 'solid' ? `bg-${tt}` : theme === 'light' ? `bg-light-${tt}` : `alert-${tt}`
  const tc = theme === 'solid' ? 'text-light' : `text-${tt}`
  const acc = theme === 'solid' ? 'svg-icon-light' : `svg-icon-${tt}`
  const b = props.bordered
    ? `border border-${tt} border-${props.bordered.size || '1'} border-${
        props.bordered.style || 'dashed'
      }`
    : ''
  const {
    type = tt,
    body,
    title,
    dismissible,
    dismissIcon = (
      <KTSVG
        path='/media/icons/duotune/arrows/arr061.svg'
        className={`svg-icon ${acc} svg-icon-2x`}
      />
    ),
    alertIcon = (
      <KTSVG
        path='/media/icons/duotune/art/art006.svg'
        className={`svg-icon ${acc} svg-icon-2hx`}
      />
    ),
  } = props

  return (
    <>
      <div
        className={`alert${
          dismissible ? ' alert-dismissible' : ''
        } ${th} ${b} d-flex flex-column flex-sm-row p-5 mb-10`}
      >
        {alertIcon !== 'none' && (
          <span className={`svg-icon svg-icon-2hx svg-icon-${type} me-4 mb-5 mb-sm-0`}>
            {alertIcon}
          </span>
        )}
        <div className={`d-flex flex-column ${tc} pe-0 pe-sm-10`}>
          <h5 className={`mb-1 ${tc}`}>{title}</h5>
          <span>{body}</span>
        </div>

        {dismissible && (
          <button
            type='button'
            className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
            data-bs-dismiss='alert'
          >
            {dismissIcon}
          </button>
        )}
      </div>
    </>
  )
}

export {MetroAlert}
