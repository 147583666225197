import {MetroModal} from '../MetroModal'
import {MetroTable} from '../MetroTable'
import React, {useState} from 'react'
import {MetroButton} from '../MetroButton'
import {useIntl} from 'react-intl'
import {FormSelect} from '../Form'

const ChooseComponentModal = (props: any) => {
  const [selectedRows, setSelectedRows] = useState([])
  const intl = useIntl()
  return (
    <MetroModal
      destroyOnClose={true}
      title={props.title}
      size={'large'}
      visible={props.visible}
      okButtonProps={{
        style: !props.isMultiple ? {display: 'none'} : {},
      }}
      onOk={() => {
        props.setSelected(selectedRows)
      }}
      onDismiss={() => {
        props.hide()
      }}
    >{props.filterEmpNatura && (
      <div className={'row align-items-center'}>
        <div className={'col-md-3'}>
          <FormSelect
            formik={props.formik}
            name='bu'
            intlLabel={'BUSINESS_UNIT'}
            options={[...props.getAllBusinessUnit.list, {value: '', label: 'All'}] ||[]}
            inputProps={{
              isLoading: props.getAllBusinessUnit.isLoading,
            }}
          />
        </div>
        <div className={'col-md-3'}>
          <FormSelect
            formik={props.formik}
            name='organization'
            intlLabel={'DEPARTMENT'}
            options={[...props.getAllOrganization.list, {value: '', label: 'All'}] ||[]}
            inputProps={{
              isLoading: props.getAllOrganization.isLoading,
            }}
          />
        </div>
        <div className={'col-md-3'}>
          <FormSelect
            formik={props.formik}
            name='gender'
            intlLabel={'GENDER'}
            options={[...props.getAllParamGender.list, {value: '', label: 'All'}] || []}
            inputProps={{
              isLoading: props.getAllParamGender.isLoading,
            }}
          />
        </div>
      </div>
    )}
      <MetroTable
        rowSelection={
          props.isMultiple
            ? {
                onChange: (selectionKeys, selectionRows) => {
                  setSelectedRows(selectionRows)
                },
              }
            : undefined
        }
        loading={props.isLoading}
        scroll={{x: props.tableScrollX || true}}
        columns={[
          ...props.columns,
          props.isMultiple
            ? {}
            : {
                title: intl.formatMessage({id: 'LABEL.ACTION'}),
                dataIndex: 'action',
                fixed: 'right',
                className: 'text-center',
                render: (val: any, row: any) => {
                  return (
                    <MetroButton
                      size={'small'}
                      light
                      onClick={() => {
                        props.setSelected(row)
                        props.hide()
                      }}
                    >
                      <i className='fas fa-check' />
                      {intl.formatMessage({id: 'LABEL.SELECT'})}
                    </MetroButton>
                  )
                },
              },
        ]}
        data={props.dataSource
          .map((el: any) => ({...el, key: el.id || el.key || el.value}))
          .filter((el: any) => {
            const h: boolean[] = []
            const cek = props.hideRowForId || []
            if (cek.length === 0) {
              return true
            }
            cek.forEach((id: any) => {
              h.push(el.key === id)
            })
            return !h.includes(true)
          })}
        rowKey={'id'}
        pageSize={props.tablePageSize}
      />
    </MetroModal>
  )
}

export {ChooseComponentModal}
