import {toTitleCase} from '../../utils'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React, { ReactNode } from "react";

const MetroPageTitle = (props: {title?: ReactNode, showBackButton?: boolean}) => {
  const pathnames = window.location.pathname.split('/')
  const breadcrumb: PageLink[] = []
  pathnames.forEach((p, index) => {
    if (p && index !== pathnames.length - 1) {
      breadcrumb.push({
        title: toTitleCase(p),
        // path: '/' + pathnames[index],
        path: '#',
        isSeparator: false,
        isActive: false,
      })
      breadcrumb.push({
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      })
    }
  })
  return (
    <PageTitle
      showBackButton={props.showBackButton}
      breadcrumbs={breadcrumb}
    >{props.title ? props.title : toTitleCase(pathnames[pathnames.length - 1])}
    </PageTitle>
  )
}

export {MetroPageTitle}
