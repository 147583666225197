import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {MetroButton, useFetch} from '../../../../source/components'
import {patchNewPasswordApi} from '../../../../source/apis'
import {decryptMessage, encryptMessage, fakePromise, toast} from '../../../../source/utils'
import jwt_decode from 'jwt-decode'

const initialValues = {
  password: '',
  retype_password: '',
}


export function NewPassword() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const patchNewPassword = useFetch({apiFunction: patchNewPasswordApi})
  const queryParams = new URLSearchParams(window.location.search)
  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimum 3 symbols')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        intl.formatMessage({id: 'MESSAGE.PASSWORD_MATCH'}),
      )
      .max(16, 'Maximum 16 char or symbols')
      .required('Password is required'),
    retype_password: Yup.string()
      .oneOf([Yup.ref('password'), null], intl.formatMessage({id: 'MESSAGE.PASSWORD_CONFIRM'}))
      .required(''),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const accessToken: any = queryParams.get('token')
      const {id} = jwt_decode(accessToken) as any
      console.log(id)
      const encryptedPassword = encryptMessage(values.password)
      setLoading(true)
      setHasErrors(undefined)
      const params = {
        id: id,
        password: {
          password: encryptedPassword,
        },
      }
      await fakePromise(500)
      patchNewPassword.fetch(params)
        .then(async () => {
          toast.success('Your password has changed')
          navigate('/auth/login')
        })
        .catch(() => {
          setHasErrors(true)
        })

      console.log(decryptMessage(encryptedPassword))
      console.log(id)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Create a new Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Type your new password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::FormAddEdit group */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password: </label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              },
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className={'text-danger'}>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Re-type Password: </label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('retype_password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.retype_password && formik.errors.retype_password},
              {
                'is-valid': formik.touched.retype_password && !formik.errors.retype_password,
              },
            )}
          />
          {formik.touched.password && formik.errors.retype_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className={'text-danger'}>{formik.errors.retype_password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::FormAddEdit group */}

        {/* begin::FormAddEdit group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <MetroButton
            className={'me-5'}
            onClick={formik.submitForm}
            loading={formik.isSubmitting || loading}
            color={'secondary'}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Confirm
          </MetroButton>
          {/*<button*/}
          {/*  type='submit'*/}
          {/*  id='kt_password_reset_submit'*/}
          {/*  className='btn btn-lg btn-primary fw-bolder me-4'*/}
          {/*>*/}
          {/*  <span className='indicator-label'>Submit</span>*/}
          {/*  {loading && (*/}
          {/*    <span className='indicator-progress'>*/}
          {/*      Please wait...*/}
          {/*      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
          {/*    </span>*/}
          {/*  )}*/}
          {/*</button>*/}
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::FormAddEdit group */}
      </form>
    </>
  )
}
