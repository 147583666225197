import React from 'react'
import DatePicker, {ReactDatePickerProps} from 'react-datepicker'

interface FormikDatePickerProps {
    datePickerProps: ReactDatePickerProps
    field: any
    form: any
    sizeInput?: 'small' | 'large'
}

export function FormikDatePicker(props: FormikDatePickerProps) {
    const {datePickerProps, field, form, sizeInput} = props

    let val
    if (datePickerProps?.selectsRange) {
        const v = Array.isArray(field.value) ? field.value : [field.value]
        val = {
            startDate: v[0],
            endDate: v[1]
        }
    } else {
        val = {
            selected: field.value
        }
    }
    const fixProps = {
        ...datePickerProps,
        ...val,
        onChange: undefined
    }
    const ss = {
        'small': 'form-control-sm',
        'large': 'form-control-lg'
    }
    return (
        <DatePicker
            autoComplete={'off'}
            // @ts-ignore
            className={`form-control ${ss[sizeInput] || ''} form-control-solid`}
            name={field.name}
            // @ts-ignore
            {...fixProps}
            onChange={(value: any) => {
                form.setFieldValue(field.name, value)
                datePickerProps?.onChange && datePickerProps?.onChange(value, undefined)
            }}
        />
    )
}
