import {InputHTMLAttributes, useRef} from 'react'
import {randomString} from '../../utils'
interface MetroSwitchProps {
  addClassName?: string
  addInputClassName?: string
  label?: string
  labelClassName?: string
  sizeInput?: 'small' | 'large'
  customSizeInput?: {
    height: number | string
    width: number | string
  }
}
const MetroSwitch = (props: MetroSwitchProps & InputHTMLAttributes<any>) => {
  const id = useRef(randomString(8))
  const {
    label,
    addClassName = '',
    addInputClassName = '',
    sizeInput = '',
    customSizeInput,
    labelClassName = ''
  } = props
  const fixProps = {
    ...props,
  }
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.addInputClassName
  delete fixProps.sizeInput
  delete fixProps.customSizeInput
  delete fixProps.labelClassName

  const ss = {
    'small': 'form-check-sm',
    'large': 'form-check-lg'
  }
  const style = customSizeInput ? {
    height: customSizeInput?.height,
    width: customSizeInput?.width,
  } : {}
  return (
    // @ts-ignore
    <div className={`form-check form-switch form-check-custom form-check-solid ${ss[sizeInput] || ''} ${addClassName}`}>
      <input
        className={`form-check-input ${addInputClassName}`}
        type='checkbox'
        id={id.current}
        {...fixProps}
        style={style}
      />
      {label ? (
        <label className={`form-check-label ${labelClassName}`} htmlFor={id.current}>
          {label}
        </label>
      ) : null}
    </div>
  )
}
export {MetroSwitch}
