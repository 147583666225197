import React, {InputHTMLAttributes, useRef} from 'react'
import {randomString} from '../../utils'
export interface MetroCheckboxProps {
  addClassName?: string
  addInputClassName?: string
  label?: string
  labelClassName?: string
  sizeInput?: 'small' | 'large'
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  additionalLabel?: React.ReactNode
}
const MetroCheckbox = (props: MetroCheckboxProps & InputHTMLAttributes<any>) => {
  const id = useRef(randomString(8))
  const {
    label,
    addClassName = '',
    addInputClassName = '',
    sizeInput = '',
    customSizeInput,
    labelClassName = '',
    additionalLabel,
    className
  } = props
  const fixProps = {
    ...props,
  }
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.addInputClassName
  delete fixProps.sizeInput
  delete fixProps.customSizeInput
  delete fixProps.labelClassName
  delete fixProps.additionalLabel

  const ss = {
    'small': 'form-check-sm',
    'large': 'form-check-lg'
  }
  const style = customSizeInput ? {
    height: customSizeInput?.height,
    width: customSizeInput?.width,
  } : {}
  // @ts-ignore
  fixProps.className = className ? className : `form-check-input ${addInputClassName}`
  return (
    // @ts-ignore
    <div className={`form-check form-check-custom form-check-solid ${ss[sizeInput] || ''} ${addClassName}`}>
      <input
        type='checkbox'
        id={id.current}
        {...fixProps}
        style={style}
      />
      {label ? (
        <label className={`form-check-label ${labelClassName}`} htmlFor={id.current}>
          {label} {additionalLabel}
        </label>
      ) : null}
    </div>
  )
}
export {MetroCheckbox}
