import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {ThemeModeType} from "../_metronic/partials";
import {getToken, onMessageListener} from "../firebase";

const App = () => {
  const {setFcmToken} = useAuth()
  const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
    if (!localStorage) {
      return 'light'
    }

    const data = localStorage.getItem(lsKey)
    if (!data) {
      return 'light'
    }

    if (data === 'light') {
      return 'light'
    }

    if (data === 'dark') {
      return 'dark'
    }

    return 'light'
  }
  const lKey = 'kt_theme_mode_value'
  useEffect(() => {
    localStorage.setItem(lKey, getThemeModeFromLocalStorage(lKey))
  }, []);

  useEffect(() => {
    if (window.location.origin !== 'http://10.10.3.173') {
      try {
        Notification.requestPermission().then(function(permission) {
          // @ts-ignore
          getToken().then((value: string) => {
            console.log('getToken', value)
            setFcmToken(value)
            onMessageListener().then(value => {
              console.log('onMessageListener', value)
            })
          }).catch((e)=> {
            console.log('e getToken', e.message)
          })
          console.log('permiss', permission)
        });
      } catch (e) {
        console.error('e messaging', e)
      }
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
