import {InputHTMLAttributes} from 'react'
import InputMask , {Props as InputMaskProps} from 'react-input-mask';

export interface MetroInputProps {
  addClassName?: string
  className?: string
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  inputMask?: InputMaskProps
  sizeInput?: 'small' | 'large'
}
const MetroInput = (props: MetroInputProps & InputHTMLAttributes<any>) => {
  const {addClassName = '', customSizeInput, sizeInput = '', className} = props
  const fixProps = {
    ...props,
  }
  const style = customSizeInput
    ? {
        height: customSizeInput?.height,
        width: customSizeInput?.width,
      }
    : {}
  const propsInputMask = props.inputMask
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.sizeInput
  delete fixProps.customSizeInput
  delete fixProps.inputMask

  const ss = {
    'small': 'form-control-sm',
    'large': 'form-control-lg'
  }
  // @ts-ignore
  fixProps.className = className ? className : `form-control ${ss[sizeInput] || ''} form-control-solid ${addClassName}`

  if (propsInputMask) {
    {/*@ts-ignore*/}
    return <InputMask {...propsInputMask} {...fixProps} style={style}>{imProps => <input {...imProps} />}</InputMask>
  }
  return (<input {...fixProps} style={style} />
  )
}
export {MetroInput}
