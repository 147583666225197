/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import { useDimensions } from '../../../../source/hooks'
import {useLayout, usePageData} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

const Toolbar1 = ({setToolbarHeight = (height: any) => {}}) => {
  const {toolbarContent, toolbarAction} = usePageData()
  const {classes} = useLayout()

  const [
    ref,
    { height }
  ] = useDimensions()

  useEffect(() => {
    setToolbarHeight(height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  return (
    <>
      <div ref={ref} className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          // className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
          className={clsx(classes.toolbarContainer.join(' '), 'metro-toolbar-custom')}
        >
          {
            toolbarContent ? toolbarContent :  <DefaultTitle />
          }

          {/* begin::Actions */}
          <div className='d-flex align-items-center py-1'>
            {toolbarAction}
            {/*/!* begin::Wrapper *!/*/}
            {/*<div className='me-4'>*/}
            {/*  /!* begin::Menu *!/*/}
            {/*  <a*/}
            {/*    href='#'*/}
            {/*    className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'*/}
            {/*    data-kt-menu-trigger='click'*/}
            {/*    data-kt-menu-placement='bottom-end'*/}
            {/*    data-kt-menu-flip='top-end'*/}
            {/*  >*/}
            {/*    <KTSVG*/}
            {/*      path='/media/icons/duotune/general/gen031.svg'*/}
            {/*      className='svg-icon-5 svg-icon-gray-500 me-1'*/}
            {/*    />*/}
            {/*    Filter hai*/}
            {/*  </a>*/}

            {/*  /!* end::Menu *!/*/}
            {/*</div>*/}
            {/*/!* end::Wrapper *!/*/}

            {/*/!* begin::Button *!/*/}

            {/*<a*/}
            {/*  className='btn btn-sm btn-primary cursor-pointer'*/}
            {/*  id='kt_toolbar_primary_button'*/}
            {/*  data-bs-toggle='modal'*/}
            {/*  data-bs-target='#kt_modal_create_app'*/}
            {/*  onClick={() => setShowCreateAppModal(true)}*/}
            {/*>*/}
            {/*  Create*/}
            {/*</a>*/}
            {/*/!* end::Button *!/*/}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Toolbar1}

