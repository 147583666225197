/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

const {REACT_APP_PROD_SITE, NODE_ENV, REACT_APP_VERSION} = process.env

const MenuGroup = (props: any) => {
    // const user = useUser()
    const child = props.children || []
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{props.title}</span>
                </div>
            </div>
            {child
                // .filter((el: any) => el?.roles?.includes(user.employee?.role_id || 0))
                .filter((el: any) => !!el.menu_visible)
                .map((el: any, index: any) => (
                    <MenuMain key={index.toString()} {...el} />
                ))}
        </>
    )
}

const MenuSingle = (props: any) => {
    return (
        <AsideMenuItem
            to={props.menu_path}
            icon={props.menu_icon}
            title={props.name}
            // fontIcon={props.fontIcon}
            hasBullet={props.isSub}
        />
    )
}

const MenuMulti = (props: any) => {
    // const user = useUser()
    const child = props.children || []
    return (
        <AsideMenuItemWithSub
            to={props.menu_path}
            title={props.name}
            // fontIcon={props.fontIcon}
            icon={props.menu_icon}
            hasBullet={props.isSub}
        >
            {child
                // .filter((el: any) => el?.roles?.includes(user.employee?.role_id || 0))
                .filter((el: any) => !!el.menu_visible)
                .sort((a: any, b: any) => a.menu_position - b.menu_position)
                .map((el: any, index: any) => (
                    <MenuMain key={index.toString()} isSub={true} {...el} />
                ))}
        </AsideMenuItemWithSub>
    )
}

const MenuMain = (el: any) => {
    const child = (el.children || []).filter((el: any) => !!el.menu_visible)
    if (el.menu_path === '#') {
        return <MenuGroup {...el} />
    }
    if (child.length > 0) {
        if (el.name === 'Menu') {
            console.log('hai', el)
        }
        return <MenuMulti {...el} />
    }
    if (child.length === 0) {
        return <MenuSingle {...el} />
    }
    return null
}


export function AsideMenuMain() {
    const intl = useIntl()
    const {menuRecursive, auth} = useAuth()
    return (
        <>
            {(menuRecursive || [])
                // .filter((el: any) => el?.roles?.includes(user.employee?.role_id || 0))
                .filter((el: any) => !!el.menu_visible)
                .sort((a: any, b: any) => a.menu_position - b.menu_position)
                .map((el: any, index: any) => (
                    <MenuMain key={index.toString()} {...el} />
                ))}
            {/*<AsideMenuItem*/}
            {/*  to='/dashboard'*/}
            {/*  icon='/media/icons/duotune/art/art002.svg'*/}
            {/*  title={intl.formatMessage({id: 'MENU.DASHBOARD'})}*/}
            {/*  fontIcon='bi-app-indicator'*/}
            {/*/>*/}
            {/*<AsideMenuItem*/}
            {/*  to='/builder'*/}
            {/*  icon='/media/icons/duotune/general/gen019.svg'*/}
            {/*  title='Layout Builder'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content pt-8 pb-2'>*/}
            {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/crafted/pages'*/}
            {/*  title='Pages'*/}
            {/*  fontIcon='bi-archive'*/}
            {/*  icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*  <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
            {/*    <AsideMenuItem*/}
            {/*      to='/crafted/pages/profile/connections'*/}
            {/*      title='Connections'*/}
            {/*      hasBullet={true}*/}
            {/*    />*/}
            {/*  </AsideMenuItemWithSub>*/}

            {/*  <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
            {/*    <AsideMenuItem*/}
            {/*      to='/crafted/pages/wizards/horizontal'*/}
            {/*      title='Horizontal'*/}
            {/*      hasBullet={true}*/}
            {/*    />*/}
            {/*    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
            {/*  </AsideMenuItemWithSub>*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/crafted/accounts'*/}
            {/*  title='Accounts'*/}
            {/*  icon='/media/icons/duotune/communication/com006.svg'*/}
            {/*  fontIcon='bi-person'*/}
            {/*>*/}
            {/*  <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/error'*/}
            {/*  title='Errors'*/}
            {/*  fontIcon='bi-sticky'*/}
            {/*  icon='/media/icons/duotune/general/gen040.svg'*/}
            {/*>*/}
            {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/crafted/widgets'*/}
            {/*  title='Widgets'*/}
            {/*  icon='/media/icons/duotune/general/gen025.svg'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*>*/}
            {/*  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content pt-8 pb-2'>*/}
            {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/apps/chat'*/}
            {/*  title='Chat'*/}
            {/*  fontIcon='bi-chat-left'*/}
            {/*  icon='/media/icons/duotune/communication/com012.svg'*/}
            {/*>*/}
            {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItem*/}
            {/*  to='/apps/user-management/users'*/}
            {/*  icon='/media/icons/duotune/general/gen051.svg'*/}
            {/*  title='User management'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            {NODE_ENV !== 'production' && window.location.host !== REACT_APP_PROD_SITE && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content'>
                            <div className='separator mx-1 my-4'></div>
                        </div>
                    </div>
                    <div className='menu-item'>
                        <a
                            target='_blank'
                            className='menu-link'
                            href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
                        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2'/>
          </span>
                            <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
                        </a>
                        <a
                            // target='_blank'
                            className='menu-link'
                            href={`http://localhost:3002/code?access=${auth?.tokenv3 ?? ""}`}
                            // onClick={redirectPage}
                        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen026.svg' className='svg-icon-2'/>
          </span>
                            <span className='menu-title'>Nashta Net v3.0.0</span>
                        </a>
                    </div>
                </>
            )}
        </>
    )
}
