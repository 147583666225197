import React from 'react'
import {data_color} from '../../types'

interface MetroBadgeProps {
  className?: string
  content: string | React.ReactNode
  content2?: string | React.ReactNode
  type?: data_color
  light?: boolean
  shape?: 'square' | 'circle'
  onClick?: React.MouseEventHandler<HTMLElement>
}
const MetroBadge = (props: MetroBadgeProps) => {
  const {type = 'primary', light, shape = '', className = '', onClick} = props
  const bbs = {
    square: 'badge-square',
    circle: 'badge-circle',
  }
  // @ts-ignore
  const bs = bbs[shape] || ''
  return (
    <span
      onClick={onClick && onClick}
      className={`${onClick ? 'cursor-pointer' : ''} badge ${bs} badge${
        light ? '-light' : ''
      }-${type} ${className}`}
    >
      {props.content}
      {props.content2}
    </span>
  )
}
export {MetroBadge}
