import CurrencyInput from 'react-currency-input-field'
import {CurrencyInputProps} from 'react-currency-input-field/dist/components/CurrencyInputProps'
export interface MetroInputNumberProps {
  addClassName?: string
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  sizeInput?: 'small' | 'large'
  className?: string
}
const MetroInputNumber = (props: MetroInputNumberProps & CurrencyInputProps) => {
  const {addClassName = '', customSizeInput, sizeInput = '', className, maxLength} = props
  const fixProps = {
    decimalSeparator: '.',
    groupSeparator: ',',
    ...props,
  }
  const style = customSizeInput
    ? {
        height: customSizeInput?.height,
        width: customSizeInput?.width,
      }
    : {}
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.sizeInput
  delete fixProps.customSizeInput

  const ss = {
    'small': 'form-control-sm',
    'large': 'form-control-lg'
  }
  // @ts-ignore
  fixProps.className = className ? className : `form-control ${ss[sizeInput] || ''} form-control-solid ${addClassName}`
  return (
    <CurrencyInput
      {...fixProps}
      style={style}
    />
  )
}
export {MetroInputNumber}
