import React, {InputHTMLAttributes, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {MetroBadge} from '../MetroBadge'
import {randomString} from '../../utils'

export interface MetroRatingProps {
    onChange?: React.ChangeEventHandler | undefined
    onClick?: React.MouseEventHandler<HTMLElement>
    disabled?: boolean
    value?: number
    addClassName?: string
    label?: string
    className?: string
    customSizeInput?: {
        height: number | string
        width: number | string
    }
    sizeInput?: 'small' | 'large'
}

const MetroRating = (props: MetroRatingProps & InputHTMLAttributes<any>) => {
    const [state, setState]: any = useState(false);
    const id = useRef('metro-range-' + randomString())
    const intl = useIntl()
    const {value, onChange, label, addClassName = '', customSizeInput, sizeInput = '', className, disabled = false} = props
    const [valFunc, setValFunc] = useState(value);
    const metroProps = {
        ...props,
    }
    const style = customSizeInput
        ? {
            height: customSizeInput?.height,
            width: customSizeInput?.width,
        }
        : {}
    delete metroProps.addClassName
    delete metroProps.style
    delete metroProps.sizeInput
    delete metroProps.customSizeInput

    const size = {
        'small': 'fs-3',
        'large': 'fs-1'
    }
    // @ts-ignore
    metroProps.className = className ? className : `form-range ${size[sizeInput] || ''} form-control-solid ${addClassName}`
    const data_rating = [
        {
            id: 1,
            value: 1,
            checked: 'bi-star-fill'
        },
        {
            id: 2,
            value: 2,
            checked: 'bi-star-fill'
        },
        {
            id: 3,
            value: 3,
            checked: 'bi-star-fill'
        },
        {
            id: 4,
            value: 4,
            checked: 'bi-star-fill'
        },
        {
            id: 5,
            value: 5,
            checked: 'bi-star'
        }
    ]
    const filterRating = data_rating.map(({id, value, checked}: any) => {
        const ratingValue = valFunc || 0
        if (value <= ratingValue) {
            return {
                id, value, checked: 'bi-star-fill'
            }
        } else {
            return {
                id, value, checked: 'bi-star'
            }
        }
    })
    useEffect(() => {
        if(onChange) {
            onChange(state)
            setValFunc(state)
        }
    }, [state]);

    return (
        <>
      {
          label ?
              <label
                  htmlFor="customRange1"
                  className="form-label">
                  {intl.formatMessage({id: `LABEL.${label}`})}
                  <MetroBadge content={value} light/>
              </label> : ''
      }
          <div className={'d-flex ms-1'}>
              {
                  filterRating.map((el: any, index: any) => {
                      return (
                          <div key={el.id}>
                              <i
                                  className={`bi ${el.checked} ${metroProps.className} me-1 text-warning ${disabled ? '' : 'cursor-pointer'}`}
                                  onClick={() => {
                                      setState(el.id)
                                  }} color={'warning'}/>
                          </div>
                      )
                  })
              }
              {
                  !disabled ?
                      valFunc === 0 || !valFunc ? null : <i
                          className={'bi bi-x-circle mt-2 ms-1 cursor-pointer text-danger'}
                          onClick={() => {
                              setValFunc(0)
                              setState(0)
                          }
                          }
                      />
                   : null
              }
          </div>
        </>
    )
}
export {MetroRating}
