// eslint-disable-next-line import/no-anonymous-default-export
export default {
  localStorageKey: {
    menu: 'menus-local',
    flag_is_expire: 'b7qv9psp5dm',
  },
  formatDate:'DD/MM/YYYY',
  formatDateTime:'DD/MM/YYYY HH:mm',
  formatMonthDate:'MMM-YYYY',
  defaultTableMaxRow: 10,
  paramsPageAndPagination: {page: 1, limit: 10000},
  allowedFiles: ['pdf', 'jpg', 'png', 'xlsx', 'xls', 'jpeg', 'docx', 'doc', 'csv', 'txt', 'plain', 'ppt', 'pptx']

}
