import React, {useLayoutEffect, useRef, useState} from 'react'
import { MetroButton } from "../MetroButton";

interface MetroTabsProps {
  children?: React.ReactNode
  defaultIndex?: string
  activeIndex?: string
  className?: string
  forceRender?: boolean
  onChangeTab?: ((text: string) => void) | undefined
  action?: React.ReactNode
}

interface MetroTabsPane {
  index: string
  className?: string
  disabled?: boolean
  children?: React.ReactNode | React.ReactNode[]
  title: React.ReactNode

}
const Pane = (props: MetroTabsPane) => {
  return <>{props.children}</>
}
const MetroTabs = (props: MetroTabsProps) => {
  const {className = '', children = {}, defaultIndex = '0', activeIndex, forceRender, onChangeTab, action} = props
  const [actvIdx, setActvIdx] = useState(activeIndex || defaultIndex)
  const [state, setState] = useState({
    [defaultIndex]: true,
  })
  useLayoutEffect(() => {
    // @ts-ignore
    setActvIdx(activeIndex)
    setState((prevState) => ({
      ...prevState,
      // @ts-ignore
      [activeIndex]: true,
    }))
  }, [activeIndex])
  const tab_identifier = useRef(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5)
  )
  const c: any[] | undefined = Array.isArray(children) ? children : [children]
  const titles = c.map((el, index) => {
    if (!el) {
      return null
    }
    return (
      <li
        key={index.toString()}
        className={`nav-item ${el.props.disabled ? 'disabled' : ''}`}
        onClick={() => {
          if (el.props.disabled) {
            return
          }
          const idx = el.props.index
          setActvIdx(idx)
          setState((prevState) => ({
            ...prevState,
            [idx]: true,
          }))
          onChangeTab && onChangeTab(idx)
        }}
      >
        <a
          className={`nav-link ${actvIdx === el.props.index ? 'active' : ''} ${el.props.disabled ? 'disabled' : ''}`}
          data-bs-toggle='tab'
          href={`#tab_${tab_identifier.current}_${el.props.index}`}
        >
          {el.props.title}
        </a>
      </li>
    )
  })
  const panes = c.map((el, index) => {
    if (!el) {
      return null
    }
    return (
      <div
        key={index.toString()}
        className={`tab-pane fade ${actvIdx === el.props.index ? 'active show' : ''} ${
          el.props.className || ''
        }`}
        id={`tab_${tab_identifier.current}_${el.props.index}`}
        role='tabpanel'
      >
        {/*defaultIndex {JSON.stringify({defaultIndex, 'el.props.index': el.props.index})}*/}
        {/*css {`${defaultIndex === el.props.index ? 'active show' : ''}`}*/}
        {
          // @ts-ignore
          state[el.props.index] || forceRender ? el : null
        }
      </div>
    )
  })
  return (
    <div className={`metro-tabs ${className}`}>
      <div className={'d-flex justify-content-between'}>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 w-100'>{titles}</ul>
        {
          action && (
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              <li
                className={`nav-item`}>
                {action}

              </li>
            </ul>
          )
        }
      </div>
      <div className='tab-content'>{panes}</div>
    </div>
  )
}
MetroTabs.Pane = Pane
export {MetroTabs}
