import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import exportFromJSON from 'export-from-json'
import deMessages from '../../_metronic/i18n/messages/de.json'
import idMessages from '../../_metronic/i18n/messages/id.json'
import enMessages from '../../_metronic/i18n/messages/en.json'
import esMessages from '../../_metronic/i18n/messages/es.json'
import frMessages from '../../_metronic/i18n/messages/fr.json'
import jaMessages from '../../_metronic/i18n/messages/ja.json'
import zhMessages from '../../_metronic/i18n/messages/zh.json'
import {createIntl, createIntlCache} from 'react-intl'
import moment, {Moment} from 'moment'
import {IOption} from 'export-from-json/dist/types/exportFromJSON'
import {JSEncrypt} from 'jsencrypt'
import config from '../config'
import _, {toUpper} from 'lodash'
import {toPng} from 'html-to-image'
import {jsPDF} from 'jspdf'
import {ConfirmDialogProps, data_color, Many, UserProps} from '../types'
import {Toast} from 'bootstrap'
import Swal from 'sweetalert2'
import {downloadFileS3Api, getHrEmployeeApi, getMenuRefRolesApi, refreshTokenApi} from '../apis'
import jwt_decode from 'jwt-decode'
import {getAuth, setAuth} from '../../app/modules/auth'

const {REACT_APP_RSA_PUB_KEY, REACT_APP_RSA_PRV_KEY} = process.env
let user: UserProps = {listMenuFlat: [], listMenuRecursive: []}

export function randomString(length: number = 8): string {
    const letters = 'abcdefghijklmnopqrstuvwxyz'
    const numbers = ''
    const charset = `${letters}${letters.toUpperCase()}${numbers}`

    const randomCharacter = (character: string) =>
        character[Math.floor(Math.random() * character.length)]

    let R = ''
    for (let i = 0; i < length; i++) {
        R += randomCharacter(charset)
    }
    return R
}

const rb = 1000
const jt = 1000000
const m = 1000000000
let firstInitUseUser = false
export const setFirstInitUseUser = (value: boolean) => {
    firstInitUseUser = value
}
export const getFirstInitUseUser = () => {
    return firstInitUseUser
}

export function showAlert(message: string) {
    alert(message)
}

export function resetUser() {
    setFirstInitUseUser(false)
    user = {listMenuFlat: [], listMenuRecursive: []}
}

export function setUser(value: UserProps) {
    user = {
        ...user,
        ...value,
    }
}

export function priorityColor(value: any) {
    switch (toUpper(value)) {
        case 'HIGH':
            value = 'danger'
            break
        case 'MEDIUM':
            value = 'warning'
            break
        case 'LOW':
            value = 'success'
            break
        default:
            value = 'info'
    }
    return value
}

export function statusColor(value: any) {
    switch (toUpper(value)) {
        case 'REJECT':
            value = 'danger'
            break
        case 'IN PROGRESS':
            value = 'warning'
            break
        case 'COMPLETE':
            value = 'success'
            break
        default:
            value = 'info'
    }
    return value
}

export function statusOpenColor(value: any) {
    switch (toUpper(value)) {
        case 'OPEN':
            value = 'info'
            break
        case 'DONE':
            value = 'success'
            break
        default:
            value = 'secondary'
    }
    return value
}

export function getUser(): UserProps {
    // const getToken = (state: { token: any }) => state.token
    // const token = yield select(getToken)
    // return store.getState().auth
    // return 'store.getState().auth'
    return user
}

export async function apiGet<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
    useToken = true
): Promise<R> {
    let headers = {}
    let configFix = {
        ...config,
        _useToken: useToken,
    }
    console.log('configFix', config)
    console.log('configFix', url)
    // if (useToken) {
    //   const token = getUser().accessToken;
    //   headers = config?.headers
    //   if (token) {
    //     headers = {
    //       ...headers,
    //       authorization: 'Bearer ' + token,
    //     };
    //   }
    //   configFix = {
    //     ...configFix,
    //     headers,
    //   };
    // }
    return axios.get(url, configFix)
}

export async function apiDelete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
    useToken = true
): Promise<R> {
    let headers = {}
    let configFix = {
        ...config,
        _useToken: useToken,
    }
    // if (useToken) {
    //   const token = getUser().accessToken;
    //   headers = config?.headers
    //   if (token) {
    //     headers = {
    //       ...headers,
    //       authorization: 'Bearer ' + token,
    //     };
    //   }
    //   configFix = {
    //     ...configFix,
    //     headers,
    //   };
    // }
    return axios.delete(url, configFix)
}

export async function apiPost<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    useToken = true
): Promise<R> {
    let headers = {}
    let configFix = {
        ...config,
        _useToken: useToken,
    }
    // if (useToken) {
    //   const token = getUser().accessToken;
    //   headers = config?.headers
    //   if (token) {
    //     headers = {
    //       ...headers,
    //       authorization: 'Bearer ' + token,
    //     };
    //   }
    //   configFix = {
    //     ...configFix,
    //     headers,
    //   };
    // }
    return axios.post(url, data, configFix)
}

export async function apiPut<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    useToken = true
): Promise<R> {
    let headers = {}
    let configFix = {
        ...config,
        _useToken: useToken,
    }
    // if (useToken) {
    //   const token = getUser().accessToken;
    //   headers = config?.headers
    //   if (token) {
    //     headers = {
    //       ...headers,
    //       authorization: 'Bearer ' + token,
    //     };
    //   }
    //   configFix = {
    //     ...configFix,
    //     headers,
    //   };
    // }
    return axios.put(url, data, configFix)
}

export async function apiPatch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    useToken = true
): Promise<R> {
    let headers = {}
    let configFix = {
        ...config,
        _useToken: useToken,
    }
    // if (useToken) {
    //   const token = getUser().accessToken;
    //   headers = config?.headers
    //   if (token) {
    //     headers = {
    //       ...headers,
    //       authorization: 'Bearer ' + token,
    //     };
    //   }
    //   configFix = {
    //     ...configFix,
    //     headers,
    //   };
    // }
    return axios.patch(url, data, configFix)
}

export function arrayPaginate(arr: readonly any[] | undefined = [], size: number) {
    // return arr.reduce((acc, val, i) => {
    //   const idx = Math.floor(i / size);
    //   const page = acc[idx] || (acc[idx] = []);
    //   page.push(val);
    //
    //   return acc;
    // }, []);
    return _.chunk(arr, size)
}

export function encryptMessage(message: any) {
    const jsEncrypt: any = new JSEncrypt()
    jsEncrypt.setPublicKey(REACT_APP_RSA_PUB_KEY)
    return jsEncrypt.encrypt(message)
}

export function decryptMessage(message: any) {
    const jsDecrypt: any = new JSEncrypt()
    jsDecrypt.setPrivateKey(REACT_APP_RSA_PRV_KEY)
    return jsDecrypt.decrypt(message)
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
type Props = {
    selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh' | 'id'
}
const initialState: Props = {
    selectedLang: 'en',
}

export function getSelectedLang(): Props {
    const ls = localStorage.getItem(I18N_CONFIG_KEY)
    if (ls) {
        try {
            return JSON.parse(ls) as Props
        } catch (er) {
            console.error(er)
        }
    }
    return initialState
}

export const allMessages = {
    de: deMessages,
    en: enMessages,
    es: esMessages,
    fr: frMessages,
    ja: jaMessages,
    zh: zhMessages,
    id: idMessages,
}

export const reactIntl = () => {
    const cache = createIntlCache()
    const locale = getSelectedLang().selectedLang
    const messages = allMessages[locale]
    return createIntl(
        {
            locale,
            messages,
        },
        cache
    )
}
export const reactIntlDirectMessage = (key: keyof typeof enMessages) => {
    // @ts-ignore
    return allMessages[getSelectedLang().selectedLang][key] || key
}
export const loremIpsumText = () =>
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

export const toTitleCase = (value: string) => {
    // const valueFix = (value || '').replaceAll('-', ' ')
    // const result = valueFix.replace(/([A-Z])/g, " $1");
    // return result.charAt(0).toUpperCase() + result.slice(1)

    let valueFix = ''
    const prepare = (value || '')
        .split(/[\s-]+/)
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    prepare.map((item, index, self) =>
        self.length - 1 === index ? (valueFix += item) : (valueFix += item + ' ')
    )
    const result = valueFix.replace(/([A-Z])/g, ' $1')
    return result.length <= 3 ? result.toUpperCase() : result
}

export const isFunction = function isFunction(obj: any) {
    return typeof obj === 'function'
}

export function handlerFilterDataMetroTableServerside(value: any): object {
    const p = value
    let holdP = {}
    Object.keys(p).forEach((key: string) => {
        let v = value[key]

        if ((key === 'periode')) {
            v = convertDateToString({date: v, toFormat: 'MMYYYY'})
        }

        holdP = {
            ...holdP,
            [key]: typeof v === `object` ? v.value : v,
        }
    })
    return holdP
}

export function deleteNegativeValueInObject(value: object): object {
    const p = {
        ...value,
    }
    Object.keys(p).forEach((key: string) => {
        // @ts-ignore
        if ([undefined, '', 'null', 'undefined', null].includes(p[key])) {
            // @ts-ignore
            delete p[key]
        }
    })
    return p
}

const fileNameOrByUrl = (props: { fileName?: string }) => {
    const {fileName} = props
    return (
        (fileName || window.location.pathname.replaceAll('/', '_').replace('_', '') || 'download') +
        ' ' +
        moment().format('DD-MM-YYYY HH-mm-ss')
    )
}

export function exportData(props: IOption) {
    const {fileName} = props
    const fixFileName = fileNameOrByUrl({fileName})
    exportFromJSON({...props, fileName: fixFileName})
}

export function removeConsoleInProd() {
    if (!isDev()) {
        try {
            // console.log = () => {}
            // console.error = () => {}
            // console.debug = () => {}
            // console.warn = () => {}
        } catch (e) {
        }
    }
}

export function removeLocalStorageWhenLogin() {
    localStorage.clear()
}

export function flattenArray(props: { data: any[] }) {
    // @ts-ignore
    const flatten = (obj, path = '') => {
        if (!(obj instanceof Object)) return {[path.replace(/\.$/g, '')]: obj}

        // @ts-ignore
        return Object.keys(obj).reduce((output, key) => {
            return obj instanceof Array
                ? // @ts-ignore
                {...output, ...flatten(obj[key], path + '[' + key + '].')}
                : {...output, ...flatten(obj[key], path + key + '.')}
        }, {})
    }
    let d2 = {}
    const h2: {}[] = []
    let t2 = {}
    props.data.forEach((el) => {
        d2 = {
            ...d2,
            ...flatten(el),
        }
        h2.push(d2)
        Object.keys(d2).forEach((key) => {
            t2 = {
                ...t2,
                [key]: null,
            }
        })
        d2 = {}
    })
    return h2.map((el) => ({...t2, ...el}))
}

export function randomDate(start: Date, end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export function exportToCsv(data: {}[], fileName: string | undefined = undefined) {
    const test_array = data.map((el: any) => {
        return Object.keys(el).map((d) => {
            return el[d]
        })
    })
    // Construct the comma seperated string
    // If a column values contains a comma then surround the column value by double quotes
    const csv = test_array
        .map((row) =>
            row
                .map((item) =>
                    typeof item === 'string' && item.indexOf(',') >= 0 ? `"${item}"` : String(item)
                )
                .join(',')
        )
        .join('\n')
    const data2 = encodeURI('data:text/csv;charset=utf-8,' + csv)
    const link = document.createElement('a')
    link.setAttribute('href', data2)
    const fn = fileName
        ? fileName
        : (window.location.pathname.replaceAll('/', '_').replace('_', '') || 'download') +
        ' ' +
        moment().format('DD-MM-YYYY HH-mm-ss')
    link.setAttribute('download', `${fn}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const isDev = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}
//ts-ignore
export const renderColumnDate = {
    render: (val: any) => {
        const date = moment(val)
        if (val && date.isValid()) {
            return moment(val).format(config.formatDate)
        }
        return '-'
    },
}
export const renderColumnMonthDate = {
    render: (val: any) => {
        const date = moment(val)
        if (val && date.isValid()) {
            return moment(val).format(config.formatMonthDate)
        }
        return '-'
    },
}
export const renderColumnDateTime = {
    render: (val: any) => {
        const date = moment(val)
        if (date.isValid()) {
            return moment(val).format(config.formatDate + ' HH:mm:ss')
        }
        return '-'
    },
}
//ts-ignore
export const renderColumnNumber = {
    render: (val: any) => {
        return formattedNumber(val)
    },
}
export const formattedNumber = (val: string | number | bigint): string => {
    let nf = new Intl.NumberFormat('en-US')
    // @ts-ignore
    return nf.format(val || '0')
}
export const formattedNumberWithRp = (val: string | number | bigint): string => {
    return 'Rp. ' + formattedNumber(val)
}
export const formattedNumberWithOtherCurrency = (val: string | number | bigint, currency: string | null): string => {
    return `${currency === "IDR" ? 'Rp. ' : currency ? currency + '. ' : 'Rp. '}` + formattedNumber(val)
}
export const convertToNumber = (val: string): number => {
    return Number((val || '').replace(/,/g, ''))
}
export const getComponentName = (WrappedComponent: any) => {
    return (
        WrappedComponent.type.componentName ||
        WrappedComponent.type.displayName ||
        WrappedComponent.type.name ||
        WrappedComponent.componentName ||
        WrappedComponent.displayName ||
        WrappedComponent.name ||
        'Component'
    )
}

export function getDimensionObject(node: any) {
    const rect = node.getBoundingClientRect()

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    }
}

export const findRecursive = <T>(
    listData: any[] | undefined,
    predicate: (value: T, index: number, obj: T[]) => unknown,
    childProperty: string
): T | undefined => {
    if (!childProperty) {
        // eslint-disable-next-line no-throw-literal
        throw 'findRecursive requires parameter `childrenPropertyName`'
    }
    const array: any[] = (listData || []) as any
    let initialFind = array.find(predicate)
    let elementsWithChildren = array.filter((x) => x[childProperty])
    if (initialFind) {
        return initialFind
    } else if (elementsWithChildren.length) {
        let childElements: any[] = []
        elementsWithChildren.forEach((x) => {
            childElements.push(...x[childProperty])
        })
        return findRecursive(childElements, predicate, childProperty)
    } else {
        return undefined
    }
}
export const createRecursiveMenu = (menus: any[]): any[] => {
    let d: any[]
    d = [...menus].map((el: any) => {
        delete el.children
        return el
    })
    const flat = d
    const root: any[] = []
    // untuk mengambil yg visibiltynya true dan false
    flat.forEach((node) => {
        if (!node.menu_parent_id) return root.push(node)
        const parentIndex = flat.findIndex((el) => el.id === node.menu_parent_id)
        if (parentIndex >= 0) {
            if (!flat[parentIndex]?.children) {
                return (flat[parentIndex].children = [node])
            }
            flat[parentIndex].children.push(node)
        }
    })
    return root
}
export const createRecursiveData = (data: any[], idName: string, parentIdName: string): any[] => {
    let d: any[]
    d = [...data].map((el: any) => {
        delete el.children
        return el
    })
    const flat = d
    const root: any[] = []
    // untuk mengambil yg visibiltynya true dan false
    flat.forEach((node) => {
        if (!node[parentIdName]) return root.push(node)
        const parentIndex = flat.findIndex((el) => el[idName] === node[parentIdName])
        if (parentIndex >= 0) {
            if (!flat[parentIndex]?.children) {
                return (flat[parentIndex].children = [node])
            }
            flat[parentIndex].children.push(node)
        }
    })
    return root
}
export const downloadFileS3 = ({file}: { file: string }) => {
    return downloadFileS3V2({file})
}

export function saveFile(blob: Blob | MediaSource, filename: string) {
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 100)
    }
}

let isDownloadFileS3V2: any = {}
export const downloadFileS3V2 = ({
                                     file,
                                     showToastProcess = true,
                                 }: {
    file: string
    showToastProcess?: boolean
}) =>
    new Promise(async (resolve, reject) => {
        if (isDownloadFileS3V2[file]) {
            return
        }
        isDownloadFileS3V2[file] = true
        try {
            showToastProcess && toast.info('Downloading...')
            const response = await downloadFileS3Api(file)
            saveFile(new Blob([response.data]), file)
            resolve(true)
        } catch (e) {
            showToastProcess && toast.danger('Failed download!')
            reject(e)
        } finally {
            isDownloadFileS3V2[file] = false
        }
    })
// {

// return downloadFileS3Api(file)
// }
export const createRecursiveMenu2 = (menus: any[]): any[] => {
    let d: any[]
    d = [...menus].map((el: any) => {
        delete el.children
        return el
    })
    const flat = d
    const root: any[] = []
    // untuk mengambil yg visibiltynya true dan false
    flat.forEach((node) => {
        if (!node.parent_menu_id) return root.push(node)
        const parentIndex = flat.findIndex((el) => el.id === node.parent_menu_id)
        if (parentIndex >= 0) {
            if (!flat[parentIndex]?.children) {
                return (flat[parentIndex].children = [node])
            }
            flat[parentIndex].children.push(node)
        }
    })
    return root
}

export interface DocumentViewerProps {
    url: string
    download_able: boolean
    print_able: boolean
}

export function yupErrorRequiredMessageIntl(labelIntl: string) {
    // @ts-ignore
    return reactIntlDirectMessage(`LABEL.${labelIntl}`) + ' required'
}

export const renderValueExport = (key: string, val: any) => {
    let value: any
    const datesKey = [
        'fnc_acc_payable_payment_date',
        'fnc_apr_payment_date',
        'payment_date',
        'inv_date',
        'inv_rec_date',
        'invoice_date',
        'invoice_due_date',
        'request_date',
        'created_at',
        'sch_date',
        'updated_at',
        'close_date',
        'deal_creation_date'
    ]
    const nominalKey = [
        'nominal',
        'fnc_acc_payable_ppn',
        'fnc_acc_payable_pph21',
        'fnc_acc_payable_pph23',
        'fnc_acc_payable_nett',
        'pph21',
        'pph23',
        'pph42',
        'ppn',
        'pph42',
        'gross',
        'nett',
        'total',
        'deal_value',
        'forecast_value'
    ]
    if (datesKey.includes(key)) {
        value = convertDateStringToString({stringDate: val, initialValue: '-'})
    } else if (nominalKey.includes(key)) {
        // value = formattedNumber(val)
        value = Number(val || 0)
    } else {
        value = val || '-'
    }
    return value || '-'
}

export interface MenuData {
    listMenuFlat: []
    listMenuRecursive: []
}

// export const formatDate = 'DD/MM/YYYY'
// export const paramsPageAndPagination = {page: 1, limit: 10000}
export const getUrlTitle = () => {
    const pathnames = window.location.pathname.split('/')
    return toTitleCase(pathnames[pathnames.length - 1])
}
export const setIsExpire = (value: boolean) => {
    localStorage.setItem(config.localStorageKey.flag_is_expire, value ? '1' : '0')
}
export const getIsExpire = (): boolean => {
    return localStorage.getItem(config.localStorageKey.flag_is_expire) === '1'
}
export const fullName = (first: string, last: string | null = null) => {
    return ((first || '-') + ' ' + (last || '')).trim()
}
export const downloadFromDiv = (props: {
    node: HTMLElement
    type: 'image' | 'pdf'
    fileName?: string
}) =>
    new Promise(async (resolve) => {
        const {node, type} = props
        toPng(node, {width: node.scrollWidth})
            .then((dataUrl: string) => {
                const link = document.createElement('a')
                const file_name = fileNameOrByUrl({fileName: props.fileName})
                link.download = file_name
                if (type === 'pdf') {
                    const pdf = new jsPDF()
                    const imgProps = pdf.getImageProperties(dataUrl)
                    const pdfWidth = pdf.internal.pageSize.getWidth()
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
                    pdf.addImage(dataUrl, 'PNG', 20, 20, pdfWidth / 1.25, pdfHeight / 1.25)
                    pdf.save(`${file_name}.pdf`)
                } else {
                    link.href = dataUrl
                    link.click()
                }
                resolve(true)
            })
            .catch((err: any) => {
                toast.danger(errorMessage(err))
            })
            .finally(() => {
                resolve(false)
            })
    })

function _showToast(type: data_color, message: string) {
    const id = Math.random().toString(36)
    const toast_id = `kt_docs_toast_stack_container${id}`
    const toast_el = createElementFromHTML(`
          <div id='${toast_id}' class='toast align-items-center text-white bg-${type} border-0 mb-4' role='alert' aria-live='assertive' aria-atomic='true'>
              <div class='d-flex'>
                <div class='toast-body ${['light', 'white'].includes(type) ? 'text-dark' : ''}'>
                  ${message}
                </div>
                <button type='button' class='btn-close btn-close-${
        ['light', 'white'].includes(type) ? 'dark' : 'white'
    } me-2 m-auto' data-bs-dismiss='toast' aria-label='Close'/>
              </div>
            </div>
          `)
    const testc = document.getElementById('testc')
    if (!testc) {
        return
    }
    // @ts-ignore
    testc.appendChild(toast_el)
    const toastElement = document.getElementById(toast_id)
    if (!toastElement) {
        return
    }
    // @ts-ignore
    const toast = Toast.getOrCreateInstance(toastElement)
    if (!toast) {
        return
    }
    toast.show()
    // @ts-ignore
    toastElement.addEventListener('hide.bs.toast', () => {
        setTimeout(() => {
            const t_id = document.getElementById(toast_id)
            if (!toast) {
                return
            }
            try {
                // @ts-ignore
                t_id?.remove()
            } catch (e) {
            }
        }, 1500)
    })
}

export function convertDateStringToString({
                                              stringDate,
                                              fromFormat,
                                              toFormat = config.formatDate,
                                              initialValue,
                                          }: {
    stringDate: string
    fromFormat?: string
    toFormat?: string
    initialValue?: string
}) {
    if (!stringDate) {
        return null
    }
    let m: Moment
    const mm = () => {
        if (m.isValid()) {
            return m.format(toFormat)
        }
        return initialValue || null
    }
    if (fromFormat) {
        m = moment(stringDate, fromFormat)
    } else {
        m = moment(stringDate)
    }
    return mm()
}

export function convertDateToString({
                                        date,
                                        toFormat = config.formatDate,
                                    }: {
    date: Date | null
    toFormat?: string
}) {
    let m: Moment
    m = moment(date)
    const mm = () => {
        if (m.isValid()) {
            return m.format(toFormat)
        }
        return null
    }
    return mm()
}

export function convertDateStringToDate({
                                            stringDate,
                                            fromFormat,
                                        }: {
    stringDate: string
    fromFormat?: string
}): Date | null {
    if (!stringDate) {
        return null
    }
    let m: Moment
    const mm = () => {
        if (m.isValid()) {
            return m.toDate()
        }
        return null
    }
    if (fromFormat) {
        m = moment(stringDate, fromFormat)
    } else {
        m = moment(stringDate)
    }
    return mm()
}

export function errorMessage(e: any) {
    let defaultMsg: string = reactIntlDirectMessage('MESSAGE.ERROR1')
    const {response, message: messageE} = e || {}
    let message
    try {
        if (response) {
            const {data} = response
            const {message: messageResponse} = data
            if (data && messageResponse) {
                message = messageResponse
            } else {
                message = messageE || defaultMsg
            }
        } else {
            message = messageE || defaultMsg
        }
    } catch (e) {
        message = defaultMsg
    }

    const isArray = Array.isArray(message)
    if (isArray) {
        defaultMsg = '' as any
        const msgs = message as []
        ;(message as []).forEach((el: any, index: number) => {
            let br
            if (msgs.length - 1 > index) {
                br = '<br>'
            } else {
                br = ''
            }
            defaultMsg += String('- ' + el + br)
        })
    }
    return typeof message === 'string' ? message : defaultMsg
}

export const toast = {
    success: (message: string) => {
        _showToast('success', message)
    },
    primary: (message: string) => {
        _showToast('primary', message)
    },
    white: (message: string) => {
        _showToast('white', message)
    },
    light: (message: string) => {
        _showToast('light', message)
    },
    secondary: (message: string) => {
        _showToast('secondary', message)
    },
    info: (message: string) => {
        _showToast('info', message)
    },
    warning: (message: string) => {
        _showToast('warning', message)
    },
    danger: (message: string) => {
        _showToast('danger', message)
    },
    dark: (message: string) => {
        _showToast('dark', message)
    },
}

export function createElementFromHTML(htmlString: string) {
    const div = document.createElement('div')
    div.innerHTML = htmlString.trim()
    return div.firstChild
}

export const fakePromise = (waitMs: number, isReject: boolean = false) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            if (isReject) {
                reject(!!0)
                return
            }
            resolve(!!1)
        }, waitMs)
    })
export const getTotal = (data: any[], withValue: string) => {
    return data.reduce((a: any, b: any) => Number(a) + Number(b[withValue]), 0)
}
export const successDialog = async (props: {
    text: string
    confirmButtonText?: string
    onOk?: () => void
    onCancel?: () => void
    icon?: string
    confirmButtonClassName?: string
}) => {
    const {onOk, icon, confirmButtonText, confirmButtonClassName} = props
    await Swal.fire({
        text: props.text,
        icon: (icon as any) || 'success',
        buttonsStyling: false,
        confirmButtonText: confirmButtonText || reactIntlDirectMessage('LABEL.OK'),
        customClass: {
            confirmButton: confirmButtonClassName || 'btn btn-primary',
        },
    })
    onOk && onOk()
}
export const confirmDialog = async (props: ConfirmDialogProps) => {
    const {
        showLoaderOnConfirm,
        preConfirm,
        html,
        icon,
        title,
        onOk,
        onCancel,
        text,
        confirmButtonText,
        cancelButtonText,
        cancelButtonClassName,
        confirmButtonClassName,
    } = props
    const res = await Swal.fire({
        title,
        text: text || reactIntlDirectMessage('MESSAGE.ARE_YOU_SURE'),
        icon: (icon as any) ?? 'warning',
        html,
        showLoaderOnConfirm,
        preConfirm,
        buttonsStyling: false,
        confirmButtonText: confirmButtonText || reactIntlDirectMessage('LABEL.YES'),
        cancelButtonText: cancelButtonText || reactIntlDirectMessage('LABEL.NO'),
        showCancelButton: true,
        customClass: {
            confirmButton: confirmButtonClassName || 'btn btn-primary me-2',
            cancelButton: cancelButtonClassName || 'btn btn-light-primary ms-2',
        },
    })
    const {isConfirmed, value} = res
    if (!(value instanceof Error)) {
        if (isConfirmed) {
            onOk && (await onOk())
        } else {
            onCancel && onCancel()
        }
    } else {
        Swal.showValidationMessage(`Request failed: ${errorMessage(value)}`)
    }
    return Promise.resolve(res)
}

export function objectToQueryString(obj: Record<string, any>) {
    const str = []
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
    return str.join('&')
}

export function orderBy<T>({
                               data,
                               columns,
                               order,
                           }: {
    data: (any | never)[]
    columns: string[]
    order: Many<boolean | 'asc' | 'desc'>
}): T[] {
    return _.orderBy(data, columns, order)
}

export function orderByCreatedDate<T>({
                                          data,
                                          column = 'created_at',
                                          order = 'desc',
                                      }: {
    data: (any | never)[]
    column?: string
    order?: 'asc' | 'desc'
}): T[] {
    return _.orderBy(
        data.map((el: any) => {
            return {
                ...el,
                [column]: moment(el[column]).toDate(),
                updated_at: convertDateStringToDate({stringDate: el.updated_at}),
            }
        }),
        [column],
        [order]
    )
}

export function formattedBytes(bytes: number | string, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes as any) / Math.log(k))

    return parseFloat(((bytes as any) / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const numberRoundUp = (val: number) => {
    return Math.round(val)
}
export const formattedNominalSort = (value: string | number) => {
    let label
    const val = Number(value)
    if (val === 0) {
        label = 0
    } else if (val > rb && val < jt) {
        label = `${numberRoundUp(val / rb)} RB`
    } else if (val > jt && val < m) {
        label = `${numberRoundUp(val / jt)} JT`
    } else {
        label = `${numberRoundUp(val / m)} M`
    }
    return label
}
export const getUserAndMenu = (accessToken: string) =>
    new Promise<{
        dataEmployee: any
        dataMenu: any[]
        dataMenuRecursive: any[]
        decodeAccessToken: any
    }>(async (resolve, reject) => {
        const createMenu = (menuList = []) => {
            if (menuList.length > 0) {
                const flat = [...menuList]
                return createRecursiveMenu(flat)
            }
        }
        try {
            const decoded = jwt_decode(accessToken) as any
            let employee = {} as any
            if ((decoded?.employees || []).length > 0) {
                let initialName = (decoded.employees[0].name || '').match(/\b\w/g) || []
                // @ts-ignore
                initialName = (initialName.shift() || '') + (initialName.pop() || '')
                employee = {
                    ...decoded?.employees[0],
                    email: decoded?.email,
                    initialName,
                }
            }
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            }
            const {
                data: {data: dataEmployee},
            } = await getHrEmployeeApi({
                params: {
                    id: employee.hr_emp_info_id,
                },
                headers,
            })
            const [res] = dataEmployee || []

            const {
                data: {data: dataMenu},
            } = await getMenuRefRolesApi({
                headers,
            })
            resolve({
                dataEmployee: {
                    ...res,
                    initialName: employee.initialName,
                    company_name: employee.company_name,
                },
                dataMenu,
                dataMenuRecursive: createMenu(dataMenu) as any,
                decodeAccessToken: decoded,
            })
        } catch (e) {
            reject(e)
        }
    })
export const isAccessTokenExpire = (token: string): boolean => {
    const decoded = jwt_decode(token) as any
    const {exp} = decoded
    let isExpired: boolean
    if (!token || token === '') {
        isExpired = true
    } else if (exp < Date.now() / 1000) {
        isExpired = true
    } else {
        isExpired = false
    }
    return isExpired
}
export const doRefreshToken = () =>
    new Promise<{ accesstoken: string; refreshtoken: string }>(async (resolve, reject) => {
        try {
            const {data} = await refreshTokenApi()
            const {accesstoken, refreshtoken, tokenv3} = data?.data?.token || {}
            setAuth({
                accessToken: accesstoken,
                refreshToken: refreshtoken,
                tokenv3: tokenv3,
                api_token: '',
            })
            setIsExpire(false)
            resolve({
                accesstoken,
                refreshtoken,
            })
        } catch (e) {
            reject(e)
        }
    })
export const currentAccessToken = () => {
    const auth = getAuth()
    return auth?.accessToken
}
export const currentTokenv3 = () => {
    const auth = getAuth()
    return auth?.tokenv3
}
export const currentRefreshToken = () => {
    const auth = getAuth()
    return auth?.refreshToken
}

export function createOption(props: { api: any; property: string }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {api, property} = props
    const getData = api
    const option = getData.list
        .map((val: any) => val[property])
        .filter((item: any, index: number, self: any) => {
            return self.indexOf(item) === index
        })
        .sort()
        .map((val: string) => ({
            label: val,
            value: val,
        }))
    return option
}
