import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './MetroTextEditor.scss'
import {deleteNegativeValueInObject} from '../../utils'
export interface MetroTextEditorProps extends ReactQuill.ReactQuillProps {
  addClassName?: string
  className?: string
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  sizeInput?: 'small' | 'large'
}
function MetroTextEditor(props: MetroTextEditorProps) {
  const {addClassName = '', sizeInput = '', className} = props
  const fixProps = {
    ...props,
  }
  // const style = customSizeInput
  //   ? {
  //     height: customSizeInput?.height,
  //     width: customSizeInput?.width,
  //   }
  //   : {}
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.sizeInput
  delete fixProps.customSizeInput

  const ss = {
    'small': 'form-control-sm',
    'large': 'form-control-lg'
  }
  // @ts-ignore
  fixProps.className = className ? className : `metro-text-editor form-control ${ss[sizeInput] || ''} form-control-solid ${addClassName}`
  return (
    <ReactQuill
      theme='snow'
      modules={{
        toolbar: [
          [{header: '1'}, {header: '2'}, {font: []}],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
          [{script: 'super'}, {script: 'sub'}],
          ['link', 'image', 'video'],
          [{ 'color': [] }, { 'background': [] }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['clean'],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      }}
      formats={[
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'script',
          'code-block',
        'list', 'bullet', 'indent',
        'link', 'image', 'video', 'align', 'color', 'background'
      ]}
      {...deleteNegativeValueInObject(fixProps)}
    />
  )
}

export {MetroTextEditor}
