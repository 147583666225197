import React from 'react'
import {data_color} from '../../types'

interface MetroSeparatorProps {
  className?: string
  type?: data_color
  separator?: 'dashed' | 'dotted'
  size?: '1' | '2' | '3' | '4' | '5'
}
const MetroSeparator = (props: MetroSeparatorProps) => {
  const {type = 'dark', separator = '', className = '', size = '1'} = props
  const sp = {
    dashed: 'separator-dashed',
    dotted: 'separator-dotted',
  }
  // @ts-ignore
  const s = sp[separator] || ''
  return <div className={`separator ${s} border-${type} bb-${size} ${className}`} />
}
export {MetroSeparator}
