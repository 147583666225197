import {TextareaHTMLAttributes} from 'react'
interface MetroTextAreaProps {
  addClassName?: string
  customSizeInput?: {
    height: number | string
    width: number | string
  }
  sizeInput?: 'small' | 'large' | 'xl'
}
const MetroTextArea = (props: MetroTextAreaProps & TextareaHTMLAttributes<any>) => {
  const {addClassName = '', customSizeInput, sizeInput = '', className} = props
  const fixProps = {
    ...props,
  }
  const style = customSizeInput
    ? {
        height: customSizeInput?.height,
        width: customSizeInput?.width,
      }
    : {}
  delete fixProps.addClassName
  delete fixProps.style
  delete fixProps.sizeInput
  delete fixProps.sizeInput

  const ss = {
    'small': 'form-control-sm',
    'large': 'form-control-lg',
    'xl': 'form-control-xl'
  }
  // @ts-ignore
  fixProps.className = className ? className : `form-control ${ss[sizeInput] || ''} form-control-solid ${addClassName} resize-none min-h-25px`;
  return (
    <textarea
      data-kt-autosize="true"
      {...fixProps}
      style={style}
    />
  )
}
export {MetroTextArea}
