import React, {FC} from 'react'
const MetroFormView: FC<{
  showDivLeft?: boolean
  showDivRight?: boolean
  classNameLeft?: string
  classNameRight?: string
  classNameMiddle?: string
  children: React.ReactNode
}> = (props) => {
  const {
    showDivLeft = true,
    showDivRight = true,
    classNameLeft = 'col-md-2',
    classNameRight = 'col-md-2',
    classNameMiddle = 'col-md-8',
  } = props
  return (
    <div className={'row border-bottom border-2 pb-10 mb-10'}>
      {showDivLeft && <div className={classNameLeft} />}
      <div className={classNameMiddle}>
        {props.children}
      </div>
      {showDivRight && <div className={classNameRight} />}
    </div>
  )
}
export {MetroFormView}
