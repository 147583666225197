/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import Table from 'rc-table'
import {ColumnType, DefaultRecordType} from 'rc-table/es/interface'
import {TableProps} from 'rc-table/es/Table'
import {arrayPaginate, deleteNegativeValueInObject, randomString} from '../../utils'
import clsx from 'clsx'
import _ from 'lodash'
import {MetroPagination} from '../MetroPagination'
// import {MetroDropdown} from '../MetroDropdown'
import {MetroInput} from '../MetroInput'
import {MetroButton} from '../MetroButton'
import {useIntl} from 'react-intl'
import {MetroCheckbox, MetroDatePicker, MetroRadio, MetroSelect, useDidMount, useFetch} from '../index'
import moment from 'moment'
import Dropdown from 'rc-dropdown'
// @ts-ignore
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import config from '../../config'
import { getAllBusinessUnitApi, getHrJobLevelApi } from "../../apis";
// export interface ColumnProps<T> {
//   // title?: React.ReactNode | ((options: {
//   //   filters: TableStateFilters;
//   //   sortOrder?: SortOrder;
//   //   sortColumn?: ColumnProps<T> | null;
//   // }) => React.ReactNode);
//   title?: React.ReactNode
//   key?: React.Key
//   dataIndex?: string
//   render?: (text: any, record: T, index: number) => React.ReactNode
//   // align?: 'left' | 'right' | 'center';
//   // ellipsis?: boolean;
//   // filters?: ColumnFilterItem[];
//   // onFilter?: (value: any, record: T) => boolean;
//   // filterMultiple?: boolean;
//   // filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode);
//   // filterDropdownVisible?: boolean;
//   // onFilterDropdownVisibleChange?: (visible: boolean) => void;
//   // sorter?: boolean | CompareFn<T>;
//   // defaultSortOrder?: SortOrder;
//   // colSpan?: number;
//   width?: string | number
//   className?: string
//   // fixed?: boolean | typeof ColumnFixedPlacements[number];
//   // filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
//   // filteredValue?: any[] | null;
//   // filtered?: boolean;
//   // defaultFilteredValue?: any[];
//   // sortOrder?: SortOrder | boolean;
//   // children?: ColumnProps<T>[];
//   onCellClick?: (record: T, event: Event) => void
//   // onCell?: (record: T, rowIndex: number) => TableEventListeners;
//   // onHeaderCell?: (props: ColumnProps<T>) => TableEventListeners;
//   // sortDirections?: SortOrder[];
// }
// interface Props<T> {
//   className?: string
//   columns: ColumnProps<T>[]
//   dataSource?: T[]
//   rowKey: string
// }
type type_row_selection = 'checkbox' | 'radio'
export interface MetroTableProps<RecordType extends DefaultRecordType> extends TableProps<RecordType> {
  identifier?: string
  loading?: boolean
  pagination?: boolean | null
  pageCurrent?: number
  pageSize?: number
  onChangePage?: (pagination: {page: number, pageSize: number}) => void
  onFilterChange?: (filterData: object) => void
  onSortChange?: (sortData: object) => void
  rowSelection?: {
    showCheckboxSelectAll?:boolean
    fixed?: 'left' | 'right'
    width?: number | string
    align?: string
    columnClassName?: string
    columnName?: ReactNode
    type?: type_row_selection
    // selectionKeys?: []
    onChange?: (selectionKeys: [], selectionRows: []) => void
  }
  className?: string | 'metro-table-responsive'
}
interface RecordType {
  a: string;
  b?: string;
  c?: string;
  d?: number;
  key: string;
}
const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;
  if (props.className.includes('mt-resize-able') && width) {
    return (
      <Resizable width={width} height={0} onResize={onResize}>
        <th {...restProps} />
      </Resizable>
    )
  }
  return <th {...restProps} />;
};
function arraysEqual(a1: any[], a2: any) {
  if (!a2) {
    return false
  }
  return JSON.stringify(a1)==JSON.stringify(a2);
}

export const MetroTable = forwardRef(
    (
        props: MetroTableProps<any>,
        ref,
    ) => {
      useImperativeHandle(ref, () => ({
        resetFilter(val: any) {
          setFilterValues(val)
          setInputValues(val || {})
        },
      }));
      const getAllBusinessUnit = useFetch({
        saveDataWithName: 'getAllBusinessUnit_metro-table',
        apiFunction: getAllBusinessUnitApi,
        listValueAndLabel: {valueKey: 'name', labelKey1: 'name'},
      })
      const getHrJobLevel = useFetch({
        saveDataWithName: 'getHrJobLevel_metro-table',
        apiFunction: getHrJobLevelApi,
        listValueAndLabel: {valueKey: 'job_level_name', labelKey1: 'job_level_name'}
      })

      const previousColumns = useRef<any[]>([]);
      const [selectAllData, setSelectAllData] = useState(false)
      const isMounted = useDidMount()
      const intl = useIntl()
      const id = useRef('metro-table-' + randomString())
      const isScroll = useRef(false)
      const {pageSize = 10, loading, data, pagination} = props
      const [page, setPage] = useState(props.pageCurrent || 1)
      const [filterValues, setFilterValues] = useState(null)
      const [sortValues, setSortValues] = useState(null) as any
      const [inputValues, setInputValues] = useState({}) as any
      const [selectedRow, setSelectedRow] = useState(null) as any
      const [metroColumns, setMetroColumns] = useState(props.columns) as any[]
      useEffect(() => {
        try {
          if ((data || []).length > 0 && (props.className || '').includes('metro-table-responsive') && !isScroll.current) {
            isScroll.current = true
            const selector = '#' + id.current + ' .metro-table-content'
            const doc_content = document.querySelector(selector)
            console.log('doc_content', doc_content, id.current, selector)
            // @ts-ignore
            doc_content && doc_content.scrollTo(1,0)
          }
        } catch (e) {

        }
      }, [data])

      // @ts-ignore
      const handleResize = (index) => (e, { size }) => {
        setMetroColumns((columns: any) => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        })
      };
      useEffect(() => {
        if (!!props.pageCurrent) {
          setPage(props.pageCurrent!)
        }
      }, [props.pageCurrent])

      useEffect(() => {
        if (props.identifier) {
          setSelectedRow(null)
          setSelectAllData(false)
        }
      }, [props.identifier])

      useEffect(() => {
        if (!arraysEqual(previousColumns.current, props.columns)) {
          // @ts-ignore
          previousColumns.current = props.columns
          if ((props.columns || []).map((e: any) => e.filterType).includes('business_unit') && !getAllBusinessUnit.isLoading && getAllBusinessUnit.list.length === 0) {
            getAllBusinessUnit.fetch()
          }

          if ((props.columns || []).map((e: any) => e.filterType).includes('job_level') && !getHrJobLevel.isLoading && getHrJobLevel.list.length === 0) {
            getHrJobLevel.fetch()
          }

          let sortTemp = sortValues || {}
          let filterTemp = filterValues || {}
          props.columns?.forEach((el: any) => {
            if (el.sortAble && el.defaultSort) {
              sortTemp = {
                ...sortTemp,
                [el.dataIndex]: el.defaultSort
              }
            }
            
            if (el.filterAble && el.defaultFilter) {
              let valFilter
              // if (Array.isArray(el.defaultFilter)) {
              if (el.filterType === 'select-multi') {
                valFilter = el.defaultFilter.map((df: any) => {
                  return {
                    ...df,
                    label: df.label ? df.label : ((el.filterData || []).find((fd: any) => df.value === fd.value) || {}).label || df.value
                  }
                })
              } else if (el.filterType === 'select') {
                valFilter = {
                  ...el.defaultFilter,
                  label: el.defaultFilter.label ? el.defaultFilter.label : ((el.filterData || []).find((fd: any) => el.defaultFilter.value === fd.value) || {}).label || el.defaultFilter.value
                }
              } else {
                valFilter = el.defaultFilter
              }

              filterTemp = {
                ...sortTemp,
                [el.dataIndex]: valFilter
              }
            }
          })
          //set default sort
          if (Object.keys(sortTemp).length > 0) {
            setSortValues(sortTemp)
          }
          //set default filter
          if (Object.keys(filterTemp).length > 0) {
            setInputValues(filterTemp as any)
            setFilterValues(filterTemp as any)
          }
          console.log('heloo')
        }
      }, [props.columns])


      useEffect(() => {
        const tempColumns = props.columns?.map((el, index) => {
          return {
            ...el,
            onHeaderCell: (column: ColumnType<RecordType>) => {
              const resize = ({
                width: column.width,
                onResize: handleResize(index),
              } as any)
              el.onHeaderCell && el.onHeaderCell(column)
              return resize
            },
            // @ts-ignore
            title: el.filterAble || el.sortAble
                ? // @ts-ignore
                TitleCustom({
                  // @ts-ignore
                  name: el?.dataIndex || '',
                  ...el,
                })
                : el.title,
          }
        })
        let fixColumn = []
        if (!!props.rowSelection) {
          const {showCheckboxSelectAll = true} = props.rowSelection
          fixColumn = [
            ...[
              {
                fixed: props.rowSelection.fixed ?? 'left',
                width: props.rowSelection.width || '100px',
                className: props.rowSelection.columnClassName || 'bg-white',
                align: props.rowSelection.align || 'center',
                // title: <span>{props.rowSelection?.columnName ?? intl.formatMessage({id: 'LABEL.CHOOSE'})}</span>,
                title: <span>
               {
                   (!showCheckboxSelectAll || props.rowSelection?.type === 'radio') && (
                       <>
                         {props.rowSelection?.columnName ?? intl.formatMessage({id: 'LABEL.CHOOSE'})}
                       </>)
               }
                  {
                      (showCheckboxSelectAll && (!props.rowSelection?.type || props.rowSelection?.type === 'checkbox')) && (
                          <MetroCheckbox
                              disabled={(data as []).length === 0}
                              checked={selectAllData}
                              //@ts-ignore
                              // checked={!!(selectedRow || []).find((sr) => sr[props.rowKey] === row[props.rowKey])}
                              // name={id.current + 'metro-table'}
                              addClassName={'my-2 d-inherit'}
                              sizeInput={'small'}
                              onChange={(e) => {
                                setSelectAllData(e.target.checked)
                              }}
                          />
                      )
                  }
            </span>,
                render: (val: any, row: any) => {
                  if (!props.rowSelection?.type || props.rowSelection?.type === 'checkbox') {
                    return (
                        <span>
                    <MetroCheckbox
                        //@ts-ignore
                        checked={!!(selectedRow || []).find((sr) => sr[props.rowKey] === row[props.rowKey])}
                        name={id.current + 'metro-table'}
                        addClassName={'my-2 d-inherit'}
                        sizeInput={'small'}
                        onChange={(e) => onRowSelect(e, row, 'checkbox')}
                    />
                  </span>
                    )
                  }
                  return (
                      <span>
                  <MetroRadio
                      //@ts-ignore
                      checked={!!(selectedRow || []).find((sr) => sr[props.rowKey] === row[props.rowKey])}
                      name={id.current + 'metro-table'}
                      addClassName={'my-2 d-inherit'}
                      sizeInput={'small'}
                      onChange={(e) => onRowSelect(e, row, 'radio')}
                  />
                </span>
                  )
                },
              },
            ],
            // @ts-ignore
            ...tempColumns,
          ]
        } else {
          // @ts-ignore
          fixColumn = tempColumns
        }
        setMetroColumns(fixColumn)
      }, [props.columns, sortValues, filterValues, selectedRow, inputValues, getAllBusinessUnit.list, getHrJobLevel.list])

      useEffect(() => {
        if (filterValues && isMounted.current) {
          props.onFilterChange && props.onFilterChange(filterValues)
        }
      }, [filterValues])
      useEffect(() => {
        if (sortValues && isMounted.current) {
          props.onSortChange && props.onSortChange(sortValues)
        }
      }, [sortValues])

      useEffect(() => {
        if (isMounted.current) {
          props.onChangePage && props.onChangePage({page, pageSize})
        }
      }, [page, pageSize])


      useEffect(() => {
        if (selectedRow && !!props.rowSelection?.onChange && isMounted.current) {
          props.rowSelection?.onChange(
              // @ts-ignore
              selectedRow.map((el: any) => el[props.rowKey]),
              selectedRow
          )
        }
      }, [selectedRow])

      const onRowSelect = (event: any, data: any, type: type_row_selection) => {
        const checked = !!event.target.checked
        if (type === 'checkbox') {
          if (checked) {
            setSelectedRow((prevState: any) => [...(prevState || []), data])
          } else {
            // @ts-ignore
            setSelectedRow((prevState: any) => (prevState || []).filter((el: any) => el[props.rowKey] !== data[props.rowKey]))
          }
        } else {
          setSelectedRow([data])
        }
      }

      const filteredData = useMemo(() => {
        return filterValues ? _.filter(data, function(item) {
          // return item.VAL.indexOf(search)>-1;
          const h: boolean[] = []
          // @ts-ignore
          Object.keys(deleteNegativeValueInObject(filterValues)).forEach(key => {
            const mc = metroColumns.find((c: any) => c.dataIndex === key)
            if (!mc.filterLocal) {
              h.push(true)
            } else {
              if (mc.filterType === 'text') {
                // @ts-ignore
                h.push(String((item[key] || '')).toLowerCase().indexOf(filterValues[key].toLowerCase()) > -1)
              } else if (['business_unit', 'job_level', 'select'].includes(mc.filterType)) {
                // @ts-ignore
                const val = filterValues[key].value
                // h.push(String((item[key] ?? '')).indexOf(String(val)) > -1)
                h.push(String((item[key] ?? '').toLowerCase()) === String(val).toLowerCase())
              } else if (['select-multi'].includes(mc.filterType)) {
                // @ts-ignore
                const val: any[] = filterValues[key] || []
                const val_string = val.map(el => String(el.value).toLowerCase())
                h.push(val_string.includes(String((item[key] ?? '').toLowerCase())))
              } else if (mc.filterType === 'date') {
                // @ts-ignore
                const val = moment(filterValues[key]).format(config.formatDate)
                h.push(moment(String((item[key] || ''))).format(config.formatDate).indexOf(val) > -1)
              } else if (mc.filterType === 'date-month') {
                const dateFormat = mc.filterFormatDate || config.formatDate
                // @ts-ignore
                const val = moment(filterValues[key]).format(dateFormat)
                h.push(moment(String((item[key] || ''))).format(dateFormat).indexOf(val) > -1)
              }

            }
          })
          return !h.includes(false)
        }) : data
      }, [data, filterValues, metroColumns])


      const f_sort_fix = useMemo(() => {
        const f_sort_raw = deleteNegativeValueInObject(sortValues)
        let f_sort = f_sort_raw
        const findColSortOnly = metroColumns.filter((c: any) => !!c.sortLocal)
        findColSortOnly.forEach((el: any) => {
          // @ts-ignore
          if (f_sort_raw[el.dataIndex]) {
            f_sort = {
              ...f_sort,
              // @ts-ignore
              [el.dataIndex]: f_sort_raw[el.dataIndex]
            }
          }
        })
        return f_sort
      }, [metroColumns, sortValues])


      const sorteredData = useMemo(() => {
        // @ts-ignore
        return _.orderBy(filteredData, Object.keys(f_sort_fix), Object.keys(f_sort_fix).map(key => f_sort_fix[key]))
      }, [f_sort_fix, filteredData])

      const listData = useMemo(() => {
            let d
            if (pagination === null) {
              d = sorteredData
            } else {
              const dataPaginate = arrayPaginate(sorteredData, pageSize)
              d = dataPaginate[page - 1]
            }
            return d
          }, [page, pageSize, pagination, sorteredData]
      )

      useEffect(() => {
        if (selectAllData) {
          setSelectedRow(sorteredData)
        } else {
          setSelectedRow([])
        }
      }, [selectAllData])

      const TitleCustom = (props: {
        name: string
        title: React.ReactNode | string
        filterAble?: boolean
        sortAble?: boolean
        sortParam?: string
        defaultSort?: string
        filterIcon?: React.ReactNode
        filterType?: string
        filterData?: object | any[] | string | number,
        align?: string
      }) => {
        const {
          title,
          filterIcon = (
              <i
                  className={clsx(
                      'fas',
                      props.filterType === 'text' && 'fa-search',
                      props.filterType === 'date' && 'fa-calendar-alt',
                      props.filterType === 'date-month' && 'fa-calendar-day',
                      props.filterType === 'time' && 'fa-clock',
                      props.filterType === 'select' && 'fa-filter',
                      ['business_unit', 'job_level'].includes(props.filterType as any) && 'fa-filter',
                      props.filterType === 'select-multi' && 'fa-filter',
                      (filterValues || {}).hasOwnProperty(props.name) && 'text-primary'
                  )}
              />
          ),
          name,
          sortAble,
          sortParam,
          filterAble,
          filterType,
          filterData,
          // align
        } = props
        const btId = 'bt_' + id.current + '_filter_' + name
        const onClickEnter = (event: any) => {
          if (event?.key === 'Enter' || event?.keyCode === 13) {
            // event.preventDefault();
            const bts = document.getElementById(btId)
            try {
              // @ts-ignore
              bts.click()
            } catch (e) {

            }
          }
        }
        return (
            <div className={'metro-table-cell-filter d-flex justify-content-between '}>
              {/*// @ts-ignore*/}
              <div className={clsx('metro-table-cell-title-with-filter')}
                  // style={{textAlign: align || 'initial'}}
              >{title}</div>
              <div className={'d-flex'}>
                {sortAble && (
                    <div
                        className={clsx('cursor-pointer', filterAble && 'mx-3')}
                        onClick={() => {
                          setSortValues((prev: any = {}) => ({
                            ...prev,
                            [sortParam || name]: (prev || {})[sortParam || name] === 'ASC' ? 'DESC' : (prev || {})[sortParam || name] === 'DESC' ? null : 'ASC',
                          }))
                        }}
                    >
                      <i
                          className={clsx(
                              'fad',
                              (sortValues || {})[props.sortParam || props.name] === 'ASC' && 'fa-sort-up',
                              (sortValues || {})[props.sortParam || props.name] === 'DESC' && 'fa-sort-down',
                              !(sortValues || {})[props.sortParam || props.name] && 'fa-sort',
                              !!(sortValues || {})[props.sortParam || props.name] && 'text-primary'
                          )}
                          style={{color: '#293058'}}
                      />
                    </div>
                )}

                {filterAble && (
                    <div
                        className={clsx(!sortAble && 'ms-3')}
                    >
                      <Dropdown
                          trigger={['click']}
                          // overlayClassName={'metro-dropdown-v2  w-250px w-md-300px'}
                          overlayClassName={'metro-dropdown-v2 w-325px'}
                          overlay={
                            <div className={'metro-table-filter'} onClick={event => event.stopPropagation()}>
                              {filterType === 'text' ? (
                                  <div className={'p-4'} onClick={(e) => e.stopPropagation()}>
                                    <MetroInput
                                        onKeyDown={onClickEnter}
                                        onClick={event => event.stopPropagation()}
                                        value={inputValues[name] || ''}
                                        onChange={(e) =>
                                            setInputValues((prev: any) => ({
                                              ...prev,
                                              [name]: e.target.value,
                                            }))
                                        }
                                        placeholder={typeof title === 'string' ? title : ''}
                                        name={name}
                                        onFocus={(e) => {
                                          e.stopPropagation()
                                        }}
                                    />
                                  </div>
                              ) : ['date', 'time', 'date-month'].includes(filterType as any) ? (
                                  <div className={'d-flex justify-content-center'} onClick={(e) => e.stopPropagation()}>
                                    <MetroDatePicker
                                        onKeyDown={onClickEnter}
                                        showMonthYearPicker={filterType === 'date-month'}
                                        showTimeSelect={filterType === 'time'}
                                        showTimeSelectOnly={filterType === 'time'}
                                        onClickOutside={(e: { stopPropagation: () => any }) => e.stopPropagation()}
                                        onChangeRaw={(e: { stopPropagation: () => any }) => e.stopPropagation()}
                                        inline
                                        selected={inputValues[name]}
                                        onChange={(date: Date) => {
                                          setInputValues((prev: any) => ({
                                            ...prev,
                                            [name]: date,
                                          }))
                                        }}
                                        onFocus={(e: { stopPropagation: () => void }) => {
                                          e.stopPropagation()
                                        }}
                                        dateFormat={filterType === 'time' ? 'HH:mm:ss' : filterType === 'date-month' ? 'MMMM yyyy' : undefined}
                                        placeholderText='This only includes dates from 5 days ago to 5 days in the future'
                                    />
                                  </div>
                              ) : ['select', 'select-multi', 'business_unit', 'job_level'].includes(filterType as string) ? (
                                  <div className={'p-4'} onClick={(e) => e.stopPropagation()}>
                                    <MetroSelect
                                        onKeyDown={onClickEnter}
                                        placeholder={typeof title === 'string' ? title : ''}
                                        value={inputValues[name] || ''}
                                        onChange={(value: any) => {
                                          setInputValues((prev: any) => ({
                                            ...prev,
                                            [name]: value,
                                          }))
                                        }}
                                        onFocus={(e) => {
                                          e.stopPropagation()
                                        }}
                                        isLoading={getAllBusinessUnit.isLoading || getHrJobLevel.isLoading}
                                        // @ts-ignore
                                        isMulti={filterType === 'select-multi'}
                                        options={filterType === 'business_unit' ? getAllBusinessUnit.list : filterType === 'job_level' ? getHrJobLevel.list : ((filterData as any) || [])}
                                        sizeInput={'large'}
                                    />
                                  </div>
                              ) : null}
                              <div className={'mt-5 text-end'}>
                                <div className='row row-cols-auto justify-content-end mx-4 mb-2'>
                                  {(filterValues || {}).hasOwnProperty(name) && (
                                      <div className='col'>
                                        <MetroButton
                                            onClick={() => {
                                              const fixVal: any = {
                                                ...(filterValues || {}),
                                              }
                                              const fixVal2: any = {
                                                ...inputValues,
                                              }
                                              delete fixVal[name]
                                              delete fixVal2[name]
                                              setInputValues(fixVal2)
                                              setFilterValues(fixVal)
                                              // menu.hide(element)
                                            }}
                                            type={'white'}
                                            size={'small'}
                                        >
                                          {intl.formatMessage({id: 'LABEL.CLEAR'})}
                                        </MetroButton>
                                        <span className={'mx-1'} />
                                      </div>
                                  )}
                                  <div className='col px-2'>
                                    <MetroButton
                                        onClick={() => {
                                          // menu.hide(element)
                                        }}
                                        light
                                        size={'small'}
                                    >
                                      <i className='fas fa-times'/> {intl.formatMessage({id: 'LABEL.CANCEL'})}
                                    </MetroButton>
                                  </div>
                                  <div className='col g-0'>
                                    <MetroButton
                                        id={btId}
                                        htmlType={'submit'}
                                        onClick={() => {
                                          setFilterValues(inputValues)
                                          // menu.hide(element)
                                          console.log('inputValues', inputValues)
                                        }}
                                        size={'small'}
                                    >
                                      <i className='fas fa-search'/> {intl.formatMessage({id: 'LABEL.SEARCH'})}
                                    </MetroButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                      >
                        <div className={'cursor-pointer'}>{filterIcon}</div>
                      </Dropdown>
                      {/*<MetroDropdown*/}
                      {/*  placement={'bottom-start'}*/}
                      {/*  header={<div>{filterIcon}</div>}*/}
                      {/*  forceRender*/}
                      {/*>*/}
                      {/*  {(menu: any, element: HTMLElement) => (*/}
                      {/*    <div className={'metro-table-filter'}>*/}
                      {/*      {filterType === 'text' ? (*/}
                      {/*        <MetroInput*/}
                      {/*          value={inputValues[name] || ''}*/}
                      {/*          onChange={(e) =>*/}
                      {/*            setInputValues((prev: any) => ({*/}
                      {/*              ...prev,*/}
                      {/*              [name]: e.target.value,*/}
                      {/*            }))*/}
                      {/*          }*/}
                      {/*          placeholder={typeof title === 'string' ? title : ''}*/}
                      {/*          name={name}*/}
                      {/*        />*/}
                      {/*      ) : ['date', 'time'].includes(filterType as any) ? (*/}
                      {/*        <MetroDatePicker*/}
                      {/*          showTimeSelect={filterType === 'time'}*/}
                      {/*          showTimeSelectOnly={filterType === 'time'}*/}
                      {/*          onClickOutside={(e) => e.stopPropagation()}*/}
                      {/*          onChangeRaw={(e) => e.stopPropagation()}*/}
                      {/*          inline*/}
                      {/*          selected={inputValues[name]}*/}
                      {/*          onChange={(date: any) => {*/}
                      {/*            setInputValues((prev: any) => ({*/}
                      {/*              ...prev,*/}
                      {/*              [name]: date,*/}
                      {/*            }))*/}
                      {/*          }}*/}
                      {/*          dateFormat={filterType === 'time' ? 'HH:mm:ss' : undefined}*/}
                      {/*          placeholderText='This only includes dates from 5 days ago to 5 days in the future'*/}
                      {/*        />*/}
                      {/*      ) : ['select', 'select-multi'].includes(filterType as string) ? (*/}
                      {/*        <div onClick={(e) => e.stopPropagation()}>*/}
                      {/*          <MetroSelect*/}
                      {/*            value={inputValues[name] || ''}*/}
                      {/*            onChange={(value: any) => {*/}
                      {/*              setInputValues((prev: any) => ({*/}
                      {/*                ...prev,*/}
                      {/*                [name]: value,*/}
                      {/*              }))*/}
                      {/*            }}*/}
                      {/*            // @ts-ignore*/}
                      {/*            isMulti={filterType === 'select-multi'}*/}
                      {/*            options={(filterData as any) || []}*/}
                      {/*            sizeInput={'small'}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      ) : null}*/}
                      {/*      <div className={'mt-5 text-end'}>*/}
                      {/*        <div className='row row-cols-auto justify-content-end'>*/}
                      {/*          {(filterValues || {}).hasOwnProperty(name) && (*/}
                      {/*            <div className='col'>*/}
                      {/*              <MetroButton*/}
                      {/*                onClick={() => {*/}
                      {/*                  const fixVal: any = {*/}
                      {/*                    ...(filterValues || {}),*/}
                      {/*                  }*/}
                      {/*                  const fixVal2: any = {*/}
                      {/*                    ...inputValues,*/}
                      {/*                  }*/}
                      {/*                  delete fixVal[name]*/}
                      {/*                  delete fixVal2[name]*/}
                      {/*                  setInputValues(fixVal2)*/}
                      {/*                  setFilterValues(fixVal)*/}
                      {/*                  menu.hide(element)*/}
                      {/*                }}*/}
                      {/*                type={'white'}*/}
                      {/*                size={'small'}*/}
                      {/*              >*/}
                      {/*                {intl.formatMessage({id: 'LABEL.CLEAR'})}*/}
                      {/*              </MetroButton>*/}
                      {/*              <span className={'mx-1'} />*/}
                      {/*            </div>*/}
                      {/*          )}*/}
                      {/*          <div className='col px-2'>*/}
                      {/*            <MetroButton*/}
                      {/*              onClick={() => {*/}
                      {/*                menu.hide(element)*/}
                      {/*              }}*/}
                      {/*              light*/}
                      {/*              size={'small'}*/}
                      {/*            >*/}
                      {/*              {intl.formatMessage({id: 'LABEL.CANCEL'})}*/}
                      {/*            </MetroButton>*/}
                      {/*          </div>*/}
                      {/*          <div className='col g-0'>*/}
                      {/*            <MetroButton*/}
                      {/*              onClick={() => {*/}
                      {/*                setFilterValues(inputValues)*/}
                      {/*                menu.hide(element)*/}
                      {/*              }}*/}
                      {/*              size={'small'}*/}
                      {/*            >*/}
                      {/*              {intl.formatMessage({id: 'LABEL.SEARCH'})}*/}
                      {/*            </MetroButton>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</MetroDropdown>*/}
                    </div>
                )}
              </div>
            </div>
        )
      }

      const fixProps = {
        ...props,
        // data: d,
        data: listData,
        columns: metroColumns,
      }
      delete fixProps.loading
      delete fixProps.pageSize
      delete fixProps.pagination
      return (
          <div className={clsx(loading && `overlay overlay-block`)}>
            <Table components={{
              header: {
                cell: ResizableTitle,
              },
            }} id={id.current} prefixCls={'metro-table'} {...fixProps} />
            {props.loading && (
                <div className='overlay-layer card-rounded bg-dark bg-opacity-5 z-index-1'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
            )}
            {pagination !== null && (
                <MetroPagination
                    disabled={loading}
                    current={page}
                    pageSize={pageSize}
                    className={'my-5'}
                    total={filteredData?.length}
                    onChange={(page) => {
                      setPage(page)
                    }}
                />
            )}
          </div>
      )
    }
)

// const MetroTable: React.FC<RecordType<any>> = (props: TableProps<RecordType>) => {
//   const {className, columns, dataSource, rowKey} = props
//   return (
//     <div className={`card p-0 ${className}`}>
//       {/* begin::Header */}
//       <div className='card-header border-0 p-0 pt-5'>
//         <h3 className='card-title align-items-start flex-column'>
//           <span className='card-label fw-bolder fs-3 mb-1'>New Arrivals</span>
//           <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span>
//         </h3>
//         <div className='card-toolbar'>
//           <a href='#' className='btn btn-sm btn-light-primary'>
//             <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
//             New Member
//           </a>
//         </div>
//       </div>
//       {/* end::Header */}
//       {/* begin::Body */}
//       <div className='card-body p-0 py-3'>
//         {/* begin::Table container */}
//         {/*<div className='table-responsive'>*/}
//         {/*  /!* begin::Table *!/*/}
//         {/*  <table className='table align-middle table-row-dashed fs-6 gy-5'>*/}
//         {/*    /!* begin::Table head *!/*/}
//         {/*    <thead>*/}
//         {/*      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>*/}
//         {/*        {columns.map((el, index) => {*/}
//         {/*          return (*/}
//         {/*            <th key={index} className='min-w-125px'>*/}
//         {/*              {el.title}*/}
//         {/*            </th>*/}
//         {/*          )*/}
//         {/*        })}*/}
//         {/*      </tr>*/}
//         {/*    </thead>*/}
//         {/*    <tbody className={'text-gray-600 fw-bold'}>*/}
//         {/*      {dataSource?.map((el, index) => {*/}
//         {/*        return (*/}
//         {/*          <tr key={el[rowKey]}>*/}
//         {/*            {columns.map((col, index) => {*/}
//         {/*              if (col.render) {*/}
//         {/*                // @ts-ignore*/}
//         {/*                return <td key={index}>{col.render(el[col.dataIndex], el, index)}</td>*/}
//         {/*              }*/}
//         {/*              return (*/}
//         {/*                <td key={index}>*/}
//         {/*                  {*/}
//         {/*                    // @ts-ignore*/}
//         {/*                    el[col.dataIndex]*/}
//         {/*                  }*/}
//         {/*                </td>*/}
//         {/*              )*/}
//         {/*            })}*/}
//         {/*          </tr>*/}
//         {/*        )*/}
//         {/*      })}*/}
//         {/*    </tbody>*/}
//         {/*  </table>*/}
//         {/*</div>*/}
//         {/*<Table prefixCls={'ant-table'} columns={columns} data={data} className="ant-table-small" />*/}
//         <Table {...props} />
//       </div>
//     </div>
//   )
// }
