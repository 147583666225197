import {data_color} from '../../types'

interface MetroSpinnerProps {
  className?: string
  type?: data_color
  theme?: 'grow' | 'border'
}
const MetroSpinner = (props: MetroSpinnerProps) => {
  const {type = 'primary', className = '', theme = 'border'} = props
  return (
    <div className={`spinner-${theme} text-${type} ${className}`} role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}

export {MetroSpinner}
