/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useChooseModal, useFetch, ChooseComponentModal} from '../'
import {getFncUserRoleEmployeeApi, getFncUserRoleEmployeeApiV2} from '../../apis'
import {useIntl} from 'react-intl'
import config from '../../config'

const useHookModalFncUserRoleEmployee = (props: any, {hideRowForId}: {hideRowForId: any[]}) => {
  const intl = useIntl()
  const dataRef = useFetch({apiFunction: getFncUserRoleEmployeeApiV2})
  const chooseModal = useChooseModal({
    hideRowForId,
    isMultiple: true,
    tablePageSize: 5,
    title: 'Employee',
    component: ChooseComponentModal,
    columns: [
      {
        title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
        render: (val: any) => {
          return (
            <>
              {val.first_name}
            </>
          )
        },
      },
      {
        title: intl.formatMessage({id: 'LABEL.ROLE'}),
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
        render: (val: any) => {
          return (
            <>
              {val.role_name}
            </>
          )
        },
      },
    ]
  })
  useEffect(() => {
    if (props.business_unit_id && props.role_id ) {
      dataRef.fetch({
        ...config.paramsPageAndPagination,
        role_id: props.role_id,
        business_unit_id: props.business_unit_id
      }).then()
    }
  }, [props.business_unit_id, props.role_id])

  useEffect(() => {
    chooseModal.setIsLoading(dataRef.isLoading)
  }, [dataRef.isLoading])


  useEffect(() => {
    if (dataRef.list.length > 0) {
      chooseModal.setDataSource(dataRef.list.map(el => {
        const employee_name = (el.first_name + ' ' + el.last_name || '').trim()
        return {
          ...el,
          employee_name
        }
      }))
    }
  }, [dataRef.list])


  return chooseModal
}

export {useHookModalFncUserRoleEmployee}
