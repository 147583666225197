/* eslint-disable react-hooks/exhaustive-deps */
import {randomString, toTitleCase} from '../../utils'
import moment from 'moment'
import {MetroModal} from '../MetroModal'
import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {MetroTooltip} from '../MetroTooltip'
import {useIntl} from 'react-intl'
import {MetroBadge} from '../MetroBadge'

export const ModalTimelineProsesCashAdvance = forwardRef(
  (
    props: {
    },
    ref,
  ) => {
    const [data, setData] = useState(null) as any
    const [visible, setVisible] = useState(false)
    const intl = useIntl()
    const resetData = () => {
      setData(null)
    }
    useImperativeHandle(ref, () => ({
      show: (data: any) => {
        setVisible(true)
        setData(data)
      },
      hide: () => {
        setVisible(false)
        resetData()
      },
    }))
    return (
      <MetroModal
        destroyOnClose={true}
        title={intl.formatMessage({id: 'LABEL.CURRENT_STATUS'})}
        size={'medium'}
        footer={false}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        visible={visible}
        onDismiss={() => {
          setVisible(false)
          resetData()
        }}
      >
        <div className={'row my-1'}>
          <div className={'col-1'} />
          <div className={'col-10'}>
            {
              data?.length === 0 ? (
                <div className='my-5 fs-3 text-gray-400 text-center'>
                  <span className={'fs-5'}>No data history</span>
                </div>
              ) : (<div className='timeline-label'>
                {data?.map((el: any, index: any) => {

                  console.log('datadd', el)
                  let rev_status = false
                  let icon = ''
                  let status_color = ''
                  let reject_message = ''
                  if (el.status_approval === 'complete' || (el.status_approval === 'revised' && el.reject_notes === '')) {
                    icon = 'fa-check'
                    status_color = 'text-success'
                  } else if (el.status_approval === 'in progress') {
                    icon = 'fa-clock'
                    status_color = 'text-warning'
                  } else if (el.status_approval === 'reject') {
                    icon = 'fa-times-circle'
                    status_color = 'text-danger'
                    reject_message = ` ${el.reject_notes}`
                  } else if (el.status_approval === 'revised') {
                    icon = 'fa-times-circle'
                    status_color = 'text-info'
                    reject_message = ` ${el.reject_notes}`
                  } else {
                    icon = 'fa-check'
                    status_color = 'text-success'
                    reject_message = ` ${el.reject_notes}`
                  } if (el.reject_notes != '') {
                    icon = 'fa-undo'
                    status_color = 'text-warning'
                    rev_status = true
                  }
                  return (
                    <div className='timeline-item align-items-start' key={index.toString()}>
                      <div className='timeline-label font-weight-bolder text-dark-75'></div>
                      <div key={randomString()} className='timeline-badge'>
                        <i className={`fas ${icon} ${status_color} fs-3`} />
                      </div>
                      <div className='timeline-content fw-mormal text-muted ps-3'>
                        <MetroTooltip zIndex={11000}
                                      tooltip={el.created_by ? `${intl.formatMessage({id: 'LABEL.CURRENT_STATUS'})} : ` + toTitleCase(el.created_by) : null}>
                          <span className={status_color}>{el.job_position}</span>{' '}
                          <span className={'fw-normal'}>{el.created_by}</span>
                          {
                            rev_status? <span className={'fw-normal'}> <MetroBadge content={'Revision'} type={'warning'} light/></span> : null
                          }
                        </MetroTooltip>
                        {el.created_at && (
                          <div>
                            <span>
                              {moment(el.created_at).format('dddd, DD MMMM YYYY, HH:mm:ss')}
                            </span>
                          </div>
                        )}
                        <div>
                          {reject_message ? (
                            <>
                              {rev_status?  <i className={`fas fa-comment`} /> : null}
                              {reject_message}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>)
            }
          </div>
          <div className={'col-1'} />
        </div>
      </MetroModal>
    )
  },
)
