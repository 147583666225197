import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../utils'
import axios, {AxiosRequestHeaders, CancelToken} from 'axios'
import {getAuth} from '../../app/modules/auth'
const {
  REACT_APP_API,
  REACT_APP_S3_FILE_LOCATION,
  REACT_APP_API_HEADER_KEY,
  REACT_APP_API_KEY,
  REACT_APP_PROD_SITE,
  NODE_ENV,
} = process.env
const base_url = REACT_APP_API + 'v2/'
const base_url_v3 = REACT_APP_API + 'v3/'
const base_system = base_url + 'sysparam/'
const base_parameter = base_url + 'parameter/'
const base_approval = base_url + 'approval/'
const base_sales = base_url + 'sales/'
const base_finance = base_url + 'finance/'
const base_hr = base_url + 'hr/'
const nnet_public = base_url_v3 + 'nnet-public/'
const nnet_visitor = nnet_public + 'visitor/'
const api_jp = 'https://jsonplaceholder.typicode.com'
interface ApiProps {
  params?: {[Key: string]: any}
  headers?: AxiosRequestHeaders
  cancelToken?: CancelToken
  othersParamOrPayload?: {[Key: string]: any}
}

export interface ApiGetProps extends ApiProps {
  id?: string | number
}
export interface ApiDeleteProps extends ApiProps {
  id?: string | number
}
export interface ApiPostProps extends ApiProps {
  id?: string | number
  payload?: any
}
export interface ApiPatchProps extends ApiProps {
  id?: string | number
  payload?: any
}
const apiConfig = (props: ApiProps) => {
  return {
    params: props.params,
    cancelToken: props.cancelToken,
    headers: props.headers,
  }
}
export function testPost(data: any) {
  return apiPost(`${api_jp}/posts`, data)
}

export function viewFiles3(file: any) {
  return `${REACT_APP_S3_FILE_LOCATION}${file}`
}
export function menuTempApi() {
  return axios.get('https://api.npoint.io/20495c55676341bae8e1')
}
export function loginApi(email: string, password: string) {
  return apiPost(`${base_system}auth/signin`, {email, password}, {}, false)
}
export function logoutApi() {
  return apiPost(`${base_system}auth/signout`, undefined, {}, false)
}
export function refreshTokenApi() {
  return apiPatch(`${base_system}auth/refresh`, undefined, {}, false)
}
export function getImageQR(headers: any) {
  return apiPost(`${base_system}auth/authenticator`, undefined, {headers}, false)
}
export function confirmOtp(authenticator_code: string, headers: any) {
  return apiPost(`${base_system}auth/authenticate`, {authenticator_code}, {headers}, false)
}
export function getSalesRevenueApi(params = {}) {
  return apiGet(`${base_sales}sales/revenue`, {params})
}
export function getSalesProfitApi(params = {}) {
  return apiGet(`${base_sales}sales/profit/gross`, {params})
}
export function getSalesProfitNettApi(params = {}) {
  return apiGet(`${base_sales}sales/profit/nett`, {params})
}
export function getProfitGrossRangeProfitGrossApi(params = {}) {
  return apiGet(`${base_sales}profit-gross/range-profit/gross`, {params})
}
export function getSalesTargetApi(params = {}) {
  return apiGet(`${base_sales}sales/target`, {params})
}
export function getSalesCustomerApi(params = {}) {
  return apiGet(`${base_sales}sales/customer`, {params})
}
export function getSalesReportApi(params = {}) {
  return apiGet(`${base_sales}sales/report`, {params})
}
export function getNumberOfSalesApi(params = {}) {
  return apiGet(`${base_sales}sales/number-of-sales`, {params})
}

// fetch by date
export function getSalesRevenueApiByQuarter(value: any) {
  return apiGet(`${base_sales}sales/revenue?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesCustomerMtdApi(value: any) {
  return apiGet(`${base_sales}sales/customer?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesReportMtdApi(value: any) {
  return apiGet(`${base_sales}sales/report?date1=${value.start}&date2=${value.end}`, {})
}
export function getNumberOfSalesApiByMtd(value: any) {
  return apiGet(`${base_sales}sales/number-of-sales?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesTargetApiByMtd(value: any) {
  return apiGet(`${base_sales}sales/target?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesRevenueApiByMtd(value: any) {
  return apiGet(`${base_sales}sales/revenue?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesProfitNettApiByMtd(value: any) {
  return apiGet(`${base_sales}sales/profit/nett?date1=${value.start}&date2=${value.end}`, {})
}
export function getSalesProfitGrossApiByMtd(value: any) {
  return apiGet(`${base_sales}sales/profit/gross?date1=${value.start}&date2=${value.end}`, {})
}

export function getSalesOptyNewDashboardApi(param: any) {
  return apiGet(`${base_sales}sales-opty/new-dashboard/${param}`)
}
export function getSalesOptyNewDashboarTargetdApi(param: any) {
  return apiGet(`${base_sales}sales-opty/dashboard-target/${param}`)
}
export function getSalesOptyNewDashboardDetailApi(params: any = {}) {
  return apiGet(`${base_sales}sales-opty/detail-new-dashboard`, {params})
}

export function getSalesOptyDashboardTargetNew(year: string) {
  return apiGet(`${base_sales}sales-opty/dashboard-target/${year}?target_dashboard=true`)
}

export function getTokenDashboardTenderApi(year: string) {
  return apiPost(`${base_sales}auth/metabase`)
}

//Master Sistem User Access

export function getApprovalSettingType() {
  return apiGet(`${base_parameter}approvals/setting/type`, {})
}

//Dashboard Detail
export function getAllProjects(params: any) {
  return apiGet(`${base_parameter}project`, {params})
}
export function getParamMeetingRoomApi(params: any) {
  return apiGet(`${base_parameter}meeting-room`, {params})
}
export function getParamMeetingRoomRefApproveApi(params: any) {
  return apiGet(`${base_parameter}meeting-room/ref/approve`, {params})
}
export function getAllProjects1000Api(params: any) {
  return getAllProjects({page: 1, limit: 1000, ...params})
}

export function getAllProjectApiFilter(page: any, limit: any) {
  return apiGet(`${base_parameter}project?page=${page}&limit=${limit}`, {})
}

//Finance
export function getAllBankCompanyApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-bank-company`, {params})
}
export function getAllMstBillingApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-mst-billing`, {params})
}

export function getAllBankCompanyLimit1000Api(params: any = {}) {
  return getAllBankCompanyApi({page: 1, limit: 1000, ...params})
}

export function patchBankCompanyApi(value: any) {
  return apiPatch(`${base_finance}fnc-bank-company/${value.id}`, {...value})
}
export function postBankCompanyApi(value: any) {
  return apiPost(`${base_finance}fnc-bank-company`, {...value})
}
export function postBusinessUnitApi(payload: any) {
  return apiPost(`${base_parameter}business-unit`, payload, {})
}
export function patchBusinessUnitApi(data: any) {
  return apiPatch(`${base_parameter}business-unit/${data.id}`, data.payload)
}
export function getBusinessUnitApi(params: any) {
  return apiGet(`${base_parameter}business-unit`, {params})
}
export function deleteBusinessUnitApi(id: any) {
  return apiDelete(`${base_parameter}business-unit/${id}`)
}
export function getAllBusinessUnitApi() {
  return apiGet(`${base_parameter}business-unit`)
}
export function getAllBusinessUnit1000Api(params: any) {
  return apiGet(`${base_parameter}business-unit`, {params})
}

export function getFncGlDailyApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-gl-daily`, {params})
}
export function getFncSumGlApi(params: any) {
  return apiGet(`${base_finance}fnc-sum-gl`, {params})
}
export function postFncSumGlClosingApi(payload: any) {
  return apiPost(`${base_finance}fnc-sum-gl/closing`, payload)
}
export function postFncSumGlUnclosingApi(payload: any) {
  return apiPost(`${base_finance}fnc-process-unclosing`, payload)
}
export function deleteFncGlDailyApi(id: number | string) {
  return apiDelete(`${base_finance}fnc-gl-daily/${id}`)
}
export function deleteBatchFncGlDailyApi(ids: string) {
  return apiDelete(`${base_finance}fnc-gl-daily${ids}`)
}
export function getFncBankDailyApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-bank-daily`, {params})
}
export function deleteFncBankDailyApi(id: number | string) {
  return apiDelete(`${base_finance}fnc-bank-daily/${id}`)
}
export function deleteBatchFncBankDailyApi(ids: string) {
  return apiDelete(`${base_finance}fnc-bank-daily${ids}`)
}
export function postFncGlDailyApi(data: any = {}) {
  return apiPost(`${base_finance}fnc-gl-daily`, data)
}
export function postFncGlDailyBatchApi(data: any[] = []) {
  return apiPost(`${base_finance}fnc-gl-daily/batch`, data)
}
export function patchFncGlDailyApi(id: number | string, data: any = {}) {
  return apiPatch(`${base_finance}fnc-gl-daily/${id}`, data)
}
export function patchFncGlDailyApi2({id, data}: any) {
  return apiPatch(`${base_finance}fnc-gl-daily/${id}`, data)
}
export function patchFncBankDailyApi(id: number | string, data: any = {}) {
  return apiPatch(`${base_finance}fnc-bank-daily/${id}`, data)
}
export function patchFncBankDailyApi2({id, data}: any) {
  return apiPatch(`${base_finance}fnc-bank-daily/${id}`, data)
}
export function postFncBankDailyApi(data: any = {}) {
  return apiPost(`${base_finance}fnc-bank-daily`, data)
}
export function postFncBankDailyBatchApi(data: any[] = []) {
  return apiPost(`${base_finance}fnc-bank-daily/batch`, data)
}

export function getFncGlApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-gl`, {params})
}

export function getExportApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-acc-receivable/reports-ar`, {params})
}

export function getParamApi(params: any = {}) {
  return apiGet(`${base_parameter}ref-parameter`, {params})
}
export function getParamNaturaPolicyApi(params: any = {}) {
  return apiGet(`${base_parameter}ref-parameter/natura-policy-detail`, {params})
}
export function getLogsApi(params: any) {
  return apiGet(`${base_system}/logs`, {params})
}
export function getParamCityApi(params: any = {}) {
  return apiGet(`${base_parameter}city`, {params})
}

//Outstanding AR
export function getFncAccApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-acc-receivable`, {params})
}
export function postFncAccApi(params: any = {}) {
  return apiPost(`${base_finance}fnc-acc-receivable`, params)
}
export function patchFncAccApi(params: any = {}) {
  return apiPatch(`${base_finance}fnc-acc-receivable/${params.id_acc_rec}`, params)
}
export function deleteFncAccApi(params: any = {}) {
  return apiDelete(`${base_finance}fnc-acc-receivable/${params.id_acc_rec}`)
}

//Outstanding AP
export function getFncAccPayableApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-acc-payable`, {params})
}
export function postFncAccPayableReportApi(data: any = {}) {
  return apiPost(`${base_finance}fnc-acc-payable/report`, data, {responseType: 'blob'})
}
export function getFncAccForPaymentApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-acc-payable/for-payment`, {params})
}
export function getFncAccForPayment1000Api(params: any = {}) {
  return getFncAccForPaymentApi({page: 1, limit: 1000, ...params})
}
export function postFncAccPayableApVerifikasiApi(data: any) {
  return apiPost(`${base_finance}fnc-acc-payable/ap/verification`, data)
}
export function postFncAccPayableApApproveApi(data: any) {
  return apiPost(`${base_finance}fnc-acc-payable/ap/approve`, data)
}
export function patchFncAccPayableRejectApi(data: {id_acc_pay: string; payload: any}) {
  return apiPatch(`${base_finance}fnc-acc-payable/reject/${data.id_acc_pay}`, data.payload)
}
export function patchFncAccPayableRejectBatchAllApi(payload: {
  id_acc_pay: string[]
  remarks: string
}) {
  return apiPatch(`${base_finance}fnc-acc-payable/reject/batch/all`, payload)
}
export function getFncAccPayableApVerifikasiApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/ap/verification`, {params})
}
export function getFncAccPayableHistorySppApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/history/spp`, {params})
}
export function getFncAccPayableDashboardApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/outstanding/ap`, {params})
}
export function getFncAccPayable1000Api(params: any = {}) {
  return getFncAccPayableApi({page: 1, limit: 1000, ...params})
}
export function postFncAccPayableApi(params: any = {}) {
  return apiPost(`${base_finance}fnc-acc-payable`, params)
}
export function patchFncAccPayableApi(params: any = {}) {
  return apiPatch(`${base_finance}fnc-acc-payable/${params.id_acc_pay}`, params)
}
export function deleteFncAccPayableApi(params: any = {}) {
  return apiDelete(`${base_finance}fnc-acc-payable/${params.id_acc_pay}`)
}
export function patchFncAccPayableDownloadUpdateFncAccApi(payload: any) {
  return apiPatch(`${base_finance}fnc-acc-payable/download/update-fncAcc`, payload)
}
export function patchFncAccPayApi(params: any) {
  return apiPatch(`${base_finance}fnc-acc-payable/status/payment`, params)
}
export function getAllProjectsApi(params: any) {
  return apiGet(`${base_parameter}project`, {params})
}
export function getFncAprApi(params: any) {
  return apiGet(`${base_finance}fnc-apr`, {params})
}
export function postFncAprApi(params: any) {
  return apiPost(`${base_finance}fnc-apr`, params)
}
export function patchFncAprApi(params: any) {
  return apiPatch(`${base_finance}fnc-apr/${params.id}`, params)
}
export function patchFncAprApproveApi(params: any) {
  return apiPost(`${base_finance}fnc-apr/${params.id}/approve`, params)
}
export function patchFncAprRevisionApi(params: any) {
  return apiPost(`${base_finance}fnc-apr/${params.id}/reject`, params)
}
export function postFncAccPayablePayApi(params: any) {
  return apiPost(`${base_finance}fnc-acc-payable/pay`, params)
}
export function getFncApr1000Api(params: any = {}) {
  return getFncAprApi({page: 1, limit: 1000, ...params})
}
export function getFncAccPaySppNumberApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/fnc-acc-pay/spp-number`, {params})
}
export function getFncAccBankingApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/banking/all`, {params})
}
export function getFncAccBankingByBuApi(params: any) {
  return apiGet(
    `${base_finance}fnc-acc-payable/banking-by-bu/${params.company_id}?date1=${params.date1}&date2=${params.date2}`
  )
}
export function getFncAprRoleApprovalAllApi(params: any) {
  return apiGet(`${base_finance}fnc-apr-role-approval/approve/all`, {params})
}
export function getFncAprRoleApprovalApi(params: any) {
  return apiGet(`${base_finance}fnc-apr-role-approval`, {params})
}
export function getFncAprRoleApprovalHistoryApi(params: any) {
  return apiGet(`${base_finance}fnc-apr-role-approval/history`, {params})
}
export function getFncAprRoleApprovalRequestApi(params: any) {
  return apiGet(`${base_finance}fnc-apr-role-approval/request`, {params})
}
export function getFncAprRoleApprovalRequestAllApi(params: any) {
  return apiGet(`${base_finance}fnc-apr-role-approval/request/all`, {params})
}
export function patchParameterApi(data: any) {
  return apiPatch(`${base_parameter}employee/data/${data.id}`, data.payload)
}
export function getApprovalApi(params: any) {
  return apiGet(`${base_finance}fnc-apr/getapproval`, {params})
}
export function getFncAprHistoryApApi(params: any) {
  return apiGet(`${base_finance}fnc-apr/history/ap`, {params})
}
export function patchFncAprRoleApprovalApi(params: any) {
  return apiPatch(`${base_finance}fnc-apr-role-approval/${params.id}`, params)
}
export function patchFncAprRoleRevisionApi(params: any) {
  return apiPatch(`${base_finance}fnc-apr-role-approval/${params.id}/revision`, params)
}
export function getFncAprRoleApproval1000Api(params: any = {}) {
  return getFncAprRoleApprovalApi({page: 1, limit: 1000, ...params})
}
export function getMstBankApi(params: any) {
  return apiGet(`${base_parameter}mst-bank`, {params})
}
export function getFncUserRoleApi(params: any) {
  return apiGet(`${base_finance}fnc-user-role`, {params})
}
export function getFncUserRoleApiV2(params: any) {
  return apiGet(`${base_system}user-role`, {params})
}
export function getFncUserRoleEmployeeApi(params: any) {
  return apiGet(`${base_finance}fnc-user-role/employee/all`, {params})
}
export function getFncUserRoleEmployeeApiV2(params: any) {
  return apiGet(`${base_system}user-role/employee/all`, {params})
}
export function postFncUserRoleApi(params: any) {
  return apiPost(`${base_finance}fnc-user-role/batch`, params)
}
export function getAccPayableJasperApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/${params}/jasper_report`, {responseType: 'blob'})
}
export function getBankDailyReportApi(params: any = {}) {
  return apiPost(`${base_finance}fnc-bank-daily/bankdaily/report`, params, {responseType: 'blob'})
}
export function getArReportApi(params: any = {}) {
  return apiPost(`${base_finance}fnc-acc-receivable/report`, params, {responseType: 'blob'})
}
export function getReceivablePayableReportApi(params: any = {}) {
  return apiGet(`${base_parameter}project/report-rp/${params}`, {responseType: 'blob'})
}
export function postReceivablePayableReportApi(params: any = {}) {
  return apiPost(`${base_parameter}project/report-rp`, params, {responseType: 'blob'})
}
export function getMonitoringFinanceApi(params: any = {}) {
  return apiGet(`${base_finance}fnc-bank-daily/fnc-monitoring/${params}`, {responseType: 'blob'})
}
export function getMstBank1000Api(params: any) {
  return getMstBankApi({page: 1, limit: 1000, ...params})
}

export function getFncAccBanking1000Api(params: any = {}) {
  return getFncAccBankingApi({...params})
}
export function postMstBankApi(params: any) {
  return apiPost(`${base_parameter}mst-bank`, params)
}
export function patchMstBankApi(data: any) {
  return apiPatch(`${base_parameter}mst-bank/${data.id}`, data.payload)
}
export function deleteMstBankApi(params: any) {
  return apiDelete(`${base_parameter}mst-bank/${params}`)
}
export function deleteFncAprApi(params: any) {
  return apiDelete(`${base_finance}fnc-apr/${params}`)
}
export function deleteFncAprIdsApi(params: string) {
  return apiDelete(`${base_finance}fnc-apr/delete`, {data: params})
}

export function getFncNaturaPolicyApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-policy`, {params})
}

export function deleteFncNaturaPolicyApi(params: any) {
  return apiDelete(`${base_finance}fnc-natura-policy/${params}`)
}

export function postFncNaturaPolicyApi(params: any) {
  return apiPost(`${base_finance}fnc-natura-policy`, params)
}

export function patchFncNaturaPolicyApi(data: any) {
  return apiPatch(`${base_finance}fnc-natura-policy/${data.id}`, data.payload)
}

export function getDataFncAdjustNaturaApi(params: any) {
  return apiGet(`${base_finance}fnc-adjust-natura`, {params})
}
export function getFncAdjustNaturaSelectionApi(params: any) {
  return apiGet(`${base_finance}fnc-adjust-natura/get-selection`, {params})
}

export function postFncAdjustNaturaApi(params: any) {
  return apiPost(`${base_finance}fnc-adjust-natura`, params)
}

export function patchFncAdjustNaturaApi(data: any) {
  return apiPatch(`${base_finance}fnc-adjust-natura/${data.id}`, data.payload)
}

export function deleteFncAdjustNaturaApi(params: any) {
  return apiDelete(`${base_finance}fnc-adjust-natura/${params}`)
}

export function getDataNaturaSplitApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-split`, {params})
}

export function getFncNaturaSplitSelectionApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-split/get-selection`, {params})
}

export function getFncReportNaturaApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-split/get-report-natura`, {params})
}

export function postFncNaturaSplitApi(params: any) {
  return apiPost(`${base_finance}fnc-natura-split`, params)
}

export function patchFncNaturaSplitApi(data: any) {
  return apiPatch(`${base_finance}fnc-natura-split/${data.id}`, data.payload)
}

export function deleteFncNaturaSplitApi(params: any) {
  return apiDelete(`${base_finance}fnc-natura-split/${params}`)
}

export function getFncReportMonitoringNaturaApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-split/tax-monitoring`, {params})
}

export function getFncReportMonitoringNaturaInCashApi(params: any) {
  return apiGet(`${base_finance}fnc-natura-split/tax-monitoring/in-cash`, {params})
}

//Mst Bank

//KPI-REPORT DASHBOARD
export function getKpiReportApi(params: any) {
  return apiGet(`${base_finance}fnc-sum-gl/kpi/report`, {params})
}

//FORGOT & RESET PASSWORD
export function getForgotPasswordApi(params: any) {
  return apiGet(`${base_system}user/email/verification`, {params}, false)
}
export function getUsersApi(params: any) {
  return apiGet(`${base_system}/user`, {params})
}
export function patchNewPasswordApi(params: any) {
  return apiPatch(`${base_system}user/new-password/${params.id}`, params.password)
}
//Vendor
export function getMasterVendorApi(params: any = {}) {
  return apiGet(`${base_parameter}mst-vendor`, {params})
}
export function postMasterVendorApi(payload: any) {
  return apiPost(`${base_parameter}mst-vendor`, payload)
}
export function patchMasterVendorApi(data: any) {
  return apiPatch(`${base_parameter}mst-vendor/${data.id}`, data.payload)
}
export function patchUserPasswordApi(params: any) {
  return apiPatch(`${base_system}user/${params.user_id}/reset-password`, params)
}
export function deleteMasterVendorApi(id: any) {
  return apiDelete(`${base_parameter}mst-vendor/${id}`)
}

export function getFncGlCwcApi() {
  return getFncGlApi({
    gl_group1: 'Others',
    gl_group2: 'Current Working Capital',
  })
}

export function getFncGlClApi() {
  return getFncGlApi({
    gl_group1: 'Others',
    gl_group2: 'Current Liabilities',
  })
}

//Global Parameter
export function getAllParamApi(params: any) {
  return apiGet(`${base_parameter}ref-parameter?type=${params}`)
}
export function getRefParamApi(params: any) {
  return apiGet(`${base_parameter}ref-parameter`, {
    params,
  })
}
export function postRefParamApi(payload: any) {
  return apiPost(`${base_parameter}ref-parameter`, payload)
}
export function patchRefParamApi(data: any) {
  return apiPatch(`${base_parameter}ref-parameter/${data.id}`, data.payload)
}
export function deleteRefParamApi(id: any) {
  return apiDelete(`${base_parameter}ref-parameter/${id}`)
}

//Global Parameter
export function getRefGlTypeApi() {
  return getAllParamApi('tipe_gl')
}

export function deleteFileS3Api(name: string) {
  return apiDelete(`${base_system}s3`, {data: {name}})
}
export function getMenuRefRolesApi(props: ApiGetProps) {
  return apiGet(`${base_system}menu/ref/roles`, apiConfig(props))
}
export function postNotificationTokenApi(data: any) {
  return apiPost(`${base_system}notification-token`, data, {})
}
export function patchNotificationTokenApi(data: any) {
  return apiPatch(`${base_system}notification-token/${data.id}`, data.payload)
}
export function getNotificationTokenApi(params: {name: any}) {
  return apiGet(`${base_system}notification-token`, {params})
}
export function deleteNotificationTokenApi(id: any) {
  return apiDelete(`${base_system}notification-token/${id}`)
}
export function postMenuApi(data: any) {
  return apiPost(`${base_system}menu`, data)
}
export function patchMenuApi(data: any) {
  return apiPatch(`${base_system}menu/${data.id}`, data.payload)
}
export function getErrorRepoApi(params: {name: any}) {
  return apiGet(`${base_system}error-repo`, {params})
}
export function postErrorRepoApi(payload: any) {
  return apiPost(`${base_system}error-repo`, payload)
}
export function deleteErrorRepoApi(id: any) {
  return apiGet(`${base_system}error-repo/${id}`)
}
export function getMenuApi(params: {name: any}) {
  return apiGet(`${base_system}menu`, {params})
}

export function deleteMenuApi(id: any) {
  return apiDelete(`${base_system}menu/${id}`)
}
export function getRoleApi(params: any) {
  return apiGet(`${base_system}role`, {params})
}
export function postRoleApi(payload: any) {
  return apiPost(`${base_system}role`, payload)
}
export function patchRoleApi(data: any) {
  return apiPatch(`${base_system}role/${data.id}`, data.payload)
}
export function deleteRoleApi(id: any) {
  return apiDelete(`${base_system}role/${id}`)
}

export function getSalesOpportunityReportPerformanceApi(params: any) {
  return apiGet(
    `${base_sales}sales-opty/report/performance
`,
    {params}
  )
}
export function getSalesOptyDashboardApi({year, params}: {year: string; params: any}) {
  return apiGet(
    `${base_sales}sales-opty/dashboard/${year}
`,
    {params}
  )
}
export function getSalesOpportunityApi(params: any) {
  return apiGet(`${base_sales}sales-opty`, {params})
}
export function postSalesOpportunityApi(payload: any) {
  return apiPost(`${base_sales}sales-opty`, payload)
}
export function patchSalesOpportunityApi(data: any) {
  return apiPatch(`${base_sales}sales-opty/${data.id}`, data.payload)
}
export function deleteSalesOpportunityApi(id: any) {
  return apiDelete(`${base_sales}sales-opty/${id}`)
}

export function getHistoryOptyApi(id: any) {
  return apiGet(`${base_sales}sales-opty/history-opty/${id}`)
}

export function getSalesTaskApi(params: any) {
  return apiGet(`${base_sales}sales-task`, {params})
}
export function postSalesTaskApi(payload: any) {
  return apiPost(`${base_sales}sales-task`, payload)
}
export function patchSalesTaskApi(data: any) {
  return apiPatch(`${base_sales}sales-task/${data.id}`, data.payload)
}
export function deleteSalesTaskApi(id: any) {
  return apiDelete(`${base_sales}sales-task/${id}`)
}

export function getSysSettingApi(params: any) {
  return apiGet(`${base_approval}sys-setting`, {params})
}
export function postSysSettingApi(payload: any) {
  return apiPost(`${base_approval}sys-setting`, payload)
}
export function patchSysSettingApi(data: any) {
  return apiPatch(`${base_approval}sys-setting/${data.id}`, data.payload)
}
export function deleteSysSettingApi(id: any) {
  return apiDelete(`${base_approval}sys-setting/${id}`)
}

export function getSysSetRequestApi(params: any) {
  return apiGet(`${base_approval}sys-set-request`, {params})
}
export function getSysSetRequestDetailApi(params: any) {
  return apiGet(`${base_approval}sys-set-request/${params}`)
}
export function postSysSetRequestApi(payload: any) {
  return apiPost(`${base_approval}sys-set-request`, payload)
}
export function patchSysSetRequestApi(data: any) {
  return apiPatch(`${base_approval}sys-set-request/${data.id}`, data.payload)
}
export function deleteSysSetRequestApi(id: any) {
  return apiDelete(`${base_approval}sys-set-request/${id}`)
}

export function getRoleMenuApi(params: any) {
  return apiGet(`${base_system}role-menu`, {params})
}
export function postRoleMenuApi(payload: any) {
  return apiPost(`${base_system}role-menu`, payload)
}
export function deleteRoleMenuApi(id: any) {
  return apiDelete(`${base_system}role-menu/${id}`)
}
export function getSalesContactApi(params: any) {
  return apiGet(`${base_sales}sales-contact`, {params})
}
export function postSalesContactApi(params: any) {
  return apiPost(`${base_sales}sales-contact`, params)
}
export function patchSalesContactApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}sales-contact/${data.id}`, data.payload)
}
export function deleteSalesContactApi(id: any) {
  return apiDelete(`${base_sales}sales-contact/${id}`)
}
export function getSchPaymentListReceivableApi(params: any) {
  return apiGet(`${base_sales}sales-sch-payment-project/list-receivable`, {params})
}
export function getSchPaymentScheduleApi(params: any) {
  return apiGet(`${base_sales}sales-sch-payment-project/view-schedule`, {params})
}
export function getSchPaymentApi(params: any) {
  return apiGet(`${base_sales}sales-sch-payment-project`, {params})
}
export function postSchPaymentApi(payload: any) {
  return apiPost(`${base_sales}sales-sch-payment-project`, payload)
}
export function patchSchPaymentApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}sales-sch-payment-project/${data.id}`, data.payload)
}
export function deleteSchPaymentApi(id: any) {
  return apiDelete(`${base_sales}sales-sch-payment-project/${id}`)
}

// export function getCustomerApi(props: ApiGetProps) {
//     return apiGet(`${base_sales}customer`, apiConfig(props))
// }
export function getCustomerApi(params: any = {}) {
  return apiGet(`${base_sales}customer`, {params})
}
export function getCustomerApiV1(params: any) {
  return apiGet(`${base_sales}customer`, {params})
}
export function getCustomerBySalesApiV1(params: any) {
  return apiGet(`${base_sales}customer/customer-by-sales`, {params})
}
export function postCustomerApi(payload: any) {
  return apiPost(`${base_sales}customer`, payload)
}
export function patchCustomerApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}customer/${data.id}`, data.payload)
}
export function deleteCustomerApi(props: ApiDeleteProps) {
  return apiDelete(`${base_sales}customer/${props.id}`, apiConfig(props))
}
export function getProfitNettApi(params: any) {
  return apiGet(`${base_sales}profit-nett`, {params})
}
export function postProfitNettApi(payload: any) {
  return apiPost(`${base_sales}profit-nett`, payload)
}
export function patchProfitNettApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}profit-nett/${data.id}`, data.payload)
}
export function deleteProfitNettApi(id: any) {
  return apiDelete(`${base_sales}profit-nett/${id}`)
}

export function getProfitGrossApi(params: any) {
  return apiGet(`${base_sales}profit-gross`, {params})
}
export function postProfitGrossApi(payload: any) {
  return apiPost(`${base_sales}profit-gross`, payload)
}
export function patchProfitGrossApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}profit-gross/${data.id}`, data.payload)
}
export function deleteProfitGrossApi(id: any) {
  return apiDelete(`${base_sales}profit-gross/${id}`)
}

export function getMstSalesTargetApi(params: any) {
  return apiGet(`${base_sales}sales-target`, {params})
}
export function postMstSalesTargetApi(payload: any) {
  return apiPost(`${base_sales}sales-target`, payload)
}
export function patchMstSalesTargetApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_sales}sales-target/${data.id}`, data.payload)
}
export function deleteMstSalesTargetApi(id: any) {
  return apiDelete(`${base_sales}sales-target/${id}`)
}
export function getTargetByPositionApi(params: any) {
  return apiGet(`${base_sales}target-by-position`, {params})
}

export function getHrSchMeetingUserApi(params: any) {
  return apiGet(`${base_approval}hr-sch-meeting/user`, {params})
}
export function getHrSchMeetingApi(params: any) {
  return apiGet(`${base_approval}hr-sch-meeting`, {params})
}
export function getReimburseAllApi(params: any) {
  return apiGet(`${base_approval}hr-request-reimbursement/request-reimburse-all`, {params})
}
export function getAllRequestApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/request-all`, {params})
}
export function getHrTProsesApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/flow-approval`, {params})
}
export function getCertificateTProsesApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/certification`, {params})
}
export function patchCertificateTProsesApprovalApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-t-proses/approve-certification/${data.id}`, data.payload)
}
export function getHrTProsesMeetingRoomApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/request/meeting-room`, {params})
}
export function patchHrTRejectProsesReimburseApi(payload: any) {
  return apiPatch(`${base_approval}hr-t-proses/reject/reimburse`, payload)
}
export function patchHrTProsesMeetingRoomApi(payload: any) {
  return apiPatch(`${base_approval}hr-t-proses/approval/meeting-room`, payload)
}
export function deleteHrTProsesMeetingRoomApi(id: any) {
  return apiDelete(`${base_approval}hr-t-proses/${id}`)
}
export function patchHrTProsesApprovePcsApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/approve-pcs/${params.id}`, params.payload)
}

export function getHrTProsesSoApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/so`, {params})
}
export function patchHrTProsesApproveSoApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/approve-so/${params.id}`, params.payload)
}

export function getHrTProsessApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses`, {params})
}
export function patchHrTProsessApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses`, params)
}
export function patchHrReqTrainingApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-request-training/request/${data.id}`, data.payload)
}
export function patchHrReqAllowanceSkillApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-req-allowance-skill/${data.id}`, data.payload)
}
export function postHrSchMeetingApi(payload: any) {
  return apiPost(`${base_approval}hr-sch-meeting`, payload)
}
export function deleteHistoryApprovalApi(id: any) {
  return apiDelete(`${base_approval}hr-t-proses/history/approval/${id}`)
}

export function getReqCaApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/req-cash-advance`, {params})
}
export function patchReqCaApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/req-cash-advance`, params)
}
export function patchApproveReqCaApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/req-ca`, params)
}
export function postReqCaApi(payload: any) {
  return apiPost(`${base_approval}hr-t-proses/req-cash-advance`, payload)
}
export function deleteReqCaApi(id: any) {
  return apiDelete(`${base_approval}hr-t-proses/req-cash-advance/${id}`)
}

export function getHistoryApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/history/approval`, {params})
}
export function patchHistoryApprovalApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/history/approval`, params)
}
export function postHistoryApprovalApi(payload: any) {
  return apiPost(`${base_approval}hr-t-proses/history/approval`, payload)
}
export function getApprovalPcsApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/pcs`, {params})
}
export function patchHrSchMeetingApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-sch-meeting/${data.id}`, data.payload)
}
export function deleteHrSchMeetingApi(id: any) {
  return apiDelete(`${base_approval}hr-sch-meeting/${id}`)
}

export function getHrRequestReimbursementApi(params: any) {
  return apiGet(`${base_approval}hr-request-reimbursement`, {params})
}
export function getDetailHrRequestReimbursementApi(id: any) {
  return apiGet(`${base_approval}hr-request-reimbursement/detail-reimbursement/${id}`)
}
export function postHrRequestReimbursementApi(payload: any) {
  return apiPost(`${base_approval}hr-request-reimbursement`, payload)
}
export function patchHrRequestReimbursementApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-request-reimbursement/${data.id}`, data.payload)
}
export function deleteHrRequestReimbursementApi(id: any) {
  return apiDelete(`${base_approval}hr-request-reimbursement/${id}`)
}
export function getReportHrRequestTrainingApi(id: any) {
  return apiGet(`${base_approval}hr-request-training/report/${id}`, {responseType: 'blob'})
}
export function getReportHrRequestReimbursementApi(id: any) {
  return apiGet(`${base_approval}hr-request-reimbursement/report/${id}`, {responseType: 'blob'})
}

export function getHrRequestReimbursementMedicalListPeriodeApi(params: any) {
  return apiGet(`${base_hr}hr-request-reimbursement/medical-list-periode`, {params})
}

export function getHrReportReimbursementMedicalApi(params: any) {
  return apiGet(`${base_hr}hr-request-reimbursement/medical-list`, {params, responseType: 'blob'})
}

export function getExcelHrReportReimbursementMedicalApi(params: any) {
  return apiGet(`${base_hr}hr-request-reimbursement/export-medical`, {params, responseType: 'blob'})
}

export function getReportHrListEmployeeApi(params: any) {
  return apiGet(`${base_hr}hr-emp-info/list-employee`, {params})
}

export function getHrListEmployeeDataApi(params: any) {
  return apiGet(`${base_hr}hr-emp-info/list-employee-data`, {params})
}

export function getVisitorApi(params: any) {
  return apiGet(`${nnet_visitor}get-all`, {params})
}

export function putVisitorApi(params: any) {
  return apiPut(`${nnet_visitor}${params.id}`, {checkout_date: params.checkout_date})
}

export function getParamVisitorApi(params: any) {
  return apiGet(`${nnet_public}ref-parameter/get-all`, {params})
}

export function getReportFnRequestCaSettlementApi(id: any) {
  return apiGet(`${base_finance}fnc-req-ca-settlement/report/${id}`, {responseType: 'blob'})
}
export function getHrtRequestTrainingApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/request-training`, {params})
}
export function getRequestTrainingByIdApi(id: any) {
  return apiGet(`${base_approval}hr-request-training/get-list-hr-approval-training/${id}`)
}
export function getRequestTrainingApi(params: any) {
  return apiGet(`${base_approval}hr-request-training`, {params})
}
export function postRequestTrainingApi(payload: any) {
  return apiPost(`${base_approval}hr-request-training`, payload)
}
export function getRequestTrainingDetailApi(id: any) {
  return apiGet(`${base_approval}hr-request-training/${id}`)
}
export function patchRejectRequestTrainingApi(id: any) {
  return apiPatch(`${base_approval}hr-t-proses/reject/req-training/${id}`)
}
export function patchApproveTrainingApi(payload: any) {
  return apiPatch(`${base_approval}hr-t-proses/approve-training`, payload)
}
export function patchRequestTrainingApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-request-training/${data.id}`, data.payload)
}
export function deleteRequestTrainingApi(id: any) {
  return apiDelete(`${base_approval}hr-request-training/${id}`)
}
export function getListTrainingApi(params: any = {}) {
  return apiGet(`${base_approval}hr-request-training/list-training`, {params})
}

export function getListTraining2Api(params: any = {}) {
  return apiGet(`${base_approval}hr-request-training/list-training-employee`, {params})
}

export function getDetailSkillAllowanceApi(id: any) {
  return apiGet(`${base_approval}hr-req-allowance-skill/get-detail-approval-skill-allowance/${id}`)
}
export function getReportRequesAllowanceApi(id: any) {
  return apiGet(`${base_hr}hr-req-allowance-skill/report/${id}`, {responseType: 'blob'})
}
export function getRequestAllowanceApi(params: any) {
  return apiGet(`${base_approval}hr-req-allowance-skill`, {params})
}
export function postRequestAllowanceApi(payload: any) {
  return apiPost(`${base_approval}hr-req-allowance-skill`, payload)
}
export function patchRequestAllowanceApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-req-allowance-skill/${data.id}`, data.payload)
}
export function deleteRequestAllowanceApi(id: any) {
  return apiDelete(`${base_approval}hr-req-allowance-skill/${id}`)
}

export function getRequestInformalAwardApi(params: any) {
  return apiGet(`${base_approval}hr-req-informal-rewards`, {params})
}
export function postRequestInformalAwardApi(payload: any) {
  return apiPost(`${base_approval}hr-req-informal-rewards`, payload)
}

export function getReportInformalAwardApi(id: any) {
  return apiGet(`${base_approval}hr-req-informal-rewards/report/${id}`, {responseType: 'blob'})
}

export function patchRequestInformalAwardApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-req-informal-rewards/${data.id}`, data.payload)
}
export function deleteRequestInformalAwardApi(id: any) {
  return apiDelete(`${base_approval}hr-req-informal-rewards/${id}`)
}

export function getApprovalInformalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/request-rewards`, {params})
}
export function getDetailInformalAwardApi(id: any) {
  return apiGet(`${base_approval}hr-req-informal-rewards/get-detail-approval-informal-reward/${id}`)
}
export function patchApprovalInformalApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/approval/request-informal-rewards`, params)
}
export function getRequestAchievRewardApi(params: any) {
  return apiGet(`${base_approval}hr-req-achiev-reward`, {params})
}

export function getReportAchievRewardApi(id: any) {
  return apiGet(`${base_approval}hr-req-achiev-reward/report/${id}`, {responseType: 'blob'})
}

export function getRequestAchievRewardDetailApi(params: any) {
  return apiGet(`${base_approval}hr-req-achiev-reward/detail/${params}`)
}
export function postRequestAchievRewardApi(payload: any) {
  return apiPost(`${base_approval}hr-req-achiev-reward`, payload)
}
export function patchRequestAchievRewardApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-req-achiev-reward/${data.id}`, data.payload)
}
export function deleteRequestAchievRewardApi(id: any) {
  return apiDelete(`${base_approval}hr-req-achiev-reward/${id}`)
}
export function getHrReportPcsApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/pcs-all`, {params})
}
export function getAllRequestHRMappCertificateApi(params: any) {
  return apiGet(`${base_hr}hr-mapp-certificate`, {params})
}
export function getGaMstCarApi(params: any) {
  return apiGet(`${base_hr}ga-mst-car`, {params})
}
export function getGaMstDriverApi(params: any) {
  return apiGet(`${base_hr}ga-mst-driver`, {params})
}
export function getDetailRequestHRMappCertificateApi(id: any) {
  return apiGet(`${base_hr}hr-mapp-certificate/${id}`)
}
export function getHrRequestReimbursementBenefitApi(params: any) {
  return apiGet(`${base_hr}hr-request-reimbursement-benefit`, {params})
}

export function getCaPolicyDetailApi(params: any) {
  return apiGet(`${base_finance}fnc-ca-policy-detail`, {
    params,
  })
}
export function getCaPolicyByEmpInfoIdApi(params: any) {
  return apiGet(`${base_finance}fnc-ca-policy/get-ca-policy-by-empinfoid/${params.id}`, {
    params: params.payload,
  })
}
export function getCaPolicyApi(params: any) {
  return apiGet(`${base_finance}fnc-ca-policy`, {params})
}
export function postCaPolicyApi(payload: any) {
  return apiPost(`${base_finance}fnc-ca-policy`, payload)
}
export function patchCaPolicyApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_finance}fnc-ca-policy/${data.id}`, data.payload)
}
export function deleteCaPolicyApi(id: any) {
  return apiDelete(`${base_finance}fnc-ca-policy/${id}`)
}

export function patchUploadSettlementApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}fnc-req-ca-settlement/attach/${data.id}`, data.payload)
}
export function getCaSettlementApi(params: any) {
  return apiGet(`${base_approval}fnc-req-ca-settlement`, {params})
}
export function postCaSettlementApi(payload: any) {
  return apiPost(`${base_approval}fnc-req-ca-settlement`, payload)
}
export function patchCaSettlementApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}fnc-req-ca-settlement/${data.id}`, data.payload)
}
export function deleteCaSettlementApi(id: any) {
  return apiDelete(`${base_approval}fnc-req-ca-settlement/${id}`)
}

export function getSysCaCategoryApi(params: any) {
  return apiGet(`${base_system}sys-ca-category`, {params})
}
export function postSysCaCategoryApi(payload: any) {
  return apiPost(`${base_system}sys-ca-category`, payload)
}
export function patchSysCaCategoryApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_system}sys-ca-category/${data.id}`, data.payload)
}
export function deleteSysCaCategoryApi(id: any) {
  return apiDelete(`${base_system}sys-ca-category/${id}`)
}
export const urlUploadFileS3 = `${base_system}s3/upload`

// export function uploadFileS3Api(props: ApiPostProps) {
//   let formData = new FormData();
//   const p = props.payload;
//   const file = {
//     uri: p.uri,
//     type: p.type,
//     name: p.name,
//   };
//   formData.append('file', file);
//   return apiPost(`${base_system}s3/upload`, formData, {
//     params: props.params,
//     headers: {
//       accept: 'application/json',
//       'Content-Type': 'multipart/form-data',
//     },
//     cancelToken: props.cancelToken,
//     transformRequest: (data, headers) => {
//       return formData;
//     },
//   });
// }
// export function deleteFileS3Api(props: ApiDeleteProps) {
//   return apiDelete(`${base_system}s3`, {
//     params: props.params,
//     data: {name: props.id},
//     cancelToken: props.cancelToken,
//   });
// }
export function fileS3Url(fileName: string) {
  return `${base_system}s3/download/${encodeURI(fileName)}`
}
export function apiHeaders() {
  return {
    Authorization: `Bearer ${getAuth()?.accessToken}` as any,
    ...(() => {
      const apiKey = {}
      if (NODE_ENV === 'production' && window.location.host === REACT_APP_PROD_SITE) {
        // @ts-ignore
        apiKey[REACT_APP_API_HEADER_KEY] = REACT_APP_API_KEY
      }
      return apiKey
    })(),
  }
}
export async function downloadFileS3Api(fileName: string) {
  return apiGet(`${base_system}s3/download/${encodeURI(fileName)}`, {
    responseType: 'blob',
  })
}

export function getEmployeeApi(params: any) {
  return apiGet(`${base_parameter}employee`, {params})
}
export function getCompanyApi(params: any) {
  return apiGet(`${base_parameter}company`, {params})
}
export function patchCompanyApi(params: {values: any; id: any}) {
  return apiPatch(`${base_parameter}company/${params.id}`, params.values)
}
export function getHrEmployeeApi(props: ApiGetProps) {
  return apiGet(`${base_hr}hr-emp-info`, apiConfig(props))
}
export function getHrEmployeeApiV1(params: any) {
  return apiGet(`${base_hr}hr-emp-info`, {params})
}

export function getHrEmployee2ApiV1(params: any) {
  return apiGet(`${base_hr}hr-emp-info/display-employee-list`, {params})
}

export function patchHrEmployeeApi(params: {values: any; id: any}) {
  return apiPatch(`${base_hr}hr-emp-info/${params.id}`, params.values)
}
export function postHrEmployeeApi(params: any) {
  return apiPost(`${base_hr}hr-emp-info`, params)
}
export function deleteHrEmployeeApi(params: any) {
  return apiDelete(`${base_hr}hr-emp-info/${params}`)
}
export function getHrEmployeeAccountApi(params: any) {
  return apiGet(`${base_hr}hr-emp-account`, {params})
}
export function postHrEmployeeAccountApi(params: any) {
  return apiPost(`${base_hr}hr-emp-account`, params)
}
export function patchHrEmployeeAccountApi(data: any) {
  return apiPatch(`${base_hr}hr-emp-account/${data.id}`, data.payload)
}
export function getHrEmployee1000Api(params: any = {}) {
  return getHrEmployeeApi({page: 1, limit: 1000, ...params})
}
export function postEmployeeApi(payload: any) {
  return apiPost(`${base_parameter}employee`, payload)
}
export function patchEmployeeApi(data: any) {
  return apiPatch(`${base_parameter}employee/${data.id}`, data.payload)
}
export function deleteEmployeeApi(id: any) {
  return apiDelete(`${base_parameter}employee/${id}`)
}

export function postFncReqCaApi(payload: any) {
  return apiPost(`${base_approval}fnc-req-ca`, payload)
}
export function getFncReqCaApi(params: any) {
  return apiGet(`${base_approval}fnc-req-ca`, {params})
}
export function getReportFnRequestCaApi(id: any) {
  return apiGet(`${base_approval}fnc-req-ca/generate-report/${id}`, {responseType: 'blob'})
}
export function patchFncReqCaApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}fnc-req-ca/${data.id}`, data.payload)
}
export function deleteFncReqCaApi(id: any) {
  return apiDelete(`${base_approval}fnc-req-ca/${id}`)
}
export function getHrReportPcs(params: any) {
  return apiGet(`${base_approval}hr-t-proses/pcs-completed`, {params})
}

export function getFncDashboardArApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-receivable/fnc-dash-ar`, {params})
}
export function getFncDashboardApApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/fnc-dash-ap`, {params})
}
export function getSalesProfitGrossApi(params: any) {
  return apiGet(`${base_sales}sales/profit/gross`, {params})
}
export function getDashboardProfitNettApi(params: any) {
  return apiGet(`${base_sales}profit-nett/fnc-dash-profit-nett`, {params})
}
export function getFncDashboardBankDailyApi(params: any) {
  return apiGet(`${base_finance}fnc-bank-daily/fnc-dash-gl-daily`, {params})
}
export function getCOGSFncDashboardApi(params: any) {
  return apiGet(`${base_finance}fnc-cogs-dashboard/fnc-dash-cogs`, {params})
}
export function getExpenseFncDashboardApi(params: any) {
  return apiGet(`${base_finance}fnc-expense-dashboard/fnc-dash-expense`, {params})
}
export function getFncDashboardVendorApi(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/fnc-dash-vendor`, {params})
}

export function getFncCaAssignApi(params: any) {
  return apiGet(`${base_finance}fnc-ca-assign`, {params})
}
export function postFncCaAssignApi(payload: any) {
  return apiPost(`${base_finance}fnc-ca-assign`, payload)
}
export function patchFncCaAssignApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_finance}fnc-ca-assign/${data.id}`, data.payload)
}
export function deleteFncCaAssignApi(id: any) {
  return apiDelete(`${base_finance}fnc-ca-assign/${id}`)
}

export function postFncAprRoleApprovalApproveFncAccApi(payload: any) {
  return apiPost(`${base_finance}fnc-apr-role-approval/approveFncAcc`, payload)
}
export function getProjectScheduleApi(params: any) {
  return apiGet(`${base_parameter}project/select-project-schedule`, {params})
}
export function getProjectApi(params: any) {
  return apiGet(`${base_parameter}project`, {params})
}
export function postProjectApi(payload: any) {
  return apiPost(`${base_parameter}project`, payload)
}
export function patchProjectApi(data: any) {
  return apiPatch(`${base_parameter}project/${data.id}`, data.payload)
}
export function deleteProjectApi(id: any) {
  return apiDelete(`${base_parameter}project/${id}`)
}

export function getHrDocCategoryApi(params: any) {
  return apiGet(`${base_hr}hr-doc-category`, {params})
}
export function postHrDocCategoryApi(payload: any) {
  return apiPost(`${base_hr}hr-doc-category`, payload)
}
export function patchHrDocCategoryApi(data: any) {
  return apiPatch(`${base_hr}hr-doc-category/${data.id}`, data.payload)
}
export function deleteHrDocCategoryApi(id: any) {
  return apiDelete(`${base_hr}hr-doc-category/${id}`)
}

export function getHrJobPositionApi(params: any) {
  return apiGet(`${base_hr}hr-job-position`, {params})
}
export function postHrJobPositionApi(payload: any) {
  return apiPost(`${base_hr}hr-job-position`, payload)
}
export function patchHrJobPositionApi(data: any) {
  return apiPatch(`${base_hr}hr-job-position/${data.id}`, data.payload)
}
export function deleteHrJobPositionApi(id: any) {
  return apiDelete(`${base_hr}hr-job-position/${id}`)
}

export function getHrJobLevelApi(params: any) {
  return apiGet(`${base_hr}hr-job-level`, {params})
}

export function getHrJobLevelByIdApi(id: any) {
  return apiGet(`${base_hr}hr-job-level/${id}`)
}

export function postHrJobLevelApi(payload: any) {
  return apiPost(`${base_hr}hr-job-level`, payload)
}
export function patchHrJobLevelApi(data: any) {
  return apiPatch(`${base_hr}hr-job-level/${data.id}`, data.payload)
}
export function deleteHrJobLevelApi(id: any) {
  return apiDelete(`${base_hr}hr-job-level/${id}`)
}

export function getHrOrganizationApi(params: any) {
  return apiGet(`${base_hr}hr-organization`, {params})
}
export function postHrOrganizationApi(payload: any) {
  return apiPost(`${base_hr}hr-organization`, payload)
}
export function patchHrOrganizationApi(data: any) {
  return apiPatch(`${base_hr}hr-organization/${data.id}`, data.payload)
}
export function deleteHrOrganizationApi(id: any) {
  return apiDelete(`${base_hr}hr-organization/${id}`)
}

export function getHrClassApi(params: any) {
  return apiGet(`${base_hr}hr-class`, {params})
}
export function postHrClassApi(payload: any) {
  return apiPost(`${base_hr}hr-class`, payload)
}
export function patchHrClassApi(data: any) {
  return apiPatch(`${base_hr}hr-class/${data.id}`, data.payload)
}
export function deleteHrClassApi(id: any) {
  return apiDelete(`${base_hr}hr-class/${id}`)
}

export function getHrEmpCompanyApi(props: ApiGetProps) {
  return apiGet(`${base_hr}hr-emp-company`, apiConfig(props))
}
export function getHrEmpCompanyApiV2(params: any) {
  return apiGet(`${base_hr}hr-emp-company`, {params})
}
export function postHrEmpCompanyApi(payload: any) {
  return apiPost(`${base_hr}hr-emp-company`, payload)
}
export function patchHrEmpCompanyApi(data: any) {
  return apiPatch(`${base_hr}hr-emp-company/${data.id}`, data.payload)
}
export function deleteHrEmpCompanyApi(id: any) {
  return apiDelete(`${base_hr}hr-emp-company/${id}`)
}

export function getHrGradeApi(params: any) {
  return apiGet(`${base_hr}hr-grade`, {params})
}
export function postHrGradeApi(payload: any) {
  return apiPost(`${base_hr}hr-grade`, payload)
}
export function patchHrGradeApi(data: any) {
  return apiPatch(`${base_hr}hr-grade/${data.id}`, data.payload)
}
export function deleteHrGradeApi(id: any) {
  return apiDelete(`${base_hr}hr-grade/${id}`)
}

export function getHrDocApi(params: any) {
  return apiGet(`${base_hr}/hr-doc`, {params})
}
export function getHrRequestMeetingRoomApi(params: any) {
  return apiGet(`${base_approval}hr-request-meeting-room`, {params})
}
export function getDetailHrRequestMeetingRoomApi(id: any) {
  return apiGet(`${base_approval}hr-request-meeting-room/detail-meeting-room/${id}`)
}
export function postHrRequestMeetingRoomApi(payload: any) {
  return apiPost(`${base_approval}hr-request-meeting-room`, payload)
}
export function patchHrRequestMeetingRoomApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_approval}hr-request-meeting-room/${data.id}`, data.payload)
}
export function deleteHrRequestMeetingRoomApi(params: any) {
  return apiDelete(`${base_approval}hr-request-meeting-room/${params}`)
}
export function postHrDocApi(payload: any) {
  return apiPost(`${base_hr}/hr-doc`, payload)
}
export function patchHrDocApi(data: any) {
  return apiPatch(`${base_hr}hr-doc/${data.id}`, data.payload)
}
export function deleteHrDocApi(id: any) {
  return apiDelete(`${base_hr}hr-doc/${id}`)
}
export function postGaPolicyGrApi(payload: any) {
  return apiPost(`${base_hr}ga-policy-gr`, payload)
}
export function getGaPolicyGrApi(params: any) {
  return apiGet(`${base_hr}ga-policy-gr`, {params})
}
export function patchGaPolicyGrApi(data: any) {
  return apiPatch(`${base_hr}ga-policy-gr/${data.id}`, data.payload)
}
export function deleteGaPolicyGrApi(id: any) {
  return apiDelete(`${base_hr}ga-policy-gr/${id}`)
}
export function postGaCategoryGrApi(payload: any) {
  return apiPost(`${base_hr}ga-category-gr`, payload)
}
export function getGaCategoryGrApi(params: any) {
  return apiGet(`${base_hr}ga-category-gr`, {params})
}
export function patchGaCategoryGrApi(data: any) {
  return apiPatch(`${base_hr}ga-category-gr/${data.id}`, data.payload)
}
export function deleteGaCategoryGrApi(id: any) {
  return apiDelete(`${base_hr}ga-category-gr/${id}`)
}
export function postGaAssignGrApi(payload: any) {
  return apiPost(`${base_hr}ga-assign-gr`, payload)
}
export function getGaAssignGrApi(params: any) {
  return apiGet(`${base_hr}ga-assign-gr`, {params})
}
export function patchGaAssignGrApi(data: any) {
  return apiPatch(`${base_hr}ga-assign-gr/${data.id}`, data.payload)
}
export function deleteGaAssignGrApi(id: any) {
  return apiDelete(`${base_hr}ga-assign-gr/${id}`)
}

export function getUserApi(params: any) {
  return apiGet(`${base_system}user`, {params})
}
export function postUserApi(payload: any) {
  return apiPost(`${base_system}user`, payload)
}
export function patchUserApi(data: any) {
  return apiPatch(`${base_system}user/${data.id}`, data.payload)
}
export function deleteUserApi(id: any) {
  return apiDelete(`${base_system}user/${id}`)
}
export function postRegisterApi(params: any) {
  return apiPost(`${base_system}user/register/`, params)
}

export function getFncReimbursePolicyApi(params: any) {
  return apiGet(`${base_finance}fnc-reimburse-policy`, {params})
}
export function getFncReimbursePolicyReqApi(params: any) {
  return apiGet(`${base_finance}fnc-reimburse-policy/request-reimburse`, {params})
}
export function postFncReimbursePolicyApi(payload: any) {
  return apiPost(`${base_finance}fnc-reimburse-policy`, payload)
}
export function patchFncReimbursePolicyApi(data: any) {
  return apiPatch(`${base_finance}fnc-reimburse-policy/${data.id}`, data.payload)
}
export function deleteFncReimbursePolicyApi(id: any) {
  return apiDelete(`${base_finance}fnc-reimburse-policy/${id}`)
}

export function getFncReimburseAssignEmpApi(params: any) {
  return apiGet(`${base_finance}fnc-reimburse-assign-emp`, {params})
}
export function getFncReimburseAssignApi(params: any) {
  return apiGet(`${base_finance}fnc-reimburse-assign`, {params})
}
export function postFncReimburseAssignImportApi(payload: any) {
  return apiPost(
    `${base_finance}fnc-reimburse-assign-emp/import/029d6f88-89df-4171-b65d-e3fb002ab130`,
    payload
  )
}
export function testUrl(param: any) {
  return `${base_finance}fnc-reimburse-assign-emp/import/${param}`
}
export function postFncReimburseAssignApi(payload: any) {
  return apiPost(`${base_finance}fnc-reimburse-assign`, payload)
}
export function patchFncReimburseAssignApi(data: any) {
  return apiPatch(`${base_finance}fnc-reimburse-assign/${data.id}`, data.payload)
}
export function deleteFncReimburseAssignApi(id: any) {
  return apiDelete(`${base_finance}fnc-reimburse-assign/${id}`)
}
export function getFncReimburseBenefitApi(params: any) {
  return apiGet(`${base_finance}fnc-reimburse-benefit`, {params})
}
export function getCustomQueryApi(params: any) {
  return apiGet(`${base_sales}sales-opty/custom-query`, {params})
}
export function postCustomQueryApi(payload: any) {
  return apiPost(`${base_sales}sales-opty/custom-query`, payload)
}
export function postFncReimburseBenefitApi(payload: any) {
  return apiPost(`${base_finance}fnc-reimburse-benefit`, payload)
}
export function patchFncReimburseBenefitApi(data: any) {
  return apiPatch(`${base_finance}fnc-reimburse-benefit/${data.id}`, data.payload)
}
export function deleteFncReimburseBenefitApi(id: any) {
  return apiDelete(`${base_finance}fnc-reimburse-benefit/${id}`)
}
export function getRequestReimburseBenefitApi(params = {}) {
  return apiGet(`${base_finance}fnc-reimburse-benefit/request`, {params})
}
export function getRatePlanApi(params: any) {
  return apiGet(
    `https://api.currencyapi.com/v3/latest?apikey=KyHWKowBawH4W4Nxlq300fJ6GHI8YLucciyc0LjJ`,
    {params},
    false
  )
}
export function rawGetApi(props: {url: string; params?: string}) {
  const {url, params = {}} = props
  return apiGet(`${base_url}${url}`, {params})
}
//Approval
export function getGeneralRequestApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr`, {params})
}
export function getGeneralRequestUpdateApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr-detail/all-paid-request`, {params})
}
export function getGrDetailApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr-detail`, {params})
}
export function patchGrDetailStatusProccessApi(params: any) {
  return apiPatch(`${base_approval}ga-req-gr-detail/status-proccess/${params.id}`, params.payload)
}
export function getGeneralRequestDetailApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr/detail/${params}`)
}
export function postGeneralRequestApi(params: any) {
  return apiPost(`${base_approval}ga-req-gr`, params)
}
export function patchGeneralRequestApi(params: any) {
  return apiPatch(`${base_approval}ga-req-gr/${params.id}`, params.payload)
}
export function patchGeneralRequestApprovalApi(params: any) {
  return apiPatch(
    `${base_approval}hr-t-proses/approve-general-request/${params.id}`,
    params.payload
  )
}
export function deleteGeneralRequestApi(id: any) {
  return apiDelete(`${base_approval}ga-req-gr/${id}`)
}
export function getGeneralRequesReporttApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr/report/${params}`, {responseType: 'blob'})
}
export function getHrtGeneralRequestApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/general-request/`, {params})
}
//HR GA GR
export function getPolicyGrApi(params: any) {
  return apiGet(`${base_hr}ga-policy-gr`, {params})
}
export function getPolicyGrByEmployeeApi(params: any) {
  return apiGet(`${base_hr}ga-policy-gr/get-by-employee`, {params})
}

export function patchCashOnHandTopUpApi(params: any) {
  return apiPatch(`${base_finance}fnc-cashonhand/${params.id}`, params.payload)
}
export function getCashOnHandTopUpApi(params: any) {
  return apiGet(`${base_finance}fnc-cashonhand`, {params})
}
export function postCashOnHandTopUpApi(params: any) {
  return apiPost(`${base_finance}fnc-cashonhand`, params)
}
export function deleteCashOnHandTopUpApi(id: any) {
  return apiDelete(`${base_finance}fnc-cashonhand/${id}`)
}
export function getCashOnHandTransactionApi(params: any) {
  return apiGet(`${base_approval}fnc-cashonhand-detail`, {params})
}
export function getFncCashOnHandClosingApi(params: any) {
  return apiPost(`${base_finance}fnc-cashonhand/closing/${params.id}`, params.payload)
}
export function postCashOnHandTransactionApi(params: any) {
  return apiPost(`${base_approval}fnc-cashonhand-detail`, params)
}
export function getLastSaldoApi(params: any) {
  return apiGet(`${base_finance}fnc-cashonhand/last-saldo/${params}`)
}
export function getReportCashOnHandApi(params: any) {
  return apiPost(`${base_finance}fnc-cashonhand/report`, params, {responseType: 'blob'})
}
export function getReportEmployeExpenseApi(params: any) {
  return apiGet(`${base_finance}reports/employee-cost`, {params})
}
export function getListDataNaturaApi(params: any) {
  return apiGet(`${base_finance}fnc-req-ca-settlement/natura`, {params})
}

export function getReportPettyCashApi(params: any) {
  return apiGet(`${base_finance}fnc-bank-daily/petty-cash`, {params})
}

export function getListSummaryDataNaturaApi(params: any) {
  return apiGet(`${base_finance}fnc-req-ca-settlement/natura-excel`, {params})
}
export function getExpenseApi(params: any) {
  return apiGet(`${base_finance}fnc-expense-dashboard`, {params})
}
export function postExpenseApi(payload: any) {
  return apiPost(`${base_finance}fnc-expense-dashboard`, payload)
}
export function patchExpenseApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_finance}fnc-expense-dashboard/${data.id}`, data.payload)
}
export function deleteExpenseApi(id: any) {
  return apiDelete(`${base_finance}fnc-expense-dashboard/${id}`)
}
export function getCOGSApi(params: any) {
  return apiGet(`${base_finance}fnc-cogs-dashboard`, {params})
}
export function postCOGSApi(payload: any) {
  return apiPost(`${base_finance}fnc-cogs-dashboard`, payload)
}
export function patchCOGSApi(data: {id: any; payload: any}) {
  return apiPatch(`${base_finance}fnc-cogs-dashboard/${data.id}`, data.payload)
}
export function deleteCOGSApi(id: any) {
  return apiDelete(`${base_finance}fnc-cogs-dashboard/${id}`)
}
export function patchCashOnHandTransactionApi(params: any) {
  return apiPatch(`${base_approval}fnc-cashonhand-detail/${params.id}`, params.payload)
}
export function deleteCashOnHandTransactionApi(id: any) {
  return apiDelete(`${base_approval}fnc-cashonhand-detail/${id}`)
}
export function patchApprovalCashOnHandTransactionApi(params: any) {
  return apiPatch(
    `${base_approval}hr-t-proses/approve-cashonhand-transaction/${params.id}`,
    params.payload
  )
}
export function getHRTProcessCashOnHandTransactionApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/cashonhand-transaction`, {params})
}

export function getPolicyGrDetailApi(params: any) {
  return apiGet(`${base_hr}ga-policy-gr-detail`, {params})
}

export function getPaymentGeneralRequestApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr-detail/payment-non-transfer`, {params})
}

export function getDetailReportGrApi(params: any) {
  return apiGet(`${base_approval}ga-req-gr-detail/all-req-exc-reject`, {params})
}
export function getGaReqOpsCarsApi(params: any) {
  return apiGet(`${base_approval}ga-req-ops-cars`, {params})
}
export function getGaReqOpsCarsApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/operational-cars`, {params})
}
export function postGaReqOpsCarsApi(params: any) {
  return apiPost(`${base_approval}ga-req-ops-cars`, params)
}
export function patchGaReqOpsCarsApi(params: any) {
  return apiPatch(`${base_approval}ga-req-ops-cars/${params.id}`, params.payload)
}
export function patchGaReqOpsCarsApproveApi(params: any) {
  return apiPatch(
    `${base_approval}hr-t-proses/approve-operational-cars/${params.id}`,
    params.payload
  )
}
export function getProsesJobGuaranteesApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/job-guarantees`, {params})
}
export function patchProsesJobGuaranteesApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/job-guarantees/${params.id}`, params.payload)
}
export function getReportSalesJobGuaranteeApi(id: any) {
  return apiGet(`${base_approval}sales-job-guarantee/report/${id}`, {responseType: 'blob'})
}
export function deleteGaReqOpsCarsApi(id: any) {
  return apiDelete(`${base_approval}ga-req-ops-cars/${id}`)
}
export function getUploadCertificationApi(params: any) {
  return apiGet(`${base_approval}hr-certification`, {params})
}
export function postUploadCertificationApi(params: any) {
  return apiPost(`${base_approval}hr-certification`, params)
}
export function patchUploadCertificationApi(params: any) {
  return apiPatch(`${base_approval}hr-certification/${params.id}`, params.payload)
}
export function deleteUploadCertificationApi(id: any) {
  return apiDelete(`${base_approval}hr-certification/${id}`)
}

export function patchPaymentGeneralRequestApi(params: any) {
  return apiPatch(
    `${base_approval}ga-req-gr-detail/payment-non-transfer/${params.id}`,
    params.payload
  )
}
export function patchStatusPaymentGeneralRequestApi(params: any) {
  return apiPatch(`${base_approval}ga-req-gr-detail/status-process/${params}`)
}

export function getDashboardReportTrainingApi(params: any) {
  return apiGet(`${base_approval}hr-request-training/dashboard/${params}`)
}
export function getSalesPcsHeaderApi(params: any) {
  const id = params.id || ''
  delete params.id
  return apiGet(`${base_approval}sales-pcs-header/${id}`, {params})
}
export function getNewSalesPcsHeaderApi(params: any) {
  return apiGet(`${base_sales}sales-opty/pcs-header`, {params})
}
export function getSalesPcsHeaderDetailApi(params: any) {
  return apiGet(`${base_approval}sales-pcs-header/detail/${params}`)
}
export function postSalesPcsHeaderApi(params: any) {
  return apiPost(`${base_approval}sales-pcs-header`, params)
}
export function deleteSalesPcsHeaderApi(id: any) {
  return apiDelete(`${base_approval}sales-pcs-header/${id}`)
}
export function patchSalesPcsHeaderApi(params: any) {
  return apiPatch(`${base_approval}sales-pcs-header/${params.id}`, params.payload)
}
export function getReportSalesPcsHeaderApi(id: any) {
  return apiGet(`${base_approval}sales-pcs-header/report/${id}`, {responseType: 'blob'})
}
export function postSalesJobGuaranteeApi(params: any) {
  return apiPost(`${base_approval}sales-job-guarantee`, params)
}
export function getSalesJobGuaranteeApi(params: any) {
  return apiGet(`${base_approval}sales-job-guarantee`, {params})
}
export function deleteSalesJobGuaranteeApi(id: any) {
  return apiDelete(`${base_approval}sales-job-guarantee/${id}`)
}
export function patchSalesJobGuaranteeApi(params: any) {
  return apiPatch(`${base_approval}sales-job-guarantee/${params.id}`, params.payload)
}
export function getSalesSoApi(params: any) {
  return apiGet(`${base_approval}sales-so`, {params})
}
export function postSalesSoApi(params: any) {
  return apiPost(`${base_approval}sales-so`, params)
}
export function patchSalesSoApi(params: any) {
  return apiPatch(`${base_approval}sales-so/${params.id}`, params.payload)
}
export function deleteSalesSoApi(id: any) {
  return apiDelete(`${base_approval}sales-so/${id}`)
}
export function getReportSalesSoApi(id: any) {
  return apiGet(`${base_approval}sales-so/report/${id}`, {responseType: 'blob'})
}
export function patchSalesBoardApi(params: any) {
  return apiPatch(`${base_sales}sales-opty/board/${params.id}`, params.payload)
}

/* BUSINESS TRIP API */
export function getBtPolicyApi(param: any) {
  return apiGet(`${base_hr}trip-policy`, param)
}
export function postBtPolicyApi(payload: any) {
  return apiPost(`${base_hr}trip-policy`, payload)
}
export function patchBtPolicyApi(data: {id: string; payload: any}) {
  return apiPatch(`${base_hr}trip-policy/${data.id}`, data.payload)
}
export function deleteBtPolicyApi(id: string) {
  return apiDelete(`${base_hr}trip-policy/${id}`)
}

export function getBtEmployeeApi(param: any) {
  return apiGet(`${base_hr}trip-policy-assign`, param)
}
export function postBtEmployeeApi(payload: any) {
  return apiPost(`${base_hr}trip-policy-assign`, payload)
}
export function patchBtEmployeeApi(data: {id: string; payload: any}) {
  return apiPatch(`${base_hr}trip-policy-assign/${data.id}`, data.payload)
}
export function deleteBtEmployeeApi(id: string) {
  return apiDelete(`${base_hr}trip-policy-assign/${id}`)
}

export function getCategoryApi(params: any) {
  return apiGet(`${base_hr}trip-category`, {params})
}

export function getCategoryPurchaseRequestApi(params: any) {
  return apiGet(`${base_hr}ga-category-pr`, {params})
}

export function getCategoryPurchaseRequestByIdApi(id: any) {
  return apiGet(`${base_hr}ga-category-pr/${id}`)
}

export function postCategoryPurchaseRequestApi(payload: any) {
  return apiPost(`${base_hr}ga-category-pr`, payload)
}

export function patchCategoryPurchaseRequestApi(payload: any) {
  return apiPatch(`${base_hr}ga-category-pr/${payload.id}`, payload)
}

export function deleteCategoryPurchaseRequestApi(id: any) {
  return apiDelete(`${base_hr}ga-category-pr/${id}`)
}

export function postCategoryBusinessTripApi(params: any) {
  return apiPost(`${base_hr}trip-category`, params)
}

export function patchCategoryBusinessTripApi(params: any) {
  return apiPatch(`${base_hr}trip-category/${params.id}`, params)
}

export function deleteCategoryBusinessTripApi(id: any) {
  return apiDelete(`${base_hr}trip-category/${id}`)
}

export function getPolicyPurchaseRequestApi(params: any) {
  return apiGet(`${base_hr}ga-policy-pr`, {params})
}

export function getPolicyPurchaseRequestCategoryApi(params: any) {
  return apiGet(`${base_hr}ga-policy-pr/category`, {params})
}

export function getPolicyPurchaseRequestByIdApi(id: any) {
  return apiGet(`${base_hr}ga-policy-pr/${id}`)
}

export function postPolicyPurchaseRequestApi(payload: any) {
  return apiPost(`${base_hr}ga-policy-pr`, payload)
}

export function patchPolicyPurchaseRequestApi(params: any) {
  return apiPatch(`${base_hr}ga-policy-pr/${params.id}`, params?.payload)
}

export function deletePolicyPurchaseRequestApi(id: any) {
  return apiDelete(`${base_hr}ga-policy-pr/${id}`)
}

export function getEnrollmentPrApi(params: any) {
  return apiGet(`${base_hr}ga-policy-enrollment-pr`, {params})
}

export function getEnrollmentPrPolicyApi(params: any) {
  return apiGet(`${base_hr}ga-policy-enrollment-pr/policy-pr`, {params})
}

export function postEnrollmentPrApi(payload: any) {
  return apiPost(`${base_hr}ga-policy-enrollment-pr`, payload)
}

export function patchEnrollmentPrApi(params: any) {
  return apiPatch(`${base_hr}ga-policy-enrollment-pr/${params.id}`, params?.payload)
}

export function deleteEnrollmentPrApi(id: any) {
  return apiDelete(`${base_hr}ga-policy-enrollment-pr/${id}`)
}

export function getCategoryPurchaseOrderApi(params: any) {
  return apiGet(`${base_hr}ga-category-po`, {params})
}

export function postCategoryPurchaseOrderApi(payload: any) {
  return apiPost(`${base_hr}ga-category-po`, payload)
}

export function patchCategoryPurchaseOrderApi(payload: any) {
  return apiPatch(`${base_hr}ga-category-po/${payload.id}`, payload)
}

export function deleteCategoryPurchaseOrderApi(id: any) {
  return apiDelete(`${base_hr}ga-category-po/${id}`)
}

export function getPolicyPurchaseOrderApi(params: any) {
  return apiGet(`${base_hr}ga-policy-po`, {params})
}

export function getPolicyPurchaseOrderCategoryApi(params: any) {
  return apiGet(`${base_hr}ga-policy-po/category`, {params})
}

export function postPolicyPurchaseOrderApi(payload: any) {
  return apiPost(`${base_hr}ga-policy-po`, payload)
}

export function patchPolicyPurchaseOrderApi(params: any) {
  return apiPatch(`${base_hr}ga-policy-po/${params.id}`, params?.payload)
}

export function deletePolicyPurchaseOrderApi(id: any) {
  return apiDelete(`${base_hr}ga-policy-po/${id}`)
}

export function getEnrollmentPoApi(params: any) {
  return apiGet(`${base_hr}ga-policy-enrollment-po`, {params})
}

export function getEnrollmentPoPolicyApi(params: any) {
  return apiGet(`${base_hr}ga-policy-enrollment-po/policy-po`, {params})
}

export function postEnrollmentPoApi(payload: any) {
  return apiPost(`${base_hr}ga-policy-enrollment-po`, payload)
}

export function patchEnrollmentPoApi(params: any) {
  return apiPatch(`${base_hr}ga-policy-enrollment-po/${params.id}`, params?.payload)
}

export function deleteEnrollmentPoApi(id: any) {
  return apiDelete(`${base_hr}ga-policy-enrollment-po/${id}`)
}

export function getGaPurchaseRequestReportApi(params: any) {
  return apiGet(`${base_approval}ga-req-pr/list-report-pr`, {params})
}
export function getGaPurchaseRequestApi(params: any) {
  return apiGet(`${base_approval}ga-req-pr`, {params})
}

export function getGaPurchaseRequestDetailApi(params: any) {
  return apiGet(`${base_approval}ga-req-pr/detail/${params}`)
}

export function getReportPurchaseRequestApi(id: any) {
  return apiGet(`${base_approval}ga-req-pr/report/${id}`, {responseType: 'blob'})
}

export function postGaPurchaseRequestApi(params: any) {
  return apiPost(`${base_approval}ga-req-pr`, params?.payload)
}

export function patchGaPurchaseRequestApi(params: any) {
  return apiPatch(`${base_approval}ga-req-pr/${params.id}`, params?.payload)
}

export function deleteGaPurchaseRequestApi(id: any) {
  return apiDelete(`${base_approval}ga-req-pr/${id}`)
}

export function patchGaPurchaseRequestProcessRequestApi(params: any) {
  return apiPatch(`${base_approval}ga-req-pr/process-request/${params.id}`, params?.payload)
}

export function getGaPurchaseRequestApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/req-pr`, {params})
}

export function patchGaPurchaseRequestApproveApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/approve-request-pr/${params.id}`, params?.payload)
}

export function getContactVendorApi(params: any) {
  return apiGet(`${base_hr}contact-vendor`, {params})
}

export function postContactVendorApi(params: any) {
  return apiPost(`${base_hr}contact-vendor`, params)
}

export function getDeliveryDestinationApi(params: any) {
  return apiGet(`${base_hr}ga-delivery-destination`, {params})
}

export function postDeliveryDestinationApi(params: any) {
  return apiPost(`${base_hr}ga-delivery-destination`, params)
}

export function getGaPurchaseOrderApi(params: any) {
  return apiGet(`${base_approval}ga-req-po`, {params})
}

export function getGaPurchaseOrderReportApi(params: any) {
  return apiGet(`${base_approval}ga-req-po/list-report-po`, {params})
}

export function getGaPurchaseOrderRecapApi(params: any) {
  return apiGet(`${base_approval}ga-req-po/rekap`, {params})
}

export function getGaPurchaseOrderDetailApi(params: any) {
  return apiGet(`${base_approval}ga-req-po/detail/${params}`)
}

export function getReportPurchaseOrderApi(id: any) {
  return apiGet(`${base_approval}ga-req-po/report/${id}`, {responseType: 'blob'})
}

export function postGaPurchaseOrderApi(params: any) {
  return apiPost(`${base_approval}ga-req-po`, params?.payload)
}

export function patchGaPurchaseOrderApi(params: any) {
  return apiPatch(`${base_approval}ga-req-po/${params.id}`, params?.payload)
}

export function deleteGaPurchaseOrderApi(id: any) {
  return apiDelete(`${base_approval}ga-req-po/${id}`)
}

export function patchGaPurchaseOrderProcessRequestApi(params: any) {
  return apiPatch(`${base_approval}ga-req-po/process-request/${params.id}`, params?.payload)
}

export function getGaPurchaseOrderApprovalApi(params: any) {
  return apiGet(`${base_approval}hr-t-proses/req-po`, {params})
}

export function patchGaPurchaseOrderApproveApi(params: any) {
  return apiPatch(`${base_approval}hr-t-proses/approve-request-po/${params.id}`, params?.payload)
}

export function postGaPurchaseOrderPayment(params: any) {
  return apiPost(`${base_approval}ga-req-po/payment`, params)
}

export function getGaPurchaseOrderPayment(params: any) {
  return apiGet(`${base_approval}ga-req-po/payment`, {params})
}

export function getAssetLM(params: any) {
  return apiGet(`${base_finance}fnc-lm`, {params})
}

export function postAssetLM(params: any) {
  return apiPost(`${base_finance}fnc-lm`, params)
}

export function patchAssetLM(params: any) {
  return apiPatch(`${base_finance}fnc-lm/${params.id}`, params)
}

export function deleteAssetLM(id: any) {
  return apiDelete(`${base_finance}fnc-lm/${id}`)
}

export function getAssetLMSummary(params: any) {
  return apiGet(`${base_finance}fnc-lm/summary`, {params})
}

export function getReceivableBukuBesarReport(params: any) {
  return apiGet(`${base_finance}fnc-acc-receivable/bukubesar`, {
    params,
    responseType: 'blob',
  })
}

export function getFncAccPayableUnpaid(params: any) {
  return apiGet(`${base_finance}fnc-acc-payable/list-ap-unpaid`, {params})
}
