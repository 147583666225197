import React, {useRef} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface MetroAccordionItemProps {
  children?: React.ReactNode
  className?: string
  index: string
  title: React.ReactNode
  isOpen?: boolean
}
interface MetroAccordionProps {
  theme?: 'theme2'
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}
const Item = (props: MetroAccordionItemProps) => {
  return <>{props.children}</>
}
const MetroAccordion = (props: MetroAccordionProps) => {
  const identifier = useRef(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5)
  )
  const {className = '', children = {}, theme} = props

  const c: any[] | undefined = Array.isArray(children) ? children : [children]
  const item = c.map((el, index) => {
    if (theme === 'theme2') {
      return (
        <div key={index.toString()} className={`mb-5 ${el.props.className || ''}`}>
          <div
            className={`accordion-header py-3 d-flex ${el.props.isOpen ? 'collapse' : 'collapsed'}`}
            data-bs-toggle='collapse'
            data-bs-target={`#collapse_${identifier.current}_${el.props.index}`}
          >
            <span className='accordion-icon'>
              <KTSVG
                className='svg-icon svg-icon-4'
                path='/media/icons/duotune/arrows/arr064.svg'
              />
            </span>
            <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>{el.props.title}</h3>
          </div>
          <div
            id={`collapse_${identifier.current}_${el.props.index}`}
            className={`fs-6 collapse ${el.props.isOpen ? 'show' : ''} ps-10`}
            data-bs-parent={`#accordion_${identifier.current}_${el.props.index}`}
          >
            {el.props.children}
          </div>
        </div>
      )
    }
    return (
      <div key={index.toString()} className={`accordion-item ${el.props.className || ''}`}>
        <h2 className='accordion-header' id={`heading_${identifier.current}_${el.props.index}`}>
          <button
            className={`accordion-button ${!el.props.isOpen ? 'collapsed' : ''}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#collapse_${identifier.current}_${el.props.index}`}
            aria-expanded='false'
            aria-controls={`collapse_${identifier.current}_${el.props.index}`}
          >
            {el.props.title}
          </button>
        </h2>
        <div
          id={`collapse_${identifier.current}_${el.props.index}`}
          className={`accordion-collapse collapse ${el.props.isOpen ? 'show' : ''}`}
          aria-labelledby={`heading_${identifier.current}_${el.props.index}`}
          data-bs-parent={`#accordion_${identifier.current}_${el.props.index}`}
        >
          <div className='accordion-body'>{el.props.children}</div>
        </div>
      </div>
    )
  })
  return (
    <div className={`accordion ${theme === 'theme2' ? 'accordion-icon-toggle' : ''} ${className}`}>
      {item}
    </div>
  )
}
MetroAccordion.Item = Item
export {MetroAccordion}
