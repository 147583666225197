/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import { useIntl } from 'react-intl'
import {getUserAndMenu} from '../../../../source/utils'
import { loginApi, postNotificationTokenApi } from "../../../../source/apis";
import { useAuth } from "../core/Auth";
import { useFetch } from '../../../../source/hooks'

const loginSchema = Yup.object().shape({
  email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
  password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const {fcmToken, saveAuth, setCurrentUser, setMenuRecursive, setMenuFlat} = useAuth()
  const userAgent = navigator.userAgent;

  const postNotificationToken = useFetch({apiFunction: postNotificationTokenApi, showToastWhenSuccess: true})

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
        loginApi(values.email, values.password)
            .then( async ({data: {data}}) => {
                console.log('token', data.token)
                const {accesstoken, refreshtoken,tokenv3 } = data?.token || {};
                // removeLocalStorageWhenLogin()
                const {dataMenu, dataEmployee, dataMenuRecursive, decodeAccessToken} = await getUserAndMenu(accesstoken)
                setMenuFlat(dataMenu)
                setMenuRecursive(dataMenuRecursive)
                const {
                    role_id,
                    role_name,
                    hr_emp_info_id,
                    approval_name,
                } = (decodeAccessToken?.employees || [])[0] || {}
                setCurrentUser({
                    ...dataEmployee,
                    role_id,
                    role_name,
                    user_id: decodeAccessToken.id,
                    hr_emp_info_id,
                    approval_name,
                })
                saveAuth({
                    api_token: '',
                    tokenv3:tokenv3,
                    refreshToken: refreshtoken,
                    accessToken: accesstoken,
                });

                Notification.requestPermission().then(function(permission) {
                  if (permission === 'granted') {
                    postNotificationToken.fetch({fcm_token: fcmToken, user_agent: userAgent})
                  } 
                });
            })
            .catch((e) => {
                console.log('e', e.message);
                setLoading(false)
                setSubmitting(false)
                setStatus(intl.formatMessage({ id: 'ERROR.LOGIN' }))
            })
    },
  })

  return (
      <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='mb-10'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}</h1>
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE_EXTEND' })}</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({ id: 'AUTH.LOGIN.SUB_TITLE' })}
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
        ) : (
            <></>
        )}

        {/* begin::FormAddEdit group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'LABEL.EMAIL_ADDRESS' })}</label>
          <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
              )}
              type='email'
              name='email'
              autoComplete='on'
          />
          {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
          )}
        </div>
        {/* end::FormAddEdit group */}

        {/* begin::FormAddEdit group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'LABEL.PASSWORD' })}</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/*<Link*/}
              {/*    to='/auth/forgot-password'*/}
              {/*    className='link-primary fs-6 fw-bolder'*/}
              {/*    style={{marginLeft: '5px'}}*/}
              {/*>*/}
              {/*  Forgot Password ?*/}
              {/*</Link>*/}
              {/* end::Link */}
            </div>
          </div>
          <div className='position-relative'>
            <input
                placeholder={intl.formatMessage({ id: 'LABEL.PASSWORD' })}
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                    'form-control form-control-lg form-control-solid',
                    // {
                    //   'is-invalid': formik.touched.password && formik.errors.password,
                    // },
                    // {
                    //   'is-valid': formik.touched.password && !formik.errors.password,
                    // }
                )}
            />
            <button type='button' className='btn p-0 position-absolute top-50 end-0 translate-middle-y me-5' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <i className='fas fa-eye-slash fs-2 text-muted' /> 
              ) : (
                <i className='fas fa-eye fs-2 text-muted' /> 
              )}
            </button>
          </div>
            <div className="row mt-3">
                <div className="col">
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col">
                    <div className="text-end">
                        <Link
                            to='/auth/forgot-password'
                            className='color-orange-nashta fs-6 fw-bolder'
                            style={{ marginLeft: '5px' }}
                        >
                            {intl.formatMessage({ id: 'LABEL.FORGOT_PASSWORD' })}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        {/* end::FormAddEdit group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'> {intl.formatMessage({ id: 'BTN.LOGIN' })}</span>}
            {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>

          {/* begin::Separator */}
          {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
          {/* end::Separator */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
          {/* end::Google link */}
        </div>
        {/* end::Action */}
      </form>
  )
}

