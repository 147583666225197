/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from "react-intl";

const QuickLinks: FC = () => (
    <div
        className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
        data-kt-menu='true'
    >
      <div
          className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold mb-3'>{useIntl().formatMessage({id: 'LABEL.QUICK_LINK'})}</h3>

        {/*<span className='badge bg-primary py-2 px-3'>25 pending tasks</span>*/}
      </div>

      <div className='row g-0'>
        <div className='col-6'>
          <a
              href='https://hr.talenta.co/' target="_blank"
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <img alt={'PH'} src={'/media/products/talenta.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Talenta</span>
            {/*<span className='fs-7 text-gray-400'>eCommerce</span>*/}
          </a>
        </div>

        <div className='col-6'>
          <a
              href='http://project.indostorage.com/' target="_blank"
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
          >
            <img alt={'PH'} src={'/media/products/proof-hub.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Proof Hub</span>
            {/*<span className='fs-7 text-gray-400'>Console</span>*/}
          </a>
        </div>

        <div className='col-6'>
          <a href='https://sipp.bpjsketenagakerjaan.go.id/' target="_blank" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
            <img alt={'PH'} src={'/media/products/bpjstk.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'>BPJSTK</span>
            {/*<span className='fs-7 text-gray-400'>Pending Tasks</span>*/}
          </a>
        </div>

        <div className='col-6'>
          <a href='#https://edabu.bpjs-kesehatan.go.id/Edabu/Home/Login' target="_blank" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <img alt={'PH'} src={'/media/products/bpjs-kesehatan.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'>BPJS Kesehatan</span>
            {/*<span className='fs-7 text-gray-400'>Latest cases</span>*/}
          </a>
        </div>
        <div className='col-6'>
          <a href='#' target="https://bnidirect.bni.co.id/" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <img alt={'PH'} src={'/media/products/bni.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'><strong>BNI</strong>Direct</span>
            {/*<span className='fs-7 text-gray-400'>Latest cases</span>*/}
          </a>
        </div>
        <div className='col-6'>
          <a href='#' target="https://ui-login.oss.go.id/login" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <img alt={'PH'} src={'/media/products/oss.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'><strong>OSS</strong></span>
            {/*<span className='fs-7 text-gray-400'>Latest cases</span>*/}
          </a>
        </div>
        <div className='col-6'>
          <a href='#' target="https://wajiblapor.kemnaker.go.id/" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <img alt={'PH'} src={'/media/products/kemenaker.png'} className={'w-50'}/>
            <span className='fs-5 fw-bold text-gray-800 mb-0'><strong>WLKP</strong></span>
            {/*<span className='fs-7 text-gray-400'>Latest cases</span>*/}
          </a>
        </div>
      </div>

      <div className='py-2 text-center border-top'>
        {/*<Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>*/}
        {/*  View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />*/}
        {/*</Link>*/}
      </div>
    </div>
)

export {QuickLinks}
