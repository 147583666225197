/* eslint-disable react-hooks/exhaustive-deps */
import {KTSVG} from '../../../_metronic/helpers'
import React, {ButtonHTMLAttributes, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Modal} from 'bootstrap'
import {useIntl} from 'react-intl'
import {ButtonProps, MetroButton} from '../'

let zi = 1555

interface ModalProps {
  resizeable?: boolean
  visible?: boolean
  confirmLoading?: boolean
  title?: React.ReactNode | string
  closable?: boolean
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  afterClose?: () => void
  onDismiss?: () => void
  centered?: boolean
  width?: string | number
  footer?: React.ReactNode
  footerAdditionalButton?: React.ReactNode
  okText?: React.ReactNode
  // okType?: LegacyButtonType;
  cancelText?: React.ReactNode
  maskClosable?: boolean
  forceRender?: boolean
  okButtonProps?: ButtonProps
  cancelButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
  destroyOnClose?: boolean
  style?: React.CSSProperties
  wrapClassName?: string
  maskTransitionName?: string
  transitionName?: string
  className?: string
  headerClassName?: string
  titleColor?: string
  classNameDialog?: string
  zIndex?: number
  size?: 'large' | 'small' | 'medium' | 'extra-large' | 'fullscreen'
  bodyStyle?: React.CSSProperties
  maskStyle?: React.CSSProperties
  mask?: boolean
  keyboard?: boolean
  wrapProps?: any
  prefixCls?: string
  closeIcon?: React.ReactNode
  modalRender?: (node: React.ReactNode) => React.ReactNode
  focusTriggerAfterClose?: boolean
  children?: JSX.Element | JSX.Element[]
}
const MetroModal: React.FC<ModalProps> = ({
  resizeable,
  className = '',
  titleColor = '',
  headerClassName = '',
  onDismiss = () => {},
  visible,
  children,
  classNameDialog,
  footer,
  footerAdditionalButton,
  title = 'Modal',
  onCancel = () => {},
  onOk = () => {},
  size,
  closable,
  cancelButtonProps,
  okButtonProps,
  okText,
  cancelText,
  destroyOnClose,
  zIndex,
}) => {
  const [isMaximize, setIsMaximize] = useState(false)
  const [sizeState, setSizeState] = useState(size)
  const siz = {
    large: 'lg',
    small: 'sm',
    'extra-large': 'xl',
    medium: 'md',
    fullscreen: 'fullscreen',
  }
  // @ts-ignore
  const ms = sizeState ? `modal-${siz[sizeState]}` : ''
  const [loadingButtonOk, setLoadingButtonOk] = useState(false)
  const intl = useIntl()
  const ref = useRef(Math.random().toString(36))
  const modalRef = useRef(null)
  const evt = useRef(false)
  const modalId = 'kt_modal_' + ref.current
  // @ts-ignore
  useEffect(() => {
    zi += 1
  }, [])

  useLayoutEffect(() => {
    const ca =
      closable === false
        ? {
            backdrop: 'static',
            keyboard: false,
          }
        : {}
    try {
      const el = document.getElementById(modalId)
      if (!modalRef.current) {
        // @ts-ignore
        modalRef.current = new Modal(el, {
          ...ca,
        })
      }
      const modal = modalRef.current as any
      !evt.current &&
        el?.addEventListener('hidden.bs.modal', function (event) {
          // console.log('hidden.bs.modal')

          onDismiss()
        })
      evt.current = !!el
      visible ? modal.show() : modal.hide()
    } catch (e) {}
  }, [visible])

  useEffect(() => {
    if (visible && closable) {
      const ca = !closable
        ? {
            backdrop: 'static',
            keyboard: false,
          }
        : {}
      // @ts-ignore
      modalRef.current.options = ca
    }
  }, [visible, closable])

  return (
    <div
      className={`modal fade ${className}`}
      tabIndex={-1}
      id={modalId}
      data-keyboard='false'
      data-backdrop='static'
      style={{zIndex: zIndex || zi}}
    >
      <div className={`modal-dialog ${ms + ' ' + classNameDialog}`}>
        <div className='modal-content'>
          <div className={`modal-header ${headerClassName}`}>
            <h5 className={`modal-title w-100 ${titleColor}`}>{title}</h5>
            {size !== 'extra-large' && (
              <div hidden={!resizeable}>
                {isMaximize ? (
                  <div
                    className='btn btn-icon  btn-sm btn-active-light-primary ms-2'
                    onClick={() => {
                      setSizeState(size)
                      setIsMaximize(false)
                    }}
                  >
                    <i className='fad fa-compress fs-4' />
                  </div>
                ) : (
                  <div
                    className='btn btn-icon  btn-sm btn-active-light-primary ms-2'
                    onClick={() => {
                      setSizeState('fullscreen')
                      setIsMaximize(true)
                    }}
                  >
                    <i className='fad fa-expand fs-4' />
                  </div>
                )}
              </div>
            )}

            {closable !== false ? (
              <div
                className='btn btn-icon  btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            ) : null}
          </div>
          {destroyOnClose ? (
            <div className='modal-body'>{visible ? children : null}</div>
          ) : (
            <div className='modal-body'>{children}</div>
          )}

          {!!footer ? (
            footer
          ) : (
            <div className='modal-footer'>
              <>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={(e) => onCancel(e)}
                  {...cancelButtonProps}
                >
                  {cancelText ? cancelText : intl.formatMessage({id: 'LABEL.CLOSE'})}
                </button>
                {/*// @ts-ignore*/}
                <MetroButton
                  loading={loadingButtonOk}
                  onClick={async (e) => {
                    setLoadingButtonOk(true)
                    if (onOk) {
                      try {
                        const data = await onOk(e)
                        ;(modalRef.current as any).hide()
                        setLoadingButtonOk(false)
                        console.log('data', data)
                        setLoadingButtonOk(false)
                      } catch (e) {
                        setLoadingButtonOk(false)
                        console.error(e)
                      }
                    }
                  }}
                  {...okButtonProps}
                >
                  {okText ? okText : intl.formatMessage({id: 'LABEL.OK'})}
                </MetroButton>
                {footerAdditionalButton}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {MetroModal}
