import React from 'react'
import {iconList, MetroModal} from '../'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
const ModalIcon = (props: {
  visible: boolean
  setVisible: (visible: boolean) => void
  onIconSelect?: (icon: any) => void
}) => {
  const intl = useIntl()
  const _groupIcon = () => {
    return iconList.reduce((r, a) => {
      const name =
        a.group_name === null
          ? 'none'
          : a.group_name.toLowerCase().replace(' ', '_');
      // @ts-ignore
      r[name] = [...(r[name] || []), a];
      return r;
    }, {});
  };

  return (
    <>
      <MetroModal
        okButtonProps={{style: {display: 'none'}}}
        size={'large'}
        onDismiss={() => {
          props.setVisible(false)
        }}
        visible={props.visible}
        title={intl.formatMessage({id: 'LABEL.ICON'})}
      >
        {
          Object.keys(_groupIcon()).map(key => {
            return (
              <div key={key}>
                <h3 className='fw-bold text-dark mb-7'>{key.toUpperCase()}</h3>
                <div className='rounded border p-10 mb-20'>
                  <div className='row'>
                    {
                      // @ts-ignore
                      _groupIcon()[key].map(el => {
                        return (
                          <div key={el.number.toString()} className='col-md-6 col-lg-4 d-flex align-items-stretch'>
                            <div className='d-flex flex-grow-1 bg-hover-light py-6 px-6 rounded overlay'>
                              <div className='d-flex flex-grow-1 align-items-center overlay-wrapper'>
                                <div className='mr-4 flex-shrink-0 text-lef w-40px'>
                                  <KTSVG path={el.value} className={'svg-icon-muted svg-icon-2hx'} />
                                </div>
                                <div className='text-muted'>{(() => {
                                  let val = el.value.split('/')
                                  return val[val.length - 1]
                                })()}</div>
                              </div>
                              <div className='overlay-layer overlay bg-gray-500 bg-opacity-25 rounded d-flex svg-icon'>
                                <button className='btn btn-primary fw-bold py-2 px-4 fs-7 me-3' onClick={() => {
                                  props.onIconSelect && props.onIconSelect(el)
                                  props.setVisible(false)
                                }}>{intl.formatMessage({id: 'BTN.SELECT'})}</button>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
              </div>
            )
          })
        }
      </MetroModal>
    </>
  )
}
export {ModalIcon}
