import React from 'react'
import {MetroHtmlRenderer} from '../MetroHtmlRenderer'

const FormErrorMessage = (props: {formik: any, name: string}) => {
  const messageArray = (error: any) => {
    let msg = ''
    try {
      error.forEach((el: any, index: number) => {
        if (el && typeof el === 'object') {
          msg += ''
          Object.keys((el)).forEach((key: string) => {
            msg += 'Data ' + (index + 1) + ' ' + el[key] + '<br />'
          })
        } else if (typeof el === 'string') {
          msg += 'Data ' + (index + 1) + ' ' + el  + '<br />'
        }
      })
    } catch (e) {
      msg = JSON.stringify(error)
    }
    return msg
  }
  const message = props.formik.errors[props.name]
  // console.log('message', message)
  const msg = typeof message === 'string' ? message : messageArray(message)
    // [null,{"col_val2":"Amount"}]

  return (
    <>
      {/*// @ts-ignore*/}
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className='text-danger mt-2'>
          {/*// @ts-ignore*/}
          <div className='fv-help-block'>
            <MetroHtmlRenderer html={msg} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export {FormErrorMessage}
