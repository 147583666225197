import React from 'react'
import {MetroSelect} from '../MetroSelect'

interface FormikSelectProps {
  options: any
  field: any
  form: any
  sizeInput?: 'small' | 'large'
}
export function FormikSelect(props: FormikSelectProps) {
  const {field, form, options} = props
  const fixProps = {
    ...props,
  }
  delete fixProps.field
  delete fixProps.form
  return (
    <MetroSelect
      // @ts-ignore
      onChange={({value}) => form.setFieldValue(field.name, value)}
      name={field.name}
      onBlur={field.onBlur}
      value={options ? options.find((el: {value: any}) => el.value === field.value) : ''}
      sizeInput={'large'}
      {...props}
    />
  )
  // return (
  //   <Select
  //     // @ts-ignore
  //     className={`form-select ${ss[sizeInput] || ''} form-select-solid form-select2__container p-0`}
  //     classNamePrefix={'form-select2'}
  //     onChange={({value}) => form.setFieldValue(field.name, value)}
  //     options={options}
  //     theme={(theme) => ({
  //       ...theme,
  //       minHeight: '42.8px',
  //       colors: {
  //         ...theme.colors,
  //       },
  //     })}
  //     name={field.name}
  //     onBlur={field.onBlur}
  //     value={options ? options.find((el: {value: any}) => el.value === field.value) : ''}
  //   />
  // )
}
