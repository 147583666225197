/* eslint-disable react-hooks/exhaustive-deps */
import {usePageData} from '../../../_metronic/layout/core'
import React, {useEffect} from 'react'

const MetroToolbarAction = (props: {children: React.ReactNode}) => {
  const {setToolbarAction} = usePageData()
  useEffect(() => {
    if (props.children) {
      setToolbarAction(props.children)
    }
    return () => {
      setToolbarAction(null)
    }
  }, [props.children])

  return <></>
}
export {MetroToolbarAction}
