/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useChooseModal, useFetch, ChooseComponentModal} from '../'
import {getHrEmployeeApiV1} from '../../apis'
import {useIntl} from 'react-intl'
import config from '../../config'
import {deleteNegativeValueInObject} from '../../utils'

const useHookModalHrEmployeeInfoWithFilter = (
  {hideRowForId}: {hideRowForId: any[]},
  filter: any
) => {
  console.log(filter)
  console.log(hideRowForId)
  const intl = useIntl()
  const dataRef = useFetch({apiFunction: getHrEmployeeApiV1})
  const chooseModal = useChooseModal({
    hideRowForId,
    isMultiple: true,
    tablePageSize: 5,
    title: 'Employee',
    component: ChooseComponentModal,
    columns: [
      {
        title: intl.formatMessage({id: 'LABEL.NIK'}),
        dataIndex: 'nik',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.EMPLOYEE_NAME'}),
        dataIndex: 'employee_name',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
      {
        title: intl.formatMessage({id: 'LABEL.GENDER'}),
        dataIndex: 'gender_description',
        filterAble: true,
        filterType: 'text',
        filterLocal: true,
        sortAble: true,
        sortLocal: true,
      },
    ],
  })
  useEffect(() => {
    const param: any = {
      hr_job_level_id: filter,
    }
    dataRef
      .fetch({
        ...config.paramsPageAndPagination,
        ...deleteNegativeValueInObject(param),
      })
      .then()
  }, [filter])

  useEffect(() => {
    chooseModal.setIsLoading(dataRef.isLoading)
  }, [dataRef.isLoading, filter])

  useEffect(() => {
    if (dataRef.list.length > 0) {
      chooseModal.setDataSource(
        dataRef.list.map((el) => {
          const employee_name = (el.first_name + ' ' + el.last_name || '').trim()
          const gender_description =
            el.gender_id === 20
              ? intl.formatMessage({id: 'LABEL.MALE'})
              : intl.formatMessage({id: 'LABEL.FEMALE'})
          return {
            ...el,
            employee_name,
            gender_description,
          }
        })
      )
    } else {
      chooseModal.setDataSource(
        dataRef.list.map((el) => {
          const employee_name = (el.first_name + ' ' + el.last_name || '').trim()
          const gender_description =
            el.gender_id === 20
              ? intl.formatMessage({id: 'LABEL.MALE'})
              : intl.formatMessage({id: 'LABEL.FEMALE'})
          return {
            ...el,
            employee_name,
            gender_description,
          }
        })
      )
    }
  }, [dataRef.list, filter])

  return chooseModal
}

export {useHookModalHrEmployeeInfoWithFilter}
