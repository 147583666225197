/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {MenuComponent, PopperPlacement} from '../../../_metronic/assets/ts/components'
import {getComponentName, isFunction, randomString} from '../../utils'
import clsx from 'clsx'

interface MetroDropdownProps {
  children: React.ReactNode
  header: React.ReactNode | ReactElement
  title?: string | React.ReactNode
  showTitle?: boolean
  forceRender?: boolean
  onDismiss?: () => void
  className?: string
  placement?: PopperPlacement
  trigger?: 'click' | 'hover'

}
const MetroDropdown = (props: MetroDropdownProps) => {
  const menu = useRef(null) as any
  const [menuState, setMenuState] = useState(null) as any
  const [menuElement, setMenuElement] = useState(null) as any
  const {
    title,
    children,
    className = '',
    forceRender = false,
    onDismiss = () => {},
  } = props
  const id = useRef('metro-dropdown-' + randomString())
  const [show, setShow] = useState(false)
  useEffect(() => {
    const el = window.document.getElementById(id.current) as HTMLElement
    menu.current = new MenuComponent(el, {
      accordion: {expand: false, slideSpeed: 0},
      dropdown: {hoverTimeout: 0, zindex: 0},
    })
    menu.current.on('kt.menu.dropdown.show', function () {
      setShow(true)
    })
    menu.current.on('kt.menu.dropdown.hide', function () {
      setShow(false)
      onDismiss()
    })
    setMenuElement(el)
    setMenuState(menu.current)
  }, [])
  // @ts-ignore
  const content = isFunction(children) ? children(menuState, menuElement) : children

  // @ts-ignore
  const Header = props.header && React.cloneElement(props.header, {
    // @ts-ignore
    'className': clsx(props.header?.props?.className, 'cursor-pointer'),
    ...(()=> {
      // @ts-ignore
      if (getComponentName(props.header) === 'MetroButton') {
        return {
          data_kt_menu: {
            trigger: props.trigger || 'click',
            placement: props.placement || 'auto',
          }
        }
      }
      return {
        'data-kt-menu-trigger': props.trigger || 'click',
        'data-kt-menu-placement': props.placement || 'auto',
      }
    })(),

  })

  return (
    <>
      {/*// @ts-ignore*/}
      {props.header ? Header : null}
      <div id={id.current} className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px ${className}`} data-kt-menu='true'
           data-bs-auto-close="false"
           data-kt-auto-close="false">
        {!!title ? (
          <>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>{title}</div>
            </div>

            <div className='separator border-gray-200' />
          </>
        ) : null}
        <div className='px-7 py-5'>{show || forceRender ? content : null}</div>
      </div>
    </>

  )
}

export {MetroDropdown}
