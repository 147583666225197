import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {getUserAndMenu} from "../../../../source/utils";
import {UserModelNashTa} from "../../../../source/types";

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModelNashTa | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModelNashTa | undefined>>
  logout: () => void
  menuFlat: any[]
  setMenuFlat: Dispatch<SetStateAction<any[]>>
  menuRecursive: any[]
  setMenuRecursive: Dispatch<SetStateAction<any[]>>
  fcmToken: string
  setFcmToken: Dispatch<SetStateAction<string>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  menuFlat: [],
  setMenuFlat: () => {},
  menuRecursive: [],
  setMenuRecursive: () => {},
  fcmToken: '',
  setFcmToken: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModelNashTa | undefined>()
  const [menuFlat, setMenuFlat] = useState<any[]>([]);
  const [menuRecursive, setMenuRecursive] = useState<any[]>([]);
  const [fcmToken, setFcmToken] = useState<string>('');
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{
      auth,
      saveAuth,
      currentUser,
      setCurrentUser,
      logout,
      menuFlat,
      setMenuFlat,
      menuRecursive,
      setMenuRecursive,
      fcmToken,
      setFcmToken,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setMenuRecursive, setMenuFlat} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {dataMenu, dataEmployee, dataMenuRecursive, decodeAccessToken} = await getUserAndMenu(auth?.accessToken || '')
          if (dataEmployee) {
            const {
              role_id,
              role_name,
              hr_emp_info_id,
              approval_name,
            } = (decodeAccessToken?.employees || [])[0] || {}
            console.log('decodeAccessToken', decodeAccessToken)
            setCurrentUser({
                ...dataEmployee,
              role_id,
              role_name,
              user_id: decodeAccessToken.id,
              hr_emp_info_id,
              approval_name,
            })
            setMenuFlat(dataMenu)
            setMenuRecursive(dataMenuRecursive)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
