const iconList = [
  {
    number: 1,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs001.svg',
  },
  {
    number: 2,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs002.svg',
  },
  {
    number: 3,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs003.svg',
  },
  {
    number: 4,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs004.svg',
  },
  {
    number: 5,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs005.svg',
  },
  {
    number: 6,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs006.svg',
  },
  {
    number: 7,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs007.svg',
  },
  {
    number: 8,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs008.svg',
  },
  {
    number: 9,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs009.svg',
  },
  {
    number: 10,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs010.svg',
  },
  {
    number: 11,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs011.svg',
  },
  {
    number: 12,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs012.svg',
  },
  {
    number: 13,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs013.svg',
  },
  {
    number: 14,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs014.svg',
  },
  {
    number: 15,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs015.svg',
  },
  {
    number: 16,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs016.svg',
  },
  {
    number: 17,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs017.svg',
  },
  {
    number: 18,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs018.svg',
  },
  {
    number: 19,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs019.svg',
  },
  {
    number: 20,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs020.svg',
  },
  {
    number: 21,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs021.svg',
  },
  {
    number: 22,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs022.svg',
  },
  {
    number: 23,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs023.svg',
  },
  {
    number: 24,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs024.svg',
  },
  {
    number: 25,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs025.svg',
  },
  {
    number: 26,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs026.svg',
  },
  {
    number: 27,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs027.svg',
  },
  {
    number: 28,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs028.svg',
  },
  {
    number: 29,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs029.svg',
  },
  {
    number: 30,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs030.svg',
  },
  {
    number: 31,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs031.svg',
  },
  {
    number: 32,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs032.svg',
  },
  {
    number: 33,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs033.svg',
  },
  {
    number: 34,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs034.svg',
  },
  {
    number: 35,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs035.svg',
  },
  {
    number: 36,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs036.svg',
  },
  {
    number: 37,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs037.svg',
  },
  {
    number: 38,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs038.svg',
  },
  {
    number: 39,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs039.svg',
  },
  {
    number: 40,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs040.svg',
  },
  {
    number: 41,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs041.svg',
  },
  {
    number: 42,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs042.svg',
  },
  {
    number: 43,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs043.svg',
  },
  {
    number: 44,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs044.svg',
  },
  {
    number: 45,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs045.svg',
  },
  {
    number: 46,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs046.svg',
  },
  {
    number: 47,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs047.svg',
  },
  {
    number: 48,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs048.svg',
  },
  {
    number: 49,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs049.svg',
  },
  {
    number: 50,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs050.svg',
  },
  {
    number: 51,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs051.svg',
  },
  {
    number: 52,
    group_name: 'abstract',
    value: '/media/icons/duotune/abstract/abs052.svg',
  },
  {
    number: 53,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr001.svg',
  },
  {
    number: 54,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr002.svg',
  },
  {
    number: 55,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr003.svg',
  },
  {
    number: 56,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr004.svg',
  },
  {
    number: 57,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr005.svg',
  },
  {
    number: 58,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr006.svg',
  },
  {
    number: 59,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr007.svg',
  },
  {
    number: 60,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr008.svg',
  },
  {
    number: 61,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr009.svg',
  },
  {
    number: 62,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr010.svg',
  },
  {
    number: 63,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr011.svg',
  },
  {
    number: 64,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr012.svg',
  },
  {
    number: 65,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr013.svg',
  },
  {
    number: 66,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr014.svg',
  },
  {
    number: 67,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr015.svg',
  },
  {
    number: 68,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr016.svg',
  },
  {
    number: 69,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr017.svg',
  },
  {
    number: 70,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr018.svg',
  },
  {
    number: 71,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr019.svg',
  },
  {
    number: 72,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr020.svg',
  },
  {
    number: 73,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr021.svg',
  },
  {
    number: 74,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr022.svg',
  },
  {
    number: 75,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr023.svg',
  },
  {
    number: 76,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr024.svg',
  },
  {
    number: 77,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr025.svg',
  },
  {
    number: 78,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr026.svg',
  },
  {
    number: 79,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr027.svg',
  },
  {
    number: 80,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr028.svg',
  },
  {
    number: 81,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr029.svg',
  },
  {
    number: 82,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr030.svg',
  },
  {
    number: 83,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr031.svg',
  },
  {
    number: 84,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr032.svg',
  },
  {
    number: 85,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr033.svg',
  },
  {
    number: 86,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr034.svg',
  },
  {
    number: 87,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr035.svg',
  },
  {
    number: 88,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr036.svg',
  },
  {
    number: 89,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr037.svg',
  },
  {
    number: 90,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr038.svg',
  },
  {
    number: 91,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr039.svg',
  },
  {
    number: 92,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr040.svg',
  },
  {
    number: 93,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr041.svg',
  },
  {
    number: 94,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr042.svg',
  },
  {
    number: 95,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr043.svg',
  },
  {
    number: 96,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr044.svg',
  },
  {
    number: 97,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr045.svg',
  },
  {
    number: 98,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr046.svg',
  },
  {
    number: 99,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr047.svg',
  },
  {
    number: 100,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr048.svg',
  },
  {
    number: 101,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr049.svg',
  },
  {
    number: 102,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr050.svg',
  },
  {
    number: 103,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr051.svg',
  },
  {
    number: 104,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr052.svg',
  },
  {
    number: 105,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr053.svg',
  },
  {
    number: 106,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr054.svg',
  },
  {
    number: 107,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr055.svg',
  },
  {
    number: 108,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr056.svg',
  },
  {
    number: 109,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr057.svg',
  },
  {
    number: 110,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr058.svg',
  },
  {
    number: 111,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr059.svg',
  },
  {
    number: 112,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr060.svg',
  },
  {
    number: 113,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr061.svg',
  },
  {
    number: 114,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr062.svg',
  },
  {
    number: 115,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr063.svg',
  },
  {
    number: 116,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr064.svg',
  },
  {
    number: 117,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr065.svg',
  },
  {
    number: 118,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr066.svg',
  },
  {
    number: 119,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr067.svg',
  },
  {
    number: 120,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr068.svg',
  },
  {
    number: 121,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr069.svg',
  },
  {
    number: 122,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr070.svg',
  },
  {
    number: 123,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr071.svg',
  },
  {
    number: 124,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr072.svg',
  },
  {
    number: 125,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr073.svg',
  },
  {
    number: 126,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr074.svg',
  },
  {
    number: 127,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr075.svg',
  },
  {
    number: 128,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr076.svg',
  },
  {
    number: 129,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr077.svg',
  },
  {
    number: 130,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr078.svg',
  },
  {
    number: 131,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr079.svg',
  },
  {
    number: 132,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr080.svg',
  },
  {
    number: 133,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr081.svg',
  },
  {
    number: 134,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr082.svg',
  },
  {
    number: 135,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr084.svg',
  },
  {
    number: 136,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr085.svg',
  },
  {
    number: 137,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr086.svg',
  },
  {
    number: 138,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr087.svg',
  },
  {
    number: 139,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr088.svg',
  },
  {
    number: 140,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr089.svg',
  },
  {
    number: 141,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr090.svg',
  },
  {
    number: 142,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr091.svg',
  },
  {
    number: 143,
    group_name: 'arrows',
    value: '/media/icons/duotune/arrows/arr092.svg',
  },
  {
    number: 144,
    group_name: 'art',
    value: '/media/icons/duotune/art/art001.svg',
  },
  {
    number: 145,
    group_name: 'art',
    value: '/media/icons/duotune/art/art002.svg',
  },
  {
    number: 146,
    group_name: 'art',
    value: '/media/icons/duotune/art/art003.svg',
  },
  {
    number: 147,
    group_name: 'art',
    value: '/media/icons/duotune/art/art004.svg',
  },
  {
    number: 148,
    group_name: 'art',
    value: '/media/icons/duotune/art/art005.svg',
  },
  {
    number: 149,
    group_name: 'art',
    value: '/media/icons/duotune/art/art006.svg',
  },
  {
    number: 150,
    group_name: 'art',
    value: '/media/icons/duotune/art/art007.svg',
  },
  {
    number: 151,
    group_name: 'art',
    value: '/media/icons/duotune/art/art008.svg',
  },
  {
    number: 152,
    group_name: 'art',
    value: '/media/icons/duotune/art/art009.svg',
  },
  {
    number: 153,
    group_name: 'art',
    value: '/media/icons/duotune/art/art010.svg',
  },
  {
    number: 154,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod001.svg',
  },
  {
    number: 155,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod002.svg',
  },
  {
    number: 156,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod003.svg',
  },
  {
    number: 157,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod004.svg',
  },
  {
    number: 158,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod005.svg',
  },
  {
    number: 159,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod006.svg',
  },
  {
    number: 160,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod007.svg',
  },
  {
    number: 161,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod008.svg',
  },
  {
    number: 162,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod009.svg',
  },
  {
    number: 163,
    group_name: 'coding',
    value: '/media/icons/duotune/coding/cod010.svg',
  },
  {
    number: 164,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com001.svg',
  },
  {
    number: 165,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com002.svg',
  },
  {
    number: 166,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com003.svg',
  },
  {
    number: 167,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com004.svg',
  },
  {
    number: 168,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com005.svg',
  },
  {
    number: 169,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com006.svg',
  },
  {
    number: 170,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com007.svg',
  },
  {
    number: 171,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com008.svg',
  },
  {
    number: 172,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com009.svg',
  },
  {
    number: 173,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com010.svg',
  },
  {
    number: 174,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com011.svg',
  },
  {
    number: 175,
    group_name: 'communication',
    value: '/media/icons/duotune/communication/com012.svg',
  },
  {
    number: 176,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm001.svg',
  },
  {
    number: 177,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm002.svg',
  },
  {
    number: 178,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm003.svg',
  },
  {
    number: 179,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm004.svg',
  },
  {
    number: 180,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm005.svg',
  },
  {
    number: 181,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm006.svg',
  },
  {
    number: 182,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm007.svg',
  },
  {
    number: 183,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm008.svg',
  },
  {
    number: 184,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm009.svg',
  },
  {
    number: 185,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm010.svg',
  },
  {
    number: 186,
    group_name: 'ecommerce',
    value: '/media/icons/duotune/ecommerce/ecm011.svg',
  },
  {
    number: 187,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc001.svg',
  },
  {
    number: 188,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc002.svg',
  },
  {
    number: 189,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc003.svg',
  },
  {
    number: 190,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc004.svg',
  },
  {
    number: 191,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc005.svg',
  },
  {
    number: 192,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc006.svg',
  },
  {
    number: 193,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc007.svg',
  },
  {
    number: 194,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc008.svg',
  },
  {
    number: 195,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc009.svg',
  },
  {
    number: 196,
    group_name: 'electronics',
    value: '/media/icons/duotune/electronics/elc010.svg',
  },
  {
    number: 197,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil001.svg',
  },
  {
    number: 198,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil002.svg',
  },
  {
    number: 199,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil003.svg',
  },
  {
    number: 200,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil004.svg',
  },
  {
    number: 201,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil005.svg',
  },
  {
    number: 202,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil006.svg',
  },
  {
    number: 203,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil007.svg',
  },
  {
    number: 204,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil008.svg',
  },
  {
    number: 205,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil009.svg',
  },
  {
    number: 206,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil010.svg',
  },
  {
    number: 207,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil011.svg',
  },
  {
    number: 208,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil012.svg',
  },
  {
    number: 209,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil013.svg',
  },
  {
    number: 210,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil014.svg',
  },
  {
    number: 211,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil015.svg',
  },
  {
    number: 212,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil016.svg',
  },
  {
    number: 213,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil017.svg',
  },
  {
    number: 214,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil018.svg',
  },
  {
    number: 215,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil019.svg',
  },
  {
    number: 216,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil020.svg',
  },
  {
    number: 217,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil021.svg',
  },
  {
    number: 218,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil022.svg',
  },
  {
    number: 219,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil023.svg',
  },
  {
    number: 220,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil024.svg',
  },
  {
    number: 221,
    group_name: 'files',
    value: '/media/icons/duotune/files/fil025.svg',
  },
  {
    number: 222,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin001.svg',
  },
  {
    number: 223,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin002.svg',
  },
  {
    number: 224,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin003.svg',
  },
  {
    number: 225,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin004.svg',
  },
  {
    number: 226,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin005.svg',
  },
  {
    number: 227,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin006.svg',
  },
  {
    number: 228,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin007.svg',
  },
  {
    number: 229,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin008.svg',
  },
  {
    number: 230,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin009.svg',
  },
  {
    number: 231,
    group_name: 'finance',
    value: '/media/icons/duotune/finance/fin010.svg',
  },
  {
    number: 232,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen001.svg',
  },
  {
    number: 233,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen002.svg',
  },
  {
    number: 234,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen003.svg',
  },
  {
    number: 235,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen004.svg',
  },
  {
    number: 236,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen005.svg',
  },
  {
    number: 237,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen006.svg',
  },
  {
    number: 238,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen007.svg',
  },
  {
    number: 239,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen008.svg',
  },
  {
    number: 240,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen009.svg',
  },
  {
    number: 241,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen010.svg',
  },
  {
    number: 242,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen011.svg',
  },
  {
    number: 243,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen012.svg',
  },
  {
    number: 244,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen013.svg',
  },
  {
    number: 245,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen014.svg',
  },
  {
    number: 246,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen015.svg',
  },
  {
    number: 247,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen016.svg',
  },
  {
    number: 248,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen017.svg',
  },
  {
    number: 249,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen018.svg',
  },
  {
    number: 250,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen019.svg',
  },
  {
    number: 251,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen020.svg',
  },
  {
    number: 252,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen021.svg',
  },
  {
    number: 253,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen022.svg',
  },
  {
    number: 254,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen023.svg',
  },
  {
    number: 255,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen024.svg',
  },
  {
    number: 256,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen025.svg',
  },
  {
    number: 257,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen026.svg',
  },
  {
    number: 258,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen027.svg',
  },
  {
    number: 259,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen028.svg',
  },
  {
    number: 260,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen029.svg',
  },
  {
    number: 261,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen030.svg',
  },
  {
    number: 262,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen031.svg',
  },
  {
    number: 263,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen032.svg',
  },
  {
    number: 264,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen033.svg',
  },
  {
    number: 265,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen034.svg',
  },
  {
    number: 266,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen035.svg',
  },
  {
    number: 267,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen036.svg',
  },
  {
    number: 268,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen037.svg',
  },
  {
    number: 269,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen038.svg',
  },
  {
    number: 270,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen039.svg',
  },
  {
    number: 271,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen040.svg',
  },
  {
    number: 272,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen041.svg',
  },
  {
    number: 273,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen042.svg',
  },
  {
    number: 274,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen043.svg',
  },
  {
    number: 275,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen044.svg',
  },
  {
    number: 276,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen045.svg',
  },
  {
    number: 277,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen046.svg',
  },
  {
    number: 278,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen047.svg',
  },
  {
    number: 279,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen048.svg',
  },
  {
    number: 280,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen049.svg',
  },
  {
    number: 281,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen050.svg',
  },
  {
    number: 282,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen051.svg',
  },
  {
    number: 283,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen052.svg',
  },
  {
    number: 284,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen053.svg',
  },
  {
    number: 285,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen054.svg',
  },
  {
    number: 286,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen055.svg',
  },
  {
    number: 287,
    group_name: 'general',
    value: '/media/icons/duotune/general/gen056.svg',
  },
  {
    number: 288,
    group_name: 'general',
    value: '/media/icons/duotune/general/Money.svg',
  },
  {
    number: 289,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra001.svg',
  },
  {
    number: 290,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra002.svg',
  },
  {
    number: 291,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra003.svg',
  },
  {
    number: 292,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra004.svg',
  },
  {
    number: 293,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra005.svg',
  },
  {
    number: 294,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra006.svg',
  },
  {
    number: 295,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra007.svg',
  },
  {
    number: 296,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra008.svg',
  },
  {
    number: 297,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra009.svg',
  },
  {
    number: 298,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra010.svg',
  },
  {
    number: 299,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra011.svg',
  },
  {
    number: 300,
    group_name: 'graphs',
    value: '/media/icons/duotune/graphs/gra012.svg',
  },
  {
    number: 301,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay001.svg',
  },
  {
    number: 302,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay002.svg',
  },
  {
    number: 303,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay003.svg',
  },
  {
    number: 304,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay004.svg',
  },
  {
    number: 305,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay005.svg',
  },
  {
    number: 306,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay006.svg',
  },
  {
    number: 307,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay007.svg',
  },
  {
    number: 308,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay008.svg',
  },
  {
    number: 309,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay009.svg',
  },
  {
    number: 310,
    group_name: 'layouts',
    value: '/media/icons/duotune/layouts/lay010.svg',
  },
  {
    number: 311,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map001.svg',
  },
  {
    number: 312,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map002.svg',
  },
  {
    number: 313,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map003.svg',
  },
  {
    number: 314,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map004.svg',
  },
  {
    number: 315,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map005.svg',
  },
  {
    number: 316,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map006.svg',
  },
  {
    number: 317,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map007.svg',
  },
  {
    number: 318,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map008.svg',
  },
  {
    number: 319,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map009.svg',
  },
  {
    number: 320,
    group_name: 'maps',
    value: '/media/icons/duotune/maps/map010.svg',
  },
  {
    number: 321,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med001.svg',
  },
  {
    number: 322,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med002.svg',
  },
  {
    number: 323,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med003.svg',
  },
  {
    number: 324,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med004.svg',
  },
  {
    number: 325,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med005.svg',
  },
  {
    number: 326,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med006.svg',
  },
  {
    number: 327,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med007.svg',
  },
  {
    number: 328,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med008.svg',
  },
  {
    number: 329,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med009.svg',
  },
  {
    number: 330,
    group_name: 'medicine',
    value: '/media/icons/duotune/medicine/med010.svg',
  },
  {
    number: 331,
    group_name: 'other',
    value: '/media/icons/duotune/Chef.svg',
  },
  {
    number: 332,
    group_name: 'other',
    value: '/media/icons/duotune/Group.svg',
  },
  {
    number: 333,
    group_name: 'other',
    value: '/media/icons/duotune/PenAndRuller.svg',
  },
  {
    number: 334,
    group_name: 'other',
    value: '/media/icons/duotune/Price2.svg',
  },
  {
    number: 335,
    group_name: 'other',
    value: '/media/icons/duotune/Sale1.svg',
  },
  {
    number: 336,
    group_name: 'other',
    value: '/media/icons/duotune/Server.svg',
  },
  {
    number: 337,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc001.svg',
  },
  {
    number: 338,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc002.svg',
  },
  {
    number: 339,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc003.svg',
  },
  {
    number: 340,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc004.svg',
  },
  {
    number: 341,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc005.svg',
  },
  {
    number: 342,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc006.svg',
  },
  {
    number: 343,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc007.svg',
  },
  {
    number: 344,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc008.svg',
  },
  {
    number: 345,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc009.svg',
  },
  {
    number: 346,
    group_name: 'social',
    value: '/media/icons/duotune/social/soc010.svg',
  },
  {
    number: 347,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh001.svg',
  },
  {
    number: 348,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh002.svg',
  },
  {
    number: 349,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh003.svg',
  },
  {
    number: 350,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh004.svg',
  },
  {
    number: 351,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh005.svg',
  },
  {
    number: 352,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh006.svg',
  },
  {
    number: 353,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh007.svg',
  },
  {
    number: 354,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh008.svg',
  },
  {
    number: 355,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh009.svg',
  },
  {
    number: 356,
    group_name: 'technology',
    value: '/media/icons/duotune/technology/teh010.svg',
  },
  {
    number: 357,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt001.svg',
  },
  {
    number: 358,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt002.svg',
  },
  {
    number: 359,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt003.svg',
  },
  {
    number: 360,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt004.svg',
  },
  {
    number: 361,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt005.svg',
  },
  {
    number: 362,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt006.svg',
  },
  {
    number: 363,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt007.svg',
  },
  {
    number: 364,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt008.svg',
  },
  {
    number: 365,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt009.svg',
  },
  {
    number: 366,
    group_name: 'text',
    value: '/media/icons/duotune/text/txt010.svg',
  },
];
export {iconList}
