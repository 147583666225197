import {MetroButton, MetroS3UploadV2} from '../../components'
import {MetroInput, MetroInputProps} from '../MetroInput'
import {FormErrorMessage} from '../FormErrorMessage'
import React, {ChangeEventHandler, CSSProperties, InputHTMLAttributes} from 'react'
import {useIntl} from 'react-intl'
import {FormikProps} from 'formik'
import clsx from 'clsx'
import {MetroSelect, MetroSelectProps} from '../MetroSelect'
import {MetroInputNumber, MetroInputNumberProps} from '../MetroInputNumber'
import {CurrencyInputProps} from 'react-currency-input-field/dist/components/CurrencyInputProps'
import {MetroDatePicker, MetroDatePickerProps} from '../MetroDatePicker'
import {
  convertDateToString,
  deleteNegativeValueInObject,
  formattedBytes,
  formattedNumber,
} from '../../utils'
import {MetroTextArea} from '../MetroTextArea'
import {MetroCheckbox, MetroCheckboxProps} from '../MetroCheckbox'
import {MetroTextEditor, MetroTextEditorProps} from '../MetroTextEditor'
import {MetroRadio, MetroRadioProps} from '../MetroRadio'
import config from '../../config'
import {MetroBadge, MetroHtmlRenderer} from '../index'
const defaultLabelCol = 'col-lg-12 col-md-12 col-sm-12'
const defaultInputCol = 'col-lg-12 col-md-12 col-sm-12'
interface FormProps {
  formik: FormikProps<any>
  name: string
  intlLabel: string
  labelCol?: string
  inputCol?: string
  columnClassName?: string
  additionalLabel?: string | any
  showLabel?: boolean
  hideErrorMessage?: boolean
  required?: boolean
  contentStyle?: CSSProperties | undefined
  contentClassName?: string | undefined
  labelClassName?: string | undefined
  inputClassName?: string | undefined
  inputAction?: React.ReactNode
  viewOnly?: boolean
  addClassName?: string
}
interface FormInputProps<p> extends FormProps {
  onChange?: ChangeEventHandler<p> | undefined
  inputProps?: MetroInputProps & InputHTMLAttributes<any>
}
interface FormTextEditorProps extends FormProps {
  inputProps?: MetroTextEditorProps
  onChange?: (text: string) => void | undefined
}
interface FormInputCheckboxProps<p> extends FormProps {
  inputProps?: MetroCheckboxProps & InputHTMLAttributes<any>
  onChange?: ChangeEventHandler<p> | undefined
}
interface FormInputRadioGroupProps extends FormProps {
  contentGroupClassName?: string | undefined
  inputProps?: MetroRadioProps & InputHTMLAttributes<any>
  onChange?: (radioValue: {type: string; name: string; value: any}) => void
  options: any[] | never[]
}
interface FormDateProps extends FormProps {
  inputProps?: MetroDatePickerProps
  onChange?: (
    date: Date | [Date | null, Date | null] | /* for selectsRange */ null,
    event: React.SyntheticEvent<any> | undefined
  ) => void
}
interface FormInputNumberProps<p> extends FormProps {
  inputProps?: MetroInputNumberProps & CurrencyInputProps
  onChange?: ChangeEventHandler<p> | undefined
}
interface FormInputFloatProps<p> extends FormProps {
  inputProps?: MetroInputNumberProps & CurrencyInputProps
  onChange?: ChangeEventHandler<p> | undefined
}
interface FormSelectProps extends FormProps {
  options: any[] | never[]
  inputProps?: MetroSelectProps
  onChange?: (value: any) => void
}
export const FormInput: React.FC<FormInputProps<any>> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    showLabel = true,
    hideErrorMessage,
    columnClassName,
    inputProps,
    onChange,
    viewOnly,
    inputAction,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex')}>
          {viewOnly ? (
            <span className={'fs-6'}>{formik.values[name] || ''}</span>
          ) : (
            <>
              <MetroInput
                sizeInput={'large'}
                className={inputClassName}
                addClassName={clsx(inputProps?.disabled && 'cursor-not-allowed')}
                name={name}
                placeholder={inputProps?.placeholder}
                onBlur={(e) => {
                  inputProps?.onBlur && inputProps.onBlur(e)
                  formik.setFieldTouched(name, true)
                }}
                onChange={(e) => {
                  onChange && onChange(e)
                  formik.setFieldValue(name, e.target.value || '')
                }}
                value={formik.values[name] || ''}
                {...deleteNegativeValueInObject({
                  ...(inputProps as any),
                  onBlur: undefined,
                })}
              />
              {inputAction}
            </>
          )}
        </div>
        <div className={'col-12'}>
          <FormErrorMessage formik={formik} name={name} />
        </div>
      </div>
    </div>
  )
}
export const FormInputCheckbox: React.FC<FormInputCheckboxProps<any>> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-8 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
    // inputAction
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={clsx('row', contentClassName)} style={contentStyle}>
      {viewOnly ? (
        <>
          {showLabel && (
            <div className={clsx(labelCol)}>
              <label className={clsx(labelClassName, required && 'required')}>
                {label} {additionalLabel}
              </label>
            </div>
          )}
          <span className={'fs-6'}>
            {formik.values[name] ?? false
              ? intl.formatMessage({id: 'LABEL.YES'})
              : intl.formatMessage({id: 'LABEL.NO'})}
          </span>
        </>
      ) : (
        <div className={clsx(inputCol)}>
          <MetroCheckbox
            labelClassName={clsx(labelClassName, required && required)}
            label={
              showLabel
                ? intl.formatMessage({id: `LABEL.${intlLabel}`}) + ' ' + (additionalLabel || '')
                : ''
            }
            className={inputClassName}
            checked={formik.values[name] ?? false}
            onBlur={(e) => {
              inputProps?.onBlur && inputProps.onBlur(e)
              formik.setFieldTouched(name, true)
            }}
            onChange={(e) => {
              const v = e.target.checked
              onChange && onChange(e)
              formik.setFieldValue(name, !!v)
            }}
            {...deleteNegativeValueInObject({
              ...(inputProps as any),
              onBlur: undefined,
            })}
          />
        </div>
      )}
      {!hideErrorMessage && (
        <div className={'col-12'}>
          <FormErrorMessage formik={formik} name={name} />
        </div>
      )}
    </div>
  )
}

export const FormInputRadioGroup: React.FC<FormInputRadioGroupProps> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-8 fv-row',
    contentGroupClassName = '',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    showLabel = true,
    hideErrorMessage,
    options,
    inputProps,
    onChange,
    viewOnly,
    // inputAction,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={clsx('row', contentClassName)} style={contentStyle}>
      {(showLabel || intlLabel !== '') && (
        <div className={clsx(labelCol)}>
          <label className={clsx(labelClassName, required && 'required')}>
            {label} {additionalLabel}
          </label>
        </div>
      )}
      <div className={clsx(inputCol, contentGroupClassName)}>
        {viewOnly ? (
          <span className={'fs-6'}>
            {(options.find((el: any) => el.value === formik.values[name]) || {}).label}
          </span>
        ) : (
          <>
            {options.map((el: any) => {
              return (
                <MetroRadio
                  addInputClassName={inputClassName}
                  key={el.value}
                  name={name}
                  addClassName={'my-2 me-3'}
                  label={el.label}
                  checked={String(formik.values[name]) === String(el.value)}
                  onBlur={(e) => {
                    inputProps?.onBlur && inputProps.onBlur(e)
                    el.props?.onBlur && el.props.onBlur(e)
                    formik.setFieldTouched(name, true)
                  }}
                  onChange={(e) => {
                    onChange && onChange({type: 'radio', name, value: el.value})
                    formik.setFieldValue(name, el.value)
                  }}
                  {...deleteNegativeValueInObject({
                    ...(inputProps as any),
                    onBlur: undefined,
                  })}
                  {...deleteNegativeValueInObject({
                    ...(el.props as any),
                    onBlur: undefined,
                  })}
                />
              )
            })}
          </>
        )}
      </div>
      {!hideErrorMessage && (
        <div className={'col-12'}>
          <FormErrorMessage formik={formik} name={name} />
        </div>
      )}
    </div>
  )
}

export const FormInputNumber: React.FC<FormInputNumberProps<any>> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    columnClassName,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    addClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex')}>
          {viewOnly ? (
            <span className={'fs-6'}>{formattedNumber(formik.values[name] || '0')}</span>
          ) : (
            <MetroInputNumber
              sizeInput={'large'}
              className={inputClassName}
              addClassName={addClassName}
              name={name}
              placeholder={inputProps?.placeholder}
              onBlur={(e) => {
                inputProps?.onBlur && inputProps.onBlur(e)
                formik.setFieldTouched(name, true)
              }}
              onValueChange={(value: any, iname, values) => {
                // @ts-ignore
                onChange && onChange({value: value || 0, name: iname, values})
                formik.setFieldValue(name, Number(value || 0))
              }}
              value={formik.values[name]}
              {...inputProps}
            />
          )}
        </div>
        {!hideErrorMessage && (
          <div className={'col-12'}>
            <FormErrorMessage formik={formik} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}

export const FormInputFloat: React.FC<FormInputFloatProps<any>> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    columnClassName,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    addClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )

  const [tempValue, setTempValue] = React.useState(null)

  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex')}>
          {viewOnly ? (
            <span className={'fs-6'}>{formattedNumber(formik.values[name] || '0')}</span>
          ) : (
            <MetroInputNumber
              sizeInput={'large'}
              className={inputClassName}
              addClassName={addClassName}
              name={name}
              placeholder={inputProps?.placeholder}
              onBlur={(e) => {
                inputProps?.onBlur && inputProps.onBlur(e)
                formik.setFieldTouched(name, true)
                formik.setFieldValue(name, Number(tempValue || 0))

                setTempValue(null)
              }}
              onValueChange={(value: any, iname, values) => {
                // @ts-ignore
                onChange && onChange({value: value || 0, name: iname, values})

                // @ts-ignore
                setTempValue(values?.value ?? null)
              }}
              value={tempValue ?? formik.values[name]}
              {...inputProps}
            />
          )}
        </div>
        {!hideErrorMessage && (
          <div className={'col-12'}>
            <FormErrorMessage formik={formik} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}

export const FormSelect: React.FC<FormSelectProps> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    columnClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    options = [],
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  const value1 = () => {
    let v = undefined
    if (inputProps?.isMulti) {
      v = [] as any
      const initVal = formik.values[name] || []
      initVal.forEach((el: any) => {
        const val = options.find(
          (el2) => String(el2[name] || el2.value) === String(el[name] || el.value)
        )
        if (val) {
          v.push(val)
        }
      })
      if (initVal.length > 0 && v.length === 0) {
        v = initVal
      }
    } else {
      v =
        options.find((el) => String(el.value || el[name]) === String(formik.values[name])) ||
        (formik.values[name] && {
          label: formik.values[name],
          value: formik.values[name],
        })
    }
    return v
  }
  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex', {'cursor-not-allowed': inputProps?.isDisabled})}>
          {viewOnly ? (
            <span className={'fs-6'}>
              {Array.isArray(value1()) ? (
                value1().map((el: any) => {
                  return (
                    <MetroBadge
                      key={el.value}
                      light={true}
                      content={el.label || el.value}
                      className={'me-2'}
                    />
                  )
                })
              ) : (
                <MetroBadge
                  key={value1()?.value}
                  light={true}
                  content={value1()?.label || value1()?.value}
                  className={'me-2'}
                />
              )}
            </span>
          ) : (
            <MetroSelect
              className={inputClassName}
              sizeInput={'large'}
              // @ts-ignore
              value={value1()}
              onBlur={(e) => {
                inputProps?.onBlur && inputProps.onBlur(e)
                formik.setFieldTouched(name, true)
              }}
              onChange={(value: any) => {
                onChange && onChange(value)
                const v = value ? (inputProps?.isMulti ? value : value.value || value[name]) : null
                formik.setFieldValue(name, v)
              }}
              options={options}
              {...deleteNegativeValueInObject({
                ...(inputProps as any),
                onBlur: undefined,
              })}
            />
          )}
        </div>
        {!hideErrorMessage && (
          <div className={'col-12'}>
            <FormErrorMessage formik={formik} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}
export const FormDate: React.FC<FormDateProps> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    columnClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex')}>
          {viewOnly ? (
            <span className={'fs-6'}>
              {convertDateToString({
                date: formik.values[name],
                toFormat: (props.inputProps?.dateFormat as any) || config.formatDate,
              })}
            </span>
          ) : (
            <>
              <MetroDatePicker
                className={inputClassName}
                selected={formik.values[name]}
                onBlur={(e) => {
                  inputProps?.onBlur && inputProps.onBlur(e)
                  formik.setFieldTouched(name, true)
                }}
                onChange={(values, event) => {
                  onChange && onChange(values, event)
                  formik.setFieldValue(name, values)
                }}
                {...deleteNegativeValueInObject({
                  ...(inputProps as any),
                  onBlur: undefined,
                })}
              />
            </>
          )}
        </div>
        {!hideErrorMessage && (
          <div className={'col-12'}>
            <FormErrorMessage formik={formik} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}
export const FormTextArea: React.FC<FormInputProps<any>> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    columnClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={`${columnClassName}`}>
      <div className={clsx('row', contentClassName)} style={contentStyle}>
        {showLabel && (
          <div className={clsx(labelCol)}>
            <label className={clsx(labelClassName, required && 'required')}>
              {label} {additionalLabel}
            </label>
          </div>
        )}
        <div className={clsx(inputCol, 'd-flex')}>
          {viewOnly ? (
            <span className={'fs-6'}>{formik.values[name] || ''}</span>
          ) : (
            <MetroTextArea
              sizeInput={'large'}
              className={inputClassName}
              name={name}
              placeholder={inputProps?.placeholder}
              onBlur={(e) => {
                inputProps?.onBlur && inputProps.onBlur(e)
                formik.setFieldTouched(name, true)
              }}
              onChange={(e) => {
                onChange && onChange(e)
                formik.setFieldValue(name, e.target.value || '')
              }}
              value={formik.values[name] || ''}
              {...deleteNegativeValueInObject({
                ...(inputProps as any),
                onBlur: undefined,
              })}
            />
          )}
        </div>
        {!hideErrorMessage && (
          <div className={'col-12'}>
            <FormErrorMessage formik={formik} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}
export const FormTextEditor: React.FC<FormTextEditorProps> = (props) => {
  const {
    intlLabel,
    additionalLabel,
    name,
    formik,
    required,
    contentStyle,
    contentClassName = 'mb-10 fv-row',
    labelClassName = 'fs-6 fw-bold mb-1',
    labelCol = defaultLabelCol,
    inputCol = defaultInputCol,
    inputClassName,
    showLabel = true,
    hideErrorMessage,
    inputProps,
    onChange,
    viewOnly,
  } = props
  const intl = useIntl()
  const label = ['', ' '].includes(intlLabel) ? (
    <span>&nbsp;</span>
  ) : (
    intl.formatMessage({id: `LABEL.${intlLabel}`})
  )
  return (
    <div className={clsx('row', contentClassName)} style={contentStyle}>
      {showLabel && (
        <div className={clsx(labelCol)}>
          <label className={clsx(labelClassName, required && 'required')}>
            {label} {additionalLabel}
          </label>
        </div>
      )}
      <div className={clsx(inputCol, 'd-flex')}>
        {viewOnly ? (
          <span className={'fs-6'}>
            <MetroHtmlRenderer html={formik.values[name] || ''} />
          </span>
        ) : (
          <MetroTextEditor
            className={inputClassName}
            placeholder={inputProps?.placeholder}
            onBlur={(a, b, c) => {
              inputProps?.onBlur && inputProps.onBlur(a, b, c)
              formik.setFieldTouched(name, true)
            }}
            onChange={(text) => {
              onChange && onChange(text)
              formik.setFieldValue(name, text)
            }}
            value={formik.values[name] || ''}
            {...deleteNegativeValueInObject({
              ...(inputProps as any),
              onBlur: undefined,
            })}
          />
        )}
      </div>
      {!hideErrorMessage && (
        <div className={'col-12'}>
          <FormErrorMessage formik={formik} name={name} />
        </div>
      )}
    </div>
  )
}

// export const FormUpload: React.FC<FormTextEditorProps> = (props) => {
export const FormUpload: any = (props: any) => {
  const intl = useIntl()
  const {
    // intlLabel,
    // additionalLabel,
    // name,
    formik,
    // required,
    // contentStyle,
    // contentClassName = 'mb-10 fv-row',
    // labelClassName = 'fs-6 fw-bold mb-1',
    // labelCol = defaultLabelCol,
    // inputCol = defaultInputCol,
    // inputClassName,
    // showLabel = true,
    // hideErrorMessage,
    // inputProps,
    // onChange,
    // viewOnly,
  } = props
  return (
    <div className={'col-md-6 col-sm-12'}>
      <MetroS3UploadV2
        initialFileList={formik.values.attachment}
        onDelete={(response) => {
          const fld = 'attachment'
          formik.setFieldValue(
            fld,
            formik.values[fld].filter((el: any) => el.fileKeyUpload !== response.fileKey)
          )
        }}
        onSuccess={(response, file) => {
          const fld = 'attachment'
          const h = formik.values[fld]
          formik.setFieldValue(fld, [
            ...h,
            {
              uid: file.uid,
              name: file.name,
              type: file.type,
              size: file.size,
              fileKeyUpload: response.data,
              isSuccess: true,
            },
          ])
        }}
        multiple={true}
      >
        {(props) => {
          return (
            <>
              {/* <div className={'d-flex justify-content-between align-items-center'}> */}
              <div className={'row mb-10 fv-row'}>
                {/* <label className='fw-bold fs-6 text-dark required'> */}
                <label className='fs-6 fw-bold mb-1'>
                  {intl.formatMessage({id: 'LABEL.ATTACHMENT'})}
                </label>
                <MetroButton
                  className={'text-nowrap me-1'}
                  disabled={props.isUploading}
                  size={'small'}
                >
                  <i className={'fas fa-upload'} /> {intl.formatMessage({id: 'LABEL.UPLOAD'})}
                </MetroButton>
              </div>
              <span className={'text-secondary2 fs-9'}>
                Allowed file type {props.allowedFiles.join(', ')} with maximum size{' '}
                {formattedBytes(props.limitUploadSizeInByte)}
              </span>
            </>
          )
        }}
      </MetroS3UploadV2>
      <FormErrorMessage formik={formik} name={'attachment'} />
    </div>
  )
}
