import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {MetroUpload} from '../MetroUpload'
import {
    confirmDialog,
    doRefreshToken,
    downloadFileS3V2,
    errorMessage,
    fakePromise,
    formattedBytes,
    isAccessTokenExpire,
    isFunction,
    toast,
} from '../../utils'
import clsx from 'clsx'
import {deleteFileS3Api, downloadFileS3Api, urlUploadFileS3} from '../../apis'
import {UploadProps} from 'rc-upload'
import {RcFile} from 'rc-upload/es/interface'
import {useIntl} from 'react-intl'
import {MetroTooltip} from '../MetroTooltip'
import config from '../../config'
import {allowed_extension_file} from '../../types'
import {useAuth} from '../../../app/modules/auth'

interface RcFileMod extends RcFile {
    fileKeyUpload: string
    isUploading?: boolean
    isDeleting?: boolean
    isSuccess?: boolean
    isError?: boolean
    errorMessage?: string
}

const {REACT_APP_API_HEADER_KEY, REACT_APP_API_KEY, REACT_APP_PROD_SITE, NODE_ENV} = process.env
const MetroS3UploadV2 = (props: {
    marginTop?: boolean
    downloadAble?: boolean
    disabled?: boolean
    allowedFiles?: allowed_extension_file[]
    limitUploadSizeInByte?: number
    fileList?: RcFileMod[]
    initialFileList?: RcFileMod[]
    // keyFileList?: string[]
    // initialKeyFileList?: string[]
    multiple?: boolean
    hideFileListUpload?: boolean
    disableDeleteFile?: boolean
    children:
        | ReactNode
        | ((props: {
        disabled: boolean
        listFileUpload: RcFileMod[]
        allowedFiles: allowed_extension_file[]
        deleteData: (file: RcFileMod) => void
        getBlobUrl: (fileKeyUpload: string) => Promise<string>
        isUploading: boolean
        isDeleting: boolean
        limitUploadSizeInByte: number
    }) => ReactNode)
    onStart?: (file: any) => void
    onSuccess?: (response: any, file: any) => void
    onDelete?: (response: any, file: RcFileMod) => void
    onProgress?: (progress: number, file: any) => void
    onError?: (error: Error, ret: Record<string, unknown>, file: RcFile) => void
    onClick?: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void
}) => {
    const marginTop = props.marginTop ?? true
    const {auth} = useAuth()
    const refAccessToken = useRef('')
    useEffect(() => {
        refAccessToken.current = auth?.accessToken || ''
    }, [auth?.accessToken])
    const refInitialFileList = useRef(false)
    const {
        downloadAble = true,
        allowedFiles = config.allowedFiles,
        limitUploadSizeInByte = 10485760,
        disabled,
        initialFileList,
    } = props
    const intl = useIntl()
    const [listFileUpload, setListFileUpload] = useState<RcFileMod[] | null>(initialFileList || null)
    const [isUploading, setIsUploading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const uploadProps = {
        accept: allowedFiles.map((f) => `.${f}`).join(','),
        disabled,
        onClick: props.onClick,
        multiple: props.multiple ?? false,
        action: urlUploadFileS3,
        method: 'POST' as any,
        headers: {
            Authorization: `Bearer ${refAccessToken.current}` as any,
            ...(() => {
                const apiKey = {}
                if (NODE_ENV === 'production' && window.location.host === REACT_APP_PROD_SITE) {
                    // @ts-ignore
                    apiKey[REACT_APP_API_HEADER_KEY] = REACT_APP_API_KEY
                }
                return apiKey
            })(),
        },
        async beforeUpload(file, fileList) {
            if (isAccessTokenExpire(refAccessToken.current)) {
                const {accesstoken} = await doRefreshToken()
                if (accesstoken !== refAccessToken.current) {
                    refAccessToken.current = accesstoken
                    // @ts-ignore
                    uploadProps.headers.Authorization = `Bearer ${accesstoken}`
                    await fakePromise(1500)
                }
            }
            let isReject = false
            let messageError = 'Unknown Error'
            const manipulateData = () => {
                const newData = [
                    {
                        uid: file.uid,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        fileKeyUpload: null,
                        isUploading: !isReject,
                        isDeleting: false,
                        isSuccess: false,
                        isError: isReject,
                        errorMessage: isReject ? messageError : null,
                    },
                ] as any
                if (props.multiple) {
                    setListFileUpload((prevState) => {
                        return [...(prevState || []), ...newData]
                    })
                } else {
                    setListFileUpload(newData)
                }
            }
            if (limitUploadSizeInByte > 0) {
                if (file.size > props.limitUploadSizeInByte!) {
                    isReject = true
                    messageError = intl.formatMessage(
                        {id: 'MESSAGE.FILE_CANT_BE_MORE_THAN'},
                        {size: formattedBytes(props.limitUploadSizeInByte!)}
                    )
                }
            }

            const check_extension: boolean[] = []
            allowedFiles.forEach((ext) => {
                if (file.type.includes(ext)) {
                    check_extension.push(true)
                }
            })
            if (check_extension.length === 0) {
                isReject = true
                messageError = intl.formatMessage(
                    {id: 'MESSAGE.EXTENSION_WITH_NOT_ALLOWED'},
                    {extension: file.type}
                )
            }

            manipulateData()

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (isReject) {
                        reject(new Error(messageError))
                    } else {
                        resolve(file)
                    }
                }, 1000)
            })
        },
        onStart(file: any) {
            props.onStart && props.onStart(file)
        },
        onSuccess(res: any, file) {
            const aaa = listFileUpload || []
            const index = aaa.findIndex((el) => el.uid === file.uid)
            aaa[index] = {
                ...aaa[index],
                fileKeyUpload: res.data,
                isSuccess: true,
                isUploading: false,
            }
            props.onSuccess && props.onSuccess(res, aaa[index])
            setListFileUpload([...aaa])
        },
        onError(err: any, record, file) {
            const aaa = listFileUpload || []
            const index = aaa.findIndex((el) => el.uid === file.uid)
            aaa[index] = {
                ...aaa[index],
                isSuccess: false,
                isUploading: false,
                isError: true,
                errorMessage: record?.message || err.message,
            }
            props.onError && props.onError(err, record, file)
            setListFileUpload([...aaa])
        },
        onProgress(percent: any, file: any) {
            props.onProgress && props.onProgress(percent, file)
        },
        // customRequest({
        //   action,
        //   data,
        //   file,
        //   filename,
        //   headers,
        //   onError,
        //   onProgress,
        //   onSuccess,
        //   withCredentials,
        // }: any) {
        //   const fileKey = randomString(8) + '_' + file.name
        //   setFileKeyUpload(fileKey)
        //
        // },
    } as UploadProps

    useEffect(() => {
        if (props.fileList) {
            setListFileUpload(props.fileList)
        }
    }, [props.fileList])

    useEffect(() => {
        if (
            initialFileList &&
            initialFileList.length > 0 &&
            listFileUpload?.length === 0 &&
            !refInitialFileList.current
        ) {
            refInitialFileList.current = true
            setListFileUpload(initialFileList)
        }
    }, [initialFileList, refInitialFileList.current, listFileUpload])

    const deleteData = async (file: RcFileMod) => {
        if (file.isUploading || file.isDeleting) {
            return
        }
        const deleteFile = async (data: any) => {
            await fakePromise(1000)
            props.onDelete && props.onDelete(data, file)
            const aaa = listFileUpload || []
            setListFileUpload([...aaa.filter((el2) => el2.uid !== file.uid)])
        }
        try {
            if (file.isError || !file.fileKeyUpload) {
                await deleteFile({
                    file,
                    fileKey: file.fileKeyUpload,
                    success: true,
                    isFileFailedUpload: file.isError,
                })
                return
            }
            await confirmDialog({
                onOk: async () => {
                    const aaa = listFileUpload || []
                    const index = aaa.findIndex((el2) => el2.uid === file.uid)
                    aaa[index] = {
                        ...aaa[index],
                        isDeleting: true,
                    }
                    setListFileUpload([...aaa])

                    const {data} = await deleteFileS3Api(file.fileKeyUpload)
                    await deleteFile({file, fileKey: file.fileKeyUpload, success: true, ...data})
                },
            })
        } catch (e) {
            toast.danger(errorMessage(e))
        }
    }
    const getBlobUrl = (fileKeyUpload: string) =>
        new Promise(async (resolve, reject) => {
            try {
                const urlCreator = window.URL || window.webkitURL
                const response = await downloadFileS3Api(fileKeyUpload)
                resolve(urlCreator.createObjectURL(new Blob([response.data])))
            } catch (e) {
                reject(e)
            }
        })
    //@ts-ignore
    const content = isFunction(props.children) ? props.children({
            listFileUpload,
            deleteData,
            isUploading,
            isDeleting,
            allowedFiles,
            limitUploadSizeInByte,
            disabled,
            getBlobUrl,
        })
        : props.children

    useEffect(() => {
        const d = listFileUpload || []
        setIsUploading(d.map((el) => el.isUploading).includes(true))
        setIsDeleting(d.map((el) => el.isDeleting).includes(true))
    }, [listFileUpload])

    // @ts-ignore
    return (
        <>
            {/*.png, .jpg, .jpeg*/}
            <MetroUpload {...uploadProps}>{content}</MetroUpload>
            {!props.hideFileListUpload && listFileUpload && (
                <div className={`${marginTop && "mt-3"}`}>
                    {listFileUpload?.map((el, index) => {
                        return (
                            <div
                                key={el.uid}
                                className={clsx(
                                    'd-flex align-items-center fs-7',
                                    //@ts-ignore
                                    // el.isSuccess && 'text-blue',
                                    el.isError ? 'text-danger' : 'text-blue'
                                )}
                            >
                                <span className={'text-reset pe-1'}>{index + 1}.</span>
                                <span className='text-blue'>
                  {el.isUploading || el.isDeleting ? (
                      <i className='fas fa-spinner fa-spin text-reset'/>
                  ) : (
                      <i className={'fas fa-file text-reset'}/>
                  )}
                </span>
                                <MetroTooltip tooltip={el.errorMessage}>
                  <span
                      className={clsx(
                          'text-reset d-block px-2 my-2 text-truncate',
                          downloadAble && 'cursor-pointer'
                      )}
                      onClick={async () => {
                          if (!downloadAble) {
                              return
                          }
                          try {
                              if (el.isError || !(el.fileKeyUpload || el.name)) {
                                  return
                              }
                              await downloadFileS3V2({file: el.fileKeyUpload || el.name})
                          } catch (e) {
                          }
                      }}
                  >
                    {el.isError ? '[Failed Upload] ' + el.name : el.name}
                  </span>
                                </MetroTooltip>

                                {
                                    //@ts-ignore
                                    (el.isSuccess || el.isError) && (!props.disableDeleteFile || !props.disabled) && (
                                        <span
                                            onClick={async () => {
                                                await deleteData(el)
                                            }}
                                            className={clsx(
                                                (el.isSuccess || el.isError) && 'cursor-pointer text-primary',
                                                (el.isUploading || el.isDeleting) && 'cursor-wait'
                                            )}
                                        >
                      <i className='fas fa-trash-alt text-reset'/>
                    </span>
                                    )
                                }
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}
export {MetroS3UploadV2}
