/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, ReactNode, useContext, useState} from 'react'
import {finance_out_ap} from '../../index'
import {Outlet, Route, Routes} from 'react-router-dom'
import {MetroCard} from '../../../components'
import HeaderMenus from './components/HeaderMenus'
import {getAccPayableJasperApi} from '../../../apis'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {downloadFileS3V2, errorMessage, toast} from "../../../utils";
import {SuspensedView} from "../../../../app/routing/PrivateRoutes";
interface OutstandingApModel {
  action?: React.ReactNode
  setAction: (_node: (() => JSX.Element) | React.ReactNode) => void
}

const OutstandingContext = createContext<OutstandingApModel>({
  action: null,
  setAction: (_node: (() => JSX.Element) | React.ReactNode) => {}
})

export function useOutstandingAp() {
  return useContext(OutstandingContext)
}

export const OutstandingProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [action, setAction] = useState<React.ReactNode>(null)
  const value: OutstandingApModel = {
    action,
    setAction,
  }
  return <OutstandingContext.Provider value={value}>{children}</OutstandingContext.Provider>
};
// function usePrevious(value: any) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

export const getAccPayableJasper = async (val: any) => {
  await getAccPayableJasperApi(encodeURIComponent(val))
    .then((res: any) =>{
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    })
    .catch((e) => {
      toast.danger(errorMessage(e))
    })
}

export const downloadAttachment = async (val: any) => {
    downloadFileS3V2({file: val})
}
const EmptyRoute = () => {
    const intl = useIntl()
    return <>
        {
            window.location.pathname === '/finance/outstanding-ap' && (
                <div className='card-px text-center py-20 my-10'>
                    <p className='text-gray-400 fs-4 fw-bold mb-10'>
                        {intl.formatMessage({id: 'MESSAGE.OUT_AP'})}
                        <br />
                        {intl.formatMessage({id: 'MESSAGE.OUT_AP2'})}
                    </p>
                </div>
            )
        }
    </>
}
const OutStandingApContent:React.FC = () => {
  const {action} = useOutstandingAp()
  return (
    <MetroCard>
      <MetroCard.Header className={clsx(action && 'out-ap-header-card-sticky')}>
        <MetroCard.Header.Title>
          Account Payable
        </MetroCard.Header.Title>
        {
          action && (<MetroCard.Header.Toolbar>
            {action}
          </MetroCard.Header.Toolbar>)
        }
      </MetroCard.Header>
      <MetroCard.Body>
        <HeaderMenus />
        <Outlet />
      </MetroCard.Body>
    </MetroCard>
  )
}

const OutApRoute = () => (
    <Routes>
        <Route element={<OutStandingApContent />}>
            {
                finance_out_ap.map(({element: Component, path}) => {
                    return (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <SuspensedView>
                                    <Component />
                                </SuspensedView>
                            }
                        />
                    )
                })
            }
            <Route path='*' element={
                <SuspensedView>
                    <EmptyRoute />
                </SuspensedView>}
            />
            {/*<EmptyRoute />*/}
        </Route>
    </Routes>
)
export default function OutstandingApV2() {
  return (
    <OutstandingProvider>
      <OutApRoute />
    </OutstandingProvider>
  )
}
