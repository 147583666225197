/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {NewPassword} from "./components/NewPassword";

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-body')
        return () => {
            document.body.classList.remove('bg-body')
        }
    }, [])

    return (
        <>
            <div className="row no-bs-gutter-x">
                <div className="col-xl-6 max-400" style={{
                    backgroundImage: "url(" + toAbsoluteUrl('/media/illustrations/Login_Kiri_NNet_18_03_24.png') + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh'
                }}>
                    <div
                        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                    >
                        {/* <img alt='Side Background Nashta' src={toAbsoluteUrl('/media/illustrations/LoginKiri.png')} /> */}
                        {/* begin::Content */}

                    </div>
                </div>
                <div className="col-xl-6" style={{
                    backgroundImage: "url(" + toAbsoluteUrl('/media/illustrations/Login_Kanan_NNet_18_03_24.png') + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{height: '100vh'}}
                         className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                            {/* begin::Logo */}
                            <a href='#' className='mb-5'>
                            </a>
                            {/* end::Logo */}
                            {/* begin::Wrapper */}
                            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                                <Outlet/>
                            </div>
                            {/* end::Wrapper */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout/>}>
            <Route path='login' element={<Login/>}/>
            <Route path='registration' element={<Registration/>}/>
            <Route path='forgot-password' element={<ForgotPassword/>}/>
            <Route path='reset-password' element={<NewPassword/>}/>
            <Route index element={<Login/>}/>
        </Route>
    </Routes>
)

export {AuthPage}
